/*
* 업무구분: 해지신청
* 화 면 명: MSPTS201M
* 화면설명: 해지신청
* 접근권한: 
* 작 성 일: 2023.04.06
* 작 성 자: 김경태
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="해지신청" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <mo-decimal-field v-model="acno" numeric mask="#######-###" @input="fn_AcnoChange" maxlength="11" class="w130 input-account" :class="{'comp': acno.length > 9}" clearable />
                  <mo-dropdown :items="accSeqItems" @input="fn_AcnoSeqSearch" v-model="accSeq" class="w250" placeholder=" "/>
                  <mo-button @click="fn_OpenMSPTS206P()" class="btn-pop-download"> </mo-button>
                  <mo-text-field v-model="cnm" class="w130" disabled/>
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 일련번호 </label>
                  <mo-dropdown :items="offrSeqItems" v-model="offrSeq" @input="fn_OffrSeqChange" class="w80" placeholder=" "/>
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p"></div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_Init" :disabled="btnInit"> 초기화 </mo-button>
                  <mo-button @click="fn_Search" primary :disabled="btnSearch"> 조회 </mo-button>
                  <!--<mo-button class="w61" @click="fn_checkProcess" > 전자서식 </mo-button>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-button result">
          <mo-button @click="fn_Admission" v-if="visBtnAdmission"> {{ btnAdmissionName }} </mo-button>
          <mo-button @click="fn_PerformReAmlYn"> AML </mo-button>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table">
          <table class="table col-type w50p col2030">
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 해지구분 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-dropdown :items="tmtlTcItems" v-model="dsDetail.tmtlTc" :disabled="disTmtlTc" @input="fn_TmtlTcChange" placeholder="선택하세요"  />
                  </div>
                </td>
                <th>
                  <span> 승인상태 </span>
                </th>
                <td>
                  <div class="wrap-input row" >
                    <mo-dropdown :items="aprvBusnTc1Items" v-model="dsDetail.aprvBusnTc1" disabled placeholder=" "  />
                    <mo-dropdown :items="aprvStatTcItems" v-model="dsDetail.aprvStatTc" disabled placeholder=" "  />
                    <mo-button :disabled="btnAdmMemoInfo" class="btn-helpbox" id="location1" anchor> ? </mo-button>
                    <mo-tooltip target-id="location1" class="fts-tooltip">
                      <P class="fts-tooltip-tit">승인(반려)사유</p>
                      <div class="fts-tooltip-content">
                        {{ }}
                      </div>
                    </mo-tooltip>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span :class="{'emphasis': dsDetail.tmtlTc === '02'}"> 해지유형구분 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-dropdown :items="otxtTmtlTcItems" v-model="dsDetail.otxtTmtlTc" :disabled="disOtxtTmtlTc" @input="fn_otxtTmtlTcChange" placeholder="선택하세요" />
                  </div>
                </td>
                <th>
                  <span> 만기일 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-date-picker v-model="dsDetail.mtrtDate" :disabled="disMtrtDate" mask="####-##-##" class="input-long" :bottom="false" />
                  </div>
                </td>
              </tr>
              <tr>
              <tr>
                <th>
                  <span> 성과보수 </span>
                </th>
                 <td>
                  <div class="wrap-input">
                    <mo-dropdown :items="rufeCnfmYnItems" v-model="dsDetail.rufeCnfmYn" :disabled="disRufeCnfmYn" placeholder="선택하세요"  />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 출금가능일 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-date-picker v-model="dsDetail.tmtlSchdDate" :disabled="disTmtlSchdDate" class="input-long" :bottom="false" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 체결일 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-date-picker v-model="dsDetail.tmtlOffrDate" :disabled="disTmtlOffrDate" class="input-long" :bottom="false" />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 해지금액 </span>
                </th>
                <td>
                 <div class="wrap-input row">
                    <mo-decimal-field v-model="dsDetail.tmtlAmt" :disabled="disTmtlAmt" class="input-long" mask="number" numeric />
                    <mo-text-field class="w80" v-model="dsDetail.curC" disabled />
                  </div>
                </td>
              </tr>
             <tr>
                <th>
                  <span> 기본보수율 </span>
                </th>
                <td>
                 <div class="wrap-input row">
                    <mo-text-field v-model="dsDetail.basTfrt" :disabled="disBasTfrt" class="input-long" mask="number" />
                    %
                  </div>
                </td>
                <th rowspan="3">
                  <span> 중도해지<br>수수료율 </span>
                </th>
                <td>
                  <!-- 중도해지 수수료율1 -->
                  <div class="wrap-input row">
                    1. <mo-text-field v-model="dsDetail.mdtmEndDds01" class="w50 input-text-right" disabled/> 일 미만 해지시
                    <mo-text-field v-model="dsDetail.mdtmFert01" class="w50 input-text-right" disabled/> %
                  </div>
                </td>
              </tr>
             <tr>
                <th>
                  <span> 해지사유 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-text-field v-model="dsDetail.tmtlRsn" class="input-long" :disabled="disTmtlRsn" />
                  </div>
                </td>
                <td>
                  <!-- 중도해지 수수료율2 -->
                  <div class="wrap-input row">
                    2. <mo-text-field v-model="dsDetail.mdtmEndDds02" class="w50 input-text-right" disabled/> 일 미만 해지시
                    <mo-text-field v-model="dsDetail.mdtmFert02" class="w50 input-text-right" disabled/> %
                  </div>
                </td>
              </tr>
             <tr>
                <th>
                  <span> 신탁금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field v-model="dsDetail.trstAmt" :disabled="disTrstAmt" class="input-long" mask="number" />
                    <mo-text-field class="w80" v-model="dsDetail.curC" disabled />
                  </div>
                </td>
                <td>
                  <!-- 중도해지 수수료율3 -->
                  <div class="wrap-input row">
                    3. <mo-text-field v-model="dsDetail.mdtmEndDds03" class="w50 input-text-right" disabled /> 일 미만 해지시
                    <mo-text-field v-model="dsDetail.mdtmFert03" class="w50 input-text-right" disabled /> %
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 출금(출고)방식 </span>
                </th>
                <td>
                  <div class="wrap-input">
                    <mo-dropdown :items="oamtDlvgsTcItems" v-model="dsDetail.oamtDlvgsTc" :disabled="disOamtDlvgsTc" placeholder="선택하세요"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 수익자 </span>
                </th>
                <td colspan="3">
                  <div class="wrap-table">
                    <table class="table row-type">
                      <thead>
                        <tr>
                          <th> 등급 </th>
                          <th colspan="2"> 원금수익자 </th>
                          <th colspan="2"> 이익수익자 </th>
                          <th> 비율 </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="(data, idx) in dsDetail.tfabni"  :key="idx">
                          <td>{{data.bnfcRatingTcNm}}</td>
                          <td class="w150">{{data.prinBnfcRcno.substring(0,6) + '-' + data.prinBnfcRcno.substring(6,7) + '******'}}</td>
                          <td class="w150">{{data.prinBnfcCnm}}</td>
                          <td class="w150">{{data.prftBnfcRcno.substring(0,6) + '-' + data.prftBnfcRcno.substring(6,7) + '******'}}</td>
                          <td class="w150">{{data.prftBnfcCnm}}</td>
                          <td>{{data.pymRto}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button @click="fn_Update" :disabled="btnUpdate" size="large"> 수정 </mo-button>
          <mo-button @click="fn_Delete" :disabled="btnDelete" size="large"> 삭제 </mo-button>
          <mo-button @click="fn_Cancel" :disabled="btnCancel" size="large"> 취소 </mo-button>
          <mo-button @click="fn_Confirm" :disabled="btnConfirm" primary size="large"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </div>
  
  <ts-alert-popup 
    ref="alertPopup"
    :popupObj="pAlertPopupObj"
  ></ts-alert-popup>
  <msp-ts-206p
    ref="popup206"
    :popup206Obj="pPopup206Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup206-callback="fn_Popup206Back"
  ></msp-ts-206p>
  <msp-ts-303p
    ref="popup303"
    :popup303Obj="pPopup303Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup303-callback="fn_Popup303Back"
  ></msp-ts-303p>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                    *
 ***********************************************************************************/
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import MSPTS206P from '@/ui/ts/MSPTS206P' //계좌번호 조회
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import MSPTS303P from '@/ui/ts/MSPTS303P'
import TSInfoUtil from '~/src/ui/ts/comm/TSInfoUtil'    // Report (공통)


export default {
 /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: 'MSPTS201M',
  screenId: "MSPTS201M",
  props: [],
  components: {
    'ts-header': TSHeader,
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-206p': MSPTS206P,
    'msp-ts-303p': MSPTS303P,
  },
 /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/  
  data () {
    return {
      name: 'MSPTS201M',
      pHeaderObj: {          
        title: '해지신청',
      },
      busyDate:'',
      acno:'',
      accSeq:'',
      cnm:'',
      offrSeq:'',
      tmtlTc:'',
      aprvBusnTc1:'',
      aprvStatTc:'',
      otxtTmtlTc:'',
      mtrtDate:'',
      rufeCnfmYn:'',
      rufeCnfmYn00:'',
      tmtlSchdDate:'',
      tmtlOffrDate:'',
      tmtlAmt:0,
      basTfrt:0,
      tmtlRsn:'',
      trstAmt:0,
      curC:'',
      curC00:'',
      oamtDlvgsTc:'1',
      mdtmEndDds01:0,
      mdtmEndDds02:0,
      mdtmEndDds03:0,
      mdtmFert01:0,
      mdtmFert02:0,
      mdtmFert03:0,
      amlCtno:'',

      btnSearch: false,
      btnClear: false,
      btnUpdate: true,
      btnDelete: true,
      btnConfirm: true,
      btnCancel: true,
      btnAdmission:false,
      btnAdmMemoInfo:true,
      btnAdmissionName:'승인요청',
      btnAML:false,
      bnkbSeqItems:[],
      accSeqItems:[],
      tmtlTcItems:[],
      otxtTmtlTcItems:[],
      rufeCnfmYnItems:[],
      offrSeqItems:[],        //일련번호
      oamtDlvgsTcItems:[],
      aprvBusnTc1Items:[],
      aprvStatTcItems:[],
      /* disabled Start */
      disTmtlTc:true,
      disAprvBusnTc1:true,
      disAprvStatTc:true,
      disOtxtTmtlTc:true,
      disMtrtDate:true,
      disRufeCnfmYn:true,
      disRufeCnfmYn00:true,
      disTmtlSchdDate:true,
      disTmtlOffrDate:true,
      disTmtlAmt:true,
      disBasTfrt:true,
      disTmtlRsn:true,
      disTrstAmt:true,
      disCurC:true,
      disCurC00:true,
      disOamtDlvgsTc:true,
      disMdtmEndDds01:true,
      disMdtmEndDds02:true,
      disMdtmEndDds03:true,
      disMdtmFert01:true,
      disMdtmFert02:true,
      disMdtmFert03:true,
      /* disabled End */
      visBtnAdmission:false,
      tfabni:[],




      dsDetail: Object.assign({}, {}),

      popup206: {}, // MSPTS206P
      popup303: {}, // MSPT303P
      pAlertPopupObj: {},
      pPopup206Obj: {},
      pPopup206Type: '',      //고객조회 팝업 구분
      pPopup303Obj: {},
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      sSvcID:'',
      rufeCnfmYnItems: [
        {value: '', text: '해당없음'}
      ],

      ds_search01: {}, // 해지결과 확인

      ds_searchReportList : [],   // 보고서 출력조회
      ds_resultReportList : [],   // 보고서 출력조회
      ds_searchReport     : {},   // 보고서 출력조회

      timerInterval: '',
      pymRto:0, // 수일자 정률 비율 값

    }
  },

 /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created () {
    this.fn_ComCode()
    this.fn_Init()
  },
  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {

    this.$bizUtil.insSrnLog('MSPTS201M')
    this.fn_GetBusyDate()
    //return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
  },//destroyed

  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {

  },//watch

  /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
  },
 
 /***********************************************************************************
  * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode (){
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"5028"},
                                  {cId:"502902"},
                                  {cId:"5021"},
                                  {cId:"5130"},
                                  {cId:"5031"},
                                  {cId:"5023"},
                                  {cId:"5085"},
                                  {cId:"5024"},
                                  {cId:"5101"},
                                  {cId:"505203"},
                                  {cId:"5117"},
                                  {cId:"5192"}
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error)
    }, 
    fn_ComCodeCallBack ( res ) {
      for ( let index in res.data.tukemk ) {
        let item = res.data.tukemk[index]
        
        switch (item.cid) {
          case "5028":
            this.tmtlTcItems.push({value: item.c , text: item.cnm})
            break
          case "5029":
            if ( item.c != '08') { // 현물자산 삭제(08)
              this.otxtTmtlTcItems.push({value: item.c , text: item.cnm})
            }
            break
          //case "5021":
            //this.rufeCnfmYnItems.push({value: item.c , text: item.cnm})
            //break
          case "5117":
            this.oamtDlvgsTcItems.push({value: item.c , text: item.cnm})
            break
          case "5023":
            this.aprvStatTcItems.push({value: item.c , text: item.cnm})
            break
          case "5085":
            this.aprvBusnTc1Items.push({value: item.c , text: item.cnm})
            break
        }
      }
    },


    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content      = pPopupObj.content
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm      = false
            this.pAlertPopupObj.cancel       = true
            this.pAlertPopupObj.type         = 0

            if ( !TSCommUtil.gfn_isNull(pPopupObj.confirm) ) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm
            } else {
              this.pAlertPopupObj.confirm = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.confirmFunc) ) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
            } else {
              this.pAlertPopupObj.confirmFunc = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.closeFunc) ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
            } else {
              this.pAlertPopupObj.closeFunc = ''
            }

            if ( !TSCommUtil.gfn_isNull(pPopupObj.single) ) {
              this.pAlertPopupObj.single = pPopupObj.single
            } else {
              this.pAlertPopupObj.single = ''
            }

          }
          break
        case 7:
        case 9:

          if ( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            this.pAlertPopupObj.content      = pPopupObj.content          
            this.pAlertPopupObj.contentTitle = ''
            this.pAlertPopupObj.confirm      = false
            this.pAlertPopupObj.cancel       = true
            this.pAlertPopupObj.type         = 0

            if ( !pPopupObj.cancel ) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel
            }
            if ( pPopupObj.confirm ) {
              this.pAlertPopupObj.confirm     = pPopupObj.confirm
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData
            }
            if ( pPopupObj.closeFunc ) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
              this.pAlertPopupObj.closeData = pPopupObj.closeData
            }
            if ( !TSCommUtil.gfn_isNull(type) && type != 0 ) {
              this.pAlertPopupObj.type = type
            }
            if ( pPopupObj.contentTitle ) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle
            }
          }
          break
      }

      this.$refs.alertPopup.fn_Open()
    },


    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 초기화
    ******************************************************************************/
    fn_Init(bSearchArea){
      if(bSearchArea){
        this.acno = ''
        this.accSeq = ''
        this.cnm = ''
        this.offrSeq = ''
        this.accSeqItems = []
        this.offrSeqItems = []
      }
      
      this.btnSearch= false
      this.btnConfirm= true
      this.btnCancel= true
      this.btnInit= false
      this.btnUpdate= true
      this.btnDelete= true
      this.AmlCtno = ''
      this.teleVssTc = 'T'

      this.disTmtlTc = true //해지구분
      this.tmtlTc = ''

      this.disOtxtTmtlTc = true //해지유형구분
      this.otxtTmtlTc = ''

      this.disTmtlSchdDate = true //출금가능일
      this.disTmtlAmt = true //해지금액
      this.disCurC = true //해지금액 통화코드
      this.disTmtlRsn = true //해지사유

      this.disAprvBusnTc1 = true //승인구분
      this.aprvBusnTc1 = ''
      this.disAprvStatTc = true //승인상태
      this.aprvStatTc = ''

      this.btn_admMemoInfo= true //승인사유정보 버튼
      this.disMtrtDate = true //만기일
      this.disTmtlOffrDate = true //체결일
      this.disBasTfrt = true //기본보수율

      this.disTrstAmt = true //신탁금액
      this.disCurC00 = true //신탁금액 통화코드
      this.disTeleVssTc = true //유선내방
      this.disRufeCnfmYn = true //성과보수
      this.disRufeCnfmYn00 = true //성과보수

      this.disMdtmEndDds01 = true //중도해지
      this.disMdtmEndDds02 = true //중도해지
      this.disMdtmEndDds03 = true //중도해지
      this.disMdtmFert01 = true //중도해지
      this.disMdtmFert02 = true //중도해지

      this.disMdtmFert03 = true //중도해지
      this.disBffeBaseC = true //수익보수율 기준금리
      this.disGrdList01 = true //수익자
      this.disGrdList06 = true //수익보수율
      this.btnAdmission = false
      this.visBtnAdmission = false
      this.disOamtDlvgsTc = true //출금(출고)방식
      this.dsDetail = {}
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소
    ******************************************************************************/
    fn_Cancel(){
      this.btnSearch = false
      this.btnConfirm = true
      this.btnCancel = true
      this.btnInit = false

      this.dsDetail = {}
    },

    /******************************************************************************
    * Function명 : fn_TmtlTcChange
    * 설명       : 해지구분
    ******************************************************************************/
    fn_TmtlTcChange(){
      this.disOtxtTmtlTc = true
      // this.Static05.set_cssclass("sta_WF_Label");

      this.disTmtlAmt = true
      this.dsDetail.otxtTmtlTc = ''

      console.log('fn_TmtlTcChange this.dsDetail.tmtlTc ================> ',this.dsDetail.tmtlTc)

      if (this.dsDetail.tmtlTc === '01'){
        if (this.dsDetail.mtrtDate === '9999-12-31') 
        {
          this.pAlertPopupObj.content = '해당 계약은 만기해지 신청이 불가합니다.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
      }else if (this.dsDetail.tmtlTc === "02"){
        this.dsDetail.tmtlAmt = 0
        this.disTmtlAmt = false
        this.disOtxtTmtlTc = false
        //this.Static05.set_cssclass("sta_WF_Label_P");
      }else if (this.dsDetail.tmtlTc === "03"){
        this.dsDetail.tmtlAmt = this.dsDetail.trstAmt
      }else{
        this.dsDetail.tmtlAmt = this.dsDetail.tmtlAmt
        this.disTmtlAmt = true
      }
    },

    /******************************************************************************
    * Function명 : fn_otxtTmtlTcChange
    * 설명       : 해지유형구분
    ******************************************************************************/
    fn_otxtTmtlTcChange(){

      console.log('fn_TmtlTcChange this.dsDetail.otxtTmtlTc ================> ', this.dsDetail.otxtTmtlTc)
      
      if (this.dsDetail.otxtTmtlTc === '03'){
        this.dsDetail.tmtlAmt = 0
        this.disTmtlAmt = true
      }else{
        this.disTmtlAmt = false
      }
    },

    /******************************************************************************
    * Function명 : fn_Admission
    * 설명       : 
    ******************************************************************************/
    fn_OffrSeqChange(){
      this.fn_Init()
    },
    /******************************************************************************
    * Function명 : fn_Admission
    * 설명       : 승인요청 진행
    ******************************************************************************/
    fn_AdmissionReq(){
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSTS21I2'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600095_S'

      let mtrt = this.dsDetail.mtrtDate.replaceAll('-','')
      let tmtlSchd = this.dsDetail.tmtlSchdDate.replaceAll('-','')
      let tmtlOffr = this.dsDetail.tmtlOffrDate.replaceAll('-','')
      
      this.eaiCommObj.params = this.dsDetail
      this.eaiCommObj.params.mtrtDate = mtrt
      this.eaiCommObj.params.tmtlSchdDate = tmtlSchd
      this.eaiCommObj.params.tmtlOffrDate = tmtlOffr
      

      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AdmissionReqResult,function (error) {console.log(error)},'UF10600095')
    },

    /******************************************************************************
    * Function명 : fn_AdmissionReqResult
    * 설명       : 승인요청 진행 콜백
    ******************************************************************************/
    fn_AdmissionReqResult(res){

       this.fn_Search()

       // 해지 결과 Return 값 확인을 위한 타이머 생성
       //this.timerInterval = setInterval (this.fn_InsertIamtAr, 15000);
      
    },

    /******************************************************************************
    * Function명 : fn_Admission
    * 설명       : 승인요청
    ******************************************************************************/
    fn_Admission(){
      if ( this.btnAdmission === false && this.btnAdmissionName === "승인요청" ) // 승인 
      {	
          // 해지 결과 Return 값 확인을 위한 타이머 생성
          this.timerInterval = setInterval (this.fn_InsertIamtAr, 15000);

          this.fn_AdmissionReq()

      }else if ( this.btnAdmission === false && this.btnAdmissionName == "승인요청취소" ) // 승인 
      {
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS21I3'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600096_S'
        this.eaiCommObj.params = this.dsDetail        

        console.log('승인요청취소 C392_F10600096_S this.dsDetail ==================> ',this.dsDetail)

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AdmissionResult,function (error) {console.log(error)},'UF10600096')

      } else 	if ( this.btnAdmission && this.btnAdmissionName == "승인취소요청" ) // 승인 
      {
        this.eaiCommObj.lv_vm = this
        //this.eaiCommObj.trnstId = 'txTSSTS21I4'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600097_S'        
        this.eaiCommObj.params = this.dsDetail

        console.log('승인취소요청 C392_F10600097_S this.dsDetail ==================> ',this.dsDetail)

        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AdmissionResult2,function (error) {console.log(error)},'UF10600097')

      }
    },

    /******************************************************************************
    * Function명 : fn_AdmissionResult
    * 설명       : 승인요청취소 콜백
    ******************************************************************************/
    fn_AdmissionResult(res){
      //console.log('fn_AdmissionResult 승인요청취소 콜백 =====================> ',res)
      this.fn_timerClolse()
     // this.fn_Search()

    },


    /******************************************************************************
    * Function명 : fn_AdmissionResult2
    * 설명       : 승인취소요청 콜백
    ******************************************************************************/
    fn_AdmissionResult2(res){

      //console.log('fn_AdmissionResult2 승인취소요청 콜백 =====================> ',res)

      this.fn_timerClolse()
      
      if ( !TSCommUtil.gfn_isNull ( res.data.errorCode ) ){
        this.fn_AlertPopup(0,{ content : res.data.errorCode } )
        return
      } 

    },

    /******************************************************************************
    * Function명 : fn_AcnoChange
    * 설명       : 계좌번호 입력
    ******************************************************************************/
    fn_AcnoChange(rtnData){
      if(this.acno.length === 10){
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'selectTPUP95090List'

        this.eaiCommObj.params = {
          tacno: this.acno.substring(0,7),
          bnkbSeq: this.acno.substring(7,11),
          accStatC:'11',//this.accStatC,
          brigAccYn:this.brigAccYn
        }

        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600245_S'
        //console.log(this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
          //console.log(res)
          if (res && res.data) {
            //console.log(res.data.tpup95090)
            this.accSeqItems = []
            for(let i=0;i<res.data.tpup95090.length; i++){
              let acc = {}
              acc.value = res.data.tpup95090[i].accSeq
              acc.text = res.data.tpup95090[i].gdNm
              this.accSeqItems.push(acc)
            }
            if(_.isEmpty(rtnData.accSeq)){
              this.accSeq = res.data.tpup95090[0].accSeq
            }else{
              this.accSeq = rtnData.accSeq
            }
            this.cnm = res.data.tpup95090[0].cnm
            this.fn_AcnoSeqSearch()
          }
        },function (error) {console.log(error)},'UF10600245')
      }else{
        return
      }
      
    },

    /******************************************************************************
    * Function명 : fn_AcnoChangeResultA
    * 설명       : 계좌번호 입력 콜백
    ******************************************************************************/
    fn_AcnoChangeResultA(res){
      console.log(res)

    },

    /******************************************************************************
    * Function명 : fn_AcnoSeqSearch
    * 설명       : 계약변경 일련번호 조회
    ******************************************************************************/
    fn_AcnoSeqSearch(){
      console.log("A")
      this.btnSearch = false
      this.btnConfirm = true
      this.btnCancel = true
      this.btnInit = false
      this.btnUpdate = true
      this.btnDelete = true

      this.fn_Init(false)
      this.offrSeq = ''

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSTS21S2'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600100_S'
      this.eaiCommObj.params = {
        tacno: this.acno.substring(0,7),
        bnkbSeq: this.acno.substring(7,10),
        accSeq:this.accSeq
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
        console.log(res)
        if (res && res.data) {
          console.log(res.data.tftcrl)
          this.offrSeqItems = []
          if(res.data.tftcrl.length > 0){
            this.offrSeqItems.push({value:'', text:''})
          }
          for(let i=0;i<res.data.tftcrl.length; i++){
            let acc = {}
            acc.value = res.data.tftcrl[i].offrSeq
            acc.text = res.data.tftcrl[i].offrSeq
            this.offrSeqItems.push(acc)
          }
          this.offrSeq = ''//res.data.tftcrl[0].offrSeq
        }
      },function (error) {console.log(error)},'UF10600100')
    },

    /******************************************************************************
    * Function명 : fn_AcnoSeqSearchResult
    * 설명       : 계약변경 일련번호 조회 콜백
    ******************************************************************************/
    fn_AcnoSeqSearchResult(res){
      console.log(res)
      // res.data.body.offrSeqList
      // if (this.ds_list.insertRow(0) != -1) {
      //   this.ds_list.setColumn(0,"offrSeq","");
      // }
      //this.offrSeq = ''
    },

    /******************************************************************************
    * Function명 : fn_PerformReAmlYn
    * 설명       : 위험평가수행이력
    ******************************************************************************/
    fn_PerformReAmlYn(){
      //this.eaiCommObj0 = TSCommUtil.gfn_eaiCommObj()

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSTS21S0'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600194_S'
      this.eaiCommObj.params = {
        tacno: this.dsDetail.tacno,
        bnkbSeq: this.dsDetail.bnkbSeq,
        ctno: this.dsDetail.accSeq,
        procSeq: this.dsDetail.amlSeq
      }

      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {

        if (res && res.data) {

          this.tftarlList = res.data.tftarl

          var amlRstC = "";
          if (this.tftarlList.length > 0) 
          {
            for (var i = 0; i < this.tftarlList.length; i++) 
            {
              if (this.tftarlList[i].amlRstC == "W") 
              {
                amlRstC = this.tftarlList[i].amlRstC
              }
            }
          }
          
          if (amlRstC == "W") 
          {
            this.pAlertPopupObj.content = 'AML 재이행 대상입니다.<br>실제소유자 확인 및 AML 수행해 주시기 바랍니다.'
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          }
          let vTacno = ""
          let vBnkbSeq = ""
          let vCtno = ""
          let vProcSeq = ""

          vTacno = this.acno.substring(0,7)
          vBnkbSeq = this.acno.substring(7,10)
          vCtno = this.accSeq
          vProcSeq = this.dsDetail.amlSeq

          let properties = {
            pPage : 'MSPTS201M',
            tacno : vTacno,
            bnkbSeq : vBnkbSeq,
            ctno : vCtno,
            procSeq : vProcSeq
          };

          this.popup303 = this.$refs.popup303.fn_Open(properties)
        }
      },function (error) {console.log(error)},'UF10600194')
    },
    fn_Popup303Back(){
      this.fn_Search()
    },

    /******************************************************************************
    * Function명 : fn_PerformReAmlYnResult
    * 설명       : 위험평가수행이력 콜백
    ******************************************************************************/
    fn_PerformReAmlYnResult(res) {
      
    },

    /******************************************************************************
    * Function명 : fn_AML_Logic_2010
    * 설명       : AML 확인
    ******************************************************************************/
    fn_AML_Logic_2010(){

      if (this.offrSeqItems.length < 1 || this.offrSeqItems.length <= 0){
        return
      }
      this.amlCtno = this.acno + this.accSeq
      console.log(this.amlCtno)
      
      if( this.amlCtno.length == 13 ){
        this.btnAML = false
        let vNowGb = this.dsDetail.amlAccept
        console.log(this.dsDetail)
        console.log(vNowGb)
        
        if ( vNowGb == '1') {
          this.btnAdmissionName = ''
          this.visBtnAdmission = false
           this.pAlertPopupObj.content = '승인요청은 AML고객인증 후 가능'
           this.pAlertPopupObj.confirm = false
           this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
         // this.fn_PerformReAmlYn()
        
        }else if( vNowGb == '3'){
          this.btnAdmissionName = ''
          this.visBtnAdmission = false
          this.pAlertPopupObj.content = 'AML 거래거절 건입니다. (더이상 진행 할 수 없습니다.)'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
        
        }else{ //승인요청
          this.btnAdmissionName = '승인요청'
          this.visBtnAdmission = true
        }

      }else{
        this.btnAdmissionName = ''
        this.visBtnAdmission = false
        this.btnAML = true
      }

    },

    /******************************************************************************
    * Function명 : fn_Search
    * 설명       : 조회버튼
    ******************************************************************************/
    fn_Search() {
      //console.log("AAAAAAAAAAAAAAAAAAAAAA")
      //console.log(this.acno.replaceAll('-',''))
      console.log('acno ==>',this.acno.replaceAll('-',''))
      this.fn_Init(false)
      let tacno = this.acno.replaceAll('-','') + this.accSeq
        // this.fn_AcnoSeqSearch()
      // if(tacno.length !== 13){
      //   this.pAlertPopupObj.content = '계좌번호를 확인하세요'
      //   this.pAlertPopupObj.confirm = false
      //   this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
      //   return
      // }
      //console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBB")
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'selectTPUP95090List'

      this.eaiCommObj.params = {
        tacno: this.acno.substring(0,7),
        bnkbSeq: this.acno.substring(7,10),
        accSeq:this.accSeq,
        offrSeq:this.offrSeq
      }
      //console.log(this.eaiCommObj)
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600098_S'
      console.log(this.eaiCommObj)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult,function (error) {console.log(error)},'UF10600098')
      
    },

    /******************************************************************************
    * Function명 : fn_SearchResult
    * 설명       : 조회 콜백
    ******************************************************************************/
    fn_SearchResult (res) {

      const resData = Object.assign({}, res.data)
      console.log(resData)
      resData.mtrtDate = TSCommUtil.gfn_dateReplace(1, resData.mtrtDate)
      resData.tmtlSchdDate = TSCommUtil.gfn_dateReplace(1, resData.tmtlSchdDate)
      resData.tmtlOffrDate = TSCommUtil.gfn_dateReplace(1, resData.tmtlOffrDate)

      this.dsDetail=resData

      this.pymRto = 0 //초기값 셋팅

      if(res.data.tfabni.length > 0){
        this.tfabni = res.data.tfabni        
      }else{
        this.tfabni = []
      }
      
      for(var i=0;i<this.tfabni.length; i++){
        if(this.tfabni[i].bnfcRatingTc === '1'){
          this.tfabni[i].bnfcRatingTcNm = '1차 수익자'
        }else if(this.tfabni[i].bnfcRatingTc === '2'){
          this.tfabni[i].bnfcRatingTcNm = '2차 수익자'
        }else if(this.tfabni[i].bnfcRatingTc === '3'){
          this.tfabni[i].bnfcRatingTcNm = '3차 수익자'
        }else if(this.tfabni[i].bnfcRatingTc === '4'){
          this.tfabni[i].bnfcRatingTcNm = '4차 수익자'
        }
        //수익자 정률 비율 데이터 셋팅
        this.pymRto += Number(this.tfabni[i].pymRto)
        console.log('this.pymRto ==================>',this.pymRto)

      }
      if (_.isEmpty(res.data.tacno)){
        this.fn_Init(false)
        return
      
      }
      if (res.data.offrSeq <= 0) 
      {
        // if (nexacro.getApplication().gds_userInfo.getColumn(0, "brcd") == "916") 
        // {
        //   this.gfn_getMessage("alert", "신탁지원부는 처리 할 수 없습니다.");
        //   return;
        // }
        console.log("AAAAAAAAAAAAAA")
        this.fn_Insert()
        return
      }

      var vAprvStatTc = this.dsDetail.aprvStatTc
      var vAprvBusnTc1 = this.dsDetail.aprvBusnTc1

      console.log("{"+vAprvStatTc+"}")

      if (!_.isEmpty(this.dsDetail.offrSeq) && Number(this.dsDetail.offrSeq) > 0) 
      {
        this.offrSeq = this.dsDetail.offrSeq
      }

      if (vAprvStatTc == "11") 
      {
        // 11.요청 12.승인 13.반려(반려와,NULL값만 변경가능)
        this.btnAdmissionName = "승인요청취소"
        this.btnAdmission = false
        this.visBtnAdmission = true
      }
      else if (vAprvStatTc == "12" || vAprvStatTc == "17") 
      {
        // 11.요청 12.승인 13.반려(반려와,NULL값만 변경가능)
        console.log(this.dsDetail.tmtlSchdDate)
        console.log(this.busyDate)
        if (vAprvBusnTc1 != "4012" && this.dsDetail.tmtlSchdDate.replaceAll("-","") >= this.busyDate) 
        {
          this.btnAdmissionName = "승인취소요청"
          this.btnAdmission = true
          this.visBtnAdmission = true
        }

        this.btnAML = false
      }
      else if (vAprvStatTc == "13" || vAprvStatTc === '') 
      {
        console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
        // 11.요청 12.승인 13.반려 (반려와,NULL값만 변경가능)
        this.fn_AML_Logic_2010()
        this.btnSearch = false
        this.btnUpdate = false
        this.btnDelete = false
        this.btnConfirm = true
        this.btnCancel = true
        this.btnInit = false
      }
    },

    /******************************************************************************
    * Function명 : fn_Insert
    * 설명       : 등록
    ******************************************************************************/
    fn_Insert(){
      // 서버에 호출 할 정보를 셋팅해준다.
      this.sSvcID = "insertTFAC20100"

      this.btnSearch = true
      this.btnConfirm = false
      this.btnCancel = false
      this.btnInit = false
      this.btnUpdate = true
      this.btnDelete = true

      this.disTmtlTc = false //해지구분

      // this.dsDetail.tmtlTc_onitemchanged();
      // this.dsDetail.trstTypC_onitemchanged();
      this.disTmtlSchdDate = false //출금가능일
      this.disTeleVssTc = false //유선내방
      this.disTmtlRsn = false //해지사유
      this.disRufeCnfmYn = false //성과보수
      this.disRufeCnfmYn00 = false //성과보수
      this.disOamtDlvgsTc = false //출금(출고)방식
      this.dsDetail.oamtDlvgsTc = '1' //출고(출금)방식코드 초기세팅
    },

    /******************************************************************************
    * Function명 : fn_Update
    * 설명       : 수정
    ******************************************************************************/
    fn_Update(){
      console.log('수정=====')
      // if (nexacro.getApplication().gds_userInfo.getColumn(0, "brcd") == "916") 
      // {
      //   this.gfn_getMessage("alert", "신탁지원부는 처리 할 수 없습니다.");
      //   return;
      // }

      // 서버에 호출 할 정보를 셋팅해준다.
      this.sSvcID = "updateTFAC20100"

      this.btnSearch = true
      this.btnConfirm = false
      this.btn_Cancel = false
      this.btnInit = false
      this.btnUpdate = true
      this.btnDelete = true

      if (this.dsDetail.tmtlTc === "01"){

      }else if (this.dsDetail.tmtlTc === "02"){
        this.disTmtlAmt = false
        this.disOtxtTmtlTc = false
        // this.Static05.set_cssclass("sta_WF_Label_P");
      }else{
        this.dsDetail.tmtlAmt = this.dsDetail.tmtlAmt
        this.disTmtlAmt = true
      }
      this.disRufeCnfmYn = false //성과보수
      this.disRufeCnfmYn00 = false //성과보수
      this.disTmtlTc = false //해지구분
      this.disTmtlSchdDate = false //출금가능일
      this.disTeleVssTc = false //유선내방
      this.disTmtlRsn = false //해지사유
      this.disOamtDlvgsTc = false //출금(출고)방식
    },

    /*********************************************************
    * Function명: fn_Delete
    * 설명: 삭제
    *********************************************************/
    fn_Delete(){
      // if (nexacro.getApplication().gds_userInfo.getColumn(0, "brcd") == "916") 
      // {
      //   this.gfn_getMessage("alert", "신탁지원부는 처리 할 수 없습니다.");
      //   return;
      // }

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSTS21D0'
      this.eaiCommObj.auth = 'D'

      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600093_S'
      this.eaiCommObj.params = {
        tacno: this.acno.substring(0,7),
        bnkbSeq: this.acno.substring(7,10),
        accSeq: this.accSeq,
        offrSeq:this.offrSeq
      }
      TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
        console.log(res)
        if (res && res.data) {
          let row
          for(let i=0;i<this.offrSeqItems.length; i++){
            if(this.offrSeqItems[i].value == this.offrSeq){
              row = i
            }
          }
          console.log(row)
          this.offrSeqItems.splice(this.offrSeqItems.indexOf(row), 1)          
          this.offrSeq = ''
          this.fn_Init(false)
        }
      },function (error) {console.log(error)},'UF10600093')
    },

    /*********************************************************
    * Function명: fn_DeleteResult
    * 설명: 삭제 콜백
    *********************************************************/
    fn_DeleteResult(res){
      // this.ds_list.deleteRow(this.Div02.form.dsDetail.offrSeq.index);
      this.dsDetail.offrSeq = ''
      this.fn_init(false)
    },

    /*********************************************************
    * Function명: fn_Confirm
    * 설명: 확인
    *********************************************************/
    fn_Confirm(){

      let tmpContent = []

      if (this.sSvcID == "insertTFAC20100" || this.sSvcID == "updateTFAC20100") 
      {
        // 성과보수 확인
        if (!TSCommUtil.gfn_isNull(this.dsDetail.rufeYn) && this.dsDetail.rufeYn == "Y") 
        {
          if (this.dsDetail.rufeCnfmYn == "N") 
          {
            this.pAlertPopupObj.content = '성과보수를 먼저 입금하십시오.'
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
        }

        // if (TSCommUtil.gfn_isNull(this.dsDetail.teleVssTc)) 
        // {
        //   this.pAlertPopupObj.content = '유선내방 구분을 선택하십시오.'
        //   this.pAlertPopupObj.confirm = false
        //   this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
        //   return
        // }
        
        if (_.isEmpty(this.dsDetail.tmtlTc.replace(' ',''))) 
        {
          this.pAlertPopupObj.content = '해지 구분을 선택하십시오.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }

        if (this.dsDetail.tmtlTc == "01" || this.dsDetail.tmtlTc == "03") 
        {
          if (this.dsDetail.poaoTc == "1" && this.dsDetail.epayPoaoTc == "2") 
          {
            // 별납후취
            if (!_.isEmpty(this.dsDetail.epayCnfmYn) && this.dsDetail.epayCnfmYn == "N")
            {
              this.pAlertPopupObj.content = '별납보수를 먼저 입금하십시오.'
              this.pAlertPopupObj.confirm = false
              this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
              return
            }
          }
        }
        if (this.dsDetail.tmtlTc == "02" && TSCommUtil.gfn_isNull(this.dsDetail.otxtTmtlTc)) 
        {
          this.pAlertPopupObj.content = '해지유형 구분을 선택하십시오.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }

        if (this.dsDetail.tmtlTc == "02" && (TSCommUtil.gfn_isNull(this.dsDetail.tmtlAmt) || this.dsDetail.tmtlAmt <= 0)) 
        {
          if (this.dsDetail.otxtTmtlTc != "08" && this.dsDetail.otxtTmtlTc != "03" && this.dsDetail.otxtTmtlTc != "04") 
          {
            // 원금일부+수익전액 추가
            this.pAlertPopupObj.content = '해지금액을 확인해 주십시오.'
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
        }
        console.log(this.dsDetail.oamtDlvgsTc)
        if (TSCommUtil.gfn_isNull(this.dsDetail.oamtDlvgsTc)) 
        {
          this.pAlertPopupObj.content = '출금(출고)방식을 선택하십시오.'
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }
        // 2020.06.09 add
        if (this.dsDetail.tmtlTc == "01") 
        {
          // 만기해지인 경우
          if (this.busyDate < Number(this.dsDetail.mtrtDate.replaceAll('-',''))) 
          {
            this.pAlertPopupObj.content = '만기일 이후에 해지신청이 가능합니다.'
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
        }

        //수익자 정률 비율의 합이 100 이상일 경우 체크
        if ( this.pymRto > 100 ) {

          tmpContent.push('수익자 정보를 확인해 주세요.')
          tmpContent.push('(정률 비율의 합:100)')

          this.pAlertPopupObj.content = tmpContent
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        }

        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSTS21S1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600099_S'
        this.eaiCommObj.params = {
          tacno:this.acno.substring(0,7),
          bnkbSeq:this.acno.substring(7,10),
          accSeq:this.accSeq,
         // offrSeq:this.offrSeq
        }
  
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_ConfirmResult) 
      }
    },

    /*********************************************************
    * Function명: fn_ConfirmResult
    * 설명: 확인 콜백
    *********************************************************/
    fn_ConfirmResult(res){

      let lv_Vm    = this;
      let basInfo  = lv_Vm.getStore('tsStore').getters.getBasInfo.data

      console.log('fn_ConfirmResult res ==================> ',res)

      // 응답코드가 정상이 아닌경우 
      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }

      // 내방 / 방판 여부 셋팅
      let teleVssTc = "T"  // 내방(vss)
      if ( basInfo.vstTyp == "vst" ) {
        teleVssTc = "O"  // 방판(vst)
      }
      this.dsDetail.teleVssTc = teleVssTc

      console.log('fn_ConfirmResult this.sSvcID ==================> ',this.sSvcID)

      if(this.sSvcID === 'insertTFAC20100'){

        this.dsDetail.tmtlSchdDate = this.dsDetail.tmtlSchdDate.replaceAll('-','')
        this.dsDetail.tmtlOffrDate = this.dsDetail.tmtlOffrDate.replaceAll('-','')
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSTS21S1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600094_S'
        this.eaiCommObj.params = this.dsDetail
        TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
          console.log(res)
          if(!_.isEmpty(res.data.errorCode)){
            // this.pAlertPopupObj.content = res.data.errorMsg
            // this.pAlertPopupObj.confirm = false
            // this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          let nRow = this.offrSeqItems.length + 1
          let offr = {}
          offr.value = res.data.offrSeq
          offr.text = res.data.offrSeq
          this.offrSeqItems.push(offr)
          this.offrSeq = res.data.offrSeq
          this.fn_Search()
        },function (error) {
          return
        },'UF10600094')

      }else if(this.sSvcID === 'updateTFAC20100'){

        this.dsDetail.tmtlSchdDate = this.dsDetail.tmtlSchdDate.replaceAll('-','')
        this.dsDetail.tmtlOffrDate = this.dsDetail.tmtlOffrDate.replaceAll('-','')
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSTS21S1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600101_S'
        this.eaiCommObj.params = this.dsDetail
        TSServiceUtil.invokeInDirect(this.eaiCommObj, function (res) {
          console.log(res)
          if(!_.isEmpty(res.data.errorCode)){
            this.pAlertPopupObj.content = res.data.errorMsg
            this.pAlertPopupObj.confirm = false
            this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
            return
          }
          this.fn_Search()
        },function (error) {
          this.pAlertPopupObj.content = error
          this.pAlertPopupObj.confirm = false
          this.$refs.alertPopup.fn_Open(this.pAlertPopupObj)
          return
        },'UF10600101')

      }
      console.log('fn_ConfirmResult this.dsDetail ==================> ',this.dsDetail)
      
    },

    /******************************************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************************************/
    fn_checkProcess() {

      let lv_Vm = this;
      let tmpContentTitle = '해지신청완료'        
      let tmpContent = [] 

      let isProcess = lv_Vm.getStore('tsStore').getters.getState.isProcess
      let basInfo   = lv_Vm.getStore('tsStore').getters.getBasInfo.data
      let isLast    = lv_Vm.$bizUtil.tsUtils.getIsLastProc(lv_Vm)
      let t_type    = 0

      tmpContent.push('계약 ['+ this.acno.substring(0,7) + '-' + this.acno.substring(7,10) + '-' + this.accSeq +'] 건')
      tmpContent.push('계약해지 승인처리가 완료되었습니다.')
      tmpContent.push('신탁출금으로 이동합니다.')
      tmpContent.push('출금은 본사 계리 처리 후 가능합니다.')

     // tmpContent.push('해지신청이 완료되었습니다.')
      if ( isProcess ) { 
        if ( !isLast ) {
          if ( basInfo.procTyp === 'custInfoReg' ) {
            tmpContent.push('송금계좌등록 화면으로 이동합니다.')
          }
          t_type = 7 // 프로세스 진행 중 마지막 화면이 아닐 경우
        } else {
          t_type = 9 // 프로세스 진행 중 마지막 화면일 경우
        }
      } else { // 프로세스 진행 중이 아닐 경우
        t_type = 9 
      }

      let t_popupObj = {
        confirm      : true,
        //confirmFunc  : this.fn_searchReport,
        confirmFunc  : this.fn_movePage_MSPTS208M, //208M 화면으로 이동으로 변경
        content      : tmpContent,
        contentTitle : tmpContentTitle,
        confirmData  : {
          tacno: this.acno.substring(0,7),
          bnkbSeq: this.acno.substring(7,10),
          accSeq: this.accSeq,
        },
      }
      
      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },


    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue () {

      this.ds_searchReportList = []

      this.ds_searchParam = {}
      this.ds_searchParam.tacno       = this.acno.substring(0,7)
      this.ds_searchParam.bnkbSeq     = this.acno.substring(7,10)
      this.ds_searchParam.accSeq      = this.accSeq 
      this.ds_searchParam.offrSeq     = this.offrSeq

      this.ds_searchReport.reportMrdNm = "TS000056" //해지 신청서
      this.ds_searchReport.reportUrl   = "reportTFAC20100List" 

      //this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam)
      this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))


      //this.fn_searchReport()
      this.fn_initReport()
      
    },

    /***************************************************************************************
        리포트 데이터 조회 
    *****************************************************************************************/
    fn_initReport() {
      this.ds_resultReportList = []
      for ( let i = 0; i < this.ds_searchReportList.length; i++ ) {
        let report = {formId : this.ds_searchReportList[i].reportMrdNm}
        report.params = this.ds_searchReportList[i]
        this.ds_resultReportList.push(JSON.parse(JSON.stringify(report)))
      }
    },

    /***************************************************************************************
        리포트 데이터 조회 
    *****************************************************************************************/
    async fn_searchReport() {
      let isProcess = this.getStore('tsStore').getters.getState.isProcess
      let isLast    = this.$bizUtil.tsUtils.getIsLastProc(this)

      if ( isProcess ) { 
        if ( !isLast ) {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList, this.fn_movePage_MSPTS208M , false)  
        } else {
          TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
        }
      } else { // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo(this, this.ds_resultReportList)
      }
    },

    /******************************************************************************
     * Function명 : fn_movePage_MSPTS208M
     * 설명       : 신탁출금화면으로 이동
     ******************************************************************************/
    fn_movePage_MSPTS208M () {
      let ds_move = {}

      ds_move.acno     = this.acno 
      ds_move.accSeq     = this.accSeq

      console.log('fn_movePage_MSPTS208M ds_move ===========> ',ds_move)

      this.$router.push({name: "MSPTS208M", params: ds_move})
    },

    /*********************************************************
    * Function명: fn_OpenMSPTS206P
    * 설명: 계좌번호 조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS206P () {
      let lv_vm = this

      let properties = {
        pPage : 'MSPTS201M',
        pGdC : lv_vm.gdC,              // 신탁상품
        pTrstTypeC : lv_vm.trstTypC,  // 신탁유형
        pGdTypDtlC : lv_vm.gdTypDtlC,  // 상품유형
        pUrl : '2000',                 // 
        pProcTc : '2000',              //
      }

      this.popup206 = this.$refs.popup206.fn_Open(properties)
    },

    /*********************************************************
    * Function명: fn_Popup206Back
    * 설명: 계좌번호 조회 팝업호출 콜백
    *********************************************************/
    fn_Popup206Back(rtnData){
      this.acno = rtnData.tacno + rtnData.bnkbSeq
      this.cnm = rtnData.cnm
      this.fn_AcnoChange(rtnData)
      console.log(rtnData)
      
      this.accSeq = rtnData.accSeq
      
    },

      /******************************************************************************
       * Function명 : fn_InsertIamtAr
       * 설명       : 해지결과 처리 
       ******************************************************************************/
      fn_InsertIamtAr() {

        // TO-BE ERP 일때만 적용되는 로직 
        //해지 승인 결과 확인
        this.eaiCommObj.params = {
          tacno: this.acno.substring(0,7),
          bnkbSeq: this.acno.substring(7,10),
          accSeq:this.accSeq,
          offrSeq:this.offrSeq
        }
        //console.log(this.eaiCommObj)
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600098_S'
        console.log(this.eaiCommObj)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_F10600098,function (error) {console.log(error)},'UF10600098')

      },

      /**********************************************************************************
       * gfn_callService의 콜백 함수 : transaction 응답처리
       **********************************************************************************/
      fn_callBack_F10600098(res) {

        const resData = Object.assign({}, res.data)
        //console.log(resData)
        this.dsDetail=resData

        var vAprvStatTc = this.dsDetail.aprvStatTc

        if (vAprvStatTc == "12") { //해지 승인 완료 12

            this.fn_timerClolse()
            
            // 개선 요청으로 해지 신청서 MSPTS208(신탁출금에서 TS000056 : 해지신청서 Report 처리로 변경)
            //this.fn_confirmPrintTrue() // Report 셋팅

            this.fn_checkProcess()

        }


      },

      /**********************************************************************************
       * transaction 응답처리 timer 종료 처리
       **********************************************************************************/
      fn_timerClolse() {

        clearInterval (this.timerInterval)
        
        this.fn_Search()

      },

    /******************************************************************************
     * Function명 : fn_GetBusyDate
     * 설명       : 영업일 조회
     ******************************************************************************/  
    fn_GetBusyDate(){
      let salesYmd = ''
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.trnstId = 'txTSSFS70S9'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570264_S'
      // this.eaiCommObj.params = ds_day

      //console.log(this.eaiCommObj)

      FSServiceUtil.invokeInDirect(this.eaiCommObj, function (response) {
        if (response && response.data) {
          salesYmd = response.data.sy000200_O_00VO[0].sales_ymd
          console.log(salesYmd)
          this.busyDate = salesYmd
        }
      },function (error) {return})
      // FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_GetBusyDateResult)
    },
  }
}
</script>
