/*
 * 업무구분: 고객
 * 화 면 명: MSPFS100P
 * 화면설명: 우편번호(주소찾기)_P
 * 작 성 일: 2023.03.14
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal large" ref="modal" title="우편번호(주소찾기)">
      <template>
        <div class="wrap-modalcontents" ref="modalScroll">
          
          <div class="row">
            <mo-radio-wrapper 
              class="column radioAddress"
              :items="addressType" 
              v-model="lv_addrType.value"
              :disabled="lv_addrType.disabled"
             />
            <div class="column middle">
              <div class="wrap-input row">
                <mo-dropdown 
                  ref="dropdown1"
                  class="w100"
                  :class="lv_addrListValue1.error ? 'error' : ''"                  
                  :items="lv_addrList1" 
                  v-model="lv_addrListValue1.value" 
                  :disabled="lv_addrListValue1.disabled"
                />
                <mo-dropdown 
                  ref="dropdown2"
                  class="dropdown-long"
                  :items="lv_addrList2" 
                  v-model="lv_addrListValue2.value"
                  :disabled="lv_addrListValue2.disabled"
                />
              </div>
              <div class="wrap-input row">
                <mo-text-field 
                  ref="searchInput"
                  class="input-long" 
                  :class="lv_searchInput.error ? 'error' : ''"
                  placeholder="입력해주세요" 
                  v-model="lv_searchInput.value"
                  :disabled="lv_searchInput.disabled"
                  @keyup.enter="fn_AddrSearch"
                  maxlength="100"
                />
              </div>
            </div>
            <div class="wrap-button btn-pop-search">
              <mo-button primary @click="fn_AddrSearch" :disabled="lv_btn_search"> 조회 </mo-button>
            </div>
          </div>

          <div class="wrap-list">
            <ul class="list-area scroll">
              <li 
                v-for="(listArray, i) in lv_searchList" 
                :key="i" 
                @click="fn_SearchListSelect(lv_searchList[i])"
              > {{lv_searchList[i].full_addr}} </li>
            </ul>
          </div>

          <div class="wrap-table mt-3">
            <table class="table type-address">
              <tbody>
                <tr>
                  <th>
                    기본주소(로, 읍/면/동 단위)
                  </th>
                  <td>
                    <mo-text-field 
                      class="input-long" 
                      v-model="lv_addrDefault.value"
                      disabled 
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    상세주소
                  </th>
                  <td>
                    <mo-text-field
                      ref="addrDetail"
                      class="input-long"
                      :class="lv_addrDetail.error ? 'error' : ''"                      
                      placeholder="입력해주세요"
                      v-model="lv_addrDetail.value"
                      :disabled="lv_addrDetail.disabled"
                      @keyup.enter="fn_AddrVerify"
                      maxlength="100"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="wrap-button pop-wrap-btn-small row">
              <mo-button :disabled="lv_btn_insert"> 직접입력 </mo-button>
              <mo-button :disabled="lv_btn_addrValid" @click="fn_AddrVerify"> 주소검증 </mo-button>
              <mo-button :disabled="lv_btn_reset" @click="fn_Init"> 다시검색 </mo-button>
            </div>
          </div>

          <div class="wrap-list">
            <h2 class="title-list"> 정제결과 : <span class="colorR">{{ lv_resp_msg_cntnt }}</span> </h2>
            <ul class="list-area result scroll"> 
              <li 
                v-for="(listArrayResult, i) in lv_validList" 
                :key="i" 
                @click="fn_VaildListSelect(lv_validList[i])"
              > {{lv_validList[i].addr}} </li>
            </ul>
          </div>

          <div class="wrap-table mt-3">
            <table class="table type-address">
              <tbody>
                <tr>
                  <th rowspan="4">
                    선택된 주소
                  </th>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field 
                        class="input-long w140" 
                        v-model="lv_addrValid.zipNum"
                        disabled 
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field 
                        class="input-long"
                        v-model="lv_addrValid.value1"
                        disabled 
                      />
                      <mo-text-field 
                        class="input-long w149"
                        v-model="lv_addrValid.value2"
                        disabled 
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field 
                        class="input-long" 
                        v-model="lv_addrValid.value3"
                        disabled 
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="wrap-input row">
                      <mo-text-field 
                        class="input-long"
                        v-model="lv_addrValid.value4"
                        disabled 
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button size="large" @click="fn_Close()">닫기</mo-button>
          <mo-button primary size="large" 
            @click="fn_Confirm()"
            :disabled="lv_btn_confirm"
          >확인</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
  import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
  

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS100P",
    screenId: "MSPFS100P",
    components: {},
    props: {
      popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_SetCommCode()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPFS100P')
      this.$refs.modalScroll.onscroll = this.closeDropdown
    },
    beforeDestroy() {
      this.$refs.modalScroll.onscroll = null
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_commCode: {},          // 공통코드
        lv_commCodeSub: {},       // 공통서브코드

        lv_addrType: {},          // 구주소/신주소
        lv_addrList1: [],         // 시도(경기도, 서울시)
        lv_addrListValue1: {},    // 시도 선택
        lv_addrList2: [],         // 시군구(과천시, 강남구)
        lv_addrListValue2: {},    // 시군구 선택
        lv_searchInput: {},       // 조회 입력
        lv_searchList: [],        // 조회 결과
        lv_addrDefault: {},       // 기본주소
        lv_addrDetail: {},        // 상세주소
        lv_validList: [],         // 주소검증 결과
        lv_addrValid: {},         // 주소검증 선택한 데이터
        lv_resp_msg_cntnt: '',    // 정제결과

        // 버튼
        lv_btn_search: false,
        lv_btn_insert: true,
        lv_btn_addrValid: true,
        lv_btn_reset: true,
        lv_btn_confirm: true,

        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj()

      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      modal() {
        return this.$refs.modal
      },

      addressType() {
        let rtn = []
        rtn.push({value: '10', text: '구주소(읍/면/동 검색)'})
        rtn.push({value: '20', text: '신주소(도로명 검색)'})
        return rtn
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
      // 시도 선택
      'lv_addrListValue1.value': {
        handler(after, before) {
          this.lv_addrList2 = [{value: 'all', text: '전체'}]
          this.lv_addrListValue2.disabled = true

          if( after != 'all' && this.lv_commCodeSub['code92' + after].length > 0 ) {
            this.lv_commCodeSub['code92' + after].forEach((item, idx) => {
              this.lv_addrList2.push(item)
            })
            this.lv_addrListValue2.disabled = false
          }

          this.lv_addrListValue2.value = 'all'
        },
        deep: true
      },

    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_Open() {
        this.fn_Init()
        this.modal.open()

        // 스크롤 초기화
        setTimeout(() => {
          this.$refs.modalScroll.scrollTop = 0
        }, 100)        
      },
      fn_Close() {
        this.modal.close()
        this.closeDropdown()
      },
      fn_Confirm() {
        // 구 우편번호일 경우에는 3자리 자르고, 신 우편번호일 경우에는 2자리
        let vZipcd_tail = '';

        if (((this.lv_addrValid.zipNum).length) == 6) {
          vZipcd_tail = this.lv_addrValid.zipNum.substr(3, 3);
        } else if (((this.lv_addrValid.zipNum).length) == 5) {
          vZipcd_tail = this.lv_addrValid.zipNum.substr(3, 2);
        }

        // 주소정보 return 객체
        let objRtn = {
          vZipcd_head : this.lv_addrValid.zipNum.substr(0, 3),
          vZipcd_tail : vZipcd_tail,
          vZipcd_ubmyundong : this.lv_addrValid.value1,         // 우편번호주소
          v_bldg_no : this.lv_addrValid.value2,                 // 건물번호
          v_stand_addr : this.lv_addrValid.value3,              // 표준화상세주소
          v_adtn_addr : this.lv_addrValid.value4,               // 부가주소
          v_seq_no : this.lv_addrValid.value5,                  // 우편번호일련번호
          v_addr_stdzt_yn : this.lv_addrValid.value6,           // 주소표준화여부

          btnFlag : this.popupObj.btnFlag,                      // 메인화면 버튼 flag
          rowIdx: this.popupObj.rowIdx,                         // list row index
        }

        let callbackType = FSCommUtil.gfn_isNull(this.popupObj.callbackType) ? 0 : this.popupObj.callbackType

        this.$emit('fs-popup-callback', callbackType, objRtn)
        this.fn_Close()
      },
      closeDropdown() {
        this.$refs.dropdown1.close()
        this.$refs.dropdown2.close()
      },


      /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult, fn_SetCommCodeSub, fn_SetCommCodeSubResult
       * 설명       : 공통코드 세팅
       ******************************************************************************/
      fn_SetCommCode() {
        console.log('fn_SetCommCode...')

        let params = [
          {'cId':'9200', 'dayChkYn':'N'},
        ]
        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
      },

      fn_SetCommCodeResult(pResult) {
        this.lv_commCode = pResult

        // 소분류 코드 조회
        this.fn_SetCommCodeSub()

        // 시도 코드 재설정
        this.lv_addrList1 = [{value: 'all', text: '전체'}]
        this.lv_commCode.code9200.forEach((item, idx) => {
          this.lv_addrList1.push(item)
        })
      },

      fn_SetCommCodeSub() {
        console.log('fn_SetCommCodeSub...')

        let params = []
        this.lv_commCode.code9200.forEach((item, idx) => {
          params.push({'cId': '92' + item.value, 'dayChkYn': 'N'})
        })

        FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeSubResult)
      },

      fn_SetCommCodeSubResult(pResult) {
        console.log('fn_SetCommCodeSubResult...')

        this.lv_commCodeSub = pResult
      },


      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        this.lv_addrType  = FSCommUtil.gfn_dataSet(0, '20')        // 구주소/신주소

        this.lv_addrListValue1 = FSCommUtil.gfn_dataSet(0, 'all')  // 시도 선택값 

        this.lv_addrList2 = [{value: 'all', text: '전체'}]      // 시군구
        this.lv_addrListValue2 = FSCommUtil.gfn_dataSet(0, 'all', true)  // 시군구 선택값

        this.lv_searchInput = FSCommUtil.gfn_dataSet(1)            // 조회 입력
        this.lv_searchList = []                                 // 조회 결과

        this.lv_addrDefault = FSCommUtil.gfn_dataSet(1)            // 기본주소
        this.lv_addrDetail = FSCommUtil.gfn_dataSet(1)             // 상세주소

        this.lv_validList = []                                  // 검증 결과
        this.lv_addrValid = {
          zipNum: '',
          value1: '',
          value2: '',
          value3: '',
          value4: '',
          value5: '',
          value6: '',
        }

        this.lv_resp_msg_cntnt = ''                             // 정제결과


        this.lv_btn_search = false    // 조회 활성화
        this.lv_btn_insert = true     // 직접입력 비활성화
        this.lv_btn_addrValid = true  // 주소검증 비활성화
        this.lv_btn_reset = true      // 다시입력 비활성화
        this.lv_btn_confirm = true    // 확인 비활성화
      },

      /******************************************************************************
       * Function명 : fn_CallAlert
       * 설명       : alert popup 호출
       ******************************************************************************/
      fn_CallAlert(pData) {
          this.$emit('fs-alert-popup', 0, pData)
      },

      /******************************************************************************
       * Function명 : fn_AddrSearch, fn_AddrSearchResult
       * 설명       : 주소조회
       ******************************************************************************/
      fn_AddrSearch () {
        console.log('fn_AddrSearch...')

        // 초기화
        this.lv_searchInput.error = false
        this.lv_addrListValue1.error = false

        if( FSCommUtil.gfn_isNull(this.lv_searchInput.value) ) {
          FSCommUtil.gfn_validate(this, '검색할 주소의 일부분을 입력하여 주십시오.')
          this.lv_searchInput.error = true
          this.$refs['searchInput'].focus()
          return
        }

        if( this.lv_addrListValue1.value == 'all' ) {
          FSCommUtil.gfn_validate(this, '검색할 주소의 시도를 선택해 주십시오.')
          this.lv_addrListValue1.error = true
          return
        }

        // 시도, 시군구 값이 코드 값이 아닌 text로 확인되어 매핑함
        let t_sido, t_sigungu
        this.lv_addrList1.forEach((item, idx) => {
          if(item.value == this.lv_addrListValue1.value) {
            t_sido = item.text
          }
        })

        if( this.lv_addrListValue2.value == 'all' ) {
          t_sigungu = ''
        } else {
          this.lv_addrList2.forEach((item, idx) => {
            if(item.value == this.lv_addrListValue2.value) {
              t_sigungu = item.text
            }
          })
        }


        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS90S8'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570123_S'
        this.eaiCommObj.params = {
          seq_no        : this.lv_addrType.value, // 시퀀스, Length : 18
          sido          : t_sido, // 시도, Length : 20
          sigungu       : t_sigungu, // 시군구, Length : 20
          zip_addr      : '', // 우편번호주소, Length : 256
          zip_remn_addr : '', // 남은주소, Length : 256
          zip_umd       : this.lv_searchInput.value// 주소읍면동, Length : 100
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AddrSearchResult)
      },
      fn_AddrSearchResult (pResultData) {
        console.log('fn_AddrSearchResult...')

        let t_data = pResultData.data.sy000201_O_00VO
        this.lv_searchList = t_data
      },


      /******************************************************************************
       * Function명 : fn_SearchListSelect
       * 설명       : 조회결과 주소 선택
       ******************************************************************************/
      fn_SearchListSelect (pData) {
        this.lv_addrDefault.value = pData.zip_addr  // 기본주소
        this.lv_addrDetail.value = pData.remn_addr  // 상세주소

        this.lv_addrType.disabled = true        // 구주소/신주소 비활성화
        this.lv_addrListValue1.disabled = true  // 시도 비활성화
        this.lv_addrListValue2.disabled = true  // 시군구 비활성화
        this.lv_searchInput.disabled = true     // 조회 입력 비활성화

        this.lv_btn_search = true               // 조회버튼 비활성화
        this.lv_btn_addrValid = false           // 주소검증 활성화
        this.lv_btn_reset = false               // 다시검색 활성화
      },

      /******************************************************************************
       * Function명 : fn_AddrVerify, fn_AddrVerifyResult
       * 설명       : 주소검증
       ******************************************************************************/
      fn_AddrVerify () {
        console.log('fn_AddrVerify...')        

        // 초기화
        this.lv_addrDetail.error = false

        if( FSCommUtil.gfn_isNull(this.lv_addrDetail.value) || this.lv_addrDetail.value.length < 1 ) {
          FSCommUtil.gfn_validate(this, '상세주소를 반드시 입력하셔야 합니다.')
          this.lv_addrDetail.error = true
          this.$refs['addrDetail'].focus()
          return
        }


        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS90S9'
        this.eaiCommObj.auth = 'S'        
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570124_S'
        this.eaiCommObj.params = {
          seq_no        : this.lv_addrType.value, // 시퀀스, Length : 18
          sido          : '', // 시도, Length : 20
          sigungu       : '', // 시군구, Length : 20
          zip_addr      : this.lv_addrDefault.value, // 우편번호주소, Length : 256
          zip_remn_addr : this.lv_addrDetail.value, // 남은주소, Length : 256
          zip_umd       : '' // 주소읍면동, Length : 100
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_AddrVerifyResult)
      },
      fn_AddrVerifyResult(pResultData) {
        console.log('fn_AddrSearchResult...')

        let t_data = pResultData.data.eaf1MDI0089_O_03VO
        
        if( FSCommUtil.gfn_length(t_data) > 0 ) {
          this.lv_validList = t_data
          this.lv_addrDetail.disabled = true

          // 정제결과 매핑
          if( pResultData.data.sy000201_O_02VO.length > 0 ) {
            this.lv_resp_msg_cntnt = pResultData.data.sy000201_O_02VO[0].respMsgCntnt
          }

        } else {
          let t_popupObj = {content: pResultData.data.sy000201_O_02VO[0].respMsgCntnt}
          this.fn_CallAlert(t_popupObj)
        }
      },

      /******************************************************************************
       * Function명 : fn_VaildListSelect
       * 설명       : 정제결과 주소 선택
       ******************************************************************************/
      fn_VaildListSelect (pData) {
        console.log('fn_VaildListSelect...')

        // 구주소
        if( pData.nwOldAddrScCd == '10' ) {
          // 임시.. 팝업으로 변경예정
          FSCommUtil.gfn_validate(this, '2014년 도로명주소법 전면 시행으로 구주소로는 등록이 불가합니다.')
          return 
        }

        // 신주소
        if( pData.nwOldAddrScCd02 == '20' ) {
          this.lv_addrValid.zipNum = FSCommUtil.gfn_trim(pData.pstcd02) // 우편번호
          this.lv_addrValid.value1 = pData.pstcdAddr02                  // 우편번호주소
          this.lv_addrValid.value2 = pData.stdzBldgNo02                 // 건물번호
          this.lv_addrValid.value3 = pData.stdztDtlad02                 // 표준화상세주소
          this.lv_addrValid.value4 = pData.stdzAdtnAddr02               // 부가주소

          // hidden
          this.lv_addrValid.value5 = pData.pstcdSno02                   // 우편번호일련번호
          this.lv_addrValid.value6 = 'Y'                                // 주소표준화여부

          // 확인 버튼 활성화
          this.lv_btn_confirm = false
        }
      },

    }
  }
</script>
<style scoped>
</style>