/*
 * 업무구분: 고객
 * 화 면 명: MSPFS101P
 * 화면설명: 업무구분 POPUP
 * 작 성 일: 2023.03.14
 * 작 성 자: 이태흥
 */
<template>

    <mo-modal class="fts-modal large" ref="modal" title="업무구분">
      <template>
        <div class="wrap-modalcontents">
          
          <div class="wrap-search2">
            <mo-text-field 
              v-model="lv_codeName.value" 
              @keyup.enter="fn_JobCodeSearch(4)"
              maxlength="140"
              placeholder="입력하세요" 
            /> 
            <mo-button primary @click="fn_JobCodeSearch(4)"> 조회 </mo-button>
          </div>

          <div class="row-table step">
            <div class="wrap-table h-scroll">
              <table class="table row-type">
                <thead>
                  <tr>
                    <th scope="col">대분류</th>
                    <th scope="col">코드명</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in lv_codeList1" :key="idx" 
                    :class="item.selected ? 'selected' : ''"
                    @click="fn_JobCodeSearch(2, item)">
                    <td>{{ item.job_cd }}</td>
                    <td class="aL">{{ item.job_cd_whl_nm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <i class="icon-arrowR"></i>
            <div class="wrap-table h-scroll">
              <table class="table row-type">
                <thead>
                  <tr>
                    <th scope="col">소분류</th>
                    <th scope="col">코드명</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in lv_codeList2" :key="idx" 
                    :class="item.selected ? 'selected' : ''"
                    @click="fn_JobCodeSearch(3, item)">
                    <td>{{ item.job_cd }}</td>
                    <td class="aL">{{ item.job_cd_whl_nm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <i class="icon-arrowR"></i>
            <div class="wrap-table h-scroll">
              <table class="table row-type">
                <thead>
                  <tr>
                    <th scope="col">작업코드</th>
                    <th scope="col">코드명</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in lv_codeList3" :key="idx" 
                    :class="item.selected ? 'selected' : ''"
                    @click="fn_JobCodeSelect(item)">
                    <td>{{ item.job_cd }}</td>
                    <td class="aL">{{ item.job_cd_whl_nm }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close()">닫기</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
  import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
  import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'


  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPFS101P",
    screenId: "MSPFS101P",
    components: {},
    props: {
      popupObj: {type:Object, default:null},
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      // this.fn_Init()
      this.$bizUtil.insSrnLog("MSPFS101P")
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_codeName: {},          // 코드명칭(조회 입력값)
        lv_codeList1: [],         // 대분류
        lv_selectCodeList1: '',   // 대분류 선택 코드
        lv_codeList2: [],         // 소분류
        lv_codeList3: [],         // 작업코드
        lv_selectType: 1,         // 조회분류(1: 대분류 / 2: 소분류 / 3: 작업코드)
        lv_returnObj: {},         // Retrun 객체

        /***********************************************************************************
         * F10570128 : 보험공통코드조회(업종코드)
         ***********************************************************************************/
        // EAI(PO) 호출 객체
        eaiCommObj: FSCommUtil.gfn_eaiCommObj()

      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // modal
      modal() {return this.$refs.modal},
    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {

      fn_Open() {
        this.fn_Init()
        this.modal.open()
      },
      fn_Close() {
        this.modal.close()
      },

      /******************************************************************************
       * Function명 : fn_Init
       * 설명       : 화면 초기화
       ******************************************************************************/
      fn_Init() {
        console.log('[MSPFS101P] fn_Init...')

        this.lv_codeName        = FSCommUtil.gfn_dataSet(1)  // 코드명칭(조회 입력값)
        this.lv_codeList1       = []                      // 대분류
        this.lv_selectCodeList1 = ''                      // 대분류 선택코드
        this.lv_codeList2       = []                      // 소분류
        this.lv_codeList3       = []                      // 작업코드
        this.lv_selectType      = 1                       // 조회분류(1: 대분류 / 2: 소분류 / 3: 작업코드)

        // 업종코드조회
        this.fn_JobCodeSearch(1)

      },

      /******************************************************************************
       * Function명 : fn_CallAlert
       * 설명       : alert popup 호출
       ******************************************************************************/      
      fn_CallAlert(msg) {
          let t_popupObj = {
            confirm: false,
            content: msg
          }
          this.$emit('fs-alert-popup', 0, t_popupObj)
      },

      /******************************************************************************
       * Function명 : fn_JobCodeSearch, fn_JobCodeSearchResult
       * 설명       : 업종코드조회
       ******************************************************************************/      
      fn_JobCodeSearch (pType, pData) {
        console.log('fn_JobCodeSearch.....')

        // 조회분류(1: 대분류 / 2: 소분류 / 3: 작업코드 / 4: TEXT조회)
        if(pType == 1) {
          this.eaiCommObj.params = {
            job_cd        : '',   // 직업코드
            job_lvl_cd    : '1',  // 직업소분류
            pg_sc_cd      : '1',  // PG코드
            proc_gb       : 'S',  // 처리구분
          }

        } else if(pType == 2) {
          this.eaiCommObj.params = {
            job_cd        : pData.job_cd, // 직업코드
            job_lvl_cd    : '2',          // 직업소분류
            pg_sc_cd      : '1',          // PG코드
            proc_gb       : 'S',          // 처리구분
          }

          this.lv_codeList1.forEach((item, idx) => {
            if( item.job_cd == pData.job_cd ) {
              item.selected = true
            } else {
              item.selected = false
            }
          })
          
          // 대분류 선택값 매핑
          this.lv_selectCodeList1 = pData.job_cd

        } else if(pType == 3) {
          this.eaiCommObj.params = {
            job_clsf_l_cd : this.lv_selectCodeList1,  // 직업대분류
            job_clsf_m_cd : pData.job_cd,             // 직업중분류
            job_lvl_cd    : '3',                      // 직업소분류
            pg_sc_cd      : '1',                      // PG코드
            proc_gb       : 'S1',                     // 처리구분
          }

          this.lv_codeList2.forEach((item, idx) => {
            if( item.job_cd == pData.job_cd ) {
              item.selected = true
            } else {
              item.selected = false
            }
          })

        } else if(pType == 4){
          this.eaiCommObj.params = {
            job_cd_whl_nm : this.lv_codeName.value,   // 직업코드전체명칭
            job_lvl_cd    : '3',                      // 직업소분류
            pg_sc_cd      : '1',                      // PG코드
            proc_gb       : 'S2',                     // 처리구분
          }
        }

        // 조회분류(1: 대분류 / 2: 소분류 / 3: 작업코드 / 4: TEXT조회) 값 매핑
        this.lv_selectType = pType

        // 서비스 호출 공통 객체 세팅
        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS90S1'
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570128_S'

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_JobCodeSearchResult)
      },
      fn_JobCodeSearchResult(pResultData) {
        console.log('fn_JobCodeSearchResult.....')

        let t_data = pResultData.data.sy002400_O_00VO

        // 조회분류(1: 대분류 / 2: 소분류 / 3: 작업코드 / 4: TEXT조회)
        if( this.lv_selectType == 1 ) {
          
          // 신탁의 경우 대분류에서 기타(99)를 제외
          if ( this.popupObj.procType == 'tass' ) { // 신탁
            for ( let idx in t_data ) {
              if ( t_data[idx].job_cd == '99' ) 
                continue
              this.lv_codeList1.push(t_data[idx])  
            }
          } else { // 수익
            this.lv_codeList1 = t_data
          }

        } else if( this.lv_selectType == 2 ) {
          this.lv_codeList2 = t_data
          this.lv_codeList3 = []

        } else if( this.lv_selectType == 3 || this.lv_selectType == 4 ) {
          this.lv_codeList3 = t_data

          if( this.lv_selectType == 4 ) {
            this.lv_codeList1 = []
            this.lv_codeList2 = []
          }
        }
      },


      /******************************************************************************
       * Function명 : fn_JobCodeSelect
       * 설명       : 업종코드선택
       ******************************************************************************/  
      fn_JobCodeSelect(pData) {
        console.log('fn_JobCodeSelect.....')

        // 업종코스 선택 표시
        this.lv_codeList3.forEach((item, idx) => {
          if( item.job_cd == pData.job_cd ) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
        let t_list = this.lv_codeList3
        this.lv_codeList3 = []
        this.lv_codeList3 = t_list

        // 선택값 안내 팝업
        this.fn_CallAlert(pData.job_cd_whl_nm + '를 선택하셨습니다.')

        // 메인화면으로 선택한 코드정보 넘김
        this.lv_returnObj = {
          vjob_cd: pData.job_cd,
          vjob_nm: pData.job_cd_whl_nm
        }
        this.$emit('fs-popup-callback', 1, this.lv_returnObj)

        // 업종코드 팝업 닫기
        this.fn_Close()
      },

    }
  }
</script>
<style scoped>
</style>