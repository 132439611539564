/*
 * 업무구분: 실제소유자 확인(개인)
 * 화 면 명: MSPTS304P
 * 화면설명: 실제소유자 확인(개인)
 * 작 성 일: 2023.03.17
 * 작 성 자: 김경태 (전면개정 -> refined by anarchi) 
 * 수정일 : 2024-08-06 정수진  사유 : ASR240500691 / [사랑on신탁] 자금세탁 위험평가 제도 대응
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="실제소유자 확인(개인)">
    <template>
      <div class="wrap-modalcontents">
        <h2 class="table-title"> 고객구분: 개인 </h2>
        <div class="mt-2">
          <p class="exp">아래 설문에 응답하시기 바랍니다.</p>
          <div class="wrap-table mt-2">
            <table class="table type-address">
              <tbody>
                <tr>
                  <th>실제 소유자 여부</th>
                  <td>
                    <mo-radio-wrapper 
                      v-model="rlOwnMnCnfmYn" 
                      class="row justify-end" 
                      :items="rlOwnMnCnfmYnItems" 
                      :disabled="'Y' == idtyVrfcYn" 
                      @input="fn_IdenVeriYnChange" 
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="exp mt-2" v-if="rlOwnMnCnfmYn == 'N'">
              <em class="colorR">실제 소유자 '아니오'를 선택하셨습니다. 아래 항목을 빠짐없이 기입해 주십시오.</em>
            </p>
          </div>
        </div>
        <div>
          <div class="wrap-table mt-5">
            <table class="table type-address">
              <tbody>
                <tr>
                  <th>실제 소유자의 성명</th>
                  <td>
                    <div class="wrap-input">
                      <mo-text-field class="w130" v-model="cnm" :disabled="disRlOwnMnCnfmYn" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>실제 소유자의 실명번호</th>
                  <td>
                    <div class="wrap-input row">
                      <!-- ※ JIRA 이슈[ PMPM22000016-803 ] 조치함, amended by anarchi (2023.07.12) -->
                      <!--   → 1. (실명번호 입력필드) 앞자리 숫자 6 자리까지만 제한 // -->
                      <!--   → 1. (실명번호 입력필드) 뒷자리 매스킹 처리 // -->
                      <mo-text-field 
                        ref="ed_rname_no1" 
                        v-model="rcno1" 
                        type="number" 
                        class="w80" 
                        :class="lv_ed_rname_no.error ? 'error' : ''" 
                        :disabled="disRlOwnMnCnfmYn" 
                        maxlength="6" 
                        placeholder="앞 6자리" 
                        @keyup="fn_NextFocus" 
                        @keyup.enter="fn_OnSearch" 
                      />
                      <!-- <mo-text-field 
                        ref="ed_rname_no2" 
                        v-model="rcno2" 
                        type="number" 
                        class="w80" 
                        :class="lv_ed_rname_no.error ? 'error' : ''" 
                        :disabled="disRlOwnMnCnfmYn" 
                        maxlength="7" 
                        placeholder="뒤 7자리" 
                        password underline mask="#######" 
                        @keyup.enter="fn_OnSearch" 
                      /> -->
                      <!-- 보안키패드 (실명번호 뒷자리) 적용 (모바일전용) //-->
                      <m-trans-key-input
                        v-if="isMobile"
                        ref="ed_rname_no2"
                        v-model.trim="rcno2"
                        type="numberMax7"
                        start="1"
                        end="-1"
                        class="w80"
                        dialog="Y"
                        :class="lv_ed_rname_no.error ? 'error' : ''"
                        :isClear="lv_isClear"
                        :disabled="disRlOwnMnCnfmYn"
                        @masked-txt="fn_SetMaskedTxt"
                      />
                      <mo-text-field 
                        v-else
                        ref="ed_rname_no2" 
                        v-model="rcno2" 
                        type="number" 
                        class="w80" 
                        :class="lv_ed_rname_no.error ? 'error' : ''" 
                        :disabled="disRlOwnMnCnfmYn" 
                        maxlength="7" 
                        placeholder="뒤 7자리" 
                        password underline mask="#######" 
                        @keyup.enter="fn_OnSearch" 
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>실제 소유자의 국적</th>
                  <td>
                    <div class="wrap-input row">
                      <mo-dropdown 
                        ref="dropdown1" 
                        v-model="natnNatC" 
                        class="w130" 
                        placeholder=" " 
                        :items="natnNatCItems" 
                        :disabled="disRlOwnMnCnfmYn" 
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <!-- 전자서식(리포트)출력 연계개발/검증을 위한 임시 추가, added by anarchi, 2023.07.21 //-->
        <!-- <mo-button @click="fn_confirmPrintTrue" primary> 전자문서 조회 </mo-button> -->
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <!-- <mo-button primary size="large" @click="fn_Insert" :disabled="disIdenVeriYn">등록</mo-button> -->
        <mo-button primary size="large" @click="fn_OnSearch">등록</mo-button>
      </div>
    </template>
    <!-- <ts-alert-popup 
      ref="alertPopup"
      :alertPopupObj="pAlertPopupObj"
    ></ts-alert-popup> -->
    <ts-alert-popup 
      ref="alertPopup_304p"
      :popupObj="pAlertPopupObj_304p"
      @childReturnEvt="returnEvt"
    ></ts-alert-popup>
  </mo-modal>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import _cloneDeep from "lodash/cloneDeep"
import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: "MSPTS304P",
  // 현재 화면 ID
  screenId: "MSPTS304P",
  // 컴포넌트 선언
  components: {
    "ts-alert-popup": TSAlertPopup,
  },
  props: {
    pPage: String, // 부모 페이지명
    pGdC: String, // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
   ************************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // ------------------------------------------------------
      // ♣ (모바일공통) 보안키패드 적용 관련 추가처리, added by anarchi (2023.08.22)
      lv_masked_val: "", // 마스크 변수 체크
      lv_isClear: false, // 보안키패드 초기화
      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      // ------------------------------------------------------
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      csId: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.21)
      rcnoKindTc: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.21)
      // ------
      // 공통 변수
      lv_cob_rname_gb: {}, // 실명번호종류
      lv_ed_rname_no: {}, // 실명번호
      isInit: false, // 초기화 여부
      rlOwnMnCnfmYn: "", // 실제소유자확인여부
      cnm: "",
      rcno: "", // 실명번호 13자리(13)
      rcno1: "", // 실명번호 앞자리(6) (변경)
      rcno2: "", // 실명번호 뒷자리(7) (변경)
      natnNatC: "",
      idenVeriYn: true,
      refNo: "",
      tacno: "",
      bnkbSeq: "",
      ctno: "",
      procSeq: "",
      idtyVrfcYn: "",
      userInfo: {},
      toastMsg: "", // 문자발송 toast 문구
      searchKeyword: "", // 고객명
      trstTypC: "",
      gdTypDtlC: "",
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      isSucs: false, // 리턴값 세팅여부
      disIdenVeriYn: false,
      disRlOwnMnCnfmYn: true,
      eaiCommObj: {
        lv_vm: "",
        trnstId: "",
        auth: "",
        commHeaderVO: {
          eaiId: "",
          fahrTrnId: "S",
          requestSystemCode: "F1391",
          reqSrvcNm: "",
          reqMthdNm: "",
          targetSystemCode: "",
          resVONm: "",
          reqVONm: "",
        },
        params: {},
        response: [],
        error_msg: "",
      },
      pAlertPopupObj: {},
      pAlertPopupObj_304p: {},
    }
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // 개별업무 프로세스 진행상태 여부, 
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
    rlOwnMnCnfmYnItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::rlOwnMnCnfmYnItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "Y", text: "예"});
      rtn.push ({value: "N", text: "아니오"});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::rlOwnMnCnfmYnItems ]___[ 종료 ]");
    },
    natnNatCItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::natnNatCItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: 'GH', text:'가나'});
      rtn.push ({value: 'GA', text:'가봉'});
      rtn.push ({value: 'GY', text:'가이아나'});
      rtn.push ({value: 'GM', text:'감비아'});
      rtn.push ({value: 'GT', text:'과테말라'});
      rtn.push ({value: 'GD', text:'그레나다'});
      rtn.push ({value: 'GR', text:'그리스'});
      rtn.push ({value: 'GL', text:'그린란드'});
      rtn.push ({value: 'GN', text:'기니'});
      rtn.push ({value: 'GW', text:'기니비사우'});
      rtn.push ({value: 'NA', text:'나미비아'});
      rtn.push ({value: 'NR', text:'나우르'});
      rtn.push ({value: 'NG', text:'나이지리아'});
      rtn.push ({value: 'ZA', text:'남아프리카'});
      rtn.push ({value: 'NL', text:'네덜란드'});
      rtn.push ({value: 'NP', text:'네팔'});
      rtn.push ({value: 'NO', text:'노르웨이'});
      rtn.push ({value: 'NZ', text:'뉴질랜드'});
      rtn.push ({value: 'NC', text:'뉴칼레도니아'});
      rtn.push ({value: 'NU', text:'니우에'});
      rtn.push ({value: 'NE', text:'니제르'});
      rtn.push ({value: 'NI', text:'니카라과'});
      rtn.push ({value: 'TW', text:'대만'});
      rtn.push ({value: 'KR', text:'대한민국'});
      rtn.push ({value: 'DK', text:'덴마크'});
      rtn.push ({value: 'DO', text:'도미니카공화국'});
      rtn.push ({value: 'DE', text:'독일'});
      rtn.push ({value: 'LR', text:'라이베리아'});
      rtn.push ({value: 'LV', text:'라트비아'});
      rtn.push ({value: 'RU', text:'러시아 연방'});
      rtn.push ({value: 'LB', text:'레바논'});
      rtn.push ({value: 'LS', text:'레소토'});
      rtn.push ({value: 'RO', text:'루마니아'});
      rtn.push ({value: 'LU', text:'룩셈부르크'});
      rtn.push ({value: 'RW', text:'르완다'});
      rtn.push ({value: 'LY', text:'리비아'});
      rtn.push ({value: 'LT', text:'리투아니아'});
      rtn.push ({value: 'LI', text:'리히텐슈타인'});
      rtn.push ({value: 'MG', text:'마다가스카르'});
      rtn.push ({value: 'MH', text:'마셜군도'});
      rtn.push ({value: 'MO', text:'마카오'});
      rtn.push ({value: 'MK', text:'마케도니아'});
      rtn.push ({value: 'MW', text:'말라위'});
      rtn.push ({value: 'MY', text:'말레이시아'});
      rtn.push ({value: 'ML', text:'말리'});
      rtn.push ({value: 'MX', text:'멕시코'});
      rtn.push ({value: 'MC', text:'모나코'});
      rtn.push ({value: 'MA', text:'모로코'});
      rtn.push ({value: 'MU', text:'모리셔스'});
      rtn.push ({value: 'MR', text:'모리타니'});
      rtn.push ({value: 'MZ', text:'모잠비크'});
      rtn.push ({value: 'ME', text:'몬테네그로'});
      rtn.push ({value: 'MS', text:'몬트세라트'});
      rtn.push ({value: 'MD', text:'몰도바'});
      rtn.push ({value: 'MV', text:'몰디브'});
      rtn.push ({value: 'MT', text:'몰타'});
      rtn.push ({value: 'MN', text:'몽골'});
      rtn.push ({value: 'US', text:'미국'});
      rtn.push ({value: 'MM', text:'미얀마'});
      rtn.push ({value: 'FM', text:'미크로네시아'});
      rtn.push ({value: 'VU', text:'바누아투'});
      rtn.push ({value: 'BH', text:'바레인'});
      rtn.push ({value: 'BB', text:'바베이도스'});
      rtn.push ({value: 'VA', text:'바티칸시국'});
      rtn.push ({value: 'BS', text:'바하마'});
      rtn.push ({value: 'BD', text:'방글라데시'});
      rtn.push ({value: 'BM', text:'버뮤다'});
      rtn.push ({value: 'BJ', text:'베냉'});
      rtn.push ({value: 'VE', text:'베네수엘라'});
      rtn.push ({value: 'VN', text:'베트남'});
      rtn.push ({value: 'BE', text:'벨기에'});
      rtn.push ({value: 'BY', text:'벨라루스'});
      rtn.push ({value: 'BZ', text:'벨리즈'});
      rtn.push ({value: 'BA', text:'보스니아헤르체고비나'});
      rtn.push ({value: 'BW', text:'보츠와나'});
      rtn.push ({value: 'BO', text:'볼리비아'});
      rtn.push ({value: 'BI', text:'부룬디'});
      rtn.push ({value: 'BF', text:'부르키나 파소'});
      rtn.push ({value: 'BT', text:'부탄'});
      rtn.push ({value: 'KP', text:'북한'});
      rtn.push ({value: 'BG', text:'불가리아'});
      rtn.push ({value: 'BR', text:'브라질'});
      rtn.push ({value: 'BN', text:'브루나이'});
      rtn.push ({value: 'SA', text:'사우디아라비아'});
      rtn.push ({value: 'SM', text:'산마리노'});
      rtn.push ({value: 'EH', text:'서사하라'});
      rtn.push ({value: 'SN', text:'세네갈'});
      rtn.push ({value: 'RS', text:'세르비아'});
      rtn.push ({value: 'SC', text:'세이셸'});
      rtn.push ({value: 'LC', text:'세인트루시아'});
      rtn.push ({value: 'SO', text:'소말리아'});
      rtn.push ({value: 'SB', text:'솔로몬제도'});
      rtn.push ({value: 'SD', text:'수단'});
      rtn.push ({value: 'SR', text:'수리남'});
      rtn.push ({value: 'LK', text:'스리랑카'});
      rtn.push ({value: 'SZ', text:'스와질란드'});
      rtn.push ({value: 'SE', text:'스웨덴'});
      rtn.push ({value: 'CH', text:'스위스'});
      rtn.push ({value: 'ES', text:'스페인'});
      rtn.push ({value: 'SK', text:'슬로바키아'});
      rtn.push ({value: 'SI', text:'슬로베니아'});
      rtn.push ({value: 'SY', text:'시리아'});
      rtn.push ({value: 'SL', text:'시에라 리온'});
      rtn.push ({value: 'SG', text:'싱가포르'});
      rtn.push ({value: 'AE', text:'아랍에미리트'});
      rtn.push ({value: 'AM', text:'아르메니아'});
      rtn.push ({value: 'AR', text:'아르헨티나'});
      rtn.push ({value: 'IS', text:'아이슬란드'});
      rtn.push ({value: 'HT', text:'아이티'});
      rtn.push ({value: 'IE', text:'아일랜드'});
      rtn.push ({value: 'AZ', text:'아제르바이잔'});
      rtn.push ({value: 'AF', text:'아프가니스탄'});
      rtn.push ({value: 'AD', text:'안도라'});
      rtn.push ({value: 'AL', text:'알바니아'});
      rtn.push ({value: 'DZ', text:'알제리'});
      rtn.push ({value: 'AO', text:'앙골라'});
      rtn.push ({value: 'AG', text:'앤티가바부다'});
      rtn.push ({value: 'ER', text:'에리트리아'});
      rtn.push ({value: 'EE', text:'에스토니아'});
      rtn.push ({value: 'EC', text:'에콰도르'});
      rtn.push ({value: 'ET', text:'에티오피아'});
      rtn.push ({value: 'GB', text:'영국'});
      rtn.push ({value: 'YE', text:'예멘'});
      rtn.push ({value: 'OM', text:'오만'});
      rtn.push ({value: 'AT', text:'오스트리아'});
      rtn.push ({value: 'HN', text:'온두라스'});
      rtn.push ({value: 'JO', text:'요르단'});
      rtn.push ({value: 'UG', text:'우간다'});
      rtn.push ({value: 'UY', text:'우루과이'});
      rtn.push ({value: 'UZ', text:'우즈베키스탄'});
      rtn.push ({value: 'UA', text:'우크라이나'});
      rtn.push ({value: 'IQ', text:'이라크'});
      rtn.push ({value: 'IR', text:'이란'});
      rtn.push ({value: 'IL', text:'이스라엘'});
      rtn.push ({value: 'EG', text:'이집트'});
      rtn.push ({value: 'IT', text:'이탈리아'});
      rtn.push ({value: 'IN', text:'인도'});
      rtn.push ({value: 'ID', text:'인도네시아'});
      rtn.push ({value: 'JP', text:'일본'});
      rtn.push ({value: 'JM', text:'자메이카'});
      rtn.push ({value: 'ZM', text:'잠비아'});
      rtn.push ({value: 'GQ', text:'적도기니'});
      rtn.push ({value: 'GE', text:'조지아'});
      rtn.push ({value: 'CN', text:'중국'});
      rtn.push ({value: 'CF', text:'중앙아프리카공화국'});
      rtn.push ({value: 'DJ', text:'지부티'});
      rtn.push ({value: 'TD', text:'챠드'});
      rtn.push ({value: 'CZ', text:'체코'});
      rtn.push ({value: 'CL', text:'칠레'});
      rtn.push ({value: 'CM', text:'카메룬'});
      rtn.push ({value: 'CV', text:'카보베르데'});
      rtn.push ({value: 'KZ', text:'카자흐스탄'});
      rtn.push ({value: 'QA', text:'카타르'});
      rtn.push ({value: 'KH', text:'캄보디아'});
      rtn.push ({value: 'CA', text:'캐나다'});
      rtn.push ({value: 'KE', text:'케냐'});
      rtn.push ({value: 'KM', text:'코모로'});
      rtn.push ({value: 'CR', text:'코스타리카'});
      rtn.push ({value: 'CI', text:'코트디부아르'});
      rtn.push ({value: 'CO', text:'콜롬비아'});
      rtn.push ({value: 'CG', text:'콩고'});
      rtn.push ({value: 'CD', text:'콩고민주공화국'});
      rtn.push ({value: 'CU', text:'쿠바'});
      rtn.push ({value: 'KW', text:'쿠웨이트'});
      rtn.push ({value: 'HR', text:'크로아티아'});
      rtn.push ({value: 'KG', text:'키르기스스탄'});
      rtn.push ({value: 'KI', text:'키리바시'});
      rtn.push ({value: 'CY', text:'키프로스'});
      rtn.push ({value: 'TJ', text:'타지키스탄'});
      rtn.push ({value: 'TZ', text:'탄자니아'});
      rtn.push ({value: 'TH', text:'태국'});
      rtn.push ({value: 'TR', text:'터키'});
      rtn.push ({value: 'TG', text:'토고'});
      rtn.push ({value: 'TO', text:'통가'});
      rtn.push ({value: 'TM', text:'투르크메니스탄'});
      rtn.push ({value: 'TV', text:'투발루'});
      rtn.push ({value: 'TN', text:'튀니지'});
      rtn.push ({value: 'TT', text:'트리니다드토바고'});
      rtn.push ({value: 'PA', text:'파나마'});
      rtn.push ({value: 'PY', text:'파라과이'});
      rtn.push ({value: 'PK', text:'파키스탄'});
      rtn.push ({value: 'PG', text:'파푸아 뉴기니'});
      rtn.push ({value: 'PW', text:'팔라우'});
      rtn.push ({value: 'PS', text:'팔레스타인'});
      rtn.push ({value: 'PE', text:'페루'});
      rtn.push ({value: 'PT', text:'포르투갈'});
      rtn.push ({value: 'PL', text:'폴란드'});
      rtn.push ({value: 'PR', text:'푸에르토 리코'});
      rtn.push ({value: 'FR', text:'프랑스'});
      rtn.push ({value: 'FJ', text:'피지'});
      rtn.push ({value: 'FI', text:'핀란드'});
      rtn.push ({value: 'PH', text:'필리핀'});
      rtn.push ({value: 'HU', text:'헝가리'});
      rtn.push ({value: 'AU', text:'호주'});
      rtn.push ({value: 'HK', text:'홍콩'});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::natnNatCItems ]___[ 종료 ]");
    },
  },
  /*********************************************************************************
   * Watch 설정 영역
   *********************************************************************************/
  watch: {
    //
  },
  /*********************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   *********************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    // const lv_Vm = this;
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /*********************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   *********************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog (this.$options.screenId);
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /*********************************************************************************
   * [사용자정의 함수, 이벤트 핸들러, 콜백] Methods 설정 영역
   *********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      this.ds_searchReportList = [];
      // ---------
      // ■ [실제소유자확인] 정보항목 매핑 정의
      //   +. AS-IS: TPUP95320.xfdl---AML송수신승인처리팝업
      //   +. 기본정보: cdd_pers_form_0700.mrd		TS000039_실제소유자 확인서_개인
      // ---------
      this.ds_searchParam = {
        chkYn     : this.chkYn    , // 실제소유자 여부체크박스 (다를경우 화면단에서 입력) (1,2) [Y/N]
        rlOwnMnNm : this.rlOwnMnNm, // 실제소유자 확인사항_성명 (다를경우 화면단에서 입력)
        rcnoEncr  : this.rcnoEncr , // 실제소유자 확인사항_실명번호 (다를경우 화면단에서 입력)
        natnNatC  : this.natnNatC , // 실제소유자 확인사항_국적 (다를경우 화면단에서 입력)
        chkYn     : this.chkYn    , // 실제소유자 여부체크박스 (다를경우 화면단에서 입력) (1,2) [Y/N]
        rlOwnMnNm : this.rlOwnMnNm, // 실제소유자 확인사항_성명 (다를경우 화면단에서 입력)
        rcnoEncr  : this.rcnoEncr , // 실제소유자 확인사항_실명번호 (다를경우 화면단에서 입력)
        natnNatC  : this.natnNatC , // 실제소유자 확인사항_국적 (다를경우 화면단에서 입력)

      };
      // 화면내 추출 파라미터
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ this.tfcp11000Rst → ", this.tfcp11000Rst);
      if (this.isLoggable) console.log ("+++++++++");
      // 리포트 파라미터 내 파라미터
      // this.ds_searchParam.tacno = this.acno.substring (0, 7);
      // this.ds_searchParam.bnkbSeq = this.acno.substring (7, 10);
      this.ds_searchParam.csId = this.csId;
      // this.ds_searchParam.rcnoKindTc = this.rcnoKindTc;
      this.ds_searchParam.rcnoKindTc = "01";
      this.ds_searchParam.rcno = this.rcno;
      this.ds_searchParam.bnfcTc = this.bnfcTc;
      this.ds_searchParam.bnfcCsId = this.bnfcCsId;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷▷ this.ds_searchParam → ", this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      this.ds_searchReport.reportMrdNm = "TS000107"; // 서식폼ID 
      // ASR240500691 / [사랑on신탁] 자금세탁 위험평가 제도 대응
      this.ds_searchReport.reportUrl = ""; // 서식 PO서비스 ID:
      // this.ds_searchReport.reportUrl = "reportTFCP11000"; // 서식 PO서비스 ID:
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷▷▷ this.ds_searchReport → ", this.ds_searchReport);
      if (this.isLoggable) console.log ("+++++++++");
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
      this.fn_searchReport ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
     * 리포트 데이터 조회 
     ***************************************************************************************/
    async fn_searchReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      let formList = [];
      let TS000107 = {};
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = {
          formId: this.ds_searchReportList[i].reportMrdNm,
        };
        report.params = this.ds_searchReport;
        formList.push (report);
      }
      // TSInfoUtil.commReportInfo (this, formList, "", false, "MSPTS304P");
      // debugger;
      if (this.isProcessCheck) { // 프로세스 진행중일 경우
        if (!this.isLastProcessCheck) { // (프로세스 진행 중) 마지막 단계가 아닐 경우
          // TSInfoUtil.commReportInfo (this, this.ds_resultReportList, this.fn_goPage , false);
          TSInfoUtil.commReportInfo (this, formList, this.fn_Receipt , false);
        }
        else { // (프로세스 진행 중) 마지막 단계일 경우
          TSInfoUtil.commReportInfo (this, formList);
        }
      }
      else { // 프로세스 진행 중이 아닐 경우, 개별 화면
        TSInfoUtil.commReportInfo (this, formList);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    // ====================================
    // (이벤트 핸들러) fn_OnSearch: 실명번호 체크(공통)
    // ※ JIRA 이슈[ PMPM22000016-803 ] 조치, added by anarchi (2023.07.12)
    // ====================================
    fn_OnSearch () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OnSearch ]___[ 시작 ]");
      // 실명번호 기본 체크
      if ("N" == this.rlOwnMnCnfmYn) {
        if (!this.fn_CommResChk ()) {
          return;
        }
      }
      // 실제소유자 확인/등록 처리,
      this.fn_Insert ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OnSearch ]___[ 종료 ]");
    },
    // ====================================
    // (이벤트 핸들러) fn_CommResChk: 실명번호 체크(공통)
    // ※ JIRA 이슈[ PMPM22000016-803 ] 조치, added by anarchi (2023.07.12)
    // ====================================
    // fn_commResChk () {
    fn_CommResChk () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CommResChk ]___[ 시작 ]");
      // ★★★ 동일 함수명 소문자, 대문자 상이하게 코딩함, debugged by anarchi (2023.08.07)
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS304P ]__[ METHODS:::fn_commResChk ]___[ 시작 ]");
      this.lv_cob_rname_gb.error = false;
      this.lv_ed_rname_no.error = false;
      // if (TSCommUtil.gfn_length (this.lv_ed_rname_no.value1) != 6 || !TSCommUtil.gfn_isNum (this.lv_ed_rname_no.value1)) {
      //   TSCommUtil.gfn_validate (this, "실명번호의 형식에 맞지 않습니다.");
      //   this.lv_ed_rname_no.error = true;
      //   this.$refs['ed_rname_no1'].focus ();
      //   return false;
      // }
      // else if (TSCommUtil.gfn_length (this.lv_ed_rname_no.value2) != 7 || !TSCommUtil.gfn_isNum (this.lv_ed_rname_no.value2)) {
      //   TSCommUtil.gfn_validate (this, "실명번호의 형식에 맞지 않습니다.");
      //   this.lv_ed_rname_no.error = true;
      //   this.$refs['ed_rname_no2'].focus ();
      //   return false;
      // }
      // const _that = this;
      if (TSCommUtil.gfn_length (this.rcno1) != 6 || !TSCommUtil.gfn_isNum (this.rcno1)) {
        TSCommUtil.gfn_validate (this, "실명번호의 형식에 맞지 않습니다. 1");
        this.lv_ed_rname_no.error = true;
        this.$refs['ed_rname_no1'].focus ();
        return false;
      }
      // else if (TSCommUtil.gfn_length (this.rcno2) != 7 || !TSCommUtil.gfn_isNum (this.rcno2)) {
      //   TSCommUtil.gfn_validate (this, "실명번호의 형식에 맞지 않습니다. 2");
      //   this.lv_ed_rname_no.error = true;
      //   this.$refs['ed_rname_no2'].focus ();
      //   return false;
      // }
      // ------------------------------------------------------
      // ♣ (모바일공통) 보안키패드 적용 관련 추가처리, added by anarchi (2023.08.22)
      if (!this.isMobile) {
        if (TSCommUtil.gfn_length (this.rcno2) != 7 || !TSCommUtil.gfn_isNum (this.rcno2)) {
          TSCommUtil.gfn_validate (this, "(W)실명번호의 형식에 맞지 않습니다.");
          this.lv_ed_rname_no.error = true;
          this.$refs['ed_rname_no2'].focus ();
          return false;
        }
      }
      else {
        if (TSCommUtil.gfn_length (this.lv_masked_val) != 7) {
          this.lv_ed_rname_no.error = true; // *필요없을 것 같으나, 넣어줌
          TSCommUtil.gfn_validate (this, "(M)실명번호의 형식에 맞지 않습니다.");
          return false;
        }
      }
      // ------------------------------------------------------
      return true;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CommResChk ]___[ 종료 ]");
    },
    // ====================================
    // (이벤트 핸들러) fn_NextFocus: 실명번호(앞자리)입력 시, 포커스 이동
    // ※ JIRA 이슈[ PMPM22000016-803 ] 조치, added by anarchi (2023.07.12)
    // ====================================
    fn_NextFocus (event) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_NextFocus ]___[ 시작 ]");
      if (TSCommUtil.gfn_length (this.rcno1) == 6) {
        if (TSCommUtil.gfn_isNum (event.key) || event.key == "v") {
          this.$refs["ed_rname_no2"].focus ();
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_NextFocus ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [화면초기화]_[(송신)-파라미터]", " param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------------------------------------------
      // ♣ (모바일공통) 보안키패드 적용 관련 추가처리
      this.lv_isClear = !this.lv_isClear ? true : false;
      // ------------------------------------------------------
      // ------------------
      // ※ JIRA 이슈[ PMPM22000016-899 ] 조치함, amended by anarchi (2023.07.11)
      //   → AML등록 시, 실제소유자 여부 항목이 [예]인 경우 모든 입력항목 비활성화 처리함
      // ------------------
      this.disRlOwnMnCnfmYn = true;
      if (_.isEmpty (param.tacno)) {
        let t_popupObj = {
          confirm: false,
          content: "종합계좌번호가 제대로 넘어오지 않았습니다.",
        };
        this.$refs.alertPopup.fn_OpenA (t_popupObj);
        return;
      }
      if (_.isEmpty (param.bnkbSeq)) {
        let t_popupObj = {
          confirm: false,
          content: "통장일련번호가 제대로 넘어오지 않았습니다.",
        };
        this.$refs.alertPopup.fn_OpenA (t_popupObj);
        return;
      }
      if (_.isEmpty (param.ctno)) {
        let t_popupObj = {
          confirm: false,
          content: "계약번호가 제대로 넘어오지 않았습니다.",
        };
        this.$refs.alertPopup.fn_OpenA (t_popupObj);
        return;
      }
      // if (_.isEmpty (param.procSeq)) {
      //   let t_popupObj = {
      //     confirm: false,
      //     content: "처리일련번호가 제대로 넘어오지 않았습니다.",
      //   };
      //   this.$emit ("ts-alert-popup", 0, t_popupObj);
      //   return;
      // }
      if (_.isEmpty (param.idtyVrfcYn)) {
        let t_popupObj = {
          confirm: false,
          content: "신원검증여부가 제대로 넘어오지 않았습니다.",
        };
        this.$refs.alertPopup.fn_OpenA (t_popupObj);
        return;
      }
      if (param.idtyVrfcYn !== "Y") {
        this.idtyVrfcYn = "N";
        this.disRlOwnMnCnfmYn = false;
      }
      else {
        this.idtyVrfcYn = "Y";
        this.disRlOwnMnCnfmYn = true;
      }
      // this.fn_IdenVeriYnChange ();
      this.fn_Search (param);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_IdenVeriYnChange
     * 설명       : 실제소유자여부 변경
     ******************************************************************************/
    fn_IdenVeriYnChange () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdenVeriYnChange ]___[ 시작 ]");
      // ------------------
      // ※ JIRA 이슈[ PMPM22000016-643 ] 조치함, amended by anarchi (2023.07.12)
      //   → (실제소유자 확인(개인) 팝업에서) 실제소유자여부 중 아니오 선택 시, 입력값이 초기화되는 현상 개선함
      // ------------------
      if (this.rlOwnMnCnfmYn === "N") {
        this.disRlOwnMnCnfmYn = false;
      }
      else {
        this.disRlOwnMnCnfmYn = true;
        this.cnm = "";
        this.rcno = "";
        this.rcno1 = "";
        this.rcno2 = "";
        this.natnNatC = "";
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdenVeriYnChange ]___[ 종료 ]");
    },
    // ====================================
    // (이벤트 핸들러) fn_Open: 
    // ====================================
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [304P_공통팝업]_[(수신)-파라미터]", " param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      this.fn_Init (param);
      this.lv_Params = param;
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.$emit ("ts-popup304-callback", "");
      this.modal.close ();
      this.closeDropdown ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ====================================
    // (이벤트 핸들러) closeDropdown: 
    // ====================================
    closeDropdown () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 시작 ]");
      this.$refs.dropdown1.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      this.pOrgData116P = {};
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      const lv_Vm = this // (현) Vue객체모델 인스턴스
      if (!_.isEmpty (this.lv_Params.idtyVrfcYn)) {
        if (this.lv_Params.idtyVrfcYn !== 'Y') {
          this.disIdenVeriYn = false;
          this.disRlOwnMnCnfmYn = false;
        }
        else {
          this.disIdenVeriYn = true;
          this.disRlOwnMnCnfmYn = true;
        }
      }
      let pParams = {
        rlOwnMnCnfmYn: param.rlOwnMnCnfmYn,
        refNo: param.refNo,
        tacno: param.tacno,
        bnkbSeq: param.bnkbSeq,
        ctno: param.ctno,
        procSeq: param.procSeq,
        idtyVrfcYn: param.idtyVrfcYn,
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600221_S' |
      // 'UF10600221' // [NEXT 사랑On 신탁]실제소유자확인(개인) 상세조회-selectTPUP96040
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = 'S';
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600221_S';
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자확인(개인) 상세조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      },'UF10600221');
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자확인(개인) 상세조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // tpup95330List = pResultData.data.tpup95330List;
      this.csId = res.data.csId;
      this.rcnoKindTc = res.data.rcnoKindTc;
      this.cnm = res.data.rlOwnMnNm;
      // ---------
      // 실명번호 앞자리/뒷자리 파싱 처리
      // ---------
      this.rcno = res.data.rlOwnMnRrno;
      this.rcno1 = res.data.rlOwnMnRrno.substr (0, 6);
      this.rcno2 = res.data.rlOwnMnRrno.substr (6, 7);
      // if (!TSCommUtil.gfn_isNull (res.data.rlOwnMnRrno) && 13 == TSCommUtil.gfn_trim (this.res.data.rlOwnMnRrno.length)) {
      //   this.rcno1 = res.data.rlOwnMnRrno.substr (0, 6);
      //   this.rcno2 = res.data.rlOwnMnRrno.substr (7, 13);
      // }
      this.natnNatC = res.data.rlOwnMnNatC;
      if (res.data.rlOwnMnCnfmYn !== '') {
        this.rlOwnMnCnfmYn = res.data.rlOwnMnCnfmYn;
        // 
      }
      else {
        this.rlOwnMnCnfmYn = "Y";
      }
      // this.rlOwnMnCnfmYn = 'Y';
      if ("Y" != this.rlOwnMnCnfmYn) {
        this.disRlOwnMnCnfmYn = false;
      }
      else {
        this.disRlOwnMnCnfmYn = true;
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Insert
     * 설명       : 등록
     ******************************************************************************/
    fn_Insert () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 시작 ]");
      if (this.rlOwnMnCnfmYn === "N") {
        if (_.isEmpty (this.cnm)) {
          let t_popupObj = {
            confirm: false,
            content: "성명을 입력해주세요.",
          };
          this.$refs.alertPopup.fn_OpenA (t_popupObj);
          return;
        }
        if (_.isEmpty (this.rcno1)) {
          let t_popupObj = {
            confirm: false,
            content: "실명번호를 입력해주세요.",
          };
          this.$refs.alertPopup.fn_OpenA (t_popupObj);
          return;
        }
        if (_.isEmpty (this.natnNatC)) {
          let t_popupObj = {
            confirm: false,
            content: "국적을 선택해주세요.",
          };
          this.$refs.alertPopup.fn_OpenA (t_popupObj);
          return;
        }
      }
      // ------------------
      // ※ JIRA 이슈[ PMPM22000016-900 ] 조치함, amended by anarchi (2023.07.12)
      //   → (AML송수신승인처리P에서) 실제소유자 [예]로 선택후 등록시, 등록후 해당 (실제소유자 확인(개인))팝업 닫기 처리함
      // ------------------
      let t_popupObj = {
        confirm: true,
        content: "저장하시겠습니까?",
        confirmFunc: this.fn_InsertConfirm,
        closeFunc: this.fn_InsertConfirmClose,
      };
      // this.$emit ("ts-alert-popup", 0, t_popupObj);
      // this.$refs.alertPopup.fn_OpenA (t_popupObj);
      this.$refs.alertPopup_304p.fn_OpenA (t_popupObj);
      // this.fn_AlertPopup (0, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_InsertConfirm
     * 설명       : 등록 선택
     ******************************************************************************/
    fn_InsertConfirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirm ]___[ 시작 ]");
      const lv_vm = this; // (현) Vue객체모델 인스턴스
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [실제소유자 실명번호(전체)]", " this.rcno → ", this.rcno);
      // if (this.isLoggable) console.log ("+ ▶ [실제소유자 실명번호(앞)]", " this.rcno → ", this.rcno1);
      // if (this.isLoggable) console.log ("+ ▶ [실제소유자 실명번호(뒤)]", " this.rcno → ", this.rcno2);
      // if (this.isLoggable) console.log ("+++++++++");
      let _rcno = (13 == this.rcno.length) ? this.rcno : this.rcno1 + this.rcno2;
      let pParams = {
        tftarl: {
          rlOwnMnCnfmYn: this.rlOwnMnCnfmYn,
          refNo: this.lv_Params.refNo,
          tacno: this.lv_Params.tacno,
          bnkbSeq: this.lv_Params.bnkbSeq,
          ctno: this.lv_Params.ctno,
          procSeq: this.lv_Params.procSeq,
          idtyVrfcYn: this.lv_Params.idtyVrfcYn,
        },
        tftarlVO: {
          refNo: this.lv_Params.refNo,
          tacno: this.lv_Params.tacno,
          bnkbSeq: this.lv_Params.bnkbSeq,
          ctno: this.lv_Params.ctno.padStart (3, "0"),
          procSeq: this.lv_Params.procSeq,
          idtyVrfcYn: this.lv_Params.idtyVrfcYn,
          rlOwnMnCnfmYn: this.rlOwnMnCnfmYn,
          rlOwnMnNm: this.cnm,
          // rlOwnMnRrno: this.rcno1,
          rlOwnMnRrno: _rcno,
          rlOwnMnNatC: this.natnNatC,
        },
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600222_S' |
      // 'UF10600222' // [NEXT 사랑On 신탁]실제소유자확인(개인) 수정-updateTPUP96040
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      // this.eaiCommObj.trnstId = "updateTPUP96040";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600222_S";
      this.eaiCommObj.params = pParams.tftarlVO;
      let lv_w = this;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자확인(개인) 수정]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, function (response) {
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [실제소유자확인(개인) 수정]_[EAI(PO)_(", response.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " response → ", response);
        if (this.isLoggable) console.log ("+++++++++");
        if (response && response.data) {
          // this.rlOwnMnCnfmYn = "Y";
          lv_w.fn_IdenVeriYnChange ();
          // ------------------
          // ※ JIRA 이슈[ PMPM22000016-900 ] 조치함, amended by anarchi (2023.07.12)
          //   → (AML송수신승인처리P에서) 실제소유자 [예]로 선택후 등록시, 등록후 해당 (실제소유자 확인(개인))팝업 닫기 처리함
          // ------------------
          lv_w.fn_Close ();
        }
      }, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600222");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirm ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_InsertConfirmClose
     * 설명       : 
     ******************************************************************************/
    fn_InsertConfirmClose () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirmClose ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirmClose ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_InserrtConfirmResult
     * 설명       : 등록 콜백
     ******************************************************************************/
    fn_InserrtConfirmResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InserrtConfirmResult ]___[ 시작 ]");
      if (this.isLoggable) console.log (res);
      // this.rlOwnMnCnfmYn = 'Y';
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InserrtConfirmResult ]___[ 종료 ]");
    },
    // ======================================================
    // (화면제어) fn_AlertPopup:: 공통팝업호출 처리오류 코드 전면수정, corrected by anarchi (2023.08.07)
    // ======================================================
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [로컬메시지팝업_304P]", " type → ", type, " pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_304p, pPopupObj);
          }
          this.$refs.alertPopup_304p.fn_Open ();
          break;
        case 2:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (0, this.pAlertPopupObj_304p, pPopupObj);
          }
          this.$refs.alertPopupSec.fn_Open ();
          break;
        case 7: // 마지막 얼럿창
          this.$refs.alertPopup_304p.fn_Open ();
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_304p, pPopupObj);
          }
          this.$refs.alertPopup_304p.fn_Open ();
          break;
        default:
          this.$refs.alertPopup_304p.fn_Open ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    // ======================================================
    // (화면요소 제어--콜백) returnEvt:: 팝업 확인, 닫기 시
    // ======================================================
    returnEvt (value) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::returnEvt ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [알림창 emit 이벤트]", " value → ", value);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::returnEvt ]___[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ======================================================
    // (이벤트 핸들러) fn_SetMaskedTxt:: 실명번호(뒷자리) 보안키패드 Key-Up 시
    // ======================================================
    fn_SetMaskedTxt (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 시작 ]");
      this.lv_masked_val = val;
      if (this.lv_masked_val.length >= 7) {
        // *보안키패드 입력 완료 시 호출할 메소드
        this.fn_OnSearch ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt ]___[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  },
}
</script>
