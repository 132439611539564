/*
 * 업무구분: 개인EDD등록
 * 화 면 명: MSPTS306P
 * 화면설명: 개인EDD등록
 * 작 성 일: 2023.03.20
 * 작 성 자: 김경태 (전면개정 -> refined by anarchi) 
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="개인EDD등록">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis">종합 계좌번호</label>
              <mo-text-field v-model="tacnoSrch" class="w130" disabled/>
              <mo-text-field v-model="cnmSrch" class="w130" disabled/>
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <!-- 전자서식(리포트)출력 연계개발/검증을 위한 임시 추가, added by anarchi, 2023.07.24 //-->
              <!-- <mo-button @click="fn_confirmPrintTrue (true)" primary> 전자문서 조회 </mo-button> -->
              <mo-button primary @click="fn_Search" class="inquiry"> 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-table-title">
          <h2 class="table-title"> 기본정보 </h2>
        </div>
        <div class="wrap-table mt-3">
          <table class="table col-type">
          <colgroup>
            <col width="20%">
            <col width="30%">
            <col width="20%">
            <col width="30%">
          </colgroup>
          <tbody>
            <tr>
              <th><span> 성명<br>(외국인 영문명) </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-if="isEnnmUseYn" v-model="dsDetail.csEnnm" class="input-long" disabled />
                  <mo-text-field v-else v-model="dsDetail.cnm" class="input-long" disabled />
                </div>
              </td>
              <th><span> 실명번호 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.rcno" class="input-long" disabled/>
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 직장명 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.offcNm" class="input-long" disabled/>
                </div>
              </td>
              <th><span> 부서명 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.dpnm" class="input-long"  disabled/>
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 직위명 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.jobps" class="input-long" disabled />
                </div>
              </td>
              <th><span> 입사일자 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field class="input-long" v-model="dsDetail.incoDate" disabled />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 보험직업구분 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.insrJobTcNm" class="input-long" disabled/>
                </div>
              </td>
              <th><span> 직업/직종<br>보험직업코드 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.insrJobCNm" class="input-long" disabled/>
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 고액자산가여부 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-radio-wrapper :items="hamtWlprsYnItems" v-model="dsDetail.hamtWlprsYn" class="row"/>
                </div>
              </td>
              <th><span> 개인사업자<br>(사업자등록번호) </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="dsDetail.persnBusiMnBsno" class="input-long" maxlength="10" @keyup="fn_comEvent ($event)" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 총자산 현황(억원) </span></th>
              <td colspan="3">
                <mo-radio-wrapper :items="totAstsPnstaTcItems" v-model="dsDetail.totAstsPnstaTc" class="row justify-end; align:left"/>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 자금출처구분 </span></th>
              <td colspan="3">
                <ul class="wrap-radiolist">
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="01">근로및연금소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="02">퇴직소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="03">사업소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="04">부동산임대소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="05">부동산양도소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="06">금융소득</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="07">상속/증여</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.fdscTypeC" value="08">일시재산양도로인한소득</mo-radio></li>
                  <li>
                    <div class="row">
                      <mo-radio v-model="dsDetail.fdscTypeC" value="99">기타</mo-radio>
                      <mo-text-field class="input-long ml-1" v-model="dsDetail.fdSrcOrgplEtcCntn" placeholder="입력하세요" />
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th><li class="emphasis"> 가입사유 </li></th>
              <td colspan="3">
                <ul class="wrap-radiolist">
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="01">가족보장</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="04">자녀양육비</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="07">재계약</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="02">노후준비</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="05">저축</mo-radio></li>
                  <li class="row">
                    <mo-radio v-model="dsDetail.trPpsTc" value="99">기타</mo-radio>
                    <mo-text-field class="input-long ml-1" v-model="dsDetail.trPpsEtc" placeholder="입력하세요" />
                  </li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="03">상속준비</mo-radio></li>
                  <li><mo-radio v-model="dsDetail.trPpsTc" value="06">투자면제</mo-radio></li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Insert">등록</mo-button>
      </div>
    </template>
  </mo-modal>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import _ from "lodash"
// import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
// import TSHeader from "~/src/ui/ts/comm/TSHeader" // header 영역 (공통)
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: "MSPTS306P",
  // 현재 화면 ID
  screenId: "MSPTS306P",
  // 컴포넌트 선언
  components: {
    "ts-alert-popup": TSAlertPopup,
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String, // 부모 페이지명
    pGdC: String, // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      csId: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      rcno: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      rcnoKindTc: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      isEnnmUseYn: false, // 외국인(외국인등록번호) 영문이름 사용여부
      // 공통 변수
      isInit: false, // 초기화 여부
      tacnoSrch: "",
      cnmSrch: "",
      rlOwnMnCnfmYn: "Y",
      natnNatC: "KR",
      idenVeriYn: true,
      tacno: "",
      cnm: "",
      offcNm: "",
      dpNm: "",
      jobps: "",
      incoDate: "",
      insrJobTc: "",
      insrJobC: "",
      hamtWlprsYn: "",
      persnBusiMnBsno: "",
      totAstsPnstaTc: "",
      fdscType: "",
      fdSrcOrgplEtcCntn: "",
      trPpsTc: "",
      trPpsEtc: "",
      dsDetail: {},
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      alertPopup: {},
      pAlertPopupObj: {},
      tpup95330List: {},
      lv_IsPrint: false, // lv(local value) 프린트 여부
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // 개별업무 프로세스 진행상태 여부, 
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
    hamtWlprsYnItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::hamtWlprsYnItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "Y", text: "예"});
      rtn.push ({value: "N", text: "아니오"});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::hamtWlprsYnItems ]___[ 종료 ]");
    },
    totAstsPnstaTcItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::totAstsPnstaTcItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "1", text: "10억미만"});
      rtn.push ({value: "2", text: "10억원~100억원"});
      rtn.push ({value: "3", text: "100억원~1,000억원"});
      rtn.push ({value: "4", text: "1,000억원초과"});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::totAstsPnstaTcItems ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    //
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    // const lv_Vm = this;
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS306P");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue (isPrint = false) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      this.lv_IsPrint = isPrint;
      this.ds_searchReportList = [];
      // ---------
      // ■ [TS000040_개인 추가정보 확인서(EDD,PEP)] 정보항목 매핑 정의
      //   +. AS-IS: TPUP95320.xfdl---개인EDD등록팝업
      //             TPUP95022.mrd
      // var reportMrdNm = "/cronix/fiduciary/popup/TPUP95022.mrd"; // 보고서 파일 경로
      // var reportUrl = ""; //"reportTFCP11000.do"; // 보고서 호출 서비스url
      // // var reportUrl = "reportTFCP11002.do"; // 보고서 호출 서비스url
      // ---------
      let _mappingParam = {};
      // EDD 개인추가 정보항목 (등록시, 사용자 입력/조회값) 객체복사
      if (!_.isEmpty (this.tpup95330List)) {
        _mappingParam = _.cloneDeep (this.tpup95330List);
        this.ds_searchParam = {
          trPpsTc             : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 거래관련 기본정보_거래의 목적 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5,6,7) [01.가족보장,02.노후준비,03.상속준비,04.자녀양육비,05.저축,07.재계약,99.기타,]
          trPpsTcEtc          : (!_.isEmpty (_mappingParam.trPpsEtc)) ? _mappingParam.trPpsEtc : "", // 거래 목적_기타_내용 (다를경우 화면단에서 입력) [기타에 들어올 텍스트]
          fdSrcOrgplEtcCntn   : (!_.isEmpty (_mappingParam.fdSrcOrgplEtcCntn)) ? _mappingParam.fdSrcOrgplEtcCntn : "", // 거래관련 기본정보_자금의 원천 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5,6,7,8,9) [01.근로및연금소득,02.퇴직소득,03.사업소득,04.부동산임대소득,05.부동산양도소득,06.금융소득,07.상속/증여,08.일시재산양도로인한소득,99.기타]
          fdSrcOrgplEtcCntnEtc: (!_.isEmpty (_mappingParam.fdSrcOrgplEtcCntnEtc)) ? _mappingParam.fdSrcOrgplEtcCntnEtc : "", // 자금원천_기타_내용 (다를경우 화면단에서 입력) [기타에 들어올 텍스트]
          totAstsPnstaTc      : (!_.isEmpty (_mappingParam.totAstsPnstaTc)) ? _mappingParam.totAstsPnstaTc : "", // 거래관련 기본정보_추정자산 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5) [1. 5억원 미만,2. 5억원 이상 - 10억원 미만,3. 10억원 이상 - 100억원 미만,4. 100억원 이상 - 1000억원 미만,5. 1000억원 이상]
          offwkYn             : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_직업구분 체크박스 (다를경우 화면단에서 입력) (1,2) [1. 직장인,2. 개인사업자]

          coNm                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_직장인 회사/부서명 (다를경우 화면단에서 입력)
          dpnm                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_직장인 회사/부서명 (다를경우 화면단에서 입력)
          jobps               : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_직장인 지위 (다를경우 화면단에서 입력)
          incoDate            : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_직장인 인사일자 (다를경우 화면단에서 입력)
          persnBusiMnBsno     : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_개인사업자 사업자등록번호 (다를경우 화면단에서 입력)
          insrStndIndsTypeC   : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_개인사업자 업종 (다를경우 화면단에서 입력)
          estbDate            : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 직업정보_개인사업자 설립일자 (다를경우 화면단에서 입력)
          chkYn               : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 외국의 정치적 주요인물 관련 여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.관련있음 N.관련없음]
          chkYn               : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_고객본인과 동일인 여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.예 N.아니오]
          custNm              : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_계좌주 성명 (다를경우 화면단에서 입력)
          prhsRel             : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_관계 (다를경우 화면단에서 입력)
          rcnoEncr            : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_실명번호 (다를경우 화면단에서 입력)
          btdt                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_생년월일 (다를경우 화면단에서 입력)
          cdiNatnNatC         : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_국적 (다를경우 화면단에서 입력)
          addr                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_주소  (다를경우 화면단에서 입력)
          entrTelNo           : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_연락처 (다를경우 화면단에서 입력)
          chkYn               : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_고객 본인 관여 법인 존재여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.예 N.아니오]
          prhsReComl          : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 
          estbPpsTc           : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 
          natC                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_법인/단체명 (다를경우 화면단에서 입력)
          estbPrhsRel         : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_관계 (다를경우 화면단에서 입력)
          estbBsno            : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_법인 등록번호 (다를경우 화면단에서 입력)
          insrStndIndsTypeCom : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_업종 (다를경우 화면단에서 입력)
          natC                : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_설립국가 (다를경우 화면단에서 입력)
          estbAddr            : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_주소  (다를경우 화면단에서 입력)
          estbTelNo           : (!_.isEmpty (_mappingParam.trPpsTc)) ? _mappingParam.trPpsTc : "", // 자금출처관련 정보_연락처 (다를경우 화면단에서 입력)
        };
      }
      // 화면내 추출 파라미터 (조회용 저장 객체)
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ this.ds_searchParam → ", this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      // 리포트 파라미터 내 파라미터
      this.ds_searchParam.csId = this.csId;
      this.ds_searchParam.rcno = this.dsDetail.rcno;
      this.ds_searchParam.rcnoKindTc = this.dsDetail.rcnoKindTc;
      this.ds_searchReport.reportMrdNm = "TS000040"; // 서식폼ID
      this.ds_searchReport.reportUrl = ""; // 서식 PO서비스 ID:
      // this.ds_searchReport.reportUrl = "reportTFCP11000"; // 서식 PO서비스 ID:
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷▷▷ this.ds_searchReport → ", this.ds_searchReport);
      if (this.isLoggable) console.log ("+++++++++");
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
      this.fn_searchReport ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
     * 리포트 데이터 조회 
     ***************************************************************************************/
    async fn_searchReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      let formList = [];
      let TS000040 = {
        formId: this.ds_searchReportList[0].reportMrdNm,
      };
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        // report.params = this.ds_searchReport;
        TS000040.mappingData = this.ds_searchParam;
        formList.push (TS000040);
      }
      // TSInfoUtil.commReportInfo (this, formList);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ formList → ", formList);
      if (this.isLoggable) console.log ("+++++++++");
      let t_IsProcess = this.getStore ("tsStore").getters.getState.isProcess;
      // ---------
      // 전자문서 출력을 위한 마지막 화면 설정
      // ---------
      if (t_IsProcess && this.lv_IsPrint) {
        this.getStore ("tsStore").dispatch ("IS_LAST_START");
      }
      TSInfoUtil.commReportInfo (this, formList, "", this.lv_IsPrint , "MSPTS306P");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      // debugger;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인EDD등록 팝업-초기화]_[(수신)-파라미터]", " param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      if (_.isEmpty (param.tacno)) {
        let t_popupObj = {
          content: "종합계좌번호가 제대로 넘어오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.csId)) {
        let t_popupObj = {
          confirm: false,
          content: "고객ID가 제대로 넘어오지 않았습니다.",
        }
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.cnm)) {
        let t_popupObj = {
          confirm: false,
          content: "고객명이 제대로 넘어오지 않았습니다.",
        }
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      this.isEnnmUseYn = false;
      this.tacnoSrch = param.tacno;
      this.csId = param.csId;
      this.cnmSrch = param.cnm;
      this.fn_Search (param);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_IdenVeriYnChange -> fn_Open
     * 설명       : 실제소유자여부 변경
     ******************************************************************************/
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [개인EDD등록 팝업]_[(수신)-파라미터]", " param → ", param);
      // if (this.isLoggable) console.log ("+++++++++");
      this.modal.open ();
      this.fn_Init (param);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      this.pOrgData116P = {};
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        tacno: param.tacno,
        csId: param.csId,
        cnm: param.cnm,
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600250_S' |
      // 'UF10600250' // // [NEXT 사랑On 신탁]개인EDD 조회
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "selectTPUP95030";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600250_S";
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인EDD 조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600250");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인EDD 조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      if (!_.isEmpty (res.data) && "" == res.error_msg) {
        this.dsDetail = res.data;
        // ------------------
        // □ JIRA이슈::: PMPM22000016-162 [신탁] 영업 > 신규고객 PMPM22000016-1262
        // [신탁/신규신청/개인EDD등록P] 성명(외국인 영문명) 영역에 고객영문명이 찍혀야하는데 고객명이 찍히는 오류
        //     ---> { 내국인/외국인 케이스별 이름 표시 구분 처리함 }, enhanced by anarchi (2023.08.16)
        // ------------------
        if (!TSCommUtil.gfn_isNull (this.dsDetail.ennmUseYn) && "Y" == this.dsDetail.ennmUseYn) {
          this.isEnnmUseYn = true;
        }
        // Jira 실명 번호 뒷자리 마스킹 처리
        if (!TSCommUtil.gfn_isNull (this.dsDetail.rcno)) {
          this.dsDetail.rcno = this.dsDetail.rcno.substr (0, 6) + 
          "-" + 
          this.dsDetail.rcno.substr (6, 1) + 
          "******"; // 실명번호 (마스킹 임시)
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_comEvent
     * 설명       : 한글 입력 방지(숫자만 입력 체크) 
    *******************************************************************************/
    async fn_comEvent (event) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comEvent ]___[ 시작 ]");
      let persnBusiMnBsno = this.dsDetail.persnBusiMnBsno.replace (/[^0-9]/g, '');
      this.dsDetail.persnBusiMnBsno = persnBusiMnBsno;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comEvent ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Insert
     * 설명       : 등록
     ******************************************************************************/
    fn_Insert () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 시작 ]");
      // debugger;
      if (this.isLoggable) console.log ("_.isEmpty", _.isEmpty);
      if (_.isEmpty (this.dsDetail.fdscTypeC)) {
        let t_popupObj = {
          confirm: false,
          content: "자금출처구분을 입력해주세요.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (this.dsDetail.fdscTypeC === "99") {
        if (_.isEmpty (this.dsDetail.fdSrcOrgplEtcCntn)) {
          let t_popupObj = {
            confirm: false,
            content: "자금출처구분이 기타일 때 기타항목을 입력해 주십시오.",
          };
          this.$emit ("ts-alert-popup", 0, t_popupObj);
          return;
        }
      }
      if (_.isEmpty (this.dsDetail.trPpsTc)) {
        let t_popupObj = {
          confirm: false,
          content: "가입사유를 입력해주세요.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (this.dsDetail.trPpsTc === "99") {
        if (_.isEmpty (this.dsDetail.trPpsEtc)) {
          let t_popupObj = {
            confirm: false,
            content: "가입사유가 기타일 때 기타항목을 입력해주세요.",
          };
          this.$emit ("ts-alert-popup", 0, t_popupObj);
          return;
        }
      }
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        csId: this.dsDetail.csId,
        rcno: this.dsDetail.rcno,
        rcnoKindTc: this.dsDetail.rcnoKindTc,
        persnBusiMnBsno: this.dsDetail.persnBusiMnBsno,
        cnm: this.dsDetail.cnm,
        csEnnm: this.dsDetail.csEnnm,
        offcNm: this.dsDetail.offcNm,
        dpnm: this.dsDetail.dpnm,
        jobps: this.dsDetail.jobps,
        incoDate: this.dsDetail.incoDate,
        insrJobTc: this.dsDetail.insrJobTc,
        insrJobTcNm: this.dsDetail.insrJobTcNm,
        insrJobC: this.dsDetail.insrJobC,
        insrJobCNm: this.dsDetail.insrJobCNm,
        hamtWlprsYn: this.dsDetail.hamtWlprsYn,
        totAstsPnstaTc: this.dsDetail.totAstsPnstaTc,
        fdscTypeC: this.dsDetail.fdscTypeC,
        fdSrcOrgplEtcCntn: this.dsDetail.fdSrcOrgplEtcCntn,
        trPpsTc: this.dsDetail.trPpsTc,
        trPpsEtc: this.dsDetail.trPpsEtc,
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600251_S' |
      // 'UF10600251' // [NEXT 사랑On 신탁]개인EDD 등록/수정
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      // this.eaiCommObj.trnstId = "updateTPUP95030";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600251_S";
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인EDD 등록/수정]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_InsertResult, function (error) {
        console.log (error);
      }, "UF10600251");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_InsertResult
     * 설명       : 등록 콜백
     ******************************************************************************/
    fn_InsertResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인EDD 등록/수정]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------------------------
      // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 정보항목 추출 저장, added by anarchi (2023.09.12)
      // ------------------------------------
      // this.tpup95330List = pResultData.data.tpup95330List
      this.tpup95330List = res.data
      let t_popupObj = {
        confirm: false,
        content: "정상적으로 수정되었습니다.",
      };
      this.$emit ("ts-alert-popup", 0, t_popupObj);
      // ------------------------------------
      // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 추가처리 (TOBE 변경요건에 맞추어)
      // ------------------------------------
      this.fn_confirmPrintTrue ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertResult ]___[ 종료 ]");
    },
  }
}
</script>
