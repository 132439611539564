/*
 * 업무구분: 고객조회
 * 화 면 명: MSPTS107P
 * 화면설명: 고객조회
 * 작 성 일: 2023.02.15
 * 작 성 자: 김경태
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="고객조회" style="z-index:2999" >
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="column w100p gap12">
          <div class="row w100p">
            <div class="left">
              <div class="wrap-input row">
                <label class="emphasis"> 고객명 </label>
                <mo-text-field 
                  ref="srchCnm" 
                  v-model="cnm" 
                  class="form-input-name" 
                  :class="errValCnm ? 'error' : ''" 
                  clearable 
                />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 비밀번호 </label>
                  <m-trans-key-input
                    v-if="isMobile"
                    ref="srchPwd" 
                    v-model="pwd" 
                    class="form-input-name" 
                    type="numberMax4" 
                    maxlength="4" 
                    :class="errValPass ? 'error' : ''" 
                    :start="'0'"
                    :end="'-1'"
                    dialog="Y"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt">
                    </m-trans-key-input>
                  <mo-text-field 
                    v-else
                    ref="srchPwd" 
                    v-model="pwd" 
                    class="form-input-name" 
                    type="password" 
                    maxlength="4" 
                    :class="errValPass ? 'error' : ''" 
                    clearable 
                  />
              </div>
            </div>
          </div>
          <div class="row w100p">
            <div class="left gap15p">
              <div class="wrap-input row">
                <label class="emphasis"> 고객구분 </label>
                <mo-radio v-model="csGb" @input="fn_csGbChange.bind (null, 1)" value="1"> 개인 </mo-radio>
                <mo-radio v-model="csGb" @input="fn_csGbChange.bind (null, 2)" value="2" disabled> 법인 </mo-radio>
              </div>
              <div v-if="sexTcView" class="wrap-input row">
                <label class="emphasis"> 성별 </label>
                <mo-radio v-model="sexTc" value="1"> 남 </mo-radio>
                <mo-radio v-model="sexTc" value="2"> 여 </mo-radio>
              </div>
              <div v-if="rcnoView" class="wrap-input row">
                <label> 실명확인번호 </label>
                <mo-text-field 
                  v-model="rcno" 
                  class="form-input-name" 
                  mask="######-#######" 
                  clearable 
                />
              </div>
            </div>
          </div>
          <div class="row w100p">
            <div class="left w100p">
              <div class="wrap-input row">
                <label class="emphasis">고객조회사유</label>
                <mo-dropdown 
                  v-model="cssearchRsnCd" 
                  ref="srchRsnCd" 
                  placeholder="선택하세요" 
                  :items="cssearchRsnCdItems" 
                  :class="errValCssearchRsnCd ? 'error' : ''" 
                  @change="('' != cssearchRsnCd) ? false : true"
                />
              </div>
              <div v-if="cssearchRsnView" class="wrap-input row">
                <label class="emphasis">상세사유</label>
                <mo-text-field 
                  ref="srchRsn" 
                  v-model="cssearchRsn" 
                  class="form-input-name" 
                  :class="errValCssearchRsn ? 'error' : ''" 
                  clearable
                />
              </div>
            </div>
            <div class="right">
              <div class="wrap-button row">
                <mo-button class="btn-clear" @click="fn_Clear"> 초기화 </mo-button>
                <mo-button primary class="bnt-inquiry" @click="fn_Search"> 조회 </mo-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-table-title row">
        <h2 class="table-title"> 고객검색내역 </h2>
        <span class="red"> ※ 종합계좌 미개설 고객의 경우 부점비밀번호 조회가 불가하오니, 성별(개인) 또는 사업자번호(법인)를 입력하여 주시기 바랍니다. </span>
      </div>
      <div class="wrap-table h-scroll-200">
        <table class="table col-type num-type th-row-gray text-center">
          <thead>
            <tr>
              <th class="w40">선택</th>
              <th>종합계좌번호</th>
              <th>고객명</th>
              <th>{{ custPer }}</th>
              <th>관리점</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, idx) in tpup95320List" :key="idx" @click="fn_RowSelected (idx.toString ())">
              <td><mo-radio 
                v-model="lv_SelectedItem" 
                :value="idx.toString ()" 
                @click="fn_RowSelected (idx.toString ())"
              /></td>
              <td>{{data.tacno}}</td>
              <td>{{data.cnm}}</td>
              <td>{{data.btdt}}</td>
              <td>{{data.mngBrnm}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
    <ts-alert-popup 
      ref="alertPopup"
      :alertPopupObj="pAlertPopupObj"
    ></ts-alert-popup>
  </mo-modal>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import _ from "lodash"
// import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

export default {
  /******************************************************************************************
   * Vue 인스턴스 기본속성(화면명, ID 등) 정의
   ******************************************************************************************/
  name: "MSPTS107P", // 현재 화면명
  screenId: "MSPTS107P", // 현재 화면 ID
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
    "ts-alert-popup": TSAlertPopup,
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type:Object,
      default: null
    },
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // 공통 변수
      isInit: false,          // 초기화 여부
      cnm: "",                //고객명
      pwd: "",                //비밀번호
      csGb: "1",              //고객구분
      sexTc: "1",             //성별
      sexTcView: true,       //개인/법인구분
      rcno: "",               //실명확인번호
      rcnoView: false,        //개인/법인구분
      cssearchRsnCdItems: [],
      cssearchRsnCd: "",      //고객조회사유
      cssearchRsn: "",        //상세사유
      cssearchRsnView: false,
      rowSelect: {},
      pAlertPopupObj: {},
      lv_SelectedItem: "",
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      isSucs: false, // 리턴값 세팅여부
      disabedType: false,
      custPer: "생년월일",
      eaiCommObj: {
        lv_vm: "",
        trnstId: "",
        auth: "",
        commHeaderVO: {
          eaiId: "",
          fahrTrnId: "S",
          requestSystemCode: "F1391",
          reqSrvcNm: "",
          reqMthdNm: "",
          targetSystemCode: "",
          resVONm: "",
          reqVONm: "",
        },
        params: {},
        response: [],
        error_msg: ""
      },
      tpup95320List: [],
      errValCnm: false,
      errValPass: false,
      errValCssearchRsnCd: false,
      errValCssearchRsn: false,
      usergrpTc_19: true,
      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      lv_masked_val: '',          // 마스크 변수 체크
      lv_isClear: false,          // 보안키패드 초기화
    };
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    modal () {
      return this.$refs.modal;
    },
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    // 고객조회사유
    cssearchRsnCd: {
      deep: true,
      handler (after, before) {
        if (after === "30") {
          this.cssearchRsnView = true;
        }
        else {
          this.cssearchRsnView = false;
        }
      },
    },
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    this.tpup95320List = []; // 초기화
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS107P");
    // 공통코드 조회
    //this.fn_ComCode ();
    console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Methods 설정 영역
   ******************************************************************************************/
  methods: {
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode () {
      if (this.cssearchRsnCdItems != null && this.cssearchRsnCdItems.length > 0) return;
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600046_S' |
      // 'UF10600046' // [NEXT 사랑On 신탁]콤보 목록을 조회-selectComboList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS90S1" // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        "TUKEMK": [{
          cId: "5180",
        }],
      };
      console.log ("+++++++++");
      console.log ("+ ▶ [콤보 목록을 조회---5180]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      console.log ("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_comCodeCallBack);
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    }, 
    // ======================================================
    // (공통:비동기 서비스 호출--콜백) 메소드명:: 
    // ======================================================
    fn_comCodeCallBack (res) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCodeCallBack ]___[ 시작 ]");
      console.log ("+++++++++");
      console.log ("+ ▶▷ [콤보 목록을 조회---5180]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      console.log ("+++++++++");
      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index];
        switch (item.cid) {
          case "5180":
            this.cssearchRsnCdItems.push ({
              value: item.c,
              text: item.cnm,
            });
            break;
        }
      }
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_comCodeCallBack ]___[ 종료 ]");
    },

    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val 
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (lvParam) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      this.isInit = false; // 초기화 여부
      this.cnm = ""; // 고객명
      this.pwd = ""; // 비밀번호
      this.csGb = "1"; // 고객구분
      this.sexTc = "1"; // 성별
      this.cssearchRsnCd = ""; // 고객조회사유
      this.cssearchRsn = ""; // 상세사유
      this.rowSelect = {};
      this.lv_SelectedItem = "";
      this.errValCnm = false;
      this.errValPass = false;
      this.errValCssearchRsnCd = false;
      this.errValCssearchRsn = false;
      this.tpup95320List = [];
      if (this.pUrl === "2000") {
        this.disabedType = true;
      }

      this.lv_isClear = !this.lv_isClear ? true : false

      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open (properties) {
      this.fn_ComCode ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      this.fn_Clear ();
      this.fn_Init ();
      this.modal.open ();
      this.$refs["srchCnm"].focus ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.modal.close ();
      this.closeDropdown ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    closeDropdown () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 종료 ]");
      this.$refs.srchRsnCd.close ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 시작 ]");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      this.cnm = "";                // 고객명
      this.pwd = "";                // 비밀번호
      this.csGb = "1";              // 고객구분
      this.sexTc = "1";             // 성별
      this.sexTcView = true;        // 개인/법인구분
      this.rcno = "";               // 실명확인번호
      this.rcnoView = false;        // 개인/법인구분
      this.cssearchRsnCd = "";      // 고객조회사유
      this.cssearchRsn = "";        // 상세사유
      this.errValCnm = false;
      this.errValPass = false;
      this.errValCssearchRsnCd = false;
      this.errValCssearchRsn = false;
      this.rowSelect = {};
      this.tpup95320List = [];
      this.custPer = "생년월일";
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_csGbChange
     * 설명       : 개인/법인 선택
     ******************************************************************************/
    fn_csGbChange (type) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_csGbChange ]___[ 시작 ]");
      if (type === 1) {
        this.sexTcView = true;
        this.rcnoView = false;
        this.custPer = "생년월일";
      }
      else {
        this.sexTcView = false;
        this.rcnoView = true;
        this.custPer = "실명확인번호";
      }
      this.rowSelect = {};
      this.tpup95320List = [];
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_csGbChange ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회
     ******************************************************************************/
    fn_Search () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      const lv_Vm = this;
      if (this.cnm.length < 2) {
        TSCommUtil.gfn_validate (this, "고객명을 2글자 이상 입력해 주세요.");
        this.errValCnm = true;
        // this.$refs["srchCnm"].focus ();
        this.$refs.srchCnm.focus ();
        return;
      }
      else {
        this.errValCnm = false;
      }

      if(this.isMobile) {
        if (_.isEmpty (this.lv_masked_val)) {
          TSCommUtil.gfn_validate (this, "비밀번호를 입력해 주세요.");
          this.errValPass = true;
          this.$refs.srchPwd.focus ();
          return;
        }
        else {
          this.errValPass = false;
        }
      } else {
        if (_.isEmpty (this.pwd)) {
          TSCommUtil.gfn_validate (this, "비밀번호를 입력해 주세요.");
          this.errValPass = true;
          // this.$refs["srchPwd"].focus ();
          this.$refs.srchPwd.focus ();
          return;
        }
        else {
          this.errValPass = false;
        }

      }


      if (_.isEmpty (this.cssearchRsnCd)) {
        TSCommUtil.gfn_validate (this, "고객조회 사유를 선택해 주세요.");
        this.errValCssearchRsnCd = true;
        // this.$refs["srchRsnCd"].focus ();
        // this.$refs.srchRsnCd.focus ();
        return;
      }
      else {
        this.errValCssearchRsnCd = false;
      }
      if (this.cssearchRsnCd === "30") {
        if (_.isEmpty (this.cssearchRsn)) {
          TSCommUtil.gfn_validate (this, "고객조회 기타 사유를 입력해 주세요.");
          this.errValCssearchRsn = true;
          // this.$refs["srchRsn"].focus ();
          this.$refs.srchRsn.focus ();
          return;
        }
      }
      else {
          this.errValCssearchRsn = false;
      }
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {};
      if (lv_Vm.csGb === "1") {
        pParams = {
          sexTc: lv_Vm.sexTc,
          cnm: lv_Vm.cnm,
          pwd: lv_Vm.pwd,
          csGb: lv_Vm.csGb,
          rcno: "",
        };
      }
      else {
        pParams = {
          rcno: lv_Vm.rcno,
          cnm: lv_Vm.cnm,
          pwd: lv_Vm.pwd,
          csGb: lv_Vm.csGb,
        };
      }
      // ---------
      // 'C392_F10600226_S' |
      // 'UF10600226' // [NEXT 사랑On 신탁]고객리스트조회-selectTPUP95320List1
      // ---------
      this.eaiCommObj.lv_vm = this;
      // this.eaiCommObj.trnstId = "txTSSTS91S1";
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600226_S";
      this.eaiCommObj.params = pParams;
      console.log ("+++++++++");
      console.log ("+ ▶ [고객리스트조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        console.log (error);
      }, "UF10600226");
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
      console.log ("+++++++++");
      console.log ("+ ▶▷ [고객리스트조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      console.log ("+++++++++");
      this.tpup95320List = res.data.tpup95320;
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 시작 ]");
      if (_.isEmpty (this.rowSelect)) {
        this.fn_Close ();
      }
      else {
        if (this.tpup95320List.length > 0) {
          let userMngBrcd = this.getStore ("userInfo").getters.getUserInfo.userDeptCd;
          let rstMngBrcd = this.rowSelect.mngBrcd;
          let pwByCustYN = this.rowSelect.pwByCustYN;
          if (!_.isEmpty (rstMngBrcd)) {
            if ((userMngBrcd !== "00301221" && !this.usergrpTc_19) && userMngBrcd !== rstMngBrcd) {
              if (pwByCustYN != "Y") {
                let t_popupObj = {
                  confirm: false,
                  content: "해당 부점의 고객이 아닙니다.",
                };
                this.$refs.alertPopup.fn_OpenA(t_popupObj);
                return;
              }
            }
          }
          /*
          this.objRtnArr = new Array(18); //팝업창 close시 전달할 값을 위한 배열 선언
          this.objRtnArr[0] = this.ds_list.getColumn(this.ds_list.rowposition, "csId"); //고객ID
          this.objRtnArr[1] = this.ds_list.getColumn(this.ds_list.rowposition, "cno"); //고객번호
          this.objRtnArr[2] = this.ds_list.getColumn(this.ds_list.rowposition, "cnm"); //고객명
          this.objRtnArr[3] = this.ds_list.getColumn(this.ds_list.rowposition, "rcnoKindTc"); //실명확인번호종류구분코드
          this.objRtnArr[4] = this.ds_list.getColumn(this.ds_list.rowposition, "rcno"); //실명확인번호
          this.objRtnArr[5] = this.ds_list.getColumn(this.ds_list.rowposition, "cono"); //법인번호
          this.objRtnArr[6] = this.ds_list.getColumn(this.ds_list.rowposition, "genTaxtTc"); //일반과세구분코드
          this.objRtnArr[7] = this.ds_list.getColumn(this.ds_list.rowposition, "persnBusiMnBsno"); //개인사업자사업자등록번호
          this.objRtnArr[8] = this.ds_list.getColumn(this.ds_list.rowposition, "corpYn"); //법인여부
          this.objRtnArr[9] = this.ds_list.getColumn(this.ds_list.rowposition, "csIvtdRatingC"); //고객투자성향등급코드
          this.objRtnArr[10] = this.ds_list.getColumn(this.ds_list.rowposition, "procDt"); //설문 처리일자
          this.objRtnArr[11] = this.ds_list.getColumn(this.ds_list.rowposition, "valdTrmYys"); //유효기간년수
          this.objRtnArr[12] = this.ds_list.getColumn(this.ds_list.rowposition, "ques10"); //파생상품투자기간
          this.objRtnArr[13] = this.ds_list.getColumn(this.ds_list.rowposition, "tacno"); //종합계좌번호
          this.objRtnArr[14] = this.ds_list.getColumn(this.ds_list.rowposition, "age"); //보험나이
          this.objRtnArr[15] = this.ds_list.getColumn(this.ds_list.rowposition, "tacnoBnkbSeq"); //종합계좌번호 + 통장일련번호
          this.objRtnArr[16] = this.ds_list.getColumn(this.ds_list.rowposition, "regDate"); //고객가입일자(고객기본.등록일자)
          this.objRtnArr[17] = this.ds_list.getColumn(this.ds_list.rowposition, "tassIssncAccYn"); //TASS발급계좌여부(구고객 : N / 신고객:Y)
          */
          // ---------
          // EAI(PO) 전송 파라미터 설정
          // ---------
          let pParams = {
            tacno: this.rowSelect.tacno,
            csId: this.rowSelect.csId,
            trDate: this.rowSelect.trDate,
            cssearchRsnCd: this.cssearchRsnCd,
            cssearchRsn: this.cssearchRsn,
            linkSrnAddr: "1020",
            linkSrnNm: "계약 신규",
            custInqrPathCd: "1610",
          };
          // ---------
          // 'C392_F10600229_S' |
          // 'UF10600229' // [NEXT 사랑On 신탁]고객로그등록-insertTPUP95320Log
          // ---------
          this.eaiCommObj.lv_vm = this;
          this.eaiCommObj.auth = "I";
          // this.eaiCommObj.trnstId = "txTSSTS91I1";
          this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600229_S";
          this.eaiCommObj.params = pParams;
          console.log ("+++++++++");
          console.log ("+ ▶ [고객로그등록]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
          console.log ("+++++++++");
          // TSServiceUtil.invoke (this.eaiCommObj, this.fn_ConfirmResult);
          TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ConfirmResult);
        }
      }
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_ConfirmResult
     * 설명       : 확인 콜백
     ******************************************************************************/
    fn_ConfirmResult (res) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ConfirmResult ]___[ 시작 ]");
      console.log ("+++++++++");
      console.log ("+ ▶▷ [고객로그등록]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      console.log ("+++++++++");
      this.$emit ("ts-popup107-callback", this.rowSelect);
      this.fn_Close ();
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ConfirmResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected (idx) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 시작 ]");
      this.lv_SelectedItem = idx;
      this.lv_SearchParam = {};
      let findIdx = this.tpup95320List.findIndex (obj => obj.chnlCustId === this.lv_SelectedItem);
      if (findIdx !== -1) {
        Object.assign (this.lv_SelectedItem, this.tpup95320List[findIdx]);
      }
      this.rowSelect = this.tpup95320List[idx];
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 종료 ]");
    },
  },
}
</script>
