/*
* 업무구분: 원천징수영수증 발급
* 화 면 명: MSPTS204M
* 화면설명: 원천징수영수증 발급
* 접근권한: 
* 작 성 일: 2023.05.22
* 작 성 자: 김경태 
*/

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <ur-page-container class="fts" :show-title="false" title="원천징수영수증발급" :topButton="true">
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->
    <!-- .fts-main -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0 justify-between">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호 </label>
                  <!-- (공통) 계좌 및 금액 관련 숫자입력 키패드 교체 적용, applyed by anarchi (2023.0318) // -->
                  <!-- <mo-text-field 
                    v-model="acno" 
                    mask="#######-###" 
                    class="w130" 
                    maxlength="11" 
                    @input="fn_AcnoChange" 
                  /> -->
                  <!-- <mo-decimal-field 
                    v-model="acno" 
                    numeric
                    mask="#######-###" 
                    class="w130" 
                    maxlength="11" 
                    @keyup="fn_AcnoChange" 
                  /> -->
                  <mo-decimal-field 
                    v-model="acno" 
                    numeric
                    mask="#######-###" 
                    class="w130" 
                    maxlength="11" 
                    @input="fn_AcnoChange" 
                  />
                  <mo-dropdown 
                    v-model="accSeq" 
                    placeholder=" " 
                    class="w250" 
                    :items="accSeqItems" 
                  />
                  <mo-button class="btn-pop-download" @click="fn_OpenMSPTS206P ('00')"> </mo-button>
                  <mo-text-field v-model="cnm" disabled class="w150" />
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 거래구분 </label>
                  <mo-dropdown :items="trTypItems" v-model="trTyp" class="w130" />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label class="emphasis"> 거래기간 </label>
                  <mo-date-picker class="w130" v-model="fromBaseDate" :bottom="false" />
                  <i> ~ </i>
                  <mo-date-picker class="w130" v-model="toBaseDate" :bottom="false" />
                </div>
                <div class="wrap-input row ml-5">
                  <label class="emphasis"> 수익자구분 </label>
                  <mo-dropdown :items="bnfcTcItems" v-model="bnfcTc" :disabled="disBnfcTc" class="w150" />
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 자타익구분 </label>
                  <mo-radio-wrapper :items="selTypeSoptItems" v-model="selTypeSopt" @input="fn_SelTypeSoptChange" class="row"/>
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p"></div>
              <div class="right">
                <div class="wrap-button row">
                  <mo-button @click="fn_Init"> 초기화 </mo-button>
                  <mo-button @click="fn_Search" primary> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">
        <div class="right-bg-white mt-5">
          <div class="wrap-table table-sticky sticky-t03">
            <table class="table row-type wsN">
              <thead>
                <tr>
                  <th> <mo-checkbox class="vaBottom" v-model="isSelectAll" @input="selectAllItemsFunc (isSelectAll)" /> </th>
                  <th> 거래일 </th>
                  <th> 거래번호 </th>
                  <th> 거래구분 </th>
                  <th> 과세구분 </th>
                  <th> 거래금액 </th>
                  <th> 과세시작일 </th>
                  <th> 과세종료일</th>
                  <th> 소득금액 </th>
                  <th> 과세표준액 </th>
                  <th> 이자/배당 </th>
                  <th> 소득세 </th>
                  <th> 주민세 </th>
                  <th> 계좌번호 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in tfbz61000List" :key="idx" :class="{'checked': checkYn == String (idx)}" @click="checkYn = String (idx)">
                  <td> <mo-checkbox 
                    v-model="checkYn" 
                    :value="`${String (idx)}`" 
                    @input="fn_CheckYn (checkYn, row)" 
                  /> </td>
                  <td> {{row.baseDate}} </td>
                  <td class="aR"> {{row.tmtlReqSeq}} </td>
                  <td> {{row.trTypNm}} </td>
                  <td> {{row.taxtType}} </td>
                  <td class="aR"> {{row.tmtlWcurAmt}} </td>
                  <td> {{row.taxtStrtDate}} </td>
                  <td> {{row.taxtEndDate}} </td>
                  <td class="aR"> {{row.realBnfAmt}} </td>
                  <td class="aR"> {{row.txblWcurAmt}} </td>
                  <td> {{row.intAlotTcNm}} </td>
                  <td class="aR"> {{row.itaxWcurAmt}} </td>
                  <td class="aR"> {{row.rtaxWcurAmt}} </td>
                  <td> {{ bnkAcnoMask (row.tacno) }} </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"> 합계 </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td class="aR"> {{ tmtlWcurAmtSum }} </td>
                  <td> </td>
                  <td> </td>
                  <td class="aR"> {{ realBnfAmtSum }} </td>
                  <td class="aR"> {{ txblWcurAmtSum }} </td>
                  <td> </td>
                  <td class="aR"> {{ itaxWcurAmtSum }} </td>
                  <td class="aR"> {{ rtaxWcurAmtSum }} </td>
                  <td> </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
		  </ur-box-container>
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button @click="fn_checkProcessChk" size="large" primary> {{ btnAdmissionName }} </mo-button>          
        </div>
      </ur-box-container>
<!--
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large"> 확인 </mo-button>
        </div>
      </ur-box-container>
!-->
    </div>
    <!-- //.fts-main -->
  <ts-alert-popup 
    ref="alertPopup"
    :popupObj="pAlertPopupObj"
  ></ts-alert-popup>
  <msp-ts-206p
    ref="popup206"
    :popup206Obj="pPopup206Obj"
    @ts-alert-popup="fn_AlertPopup"
    @ts-popup206-callback="fn_Popup206Back"
  ></msp-ts-206p>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                    *
 ***********************************************************************************/
import _ from "lodash"
import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSHeader from "~/src/ui/ts/comm/TSHeader" // header 영역 (공통)
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

import MSPTS206P from "@/ui/ts/MSPTS206P" //계좌번호 조회

export default {
 /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPTS204M",
  screenId: "MSPTS204M",
  props: [],
  components: {
    "ts-header": TSHeader,
    "ts-alert-popup": TSAlertPopup,
    "msp-ts-206p": MSPTS206P,
  },
 /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/  
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS204M ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: true,
      // ------
      // 화면간 이동 및 서브팝업 연계호출용 파라미터
      // ------
      pParams: {},
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      name: "MSPTS204M",
      pHeaderObj: {          
        title: "원천징수영수증 발급",
      },
      acno: "",
      accSeq: "",
      cnm: "",
      csId: "",
      selTypeSopt: "10",
      trTyp: "10",
      offrSeq: "",
      tmtlTc: "",
      aprvBusnTc1: "",
      aprvStatTc: "",
      otxtTmtlTc: "",
      mtrtDate: "",
      rufeCnfmYn: "",
      rufeCnfmYn00: "",
      tmtlSchdDate: "",
      tmtlOffrDate: "",
      tmtlAmt: 0,
      basTfrt: 0,
      tmtlRsn: "",
      trstAmt: 0,
      curC: "",
      curC00: "",
      oamtDlvgsTc: "",
      mdtmEndDds01: 0,
      mdtmEndDds02: 0,
      mdtmEndDds03: 0,
      mdtmFert01: 0,
      mdtmFert02: 0,
      mdtmFert03: 0,
      amlCtno: "",
      fromBaseDate: TSCommUtil.fn_strToday (),
      toBaseDate: TSCommUtil.fn_strToday (),
      isSelectAll: false,
      checkYn: [],
      accSeqItems: [],
      tfbz61000List: [],
      tfbz61000Temp: [],   //전자서식 Temp
      tfbz61000Report: [], //전자서식 출력 위한 정보
      btnSearch: false,
      btnClear: false,
      btnUpdate: true,
      btnDelete: true,
      btnConfirm: true,
      btnCancel: true,
      btnAdmission: false,
      btnAdmMemoInfo: true,
      btnAdmissionName: "보고서",
      btnAML: false,
      bnkbSeqItems: [],
      bnfcTcItems: [],
      bnfcTc: "0",
      tmtlWcurAmtSum: 0,
      realBnfAmtSum: 0,
      txblWcurAmtSum: 0,
      itaxWcurAmtSum: 0,
      rtaxWcurAmtSum: 0,
      popup206: {}, // MSPTS206P
      pAlertPopupObj: {},
      pPopup206Obj: {},
      pPopup206Type: "", // 고객조회 팝업 구분
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      sSvcID: "",
      rufeCnfmYnItems: [
        {value: "", text: "해당없음"},
        {value: "Y", text: "처리"},
        {value: "N", text: "미처리"},
      ],
      disBnfcTc: true,
      BaseDate:'', //Report 발행을 위한 기준 일자
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS204M ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },

 /***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    this.fn_ComCode ();
    this.fn_Init ();
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeMount ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeMount ]___[ 종료 ]");
  },
  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    this.$bizUtil.insSrnLog ("MSPTS204M");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeUpdate ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeUpdate ]___[ 종료 ]");
  },
  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::updated ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::updated ]___[ 종료 ]");
  },
  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 종료 ]");
  },
  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::destroyed ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::destroyed ]___[ 종료 ]");
  },
  /***********************************************************************************
  * watch 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
  *   (변수에 대한 값 변경시 반응형 콜백 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *       userNm : function () { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    //
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
   *   지정 함수는 반드시 리턴 하는 함수로 작성
   *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *   userNm : function () { return this.userNm = this.userNm? this.userNm.trim() : '' } 
   ***********************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // 개별업무 프로세스 진행상태 여부, 
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    selTypeSoptItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::selTypeSoptItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "", text: "전체",},);
      rtn.push ({value: "10", text: "자익",},);
      rtn.push ({value: "20", text: "타익",},);
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::selTypeSoptItems ]___[ 종료 ]");
    },
    trTypItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::trTypItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "10", text: "전체",},);
      rtn.push ({value: "20", text: "분기원천과세",},);
      rtn.push ({value: "30", text: "해지결과",},);
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::trTypItems ]___[ 종료 ]");
    },
    // isSelectAll: {
    //   get: function () {
    //     if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isSelectAll_get ]___[ 시작 ]");
    //     return this.tfbz61000List.length === this.isSelectAll.length;
    //     if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isSelectAll_get ]___[ 종료 ]");
    //   },
    //   set: function () {
    //     if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isSelectAll_set ]___[ 시작 ]");
    //     this.isSelectAll = e ? this.tfbz61000List : [];
    //     if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isSelectAll_set ]___[ 종료 ]");
    //   },
    // },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /******************************************************
     * Function명 : fn_checkProcessChk
     * 설명       : 프로세스에 관련 체크 로직 처리
     ******************************************************/
    fn_checkProcessChk () {

      if (!this.isProcessCheck) { // 프로세스 NO
        // 조회 된 데이터 없을 시 진행 안됨
        if ( this.tfbz61000Temp.length == 0) {
          return

        } else if ( this.tfbz61000Temp.length > 0) {

          for(let i = 0; i < this.tfbz61000Temp.length; i++){	
            if ( this.tfbz61000Temp[i].txblWcurAmt == 0 ) {
              this.fn_AlertPopup(0,{ content : '과세표준액이 0원 이상일때만 출력가능합니다.'} )
              return
            }
          }	

        }
      }

      this.fn_checkProcess() // 프로세스에 따라 분기 처리  호출

    },

    /******************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************/
    fn_checkProcess () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 시작 ]");

      // debugger;
      // let lv_Vm = this;
      let tmpContentTitle = "원천징수영수증발행 완료";
      let tmpContent = [];
      let t_type = 0;
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) { // isLastProcessCheck
          if (this.baseInfoObj.procTyp === "custInfoReg") {
            // tmpContent.push ("원천징수영수증발행 화면으로 이동합니다.");
          }
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        }
        else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      let t_popupObj = {
        confirm: true,
        confirmFunc: this.fn_confirmPrintTrue,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          tacno: this.acno.substring (0, 7),
          bnkbSeq: this.acno.substring (7, 10),
          accSeq: this.accSeq,
          csId: this.csId, // 정보동의서 수령방법 (메일, 전화번호 정보항목) 설정관련 추가
        },
      };
      // debugger;
      this.fn_AlertPopup (t_type, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_checkProcess ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");

      this.ds_searchReportList = [];  // 초기화
      this.ds_searchParam      = {};  // 초기화
      this.tfbz61000Report     = [];  // 초기화

      let acctList     = "";  // 초기화
      let baseDatePost = "";  // 초기화
      let rowtrTyp     = "";  // 초기화


      this.ds_searchParam.tacno    = this.acno.substring (0, 7);
      this.ds_searchParam.bnkbSeq  = this.acno.substring (7, 10);
      this.ds_searchParam.ctno     = Number (this.accSeq);
      this.ds_searchParam.accSeq   = this.accSeq;
      this.ds_searchParam.trTyp    = this.trTyp; //거래구분
      this.ds_searchParam.bizDt    = TSCommUtil.gfn_dateReplace (1, TSCommUtil.fn_strToday ());
      this.ds_searchParam.bnfcTc   = this.bnfcTc;
      this.ds_searchParam.bnfcCsId = "0";
      this.ds_searchParam.csId     = this.csId; // "674943181"

      

      // 전자서명 동의서   
      this.ds_searchReport.reportMrdNm = "TS000001";
      this.ds_searchReport.reportUrl = "reportTFAC20000";
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));

      console.log('fn_confirmPrintTrue this.ds_searchParam =============> ', this.ds_searchParam)       

      // 원천징수영수증 전자서식 데이터 셋팅
      this.tfbz61000Report = _.cloneDeep (this.tfbz61000Temp);
      console.log('fn_confirmPrintTrue this.tfbz61000Report =============> ', this.tfbz61000Report)

      // 기준일자 셋팅 및 거래구분 셋팅
      for(let i = 0; i < this.tfbz61000Report.length; i++){	
        if ( i == 0 ) {
          baseDatePost = this.tfbz61000Report[i].baseDate.replaceAll ("-", "");
        } else {
          baseDatePost = baseDatePost + ","+ this.tfbz61000Report[i].baseDate.replaceAll ("-", "");
        }
        rowtrTyp = this.tfbz61000Report[i].trTyp // row 별 거래구분
      }
      this.ds_searchParam.baseDate = baseDatePost; // 기준일자
      console.log('fn_confirmPrintTrue baseDatePost =============> ', baseDatePost)

      if ( this.trTyp == "20" || rowtrTyp == "20" ) {  // 거래구분 20 : 분기원천과세

        this.ds_searchParam.fromBaseDate = this.fromBaseDate.replaceAll ("-", "");
        this.ds_searchParam.toBaseDate   = this.toBaseDate.replaceAll ("-", "");
        this.ds_searchParam.soptType     = this.trTyp;

        this.ds_searchParam.acctNo       = ""; //acctList
        this.ds_searchParam.bnfcTc       = "0";
        this.ds_searchParam.bnfcCsId     = "0";        

      }

      console.log('this.ds_searchParam =================> ',this.ds_searchParam)

      //원천징수영수증
      this.ds_searchReport.reportMrdNm = "TS000059";
      this.ds_searchReport.reportUrl = "reportTFBZ61000List";
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));


      this.fn_initReport()
      this.fn_searchReport()

      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
        리포트 데이터 조회 
     *****************************************************************************************/
    fn_initReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_initReport ]___[ 시작 ]");
      this.ds_resultReportList = [];
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = {formId : this.ds_searchReportList[i].reportMrdNm};
        report.params = this.ds_searchReportList[i];
        this.ds_resultReportList.push (JSON.parse (JSON.stringify (report)));
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_initReport ]___[ 종료 ]");
    },
    /***************************************************************************************
        리포트 데이터 조회 
     *****************************************************************************************/
    async fn_searchReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      if (this.isProcessCheck) { 
        if (!this.isLastProcessCheck) {
          // TSInfoUtil.commReportInfo (this, this.ds_resultReportList, this.fn_goPage , false);
          TSInfoUtil.commReportInfo (this, this.ds_resultReportList);
        }
        else {
          TSInfoUtil.commReportInfo (this, this.ds_resultReportList);
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo (this, this.ds_resultReportList);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
     *****************************************************************************************/
    fn_ComCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600046_S' |
      // 'UF10600046' // [NEXT 사랑On 신탁]콤보 목록을 조회-selectComboList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS90S1"; // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        "TUKEMK": [
          {cId: "5147",},
        ]
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [콤보 목록을 조회---5147]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error)
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    }, 
    // ======================================================
    // (공통:데이터 초기화--콜백) fn_ComCodeCallBack:: 
    // ======================================================
    fn_ComCodeCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [콤보 목록을 조회---5147]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index];
        switch (item.cid) {
          case "5147":
            this.bnfcTcItems.push ({
              value: item.c,
              text: item.cnm,
            });
            break;
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ [로컬알림-공통팝업처리용]", " type → ", type, ", pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;
            this.pAlertPopupObj.contentTitle = "";
            this.pAlertPopupObj.confirm = false;
            this.pAlertPopupObj.cancel = true;
            this.pAlertPopupObj.type = 0;
            if (!TSCommUtil.gfn_isNull (pPopupObj.confirm)) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
            }
            else {
              this.pAlertPopupObj.confirm = "";
            }
            if (!TSCommUtil.gfn_isNull (pPopupObj.confirmFunc)) {
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
            }
            else {
              this.pAlertPopupObj.confirmFunc = "";
            }
            if (!TSCommUtil.gfn_isNull (pPopupObj.closeFunc)) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
            }
            else {
              this.pAlertPopupObj.closeFunc = "";
            }
            if (!TSCommUtil.gfn_isNull (pPopupObj.single)) {
              this.pAlertPopupObj.single = pPopupObj.single;
            }
            else {
              this.pAlertPopupObj.single = "";
            }
          }
          break;
        case 1:
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        case 6:
          break;
        case 7:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj, pPopupObj);
          }
          break;
        case 8:
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            this.pAlertPopupObj.content = pPopupObj.content;
            this.pAlertPopupObj.contentTitle = "";
            this.pAlertPopupObj.confirm = false;
            this.pAlertPopupObj.cancel = true;
            this.pAlertPopupObj.type = 0;
            if (!pPopupObj.cancel) {
              this.pAlertPopupObj.cancel = pPopupObj.cancel;
            }
            if (pPopupObj.confirm) {
              this.pAlertPopupObj.confirm = pPopupObj.confirm;
              this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc;
              this.pAlertPopupObj.confirmData = pPopupObj.confirmData;
            }
            if (pPopupObj.closeFunc) {
              this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc;
              this.pAlertPopupObj.closeData = pPopupObj.closeData;
            }
            if (!TSCommUtil.gfn_isNull (type) && type != 0) {
              this.pAlertPopupObj.type = type;
            }
            if (pPopupObj.contentTitle) {
              this.pAlertPopupObj.contentTitle = pPopupObj.contentTitle;
            }
          }
          break;
      }
      // TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj, pPopupObj);
      this.$refs.alertPopup.fn_Open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SelTypeSoptChange
     * 설명       : 자타익구분 변경
    ******************************************************************************/      
    fn_SelTypeSoptChange () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SelTypeSoptChange ]___[ 시작 ]");
      if (this.selTypeSopt !== "10" && this.selTypeSopt !== "20") {
        this.bnfcTc = "0";
        this.disBnfcTc = true;
      }
      else {
        this.bnfcTc = "0";
        this.disBnfcTc = false;
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SelTypeSoptChange ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : selectAllItemsFunc
     * 설명       : 
    ******************************************************************************/      
    selectAllItemsFunc (state) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::selectAllItemsFunc ]___[ 시작 ]");
      if (state) {
        let chk = [];
        let chkSum = 0;
        let chkSum1 = 0;
        let chkSum2 = 0;
        let chkSum3 = 0;
        let chkSum4 = 0;
        this.tfbz61000Temp = []; // Report Temp Data 초기화
        for (let i = 0; i < this.tfbz61000List.length; i++) {
        chkSum = Number (chkSum) + Number (this.tfbz61000List[i].tmtlWcurAmt.replaceAll (',',''));
          chkSum1 = Number (chkSum1) + Number (this.tfbz61000List[i].realBnfAmt.replaceAll (',',''));
          chkSum2 = Number (chkSum2) + Number (this.tfbz61000List[i].txblWcurAmt.replaceAll (',',''));
          chkSum3 = Number (chkSum3) + Number (this.tfbz61000List[i].itaxWcurAmt.replaceAll (',',''));
          chkSum4 = Number (chkSum4) + Number (this.tfbz61000List[i].rtaxWcurAmt.replaceAll (',',''));
          chk.push (String (i));
        }        
        this.checkYn = chk;
        this.tmtlWcurAmtSum = String (chkSum).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.realBnfAmtSum = String (chkSum1).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.txblWcurAmtSum = String (chkSum2).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.itaxWcurAmtSum = String (chkSum3).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.rtaxWcurAmtSum = String (chkSum4).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        // this.fn_CheckYnAll ();
      } else {
        this.tmtlWcurAmtSum = 0;
        this.realBnfAmtSum = 0;
        this.txblWcurAmtSum = 0;
        this.itaxWcurAmtSum = 0;
        this.rtaxWcurAmtSum = 0;
        this.checkYn.splice (0, this.checkYn.length);
      }

      // 원천징수영수증 전자서식 Report Temp Data 선택 된 데이터 셋팅
      this.tfbz61000Temp = _.cloneDeep (this.tfbz61000List);

      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::selectAllItemsFunc ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 초기화
     ******************************************************************************/
    fn_Init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      this.acno = "";
      this.accSeq = "";
      this.accSeqItems = [];
      this.cnm = "";
      this.csId = "";
      this.bnfcTc = "0";
      this.trTyp = "10";
      this.selTypeSopt = "10";
      this.tfbz61000List = [];
      let busyDate = TSCommUtil.gfn_dateReplace (1, TSCommUtil.fn_strToday ());
      this.fromBaseDate = busyDate;
      this.toBaseDate = busyDate;
      this.tmtlWcurAmtSum = "0";
      this.realBnfAmtSum = "0";
      this.txblWcurAmtSum = "0";
      this.itaxWcurAmtSum = "0";
      this.rtaxWcurAmtSum = "0";
      this.checkYn = [];
      // ---------------------------
      // 업무연계용 필수 파라미터 수신 처리
      // ---------------------------
      // debugger;
      if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [Parent 내장객체-(수신)-파라미터]", " this.$parent → ", this.$parent);
      // if (this.isLoggable) console.log ("+ ▶ [El 내장객체-(수신)-파라미터]", " this.$el → ", this.$el);
      // if (this.isLoggable) console.log ("+ ▶ [Options 내장객체-(수신)-파라미터]", " this.$options → ", this.$options);
      // if (this.isLoggable) console.log ("+ ▶ [Refs 내장객체-(수신)-파라미터]", " this.$refs → ", this.$refs);
      // if (this.isLoggable) console.log ("+ ▶ [Props 내장객체-(수신)-파라미터]", " this.$props → ", this.$props);
      // if (this.isLoggable) console.log ("+ ▶ [Data 내장객체-(수신)-파라미터]", " this.$data → ", this.$data);
      // if (this.isLoggable) console.log ("+ ▶ [Store 내장객체-(수신)-파라미터]", " this.$store → ", this.$store);
      if (this.isLoggable) console.log ("+ ▶ [Router 내장객체-(수신)-파라미터]", " this.$router → ", this.$router);
      if (this.isLoggable) console.log ("+ ▶ [Route 내장객체-(수신)-파라미터]", " this.$route → ", this.$route);
      if (this.isLoggable) console.log ("+++++++++");
      this.pParams = _.cloneDeep (this.$route.params);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [사용자라우터객체-(저장)-파라미터]", " this.pParams → ", this.pParams);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------------------------------------------
      // (프로세스 연결):::
      //   → 업무화면간 이동 및 연계팝업 호출 시, (필수)인수 송수신 설정처리 추가
      // ------------------------------------------------------
      let _rParamObj = {
        accSeq: "",
        baseDate: "",
        bnkbSeq: "",
        tacno: "",
      };
      if (!TSCommUtil.gfn_isNull (this.pParams.tacno)) {
        _rParamObj.accSeq = TSCommUtil.gfn_isNull_replace (this.$route.params.accSeq, "");
        _rParamObj.baseDate = TSCommUtil.gfn_isNull_replace (this.$route.params.baseDate, "");
        _rParamObj.bnkbSeq = TSCommUtil.gfn_isNull_replace (this.$route.params.bnkbSeq, "");
        _rParamObj.tacno = TSCommUtil.gfn_isNull_replace (this.$route.params.tacno, "");
        this.acno = _rParamObj.tacno + _rParamObj.bnkbSeq;
        this.accSeq = _rParamObj.accSeq;
        // this.aseDate = _rParamObj.baseDate;
        this.BaseDate = _rParamObj.baseDate;
      }
      // this.acno = "1102234999";
      // this.accSeq = "003";
      // this.baseDate = "2023-09-08";
      // ------------------------------------------------------
      // if (this.$route.params !== undefined) {
      //   let _rParamObj = {
      //     // accSeq: "",
      //     // baseDate: "",
      //     // bnkbSeq: "",
      //     // tacno: "",
      //   };
      //   _rParamObj.accSeq = TSCommUtil.gfn_isNull_replace (this.$route.params.accSeq, "");
      //   _rParamObj.baseDate = TSCommUtil.gfn_isNull_replace (this.$route.params.baseDate, "");
      //   _rParamObj.bnkbSeq = TSCommUtil.gfn_isNull_replace (this.$route.params.bnkbSeq, "");
      //   _rParamObj.tacno = TSCommUtil.gfn_isNull_replace (this.$route.params.tacno, "");
      //   this.accSeq = _rParamObj.accSeq;
      //   this.acno = _rParamObj.tacno + _rParamObj.bnkbSeq;
      //   this.baseDate = _rParamObj.baseDate;
      // }
      // ---------------------------
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 취소
     ******************************************************************************/
    fn_Cancel () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Cancel ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Cancel ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_CheckYn
     * 설명       : 합계
     ******************************************************************************/
    fn_CheckYnAll () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CheckYnAll ]___[ 시작 ]");
      if (this.isSelectAll) {
        let chkSum = 0;
        let chkSum1 = 0;
        let chkSum2 = 0;
        let chkSum3 = 0;
        let chkSum4 = 0;
        this.checkYn = [];
        if (this.tfbz61000List.length > 0) {
          for (let i = 0; i < this.tfbz61000List.length; i++) {
            chkSum = Number (chkSum) + Number (this.tfbz61000List[i].tmtlWcurAmt.replaceAll (',', ''));
            chkSum1 = Number (chkSum1) + Number (this.tfbz61000List[i].realBnfAmt.replaceAll (',', ''));
            chkSum2 = Number (chkSum2) + Number (this.tfbz61000List[i].txblWcurAmt.replaceAll (',', ''));
            chkSum3 = Number (chkSum3) + Number (this.tfbz61000List[i].itaxWcurAmt.replaceAll (',', ''));
            chkSum4 = Number (chkSum4) + Number (this.tfbz61000List[i].rtaxWcurAmt.replaceAll (',', ''));
            // this.checkYn.push (i)
          }
        }
        this.tmtlWcurAmtSum = String (chkSum).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.realBnfAmtSum = String (chkSum1).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.txblWcurAmtSum = String (chkSum2).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.itaxWcurAmtSum = String (chkSum3).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.rtaxWcurAmtSum = String (chkSum4).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      else {
        this.tmtlWcurAmtSum = 0;
        this.realBnfAmtSum = 0;
        this.txblWcurAmtSum = 0;
        this.itaxWcurAmtSum = 0;
        this.rtaxWcurAmtSum = 0;
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CheckYnAll ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_CheckYn
    * 설명       : 합계
    ******************************************************************************/
    fn_CheckYn (chk, data) {
      this.tfbz61000Temp = []; // Report Temp Data 초기화
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CheckYn ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [원천징수내역 체크시, 합계 처리]", " chk → ", chk, ", data → ", data);
      if (this.isLoggable) console.log ("+++++++++");
      let chkSum = 0;
      let chkSum1 = 0;
      let chkSum2 = 0;
      let chkSum3 = 0;
      let chkSum4 = 0;

      // 원천징수영수증 전자서식 Report Temp Data 선택 된 데이터 셋팅
      this.tfbz61000Temp.push(this.tfbz61000List[chk]);
      
      if (chk.length > 0) {
        for (let i = 0; i < chk.length; i++) {
          chkSum = Number (chkSum) + Number (this.tfbz61000List[chk[i]].tmtlWcurAmt.replaceAll (',', ''));
          chkSum1 = Number (chkSum1) + Number (this.tfbz61000List[chk[i]].realBnfAmt.replaceAll (',', ''));
          chkSum2 = Number (chkSum2) + Number (this.tfbz61000List[chk[i]].txblWcurAmt.replaceAll (',', ''));
          chkSum3 = Number (chkSum3) + Number (this.tfbz61000List[chk[i]].itaxWcurAmt.replaceAll (',', ''));
          chkSum4 = Number (chkSum4) + Number (this.tfbz61000List[chk[i]].rtaxWcurAmt.replaceAll (',', ''));
        }
        this.tmtlWcurAmtSum = String (chkSum).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.realBnfAmtSum = String (chkSum1).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.txblWcurAmtSum = String (chkSum2).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.itaxWcurAmtSum = String (chkSum3).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.rtaxWcurAmtSum = String (chkSum4).replace (/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_CheckYn ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_AcnoChange
    * 설명       : 계좌번호 입력
    ******************************************************************************/
    fn_AcnoChange () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoChange ]___[ 시작 ]");
      if (this.acno.length < 10) {
        return;
      }
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600245_S' |
      // 'UF10600245' // [NEXT 사랑On 신탁]계좌 정보 조회 TR 전송
      // ---------
      this.eaiCommObj.lv_vm = this;
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accStatC: "", // this.accStatC,
        brigAccYn: "", // this.brigAccYn,
      };
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = 'selectTPUP95090List'
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600245_S";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, function (res) {
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
        if (this.isLoggable) console.log ("+++++++++");
        if (res && res.data) {
          this.cnm = res.data.tpup95090[0].cnm; //
          this.csId = res.data.tpup95090[0].csId; //
          this.accSeqItems = [];
          for (let i = 0; i < res.data.tpup95090.length; i++) {
            let acc = {};
            acc.value = res.data.tpup95090[i].accSeq;
            acc.text = res.data.tpup95090[i].gdNm;
            this.accSeqItems.push (acc);
          }
          if (this.isProcessCheck) {
            this.accSeq = this.pParams.accSeq;
            // this.accSeq = "003";
          }
          else {
            this.accSeq = res.data.tpup95090[0].accSeq;
          }

          // 계좌 결과에 따른 자타익구분 값 셋팅
          if ( res.data.tpup95090[0].soptTc == '10' ) {
            this.selTypeSopt = '10' // 자익
          } else if ( res.data.tpup95090[0].soptTc == '20' ) {
            this.selTypeSopt = '20' // 타익
          }
        }
      }, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600245");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoChange ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_AcnoSeqSearch
    * 설명       : 계약변경 일련번호 조회
    ******************************************************************************/
    fn_AcnoSeqSearch () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoSeqSearch ]___[ 시작 ]");
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600100_S' |
      // 'UF10600100' // [NEXT 사랑On 신탁]해지 일련번호를 조회한다.-selectTFAC20100AList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS21S2";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600100_S";
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accSeq: this.accSeq,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [해지 일련번호를 조회한다.]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_AcnoSeqSearchResult)
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_AcnoSeqSearchResult);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoSeqSearch ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_AcnoSeqSearchResult
    * 설명       : 계약변경 일련번호 조회 콜백
    ******************************************************************************/
    fn_AcnoSeqSearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoSeqSearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [해지 일련번호를 조회한다.]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // res.data.body.offrSeqList
      // if (this.ds_list.insertRow(0) != -1) {
      //   this.ds_list.setColumn(0,"offrSeq","");
      // }
      this.offrSeq = "";
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AcnoSeqSearchResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회버튼
     ******************************************************************************/
    fn_Search () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      let isProcess = this.getStore("tsStore").getters.getState.isProcess;
      if (!isProcess) { // 프로세스 아닐경우 계좌번호 필수 값 체크로직 적용
        if (_.isEmpty (this.acno)) {
          this.pAlertPopupObj.content = "계좌번호는 필수입력항목입니다.";
          this.pAlertPopupObj.confirm = false;
          this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
          return;
        }
      }
      if (_.isEmpty (this.fromBaseDate)) {
        this.pAlertPopupObj.content = "거래시작 일자를 입력하십시요.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      if (_.isEmpty (this.toBaseDate)) {
        this.pAlertPopupObj.content = "거래종료 일자를 입력하십시요.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      if (Number (this.fromBaseDate) > Number (this.toBaseDate)) {
        this.pAlertPopupObj.content = "일자(From)이 일자(To) 보다 이후 일자 일수는 없습니다.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      this.tfbz61000List = [];
      this.tmtlWcurAmtSum = "0";
      this.realBnfAmtSum = "0";
      this.txblWcurAmtSum = "0";
      this.itaxWcurAmtSum = "0";
      this.rtaxWcurAmtSum = "0";
      this.checkYn = [];
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600134_S' |
      // 'F10600134' // [NEXT 사랑On 신탁]원청징수영수증발급 목록을 조회-selectTFBZ61000List
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "selectTPUP95090List";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600134_S";
      // ------
      // EAI(PO) 전송 파라미터 설정
      // ------
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accSeq: this.accSeq,
        bnfcTc: this.bnfcTc,
        bnfcCsId: "0",
        soptType: this.selTypeSopt,
        trTyp: this.trTyp,
        fromBaseDate: this.fromBaseDate.replaceAll ("-", ""),
        toBaseDate: this.toBaseDate.replaceAll ("-", ""),
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [원청징수영수증발급 목록을 조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600134");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [원청징수영수증발급 목록을 조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------------------------
      // 버튼 단일: 보고서, 프로세스: 확인
      let isProcess = this.getStore("tsStore").getters.getState.isProcess;
      let isLast = this.$bizUtil.tsUtils.getIsLastProc (this);
      if (isProcess) { 
        if (!isLast) {
          this.btnAdmissionName = "보고서";
        }
        else {
          this.btnAdmissionName = "확인";
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        this.btnAdmissionName = "보고서";
      }
      // ---------------------------
      let resData = res.data;
      this.checkYn = [];
      // this.isSelectAll = false;
      if (resData.tfbz61000.length > 0) {
        this.tfbz61000List = resData.tfbz61000;
      }
      else {
        this.tfbz61000List = [];
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [원천징수영수증 목록 데이터]", " this.tfbz61000List → ", this.tfbz61000List);
      if (this.isLoggable) console.log ("+++++++++");
      for (let i = 0; this.tfbz61000List.length; i++) {
        this.tfbz61000List[i].tmtlWcurAmt = this.tfbz61000List[i].tmtlWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.tfbz61000List[i].realBnfAmt = this.tfbz61000List[i].realBnfAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.tfbz61000List[i].txblWcurAmt = this.tfbz61000List[i].txblWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.tfbz61000List[i].itaxWcurAmt = this.tfbz61000List[i].itaxWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.tfbz61000List[i].rtaxWcurAmt = this.tfbz61000List[i].rtaxWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.tfbz61000List[i].baseDate = TSCommUtil.gfn_dateReplace (1, this.tfbz61000List[i].baseDate);
        this.tfbz61000List[i].taxtStrtDate = TSCommUtil.gfn_dateReplace (1, this.tfbz61000List[i].taxtStrtDate);
        this.tfbz61000List[i].taxtEndDate = TSCommUtil.gfn_dateReplace (1, this.tfbz61000List[i].taxtEndDate);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS206P
    * 설명: 계좌번호 조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS206P () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 시작 ]");
      let lv_vm = this;
      let properties = {
        pPage: "MSPTS204M",
        pGdC: lv_vm.gdC, // 신탁상품
        pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000", // 
        pProcTc: "2000",//
      };
      this.popup206 = this.$refs.popup206.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup206Back
    * 설명: 계좌번호 조회 팝업호출 콜백
    *********************************************************/
    fn_Popup206Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup206Back ]___[ 시작 ]");
      this.acno = rtnData.tacno + rtnData.bnkbSeq;
      this.cnm = rtnData.cnm;
      // this.fn_AcnoSeqSearch ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup206Back ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:데이터 제어) bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    bnkAcnoMask (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 시작 ]");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull (_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask (_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 종료 ]");
    },
  }
}
</script>
