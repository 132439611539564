/*
 * 업무구분: 고객
 * 화 면 명: MSPTS100P
 * 화면설명: 등록시CDI고객조회 POPUP
 * 작 성 일: 2023.03.20
 * 작 성 자: 지재호
 */
<template>
  <mo-modal class="fts-modal large" ref="modal" title="등록시 보험ERP 고객조회">
    <template>
      <div class="wrap-modalcontents" v-if="gType == true">
        <h2 class="title-list">고객조회</h2>
        <ul class="list-noicon">
          <li>
            <strong class="colorR"
              >삼성생명에 등록된 고객인지 확인합니다.</strong
            >
          </li>
          <li>
            <strong class="colorR"
              >고객명을 입력 후 확인버튼을 눌러주십시오.</strong
            >
          </li>
        </ul>
        <div class="wrap-table mt-3">
          <table class="table col-type">
            <tbody>
              <tr>
                <th scope="row">실명번호</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="rcno" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">고객명</th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      v-model="cnm" ref="cnm" 
                      :class="valid.cnm.error ? 'error' : ''"
                      @click="removeError($event.currentTarget)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="wrap-modalcontents" v-if="gType == false">
        <div class="wrap-table mt-2 h-scroll">
          <table class="table row-type aC">
            <thead>
              <tr>
                <th class="w40"></th>
                <th>보험고객ID</th>
                <th>고객명</th>
                <th>생년월일</th>
                <th>상태</th>
                <th>분리파기</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr v-for="(row, idx) in popupObj.ds_cdiList"  -->
              <tr
                v-for="(row, idx) in ds_list"
                :key="idx"
                :class="{ checked: cdiListSelIdx == idx }"
                @click="cdiListSelIdx = idx.toString()"
              >
                <td><mo-radio v-model="cdiListSelIdx" :value="idx.toString()" /></td>
                <td>{{ row.custId }}</td>
                <td>{{ row.custNm }}</td>
                <td>{{ row.dob }}</td>
                <td>
                  <mo-badge
                    class="badge-type type02"
                    :class="row.custStatCdClass"
                    :text="row.custStatCdText"
                  />
                </td>
                <td>
                  <mo-badge
                    class="badge-type type02"
                    :class="row.spltDstyStatCClass"
                    :text="row.spltDstyStatCText"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button size="large" @click="fn_Close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm()">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil";
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil";

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS100P",
  screenId: "MSPTS100P",
  components: {},
  props: {
    popupObj: { type: Object, default: null },
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // this.fn_comCode()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS100P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      gTypeTest: true,
      // EAI(PO) 호출 객체
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(),
      sSvcID: "", // 서비스 id
      snbGdTc: "",
      custStatCd: "",
      spltDstyStatC: "",
      gType: "", // 단건: true, 복수: false
      ds_list: [],
      rcnoKindTc: "",
      rcno: "",
      cnm: "",
      cdiListSelIdx: '',
      valid: { 
        cnm: { error:false , title: '고객명' }, 
      }
      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    modal() {
      return this.$refs.modal;
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Open() {
      this.fn_Init();
      this.fn_comCode();
      this.modal.open();
    },
    fn_Close() {
      this.modal.close();
    },
    fn_Confirm() {
      let objRtnArr = {}; // 콜백함수 파라미터 객체
      objRtnArr.gType = this.gType;

      if (this.gType) {
        // 단건
        if (this.cnm.length < 1) {
          //this.$emit("ts-alert-popup", 0, {content: "고객명을 입력하시기 바랍니다."});
          this.$refs.cnm.$el.focus()
          TSCommUtil.gfn_validate(this, "고객명을 입력하시기 바랍니다.");
          this.valid.cnm.error = 'error'
          return false;
        }
        let gRcnoKindTc = this.rcnoKindTc;

        // cnm = this.cnm;
        if (gRcnoKindTc == "01") {
          if (TSCommUtil.gfn_isAlpha(cnm)) {
            this.$refs.cnm.$el.focus()
            TSCommUtil.gfn_validate(this, "외국인만 영문명 입력이 가능합니다.");
            return false;
          }
        }

        objRtnArr.cnm = this.cnm;
      } else {
        // 다건

        if ( TSCommUtil.gfn_isNull (this.cdiListSelIdx) ) {
          this.$emit("ts-alert-popup", 0, {content : "선택후 확인 가능합니다."});
          return false
        }

        objRtnArr.cnm = this.ds_list[this.cdiListSelIdx].custNm;
        objRtnArr.custStatCd = this.ds_list[this.cdiListSelIdx].custStatCd;
        objRtnArr.custId = this.ds_list[this.cdiListSelIdx].custId;
        objRtnArr.spltDstyStatC = this.ds_list[this.cdiListSelIdx].spltDstyStatC;
      }

      /*********************************************
       * 부모화면으로 파라미터를 보내는 방법
       * - this.close(sVal) 안에 담는다
       * - 문자열만 가능
       * - Modeless인 경우, this.opener.함수명(보낼데이터)
       *   예) this.opener.fn_popupAfter("popupID","abc1234");
       ********************************************/
      // this.rcnoKindTc = "";
      // this.rcno = "";
      this.$emit("ts-popup-callback", 8, objRtnArr);
      this.modal.close();
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      this.rcno = "";
      this.rcnoKindTc = "";
      this.cnm = ""; 
      this.cdiListSelIdx = ""; // 다건 row 선택값
    },

    /***************************************************************************************      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_comCode() {
      // 현재일자 구하기
      this.strToday = TSCommUtil.fn_strToday();

      //데이터셋 row 초기화후 row추가 : row 없으면 입력 불가능.
      // this.ds_search.clearData();
      // this.ds_search.addRow();
      // this.ds_list.clearData();

      // TODO : 응답결과가 어떻게 나오는지 봐야 정확한 분기가됨.....
      if (this.popupObj.ds_cdiList.length > 0) {
        // 고객다건조회(SC) 응답 setting
        // TODO : 구현해야함
        // this.fn_commonAfterOnload ( false )
        this.gType = false; // 복수
        this.ds_list = _.cloneDeep(this.popupObj.ds_cdiList);
        this.rcno = "";
        this.rcnoKindTc = "";
      } else {
        // 고객명 / 실명번호 검증 setting
        // TODO : 구현해야함
        // this.fn_commonAfterOnload ( true )
        this.gType = true; // 단건
        if (
          !TSCommUtil.gfn_isNull(this.popupObj.rcno) &&
          !TSCommUtil.gfn_isNull(this.popupObj.rcnoKindTc)
        ) {
          if (
            this.popupObj.rcnoKindTc == "01" ||
            this.popupObj.rcnoKindTc == "03" ||
            this.popupObj.rcnoKindTc == "04" ||
            this.popupObj.rcnoKindTc == "05"
          ) {
            if ( this.isMobile ) {
              this.rcno =
                this.popupObj.rcno.substr(0, 6) +
                "-" + "*******";
            } else {
              this.rcno =
                this.popupObj.rcno.substr(0, 6) +
                "-" +
                this.popupObj.rcno.substr(6, 1) +
                "******";
            }
            
          } else if (this.popupObj.rcnoKindTc == "06") {
            this.rcno = "*********" + right(this.popupObj.rcno, 4);
          } else if (this.popupObj.rcnoKindTc == "07") {
            this.rcno =
              this.popupObj.rcno.substr(0, 3) +
              "-" +
              this.popupObj.rcno.substr(3, 2) +
              "-" +
              this.popupObj.rcno.substr(5, 10);
          } else {
            this.rcno = this.popupObj.rcno;
          }
        }
      }
    },

    /**********************************************************************************
     * 초기화 : 화면입출력항목 초기화/활성화
     **********************************************************************************/
    fn_commonAfterOnload(type) {
      const tmp = "";
      if (type == true) {
        // 고객단건처리
        //tmp = false;
        this.sta_cnm.set_visible(true);
        this.sta_cnm1.set_visible(true);
        this.cnm.set_visible(true);
        this.sta_rcno.set_visible(true);
        this.sta_rcno1.set_visible(true);
        this.edt_rcno.set_visible(true);
        this.sta_Top.set_visible(true);
        this.sta_End.set_visible(true);
        this.btn_confirm.set_visible(true);
        this.Grid00.set_visible(false);
        this.sta_Notice1.set_visible(true); // 단건메세지
        this.sta_Notice2.set_visible(false); // 다건메세지
      } else {
        // 고객다건처리
        //tmp = true;
        this.sta_cnm.set_visible(false);
        this.sta_cnm1.set_visible(false);
        this.cnm.set_visible(false);
        this.sta_rcno.set_visible(false);
        this.sta_rcno1.set_visible(false);
        this.edt_rcno.set_visible(false);
        this.sta_Top.set_visible(false);
        this.sta_End.set_visible(false);
        this.btn_confirm.set_visible(false);
        this.Grid00.set_visible(true);
        this.sta_Notice1.set_visible(false); // 단건메세지
        this.sta_Notice2.set_visible(true); // 다건메세지
      }
    },

    /******************************************************************************
     * Function명 : removeError
     * 설명       : css error 삭제
     ******************************************************************************/
    removeError (target) {
      // target.classList.remove('error')
      if ( target.parentElement.parentElement.classList.contains('error') ) {
        target.parentElement.parentElement.classList.remove('error')
      }
      else if ( target.parentElement.classList.contains('error') ) {
        target.parentElement.classList.remove('error')
      }
      else {
        target.classList.remove('error')
      }
    }, 
  },
  
};
</script>
<style scoped>
</style>