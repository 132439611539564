/*
 * 업무구분: 개인PEP등록
 * 화 면 명: MSPTS307P
 * 화면설명: 개인PEP등록
 * 작 성 일: 2023.03.21
 * 작 성 자: 김경태 (전면개정 -> refined by anrachi) 
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="개인PEP등록">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label>종합 계좌번호</label>
              <mo-text-field class="w130" v-model="acno" />
              <mo-button @click="fn_OpenMSPTS107P" class="btn-pop-download"> </mo-button>
              <mo-text-field class="w130" v-model="cnm" disabled />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <!-- 전자서식(리포트)출력 연계개발/검증을 위한 임시 추가, added by anarchi, 2023.07.24 //-->
              <!-- <mo-button @click="fn_confirmPrintTrue" primary> 전자문서 조회 </mo-button> -->
              <mo-button primary @click="fn_ChkSearch" class="inquiry"> 조회 </mo-button>
            </div>
          </div>
        </div>
        <div class="wrap-table-title">
          <h2 class="table-title"> 개인 </h2>
        </div>
        <div class="wrap-table mt-3">
          <table class="table col-type col-line">
          <colgroup>
            <col width="136px">
            <col width="*">
            <col width="*">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <td colspan="4" class="no-border-left">
                <div class="row justify-between">
                  <div class="row">
                    <strong class="mr-4">예금주가 고객이 아닌 경우?</strong>
                    <mo-radio-wrapper 
                      v-model="custYn" 
                      :items="custYnItems" 
                      :disabled="custYnEnabled"
                      @input="fn_DataEnable ('A', custYn)" 
                    />
                  </div>
                  <p class="text-xs">'예'를 선택한 경우 아래 정보를 입력해 주세요</p>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 성명<br>(외국인 영문명) </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox v-model="okYn0" @input="fn_DataEnable ('1', okYn0)" :disabled="enabled1Chk" />
                  <mo-text-field v-model="cnm0" :disabled="enabled1" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox v-model="okYn1" @input="fn_DataEnable ('2', okYn1)" :disabled="enabled2Chk" />
                  <mo-text-field v-model="cnm1" :disabled="enabled2" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox v-model="okYn2" @input="fn_DataEnable ('3', okYn2)" :disabled="enabled3Chk" />
                  <mo-text-field v-model="cnm2" :disabled="enabled3" class="input-long" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 구분 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown 
                    v-model="rcnoType0" 
                    placeholder="선택하세요" 
                    :items="rcnoTypeItems" 
                    :disabled="enabled1" 
                    ref="dropdown1" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown 
                    v-model="rcnoType1" 
                    placeholder="선택하세요" 
                    :items="rcnoTypeItems" 
                    :disabled="enabled2" 
                    ref="dropdown2" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown 
                    v-model="rcnoType2" 
                    placeholder="선택하세요" 
                    :items="rcnoTypeItems" 
                    :disabled="enabled3" 
                    ref="dropdown3" 
                  />
                </div>
              </td>
            </tr>
            <!-- <tr>
              <th><span class="emphasis"> 실명번호 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="rcno0" :disabled="enabled1" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="rcno1" :disabled="enabled2" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="rcno2" :disabled="enabled3" class="input-long" />
                </div>
              </td>
            </tr> -->
            <!-- 실명번호/외국인번호 등 개인식별정보 입력요소 레이아웃 변경(영역분할) 및 보안키패드 적용, modified by anarchi f(2023.09.19) -->
            <tr>
              <th>
                <span class="emphasis"> 실명번호 </span>
              </th>
              <td>
                <!-- 개인1 -->
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="rcno0_1" 
                    clearable 
                    class="w80" 
                    type="number" 
                    maxlength="6" 
                    placeholder="앞 6자리" 
                    :disabled="enabled1" 
                    @input="fn_SearchValid ($event, 0, 'rcno0_1')" 
                    @paste="fn_SearchValid ($event, 0, 'rcno0_1', 'P')" 
                    ref="rcno0_1" 
                  />
                  <m-trans-key-input
                    v-if="isMobile" 
                    v-model="rcno0_2" 
                    class="w80" 
                    type="numberMax7" 
                    dialog="Y" 
                    start="1" 
                    end="-1" 
                    :disabled="enabled1" 
                    :isClear="lv_isClear_rcno0_2" 
                    @masked-txt="fn_SetMaskedTxt_Rcno0" 
                    ref="rcno0_2" 
                  />
                  <mo-text-field
                    v-else
                    v-model="rcno0_2"
                    clearable 
                    numeric 
                    class="w80"
                    type="password" 
                    mask="#######"
                    placeholder="뒤 7자리" 
                    :disabled="enabled1"  
                    @keyup="fn_SearchValid ($event, 0, 'rcno0_2')" 
                    ref="rcno0_2" 
                  />
                </div>
              </td>
              <td>
                <!-- 개인2 -->
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="rcno1_1" 
                    clearable 
                    class="w80" 
                    type="number" 
                    maxlength="6" 
                    placeholder="앞 6자리" 
                    :disabled="enabled2" 
                    @input="fn_SearchValid ($event, 1, 'rcno1_1')" 
                    @paste="fn_SearchValid ($event, 1, 'rcno1_1', 'P')" 
                    ref="rcno1_1" 
                  />
                  <m-trans-key-input
                    v-if="isMobile" 
                    v-model="rcno1_2" 
                    class="w80" 
                    type="numberMax7" 
                    dialog="Y" 
                    start="1" 
                    end="-1" 
                    :disabled="enabled2" 
                    :isClear="lv_isClear_rcno1_2" 
                    @masked-txt="fn_SetMaskedTxt_Rcno1" 
                    ref="rcno1_2" 
                  />
                  <mo-text-field
                    v-else
                    v-model="rcno1_2"
                    clearable 
                    numeric 
                    class="w80"
                    type="password" 
                    mask="#######"
                    placeholder="뒤 7자리" 
                    :disabled="enabled2"  
                    @keyup="fn_SearchValid ($event, 1, 'rcno1_2')" 
                    ref="rcno1_2" 
                  />
                </div>
              </td>
              <td>
                <!-- 개인3 -->
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="rcno2_1" 
                    clearable 
                    class="w80" 
                    type="number" 
                    maxlength="6" 
                    placeholder="앞 6자리" 
                    :disabled="enabled3" 
                    @input="fn_SearchValid ($event, 2, 'rcno2_1')" 
                    @paste="fn_SearchValid ($event, 2, 'rcno2_1', 'P')" 
                    ref="rcno2_1" 
                  />
                  <m-trans-key-input
                    v-if="isMobile" 
                    v-model="rcno2_2" 
                    class="w80" 
                    type="numberMax7" 
                    dialog="Y" 
                    start="1" 
                    end="-1" 
                    :disabled="enabled3" 
                    :isClear="lv_isClear_rcno2_2" 
                    @masked-txt="fn_SetMaskedTxt_Rcno2" 
                    ref="rcno2_2" 
                  />
                  <mo-text-field
                    v-else
                    v-model="rcno2_2"
                    clearable 
                    numeric 
                    class="w80"
                    type="password" 
                    mask="#######"
                    placeholder="뒤 7자리" 
                    :disabled="enabled3"  
                    @keyup="fn_SearchValid ($event, 2, 'rcno2_2')" 
                    ref="rcno2_2" 
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 관계 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC0" :disabled="enabled1" placeholder="선택하세요" ref="dropdown4" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC1" :disabled="enabled2" placeholder="선택하세요" ref="dropdown5" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC2" :disabled="enabled3" placeholder="선택하세요" ref="dropdown6" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 관계 (관계자) </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlRelItems" v-model="amlRelIstm0" :disabled="enabled1" placeholder="선택하세요" ref="dropdown7" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlRelItems" v-model="amlRelIstm1" :disabled="enabled2" placeholder="선택하세요" ref="dropdown8" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlRelItems" v-model="amlRelIstm2" :disabled="enabled3" placeholder="선택하세요" ref="dropdown9" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 국적 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC0" :disabled="enabled1" placeholder="선택하세요" ref="dropdown10" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC1" :disabled="enabled2" placeholder="선택하세요" ref="dropdown11" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC2" :disabled="enabled3" placeholder="선택하세요" ref="dropdown12" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 생년월일 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-date-picker class="input-long" v-model="btdt0" :disabled="enabled1" :bottom="false" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-date-picker class="input-long" v-model="btdt1" :disabled="enabled2" :bottom="false" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-date-picker class="input-long" v-model="btdt2" :disabled="enabled3" :bottom="false" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 보험직업 </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC0" 
                    v-model="jobC0" 
                    class="input-long" 
                    :disabled="enabled1" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled1"
                    @click="fn_OpenPopup (6, 'insrJOBC_0')"
                  > 보험코드 </mo-button>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC1" 
                    v-model="jobC1" 
                    class="input-long" 
                    :disabled="enabled2" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled2"
                    @click="fn_OpenPopup (6, 'insrJOBC_1')"
                  > 보험코드 </mo-button>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC2" 
                    v-model="jobC2" 
                    class="input-long" 
                    :disabled="enabled3" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled3"
                    @click="fn_OpenPopup (6, 'insrJOBC_2')"
                  > 보험코드 </mo-button>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 주소 </span></th>
              <td>
                <div class="wrap-checkbox row">
                  <mo-checkbox 
                    v-model="ok_yn0" 
                    :checked="'Y' == ok_yn0" 
                    :disabled="enabled1" 
                    @input="fn_ChkOwnAddr (0)"
                  >본인주소와 같음</mo-checkbox>
                </div>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" :disabled="enabled1" v-model="psno0" readonly />
                  <mo-button 
                    class="btn-input"
                    :disabled="'Y' == ok_yn0" 
                    @click="fn_OpenPopup (0, 'HOME_0')" 
                  > 우편번호 </mo-button>
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr0" :disabled="enabled1" readonly />
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr0" :disabled="enabled1" readonly />
                </div>
              </td>
              <td>
                <div class="wrap-checkbox row">
                  <mo-checkbox 
                    v-model="ok_yn1" 
                    :checked="'Y' == ok_yn1" 
                    :disabled="enabled2" 
                    @input="fn_ChkOwnAddr (1)"
                  >본인주소와 같음</mo-checkbox>
                </div>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" v-model="psno1" :disabled="enabled2" readonly />
                  <mo-button 
                    class="btn-input" 
                    :disabled="'Y' == ok_yn1"
                    @click="fn_OpenPopup (0, 'HOME_1')" 
                  > 우편번호 </mo-button>
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr1"  :disabled="enabled2" readonly />
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr1"  :disabled="enabled2" readonly />
                </div>
              </td>
              <td>
                <div class="wrap-checkbox row">
                  <mo-checkbox 
                    v-model="ok_yn2" 
                    :checked="'Y' == ok_yn2" 
                    :disabled="enabled3" 
                    @input="fn_ChkOwnAddr (2)"
                  >본인주소와 같음</mo-checkbox>
                </div>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" v-model="psno2" :disabled="enabled3" readonly />
                  <mo-button 
                    class="btn-input" 
                    :disabled="'Y' == ok_yn2"
                    @click="fn_OpenPopup (0, 'HOME_2')" 
                  > 우편번호 </mo-button>
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr2" :disabled="enabled3" readonly />
                </div>
                <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr2" :disabled="enabled3" readonly />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 전화번호 </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    class="w50" 
                    v-model="telArno0" 
                    :disabled="enabled1" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telPofn0" 
                    :disabled="enabled1" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telSeq0" 
                    :disabled="enabled1" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    class="w50" 
                    v-model="telArno1" 
                    :disabled="enabled2" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telPofn1" 
                    :disabled="enabled2" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telSeq1" 
                    :disabled="enabled2" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    class="w50" 
                    v-model="telArno2" 
                    :disabled="enabled3" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telPofn2" 
                    :disabled="enabled3" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    class="w50" 
                    v-model="telSeq2" 
                    :disabled="enabled3" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
            </tr>
          </tbody>
          </table>
        </div>
        <!-- 법인 -->
        <div class="wrap-table-title">
          <h2 class="table-title"> 법인 </h2>
        </div>
        <div class="wrap-table mt-3">
          <table class="table col-type col-line">
          <colgroup>
            <col width="136px">
            <col width="*">
            <col width="*">
            <col width="*">
          </colgroup>
          <tbody>
            <tr>
              <td colspan="4" class="no-border-left">
                <div class="row justify-between">
                  <div class="row">
                    <strong class="mr-4">고객이 관련된 법인이 있는지 여부?</strong>
                    <mo-radio-wrapper 
                      v-model="businessYn" 
                      :items="businessYnItems" 
                      :disabled="businessYnEnabled"
                      @input="fn_DataEnable ('B', businessYn)" />
                  </div>
                  <!-- <span> '예'를 선택한 경우 아래 정보를 입력해 주세요</span> -->
                  <p class="text-xs">'예'를 선택한 경우 아래 정보를 입력해 주세요</p>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 법인/단체명<br>(외국법인 영문명) </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox 
                    v-model="okYn3" 
                    :disabled="enabled4Chk" 
                    @input="fn_DataEnable ('4', okYn3)" 
                  />
                  <mo-text-field 
                    v-model="cnm3" 
                    class="input-long" 
                    :disabled="enabled4" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox 
                    v-model="okYn4" 
                    :disabled="enabled5Chk" 
                    @input="fn_DataEnable ('5', okYn4)" 
                  />
                  <mo-text-field 
                    v-model="cnm4" 
                    class="input-long" 
                    :disabled="enabled5" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-checkbox 
                    v-model="okYn5" 
                    :disabled="enabled6Chk" 
                    @input="fn_DataEnable ('6', okYn5)" 
                  />
                  <mo-text-field 
                    v-model="cnm5" 
                    class="input-long" 
                    :disabled="enabled6" 
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 사업자등록번호 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field 
                    v-model="bsno3" 
                    class="input-long" 
                    type="number" 
                    :disabled="enabled4" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field 
                    v-model="bsno4" 
                    type="number" 
                    class="input-long" 
                    :disabled="enabled5" 
                  />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field 
                    v-model="bsno5" 
                    type="number" 
                    class="input-long" 
                    :disabled="enabled6" 
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 직위 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="psnm3" :disabled="enabled4" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="psnm4" :disabled="enabled5" class="input-long" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-text-field v-model="psnm5" :disabled="enabled6" class="input-long" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 지분율 </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field v-model="psrt3" :disabled="enabled4" class="input-long" mask="number" />
                  <span>%</span>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field v-model="psrt4" :disabled="enabled5" class="input-long" mask="number" />
                  <span>%</span>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field v-model="psrt5" :disabled="enabled6" class="input-long" mask="number" />
                  <span>%</span>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 관계 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC3" :disabled="enabled4" placeholder="선택하세요" ref="dropdown13" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC4" :disabled="enabled5" placeholder="선택하세요" ref="dropdown14" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="amlPersnRelItems" v-model="amlPersnRelC5" :disabled="enabled6" placeholder="선택하세요" ref="dropdown15" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 국적 </span></th>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC3" :disabled="enabled4" placeholder="선택하세요" ref="dropdown16" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC4" :disabled="enabled5" placeholder="선택하세요" ref="dropdown17" />
                </div>
              </td>
              <td>
                <div class="wrap-input">
                  <mo-dropdown :items="natnNatCItems" v-model="natnNatC5" :disabled="enabled6" placeholder="선택하세요" ref="dropdown18" />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 심사업종 </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC3" 
                    v-model="jobC3" 
                    class="input-long" 
                    :disabled="enabled4" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled4"
                    @click="fn_OpenPopup (6, 'insrJOBC_3')"
                  > 업종코드 </mo-button>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC4" 
                    v-model="jobC4" 
                    class="input-long" 
                    :disabled="enabled5" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled5"
                    @click="fn_OpenPopup (6, 'insrJOBC_4')"
                  > 업종코드 </mo-button>
                </div>
              </td>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    ref="edt_insrJobC5" 
                    v-model="jobC5" 
                    class="input-long" 
                    :disabled="enabled6" 
                    readonly 
                  />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled6"
                    @click="fn_OpenPopup (6, 'insrJOBC_5')"
                  > 업종코드 </mo-button>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="emphasis"> 주소 </span></th>
              <td>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" v-model="psno3" :disabled="enabled4" readonly />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled4" 
                    @click="fn_OpenPopup (0, 'JOB_0')" 
                  > 우편번호 </mo-button>
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr3" :disabled="enabled4" readonly />
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr3" :disabled="enabled4" readonly />
                </div>
              </td>
              <td>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" v-model="psno4" :disabled="enabled5" readonly />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled5" 
                    @click="fn_OpenPopup (0, 'JOB_1')" 
                  > 우편번호 </mo-button>
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr4" :disabled="enabled5" readonly />
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr4" :disabled="enabled5" readonly />
                </div>
              </td>
              <td>
                <div class="wrap-input row mt-2">
                  <mo-text-field class="input-long" v-model="psno5" :disabled="enabled6" readonly />
                  <mo-button 
                    class="btn-input" 
                    :disabled="enabled6" 
                    @click="fn_OpenPopup (0, 'JOB_2')" 
                  > 우편번호 </mo-button>
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="psnoAddr5" :disabled="enabled6" readonly />
                </div>
                  <div class="wrap-input mt-2">
                  <mo-text-field class="input-long" v-model="rmnAddr5" :disabled="enabled6" readonly />
                </div>
              </td>
            </tr>
            <tr>
              <th><span> 전화번호 </span></th>
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="telArno3" 
                    :disabled="enabled4" 
                    class="w50" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    v-model="telPofn3" 
                    :disabled="enabled4" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    v-model="telSeq3" 
                    :disabled="enabled4" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="telArno4" 
                    :disabled="enabled5" 
                    class="w50" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    v-model="telPofn4" 
                    :disabled="enabled5" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    v-model="telSeq4" 
                    :disabled="enabled5" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
              <td>
                <div class="wrap-input row">
                  <mo-text-field 
                    v-model="telArno5" 
                    :disabled="enabled6" 
                    class="w50" 
                    type="number" 
                    maxlength="3" 
                  />
                  <mo-text-field 
                    v-model="telPofn5" 
                    :disabled="enabled6" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                  <mo-text-field 
                    v-model="telSeq5" 
                    :disabled="enabled6" 
                    class="w50" 
                    type="number" 
                    maxlength="4" 
                  />
                </div>
              </td>  
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" :disabled="trnsEnabled" @click="fn_Insert">등록</mo-button>
      </div>
    </template>
    <!-- alertPopup (공통) 모달 알림팝업 -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    <!-- popup100 우편번호 //--> 
    <msp-fs-100p
      ref="popup100"
      :popupObj="pPopup100Obj"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"      
    ></msp-fs-100p>
    <!-- popup101 보험코드 -->
    <msp-fs-101p
      ref="popup101"
      :popupObj="pPopup101Obj"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack_Code"
    ></msp-fs-101p>
    <!-- popup100 CDI 고객조회_P --> 
    <msp-ts-100p
      ref="popupCdi"
      :popupObj="pPopupCdiObj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup-callback="fn_Popup_CallBack"
    ></msp-ts-100p>
    <!-- popup107 고객조회 //-->
    <msp-ts-107p
      ref="popup107"
      :popup107Obj="pPopup107Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup107-callback="fn_Popup107Back"
    ></msp-ts-107p>
  </mo-modal>
</template>

<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ************************************************************************************************/
import moment from "moment"
import _ from "lodash"
// import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

// import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)
// import MSPFS101P from '~/src/ui/fs/MSPFS101P' // 보험코드 (공통)
import MSPFS100P from "~/src/ui/fs/MSPFS100P" // 우편번호 (공통)
import MSPFS101P from "~/src/ui/fs/MSPFS101P" // 보험코드 (공통)

import MSPTS100P from "~/src/ui/ts/MSPTS100P" // CDI 고객조회_p
import MSPTS107P from "~/src/ui/ts/MSPTS107P" // 고객조회

export default {
  /************************************************************************************************
   * Vue 파일 속성 정의 영역
   ************************************************************************************************/
  // 현재 화면명
  name: "MSPTS307P",
  // 현재 화면 ID
  screenId: "MSPTS307P",
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
    "ts-alert-popup": TSAlertPopup,
    "msp-fs-100p": MSPFS100P, // 우편번호(공통)
    "msp-fs-101p": MSPFS101P, // 보험코드 (공통)
    "msp-ts-100p": MSPTS100P, // CDI 고객조회_p
    "msp-ts-107p": MSPTS107P, // 고객조회
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String, // 부모 페이지명
    pGdC: String, // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    popupObj: {
      type: Object,
      default: null,
    },
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // ------
      comCdTrnstIds: "F10600046", // (업무공통) 조회용 트랜ID
      selTrnstIds: "F10600257,F10600258", // (업무메인) 조회용 트랜ID -- F10600257 | F10600258
      insTrnstIds: "F10600259", // (업무메인) 등록(신규)용 트랜ID
      uptTrnstIds: "F10600260", // (업무메인) 변경(갱신)용 트랜ID
      delTrnstIds: "F10600XXX", // (업무메인) 삭제(해지)용 트랜ID
      currTrnstId: "F10600135", // 현재 처리중 호출될 트랜ID (기본 조회용으로 설정함) // selectTFBZ99998
      chkAddr: "", // 현재 체크된 '본인주소와 동일한 경우' 주소입력란 인덱스(키)
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      lv_IsPrint: false, // lv(local value) 프린트 여부
      // ------
      csId: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      rcno: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      rcnoKindTc: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.24)
      // ------------------
      // ★ 모바일 환경여부 및 보안키패드 처리 관련 정보항목, added by anarchi (2023.09.19)
      // ------------------
      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      isMtrans: this.getStore ("fsStore").getters.getState.isMtrans,
      lv_isClear_rcno0_2: false, // 개인식별정보(실명번호[0] 뒷자리 등) 보안키패드 초기화 여부
      lv_isClear_rcno1_2: false, // 개인식별정보(실명번호[1] 뒷자리 등) 보안키패드 초기화 여부
      lv_isClear_rcno2_2: false, // 개인식별정보(실명번호[2] 뒷자리 등) 보안키패드 초기화 여부
      lv_masked_rcno0_2: "", // 개인식별정보(실명번호[0] 뒷자리 등) 보안키패드 입력(치환값)
      lv_masked_rcno1_2: "", // 개인식별정보(실명번호[1] 뒷자리 등) 보안키패드 입력(치환값)
      lv_masked_rcno2_2: "", // 개인식별정보(실명번호[2] 뒷자리 등) 보안키패드 입력(치환값)
      // ------------------
      // ------------------
      // ★★★ (Vue 렌더링 오류) 누락 정보항목 임의 추가, corrected by anarchi (2023.07.18)
      // ------------------
      acno: "", // 
      // ------------------
      // 공통 변수
      isInit: false, // 초기화 여부
      rlOwnMnCnfmYn: "Y",
      cnm: "",
      // rcno: "",
      trnsEnabled: false,
      enabled1Chk: true,
      enabled2Chk: true,
      enabled3Chk: true,
      enabled4Chk: true,
      enabled5Chk: true,
      enabled6Chk: true,
      enabled1: true,
      enabled2: true,
      enabled3: true,
      enabled4: true,
      enabled5: true,
      enabled6: true,
      custYnEnabled: false,
      businessYnEnabled: false,
      custYn: "N",
      businessYn: "N",
      // okYn0: false,
      // okYn1: false,
      // okYn2: false,
      // okYn3: false,
      // okYn4: false,
      // okYn5: false,
      okYn0: "N",
      okYn1: "N",
      okYn2: "N",
      okYn3: "N",
      okYn4: "N",
      okYn5: "N",
      cnm0: "",
      cnm1: "",
      cnm2: "",
      cnm3: "",
      cnm4: "",
      cnm5: "",
      rcnoType0:"",
      rcnoType1:"",
      rcnoType2:"",
      rcno0: "",
      rcno1: "",
      rcno2: "",
      bsno3: "",
      bsno4: "",
      bsno5: "",
      // ---------
      // 개인식별정보 (화면 입력요소 분할 변경) 관련 처리용
      // ---------
      rcno0_1: "", // 실명번호[0] 앞자리
      rcno0_2: "", // 실명번호[0] 뒷자리 (입력요소 분할)
      rcno1_1: "", // 실명번호[1] 앞자리 (입력요소 분할)
      rcno1_2: "", // 실명번호[1] 뒷자리 (입력요소 분할)
      rcno2_1: "", // 실명번호[2] 앞자리 (입력요소 분할)
      rcno2_2: "", // 실명번호[2] 뒷자리 (입력요소 분할)
      // ---------
      amlPersnRelC0: "",
      amlPersnRelC1: "",
      amlPersnRelC2: "",
      amlPersnRelC3: "",
      amlPersnRelC4: "",
      amlPersnRelC5: "",
      amlRelIstm0: "",
      amlRelIstm1: "",
      amlRelIstm2: "",
      natnNatC0: "",
      natnNatC1: "",
      natnNatC2: "",
      natnNatC3: "",
      natnNatC4: "",
      natnNatC5: "",
      btdt0: "",
      btdt1: "",
      btdt2: "",
      jobC0: "",
      jobC1: "",
      jobC2: "",
      jobC3: "",
      jobC4: "",
      jobC5: "",
      ok_yn0: "N",
      ok_yn1: "N",
      ok_yn2: "N",
      psno0: "",
      psno1: "",
      psno2: "",
      psno3: "",
      psno4: "",
      psno5: "",
      amlPsno0_1: "",
      amlPsno0_2: "",
      amlPsno1_1: "",
      amlPsno1_2: "",
      amlPsno2_1: "",
      amlPsno2_2: "",
      amlPsno3_1: "",
      amlPsno3_2: "",
      amlPsno4_1: "",
      amlPsno4_2: "",
      amlPsno5_1: "",
      amlPsno5_2: "",
      psnoAddr0: "",
      psnoAddr1: "",
      psnoAddr2: "",
      psnoAddr3: "",
      psnoAddr4: "",
      psnoAddr5: "",
      rmnAddr0: "",
      rmnAddr1: "",
      rmnAddr2: "",
      rmnAddr3: "",
      rmnAddr4: "",
      rmnAddr5: "",
      telArno0: "",
      telArno1: "",
      telArno2: "",
      telArno3: "",
      telArno4: "",
      telArno5: "",
      telPofn0: "",
      telPofn1: "",
      telPofn2: "",
      telPofn3: "",
      telPofn4: "",
      telPofn5: "",
      telSeq0: "",
      telSeq1: "",
      telSeq2: "",
      telSeq3: "",
      telSeq4: "",
      telSeq5: "",
      psnm3: "",
      psnm4: "",
      psnm5: "",
      psrt3: "",
      psrt4: "",
      psrt5: "",
      natnNatC: "KR",
      idenVeriYn: true,
      refNo: "",
      tacno: "",
      bnkbSeq: "",
      ctno: "",
      procSeq: "",
      idtyVrfcYn: "",
      userInfo: {},
      toastMsg: "", // 문자발송 toast 문구
      searchKeyword: "", // 고객명
      trstTypC: "",
      gdTypDtlC: "",
      // ---------------------------
      tfbz99998: {}, // ERP 판단 PO호출 시 반환객체 (오브젝트형)
      tfbz99998Arr: {}, //  ERP 판단 PO호출시 반환객체 (배열형)
      tfgpplRst: {}, // 기본조회 시, 반환객체 (오브젝트형)
      tfgpplRstArr: [], // 기본조회 시, 반환객체 (배열형)
      pepAddrRst: {}, // 본인주소와 동일한 경우, PO호출시 반환객체 (오브젝트형)
      pepAddrRstArr: [], // 본인주소와 동일한 경우, PO호출시 반환객체 (배열형)
      // ---------------------------
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      isSucs: false, // 리턴값 세팅여부
      disabedType: false,
      amlPrcsPfrmType: "", // (수신)정보항목 추가, added by anarchi (2023.09.22)
      insertYn: "", // (수신)정보항목 추가, added by anarchi (2023.09.22)
      // ---------
      // 공통팝업 설정 관련
      alertPopup: {},
      pAlertPopupObj: {},
      popup100: {}, // MSPFS100P 우편번호 팝업
      popup101: {}, // MSPFS101P 보험코드 팝업
      popupCdi: {}, // MSPTS100P CDI 고객조회 팝업
      popup107: {}, // MSPTS107P 고객조회 팝업
      pPopup100Obj: {}, // MSPFS100P 우편번호 (팝업 매개변수)
      pPopup101Obj: {}, // MSPFS101P 보험코드 (팝업 매개변수)
      pPopupCdiObj: {}, // MSPTS100P CDI 고객조회 (팝업 매개변수)
      pPopup107Obj: {}, // MSPTS107P 고객조회 (팝업 매개변수)
      // ---------
      eaiCommObj: {
        lv_vm: "",
        trnstId: "",
        auth: "",
        commHeaderVO: {
          eaiId: "",
          fahrTrnId: "S",
          requestSystemCode: "F1391",
          reqSrvcNm: "",
          reqMthdNm: "",
          targetSystemCode: "",
          resVONm: "",
          reqVONm: "",
        },
        params: {},
        response: [],
        error_msg: "",
      },
      rcnoTypeItems: [], // 실명번호구분 (5036)
      amlPersnRelItems: [], // 관계 (5069)
      amlRelItems: [], // 관계(관계자) (5070)
      insrJobCItems: [], // 보험직업 구분 (5038)
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // 실명번호1 뒷자리 검증 비교할값
    // ---------
    valid_rcno0_2 () {
      if (this.isMobile) { // 보안키패드(실명번호1)
        return this.lv_masked_rcno0_2;
      }
      else { // 보안키패드가 아닌경우
        return this.rcno0_2;
      }
    },
    // ---------
    // 실명번호1 뒷자리 검증 비교할값
    // ---------
    valid_rcno1_2 () {
      if (this.isMobile) { // 보안키패드(실명번호2)
        return this.lv_masked_rcno1_2;
      }
      else { // 보안키패드가 아닌경우
        return this.rcno1_2;
      }
    },
    // ---------
    // 실명번호1 뒷자리 검증 비교할값
    // ---------
    valid_rcno2_2 () {
      if (this.isMobile) { // 보안키패드(실명번호3)
        return this.lv_masked_rcno2_2;
      }
      else { // 보안키패드가 아닌경우
        return this.rcno2_2;
      }
    },
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // 개별업무 프로세스 진행상태 여부, 
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
    custYnItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::custYnItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "Y", text: "예"});
      rtn.push ({value: "N", text: "아니오"});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::custYnItems ]___[ 종료 ]");
    },
    businessYnItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::businessYnItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: "Y", text: "예"});
      rtn.push ({value: "N", text: "아니오"});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::businessYnItems ]___[ 종료 ]");
    },
    natnNatCItems () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::natnNatCItems ]___[ 시작 ]");
      let rtn = [];
      rtn.push ({value: 'GH', text: '가나'});
      rtn.push ({value: 'GA', text: '가봉'});
      rtn.push ({value: 'GY', text: '가이아나'});
      rtn.push ({value: 'GM', text: '감비아'});
      rtn.push ({value: 'GT', text: '과테말라'});
      rtn.push ({value: 'GD', text: '그레나다'});
      rtn.push ({value: 'GR', text: '그리스'});
      rtn.push ({value: 'GL', text: '그린란드'});
      rtn.push ({value: 'GN', text: '기니'});
      rtn.push ({value: 'GW', text: '기니비사우'});
      rtn.push ({value: 'NA', text: '나미비아'});
      rtn.push ({value: 'NR', text: '나우르'});
      rtn.push ({value: 'NG', text: '나이지리아'});
      rtn.push ({value: 'ZA', text: '남아프리카'});
      rtn.push ({value: 'NL', text: '네덜란드'});
      rtn.push ({value: 'NP', text: '네팔'});
      rtn.push ({value: 'NO', text: '노르웨이'});
      rtn.push ({value: 'NZ', text: '뉴질랜드'});
      rtn.push ({value: 'NC', text: '뉴칼레도니아'});
      rtn.push ({value: 'NU', text: '니우에'});
      rtn.push ({value: 'NE', text: '니제르'});
      rtn.push ({value: 'NI', text: '니카라과'});
      rtn.push ({value: 'TW', text: '대만'});
      rtn.push ({value: 'KR', text: '대한민국'});
      rtn.push ({value: 'DK', text: '덴마크'});
      rtn.push ({value: 'DO', text: '도미니카공화국'});
      rtn.push ({value: 'DE', text: '독일'});
      rtn.push ({value: 'LR', text: '라이베리아'});
      rtn.push ({value: 'LV', text: '라트비아'});
      rtn.push ({value: 'RU', text: '러시아 연방'});
      rtn.push ({value: 'LB', text: '레바논'});
      rtn.push ({value: 'LS', text: '레소토'});
      rtn.push ({value: 'RO', text: '루마니아'});
      rtn.push ({value: 'LU', text: '룩셈부르크'});
      rtn.push ({value: 'RW', text: '르완다'});
      rtn.push ({value: 'LY', text: '리비아'});
      rtn.push ({value: 'LT', text: '리투아니아'});
      rtn.push ({value: 'LI', text: '리히텐슈타인'});
      rtn.push ({value: 'MG', text: '마다가스카르'});
      rtn.push ({value: 'MH', text: '마셜군도'});
      rtn.push ({value: 'MO', text: '마카오'});
      rtn.push ({value: 'MK', text: '마케도니아'});
      rtn.push ({value: 'MW', text: '말라위'});
      rtn.push ({value: 'MY', text: '말레이시아'});
      rtn.push ({value: 'ML', text: '말리'});
      rtn.push ({value: 'MX', text: '멕시코'});
      rtn.push ({value: 'MC', text: '모나코'});
      rtn.push ({value: 'MA', text: '모로코'});
      rtn.push ({value: 'MU', text: '모리셔스'});
      rtn.push ({value: 'MR', text: '모리타니'});
      rtn.push ({value: 'MZ', text: '모잠비크'});
      rtn.push ({value: 'ME', text: '몬테네그로'});
      rtn.push ({value: 'MS', text: '몬트세라트'});
      rtn.push ({value: 'MD', text: '몰도바'});
      rtn.push ({value: 'MV', text: '몰디브'});
      rtn.push ({value: 'MT', text: '몰타'});
      rtn.push ({value: 'MN', text: '몽골'});
      rtn.push ({value: 'US', text: '미국'});
      rtn.push ({value: 'MM', text: '미얀마'});
      rtn.push ({value: 'FM', text: '미크로네시아'});
      rtn.push ({value: 'VU', text: '바누아투'});
      rtn.push ({value: 'BH', text: '바레인'});
      rtn.push ({value: 'BB', text: '바베이도스'});
      rtn.push ({value: 'VA', text: '바티칸시국'});
      rtn.push ({value: 'BS', text: '바하마'});
      rtn.push ({value: 'BD', text: '방글라데시'});
      rtn.push ({value: 'BM', text: '버뮤다'});
      rtn.push ({value: 'BJ', text: '베냉'});
      rtn.push ({value: 'VE', text: '베네수엘라'});
      rtn.push ({value: 'VN', text: '베트남'});
      rtn.push ({value: 'BE', text: '벨기에'});
      rtn.push ({value: 'BY', text: '벨라루스'});
      rtn.push ({value: 'BZ', text: '벨리즈'});
      rtn.push ({value: 'BA', text: '보스니아헤르체고비나'});
      rtn.push ({value: 'BW', text: '보츠와나'});
      rtn.push ({value: 'BO', text: '볼리비아'});
      rtn.push ({value: 'BI', text: '부룬디'});
      rtn.push ({value: 'BF', text: '부르키나 파소'});
      rtn.push ({value: 'BT', text: '부탄'});
      rtn.push ({value: 'KP', text: '북한'});
      rtn.push ({value: 'BG', text: '불가리아'});
      rtn.push ({value: 'BR', text: '브라질'});
      rtn.push ({value: 'BN', text: '브루나이'});
      rtn.push ({value: 'SA', text: '사우디아라비아'});
      rtn.push ({value: 'SM', text: '산마리노'});
      rtn.push ({value: 'EH', text: '서사하라'});
      rtn.push ({value: 'SN', text: '세네갈'});
      rtn.push ({value: 'RS', text: '세르비아'});
      rtn.push ({value: 'SC', text: '세이셸'});
      rtn.push ({value: 'LC', text: '세인트루시아'});
      rtn.push ({value: 'SO', text: '소말리아'});
      rtn.push ({value: 'SB', text: '솔로몬제도'});
      rtn.push ({value: 'SD', text: '수단'});
      rtn.push ({value: 'SR', text: '수리남'});
      rtn.push ({value: 'LK', text: '스리랑카'});
      rtn.push ({value: 'SZ', text: '스와질란드'});
      rtn.push ({value: 'SE', text: '스웨덴'});
      rtn.push ({value: 'CH', text: '스위스'});
      rtn.push ({value: 'ES', text: '스페인'});
      rtn.push ({value: 'SK', text: '슬로바키아'});
      rtn.push ({value: 'SI', text: '슬로베니아'});
      rtn.push ({value: 'SY', text: '시리아'});
      rtn.push ({value: 'SL', text: '시에라 리온'});
      rtn.push ({value: 'SG', text: '싱가포르'});
      rtn.push ({value: 'AE', text: '아랍에미리트'});
      rtn.push ({value: 'AM', text: '아르메니아'});
      rtn.push ({value: 'AR', text: '아르헨티나'});
      rtn.push ({value: 'IS', text: '아이슬란드'});
      rtn.push ({value: 'HT', text: '아이티'});
      rtn.push ({value: 'IE', text: '아일랜드'});
      rtn.push ({value: 'AZ', text: '아제르바이잔'});
      rtn.push ({value: 'AF', text: '아프가니스탄'});
      rtn.push ({value: 'AD', text: '안도라'});
      rtn.push ({value: 'AL', text: '알바니아'});
      rtn.push ({value: 'DZ', text: '알제리'});
      rtn.push ({value: 'AO', text: '앙골라'});
      rtn.push ({value: 'AG', text: '앤티가바부다'});
      rtn.push ({value: 'ER', text: '에리트리아'});
      rtn.push ({value: 'EE', text: '에스토니아'});
      rtn.push ({value: 'EC', text: '에콰도르'});
      rtn.push ({value: 'ET', text: '에티오피아'});
      rtn.push ({value: 'GB', text: '영국'});
      rtn.push ({value: 'YE', text: '예멘'});
      rtn.push ({value: 'OM', text: '오만'});
      rtn.push ({value: 'AT', text: '오스트리아'});
      rtn.push ({value: 'HN', text: '온두라스'});
      rtn.push ({value: 'JO', text: '요르단'});
      rtn.push ({value: 'UG', text: '우간다'});
      rtn.push ({value: 'UY', text: '우루과이'});
      rtn.push ({value: 'UZ', text: '우즈베키스탄'});
      rtn.push ({value: 'UA', text: '우크라이나'});
      rtn.push ({value: 'IQ', text: '이라크'});
      rtn.push ({value: 'IR', text: '이란'});
      rtn.push ({value: 'IL', text: '이스라엘'});
      rtn.push ({value: 'EG', text: '이집트'});
      rtn.push ({value: 'IT', text: '이탈리아'});
      rtn.push ({value: 'IN', text: '인도'});
      rtn.push ({value: 'ID', text: '인도네시아'});
      rtn.push ({value: 'JP', text: '일본'});
      rtn.push ({value: 'JM', text: '자메이카'});
      rtn.push ({value: 'ZM', text: '잠비아'});
      rtn.push ({value: 'GQ', text: '적도기니'});
      rtn.push ({value: 'GE', text: '조지아'});
      rtn.push ({value: 'CN', text: '중국'});
      rtn.push ({value: 'CF', text: '중앙아프리카공화국'});
      rtn.push ({value: 'DJ', text: '지부티'});
      rtn.push ({value: 'TD', text: '챠드'});
      rtn.push ({value: 'CZ', text: '체코'});
      rtn.push ({value: 'CL', text: '칠레'});
      rtn.push ({value: 'CM', text: '카메룬'});
      rtn.push ({value: 'CV', text: '카보베르데'});
      rtn.push ({value: 'KZ', text: '카자흐스탄'});
      rtn.push ({value: 'QA', text: '카타르'});
      rtn.push ({value: 'KH', text: '캄보디아'});
      rtn.push ({value: 'CA', text: '캐나다'});
      rtn.push ({value: 'KE', text: '케냐'});
      rtn.push ({value: 'KM', text: '코모로'});
      rtn.push ({value: 'CR', text: '코스타리카'});
      rtn.push ({value: 'CI', text: '코트디부아르'});
      rtn.push ({value: 'CO', text: '콜롬비아'});
      rtn.push ({value: 'CG', text: '콩고'});
      rtn.push ({value: 'CD', text: '콩고민주공화국'});
      rtn.push ({value: 'CU', text: '쿠바'});
      rtn.push ({value: 'KW', text: '쿠웨이트'});
      rtn.push ({value: 'HR', text: '크로아티아'});
      rtn.push ({value: 'KG', text: '키르기스스탄'});
      rtn.push ({value: 'KI', text: '키리바시'});
      rtn.push ({value: 'CY', text: '키프로스'});
      rtn.push ({value: 'TJ', text: '타지키스탄'});
      rtn.push ({value: 'TZ', text: '탄자니아'});
      rtn.push ({value: 'TH', text: '태국'});
      rtn.push ({value: 'TR', text: '터키'});
      rtn.push ({value: 'TG', text: '토고'});
      rtn.push ({value: 'TO', text: '통가'});
      rtn.push ({value: 'TM', text: '투르크메니스탄'});
      rtn.push ({value: 'TV', text: '투발루'});
      rtn.push ({value: 'TN', text: '튀니지'});
      rtn.push ({value: 'TT', text: '트리니다드토바고'});
      rtn.push ({value: 'PA', text: '파나마'});
      rtn.push ({value: 'PY', text: '파라과이'});
      rtn.push ({value: 'PK', text: '파키스탄'});
      rtn.push ({value: 'PG', text: '파푸아 뉴기니'});
      rtn.push ({value: 'PW', text: '팔라우'});
      rtn.push ({value: 'PS', text: '팔레스타인'});
      rtn.push ({value: 'PE', text: '페루'});
      rtn.push ({value: 'PT', text: '포르투갈'}); 
      rtn.push ({value: 'PL', text: '폴란드'});
      rtn.push ({value: 'PR', text: '푸에르토 리코'});
      rtn.push ({value: 'FR', text: '프랑스'});
      rtn.push ({value: 'FJ', text: '피지'});
      rtn.push ({value: 'FI', text: '핀란드'});
      rtn.push ({value: 'PH', text: '필리핀'});
      rtn.push ({value: 'HU', text: '헝가리'});
      rtn.push ({value: 'AU', text: '호주'});
      rtn.push ({value: 'HK', text: '홍콩'});
      return rtn;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::natnNatCItems ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    //
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    // const lv_Vm = this;
    this.fn_ComCode ();
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS307P");
    document.getElementsByClassName ("wrap-modalcontents")[0].addEventListener ("scroll", this.closeDropdown);
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Before-Destroy 설정 영역
   ******************************************************************************************/
  beforeDestroy () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 시작 ]");
    document.getElementsByClassName ("wrap-modalcontents")[0].removeEventListener ("scroll", this.closeDropdown);
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Methods 설정 영역
   ******************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue (isPrint = false) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      this.lv_IsPrint = isPrint;
      this.ds_searchReportList = [];
      // ---------
      // ■ [TS000040_개인 추가정보 확인서(EDD,PEP)] 정보항목 매핑 정의
      //   +. AS-IS: TPUP95320.xfdl---개인EDD등록팝업
      //             TPUP95022.mrd
      // var reportMrdNm = "/cronix/fiduciary/popup/TPUP95022.mrd"; // 보고서 파일 경로
      // var reportUrl = ""; //"reportTFCP11000.do"; // 보고서 호출 서비스url
      // // var reportUrl = "reportTFCP11002.do"; // 보고서 호출 서비스url
      // ---------
      this.ds_searchParam = {
        trPpsTc             : "", // 거래관련 기본정보_거래의 목적 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5,6,7) [01.가족보장,02.노후준비,03.상속준비,04.자녀양육비,05.저축,07.재계약,99.기타,]
        trPpsTcEtc          : "", // 거래 목적_기타_내용 (다를경우 화면단에서 입력) [기타에 들어올 텍스트]
        fdSrcOrgplEtcCntn   : "", // 거래관련 기본정보_자금의 원천 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5,6,7,8,9) [01.근로및연금소득,02.퇴직소득,03.사업소득,04.부동산임대소득,05.부동산양도소득,06.금융소득,07.상속/증여,08.일시재산양도로인한소득,99.기타]
        fdSrcOrgplEtcCntnEtc: "", // 자금원천_기타_내용 (다를경우 화면단에서 입력) [기타에 들어올 텍스트]
        totAstsPnstaTc      : "", // 거래관련 기본정보_추정자산 체크박스 (다를경우 화면단에서 입력) (1,2,3,4,5) [1. 5억원 미만,2. 5억원 이상 - 10억원 미만,3. 10억원 이상 - 100억원 미만,4. 100억원 이상 - 1000억원 미만,5. 1000억원 이상]
        offwkYn             : "", // 직업정보_직업구분 체크박스 (다를경우 화면단에서 입력) (1,2) [1. 직장인,2. 개인사업자]
        coNm                : "", // 직업정보_직장인 회사/부서명 (다를경우 화면단에서 입력)
        dpnm                : "", // 직업정보_직장인 회사/부서명 (다를경우 화면단에서 입력)
        jobps               : "", // 직업정보_직장인 지위 (다를경우 화면단에서 입력)
        incoDate            : "", // 직업정보_직장인 인사일자 (다를경우 화면단에서 입력)
        persnBusiMnBsno     : "", // 직업정보_개인사업자 사업자등록번호 (다를경우 화면단에서 입력)
        insrStndIndsTypeC   : "", // 직업정보_개인사업자 업종 (다를경우 화면단에서 입력)
        estbDate            : "", // 직업정보_개인사업자 설립일자 (다를경우 화면단에서 입력)
        chkYn               : "", // 외국의 정치적 주요인물 관련 여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.관련있음 N.관련없음]
        chkYn               : "", // 자금출처관련 정보_고객본인과 동일인 여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.예 N.아니오]
        custNm              : "", // 자금출처관련 정보_계좌주 성명 (다를경우 화면단에서 입력)
        prhsRel             : "", // 자금출처관련 정보_관계 (다를경우 화면단에서 입력)
        rcnoEncr            : "", // 자금출처관련 정보_실명번호 (다를경우 화면단에서 입력)
        btdt                : "", // 자금출처관련 정보_생년월일 (다를경우 화면단에서 입력)
        cdiNatnNatC         : "", // 자금출처관련 정보_국적 (다를경우 화면단에서 입력)
        addr                : "", // 자금출처관련 정보_주소  (다를경우 화면단에서 입력)
        entrTelNo           : "", // 자금출처관련 정보_연락처 (다를경우 화면단에서 입력)
        chkYn               : "", // 자금출처관련 정보_고객 본인 관여 법인 존재여부 체크박스 (다를경우 화면단에서 입력) (1,2) [Y.예 N.아니오]
        prhsReComl          : "", // 
        estbPpsTc           : "", // 
        natC                : "", // 자금출처관련 정보_법인/단체명 (다를경우 화면단에서 입력)
        estbPrhsRel         : "", // 자금출처관련 정보_관계 (다를경우 화면단에서 입력)
        estbBsno            : "", // 자금출처관련 정보_법인 등록번호 (다를경우 화면단에서 입력)
        insrStndIndsTypeCom : "", // 자금출처관련 정보_업종 (다를경우 화면단에서 입력)
        natC                : "", // 자금출처관련 정보_설립국가 (다를경우 화면단에서 입력)
        estbAddr            : "", // 자금출처관련 정보_주소  (다를경우 화면단에서 입력)
        estbTelNo           : "", // 자금출처관련 정보_연락처 (다를경우 화면단에서 입력)
      };
      // 화면내 추출 파라미터
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ this.tfgpplRst → ", this.tfgpplRst);
      if (this.isLoggable) console.log ("+++++++++");
      // 리포트 파라미터 내 파라미터
      this.ds_searchParam.csId = this.csId;
      // this.ds_searchParam.rcno = this.dsDetail.rcno;
      // this.ds_searchParam.rcnoKindTc = this.dsDetail.rcnoKindTc;
      this.ds_searchReport.reportMrdNm = "TS000040"; // 서식폼ID
      this.ds_searchReport.reportUrl = ""; // 서식 PO서비스 ID:
      // this.ds_searchReport.reportUrl = "reportTFCP11000"; // 서식 PO서비스 ID:
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷▷ this.ds_searchReport → ", this.ds_searchReport);
      if (this.isLoggable) console.log ("+++++++++");
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
      this.fn_searchReport ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
     * 리포트 데이터 조회 
     ***************************************************************************************/
    async fn_searchReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      let formList = [];
      let TS000040 = {
        formId: this.ds_searchReportList[0].reportMrdNm,
      };
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        // report.params = this.ds_searchReport;
        TS000040.mappingData = this.ds_searchParam;
        formList.push (TS000040);
      }
      // TSInfoUtil.commReportInfo (this, formList);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ formList → ", formList);
      if (this.isLoggable) console.log ("+++++++++");
      let t_IsProcess = this.getStore ("tsStore").getters.getState.isProcess;
      // ---------
      // 전자문서 출력을 위한 마지막 화면 설정
      // ---------
      if (t_IsProcess && this.lv_IsPrint) {
        this.getStore ("tsStore").dispatch ("IS_LAST_START");
      }
      TSInfoUtil.commReportInfo (this, formList, "", this.lv_IsPrint , "MSPTS307P");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600046_S' |
      // 'UF10600046' // [NEXT 사랑On 신탁]콤보 목록을 조회-selectComboList
      // ---------
      this.eaiCommObj.lv_vm = this;
      // this.eaiCommObj.trnstId = "txTSSTS90S1"; // selectTFCP11000A
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        "TUKEMK": [
          {cId: "5036"}, // 실명번호 구분 코드번호
          {cId: "5038"}, // 보험직업구분 코드번호
          {cId: "5069"}, // 고객관계 국분 코드번호
          {cId: "5070"}, // 고객관계 구분(관계자) 코드번호
      ]};
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [콤보 목록을 조회---5036|5069|5070]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      // TSServiceUtil.invoke(this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600046");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    }, 
    // ======================================================
    // (공통:초기화--콜백) fn_ComCodeCallBack:: 
    // ======================================================
    fn_ComCodeCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [콤보 목록을 조회---5036|5069|5070]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      for (let index in res.data.tukemk) {
          let item = res.data.tukemk[index];
          switch (item.cid) {
            case "5036":
              this.rcnoTypeItems.push ({
                value: TSCommUtil.gfn_lpad (item.c, 2, "0"),
                text: item.cnm,
              });
              break;
            case "5038":
              this.insrJobCItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
            case "5069":
              this.amlPersnRelItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
            case "5070":
              this.amlRelItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
          }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert 팝업
     ******************************************************************************/
    fn_AlertPopup (type = 0, pData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      this.$emit ("ts-alert-popup", type, pData);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      // ---------
      // 기본 필수 (수신)파라미터 검증
      // ---------
      if (_.isEmpty (param.tacno)) { // 종합계좌번호
        let t_popupObj = {
          confirm: false,
          content: "계좌번호가 제대로 넘어오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.csId)) { // 고객ID
        let t_popupObj = {
          confirm: false,
          content: "고객ID 제대로 넘어 오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.cnm)) { // 고객명
        let t_popupObj = {
          confirm: false,
          content: "고객명 제대로 넘어 오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.refNo)) { // 참조번호
        let t_popupObj = {
          confirm: false,
          content: "참조번호 제대로 넘어 오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.amlPrcsPfrmType)) { // AML송수신처리 상태값
        let t_popupObj = {
          confirm: false,
          content: "AML프로세스수행구분 제대로 넘어 오지 않았습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      this.fn_Clear ();
      this.currTrnstId = "F10600135";
      this.csId = param.csId;
      this.acno = param.tacno;
      this.cnm = param.cnm;
      this.refNo = param.refNo;
      this.amlPrcsPfrmType = param.amlPrcsPfrmType;
      this.insertYn = param.insertYn;
      this.fn_Search (param);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_Open:: 
    // ======================================================
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      this.fn_Init (param);
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.fn_Clear ();
      this.modal.close ();
      this.closeDropdown ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    // ======================================================
    // (화면요소 제어) closeDropdown:: 화면데이터 변수 초기화 및 팝업 닫기
    // ======================================================
    closeDropdown () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 시작 ]");
      this.$refs.dropdown1.close ();
      this.$refs.dropdown2.close ();
      this.$refs.dropdown3.close ();
      this.$refs.dropdown4.close ();
      this.$refs.dropdown5.close ();
      this.$refs.dropdown6.close ();
      this.$refs.dropdown7.close ();
      this.$refs.dropdown8.close ();
      this.$refs.dropdown9.close ();
      this.$refs.dropdown10.close ();
      this.$refs.dropdown11.close ();
      this.$refs.dropdown12.close ();
      this.$refs.dropdown13.close ();
      this.$refs.dropdown14.close ();
      this.$refs.dropdown15.close ();
      this.$refs.dropdown16.close ();
      this.$refs.dropdown17.close ();
      this.$refs.dropdown18.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::closeDropdown ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      // ---------------------------
      this.lv_isClear_rcno0_2 = !this.lv_isClear_rcno0_2 ? true : false // 개인식별정보(실명번호 뒷자리 등) 보안키패드[0] 초기화 여부 설정
      this.lv_isClear_rcno1_2 = !this.lv_isClear_rcno1_2 ? true : false // 개인식별정보(실명번호 뒷자리 등) 보안키패드[1] 초기화 여부 설정
      this.lv_isClear_rcno2_2 = !this.lv_isClear_rcno2_2 ? true : false // 개인식별정보(실명번호 뒷자리 등) 보안키패드[2] 초기화 여부 설정
      this.lv_masked_rcno0_2 = ""; // 개인식별정보(실명번호[0] 뒷자리 등) 보안키패드 입력(치환값)
      this.lv_masked_rcno1_2 = ""; // 개인식별정보(실명번호[1] 뒷자리 등) 보안키패드 입력(치환값)
      this.lv_masked_rcno2_2 = ""; // 개인식별정보(실명번호[2] 뒷자리 등) 보안키패드 입력(치환값)
      this.tfbz99998 = {}; // ERP 판단 PO호출 시 반환객체 (오브젝트형)
      this.tfbz99998Arr = {}; //  ERP 판단 PO호출시 반환객체 (배열형)
      this.tfgpplRst = {}; // 기본조회 시, 반환객체 (오브젝트형)
      this.tfgpplRstArr = []; // 기본조회 시, 반환객체 (배열형)
      this.pepAddrRst = {}; // 본인주소와 동일한 경우, PO호출시 반환객체 (오브젝트형)
      this.pepAddrRstArr = []; // 본인주소와 동일한 경우, PO호출시 반환객체 (배열형)
      this.custYn = "N";
      this.businessYn = "N";
      this.okYn0 = "N";
      this.okYn1 = "N";
      this.okYn2 = "N";
      this.okYn3 = "N";
      this.okYn4 = "N";
      this.okYn5 = "N";
      this.ok_yn0 = "N";
      this.ok_yn1 = "N";
      this.ok_yn2 = "N";
      this.cnm0 = "";
      this.cnm1 = "";
      this.cnm2 = "";
      this.cnm3 = "";
      this.cnm4 = "";
      this.cnm5 = "";
      // ---------------------------
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS107P
    * 설명: 고객조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS107P (type) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS107P ]___[ 시작 ]");
      // this.$refs.popup110.fn_Init ();
      let lv_vm = this;
      this.pPopup107Type = type;
      if (this.isLoggable) console.log ("++++++++++++++++++");
      if (this.isLoggable) console.log ("+ ▷▷ this.$refs.popup107 → ", this.$refs.popup107);
      if (this.isLoggable) console.log ("++++++++++++++++++");
      let properties = {
        pPage: "MSPTS200M",
        pGdC: lv_vm.gdC, // 신탁상품
        pTrstTypeC: lv_vm.trstTypC, // 신탁유형
        pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",
        pProcTc: "2000",
      };
      // this.popup107 = this.$refs.popup107.fn_Open (properties);
      this.$refs.popup107.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS107P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup107Back
    * 설명: 고객조회 팝업호출 콜백
    *********************************************************/
    fn_Popup107Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup107Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ rtnData → ", rtnData);
      if (this.isLoggable) console.log ("+++++++++");
      // var arrVal = new Array(18);
      // if (_.isEmpty (strVal)) 
      // {
      //   return;
      // }
      // else 
      // {
      //   arrVal = strVal.split(",");
      // }
      // this.ds_list.clearData();
      // var nRow = this.ds_list.addRow();
      // this.ds_list.setColumn(nRow, "csId", arrVal[0]); //고객ID
      // this.ds_list.setColumn(nRow, "cno", arrVal[1]); //고객번호
      // this.ds_list.setColumn(nRow, "cnm", arrVal[2]);
      // this.ds_list.setColumn(nRow, "rcnoKindTc", arrVal[3]); //실명구분
      // this.ds_list.setColumn(nRow, "rcno", arrVal[4]); //실명번호
      // this.ds_list.setColumn(nRow, "cono", arrVal[5]); //사업자번호
      // this.ds_list.setColumn(nRow, "genTaxtTc", arrVal[6]); //일반과세구분코드
      // this.ds_list.setColumn(nRow, "persnBusiMnBsno", arrVal[7]); //개인사업자사업자등록번호
      // this.ds_list.setColumn(nRow, "corpYn", arrVal[8]); //법인여부
      // this.ds_list.setColumn(nRow, "csIvtdRatingC", arrVal[9]); //고객투자성향등급코드
      // this.ds_list.setColumn(nRow, "procDt", arrVal[10]); //처리일시
      // this.ds_list.setColumn(nRow, "valdTrmYys", arrVal[11]); //유효기간년수
      // this.ds_list.setColumn(nRow, "ques10", arrVal[12]); //파생상품투자기간
      // this.ds_list.setColumn(nRow, "tacno", arrVal[13]); //종합계좌번호
      // this.ds_list.setColumn(nRow, "age", arrVal[14]); //나이
      // this.ds_list.setColumn(nRow, "tacnoBnkbSeq", arrVal[15]); //종합계좌번호 + 통장일련버호
      // this.ds_list.setColumn(nRow, "regDate", arrVal[16]); //고객가입일자
      // this.ds_list.setColumn(nRow, "tassIssncAccYn", arrVal[17]); //TASS발급계좌여부
      // this.Edt_acno.set_value(arrVal[13]); //종합계좌번호
      // this.edt_regDate.set_value(arrVal[16]); //고객가입일자
      // trace("16");
      // // 계좌번호가 있는 경우에만 호출화면으로 메소드를 전달한다.
      // if (this.ds_list.rowcount > 0) 
      // {
      //   try 
      //   {
      //     this.lookupFunc("acnoCallBackFunc").call();
      //   }
      //   catch (e) 
      //   {
      //   }
      // }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup107Back ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) fn_ChkSearch:: 조회버튼 클릭 시
    // ======================================================
    fn_ChkSearch () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ChkSearch ]___[ 시작 ]");
      let _selTrnstIdArr = this.selTrnstIds.split (",");
      this.currTrnstId = _selTrnstIdArr[0];
      this.fn_Search ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ChkSearch ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_Search
    // * 설명       : 조회 후 팝업 닫음
    // ======================================================
    fn_Search (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      // const lv_Vm = this; // (현) Vue객체모델 인스턴스
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        // csId: param.rlOwnMnCnfmYn,
        csId: (!_.isEmpty (this.csId)) ? this.csId : param.csId, // csid 값 매핑 오류로 수정
        amlProcType: "S",
        inputOk1: this.okYn0,
        inputOk2: this.okYn1,
        inputOk3: this.okYn2,
        inputOk4: this.okYn3,
        inputOk5: this.okYn4,
        inputOk6: this.okYn5,
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600257_S' |
      // 'UF10600257' // [NEXT 사랑On 신탁]개인PEP 상세조회
      // 'C392_F10600258_S' |
      // 'UF10600258' // [NEXT 사랑On 신탁]개인PEP 상세조회 (본인주소와 같을 경우)
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.params = pParams;
      // ---------
      // this.eaiCommObj.trnstId = "selectTPUP96020" // 개인PEP 상세조회
      // this.eaiCommObj.trnstId = "selectTPUP96020PEP" // 개인PEP 상세조회 (본인주소와 같은 경우)
      // 서비스 ID 매핑 오류로 C391_F10600079_S -> C392_F10600257_S 변경
      // this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600257_S"; // 개인PEP 상세조회
      // this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600258_S"; // 개인PEP 상세조회 (본인주소와 같은 경우)
      this.eaiCommObj.commHeaderVO.eaiId = "C392_" + this.currTrnstId + "_S"; // 개인PEP 상세조회
      // ---------
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인PEP 상세조회]_[EAI(PO)]_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        console.log (error);
      }, "U" + this.currTrnstId);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_SearchResult
    // * 설명       : 조회 콜백
    // ======================================================
    fn_SearchResult (pResultData, errorCode) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인PEP 상세조회]_[EAI(PO)_(", pResultData.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " pResultData → ", pResultData, "errorCode → ", errorCode);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // ---------
      if (undefined != pResultData.data && !_.isEmpty (pResultData.data)) {
        if ("F10600257" == this.currTrnstId) {
          this.tfgpplRst = pResultData.data;
          // this.tfgpplRstObj = pResultData.data;
          // this.tfgpplRstArr = pResultData.data;
        }
        else if ("F10600258" == this.currTrnstId) { // 본인주소와 동일 체크 시,
          this.pepAddrRst = pResultData.data;
          // this.pepAddrRstObj = pResultData.data;
          // this.pepAddrRstArr = pResultData.data;
        }
        else {
          // this.pepAddrRst = pResultData.data;
          // this.pepAddrRstObj = pResultData.data;
          // this.pepAddrRstArr = pResultData.data;
        }
      }
      // ---------
      // ---------
      if ("F10600257" == this.currTrnstId) {
        this.fn_SetResultData ();
      }
      else if ("F10600258" == this.currTrnstId) { // 본인주소와 동일 체크 시,
        this.fn_SetResultData_PEP ();
      }
      else {
        // this.custYnEnabled = !this.custYnEnabled;
        // this.businessYnEnabled = !this.businessYnEnabled;
        if ("F10600259" == this.currTrnstId || "F10600260" == this.currTrnstId) {
          // this.trnsEnabled = !this.trnsEnabled ;
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    // ======================================================
    // (공통:데이터 제어) fn_SetPepData:: 
    // ======================================================
    fn_SetPepData (pIdx) { // 사업자번호
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetPepData ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetPepData ]___[ 종료 ]");
      let vIdxStr = pIdx.toString ();
      let vIdxAdd1Str = (Number (pIdx) + 1).toString ();
      if (undefined != this.tfgpplRst && null != this.tfgpplRst) {
        let v_tfgpplRst = this.tfgpplRst;
        // this.okYn0 = v_tfgpplRst.inputOk1; // = this.okYn0;
        this["amlPersnRelC" + vIdxStr] = v_tfgpplRst["amlPersnRelC" + vIdxAdd1Str]; // = this.amlPersnRelC0; // 관계구분(1)
        this["cnm" + vIdxStr] = v_tfgpplRst["persnCnm" + vIdxAdd1Str]; // = this.cnm0; // 이름(1)
        this["rcnoType" + vIdxStr] = TSCommUtil.gfn_lpad (v_tfgpplRst["persnRcnoTc" + vIdxAdd1Str], 2, "0"); // = this.rcnoType0; // 개인식별번호종류(1)
        this["btdt" + vIdxStr] = moment (v_tfgpplRst["amlRlBtdt" + vIdxAdd1Str]).format ("YYYY-MM-DD"); // = this.btdt0; // 생년월일(1)
        // this.eddRno0 = // v_tfgpplRst.eddRno0; // = this.eddRno0; // 개인식별번호(1)
        this["amlRelIstm" + vIdxStr] = v_tfgpplRst["amlRelIstm" + vIdxAdd1Str]; // = this.amlRelIstm0; // 관계자(1)
        this["natnNatC" + vIdxStr] = v_tfgpplRst["amlNatnC" + vIdxAdd1Str]; // = this.natnNatC0; // 국적(1)
        if (3 > pIdx) {
          this["jobC" + vIdxStr] = v_tfgpplRst["amlInsrJobC" + vIdxAdd1Str]; // = this.jobC0; // 보험직업 코드(1)
        }
        else {
          this["jobC" + vIdxStr] = v_tfgpplRst["amlInsrStndIndsTypeC" + vIdxAdd1Str]; // = this.jobC3; // 개인사업자직종 코드
        }
        // this.ok_yn0 = v_tfgpplRst.amlCsAddrUseYn1; // = this.ok_yn0; // ??? 본인 주소와 동일 여부값
        this["amlPsno" + vIdxStr + "_1"] = v_tfgpplRst["amlPsno1" + vIdxAdd1Str]; // = this.amlPsno0_1; // 우편번호 앞자리(1)
        this["amlPsno" + vIdxStr + "_2"] = v_tfgpplRst["amlPsno2" + vIdxAdd1Str]; // = this.amlPsno0_2; // 우편번호 뒷자리(1)
        this["psno" + vIdxStr] = v_tfgpplRst["amlPsno1" + vIdxAdd1Str] + v_tfgpplRst["amlPsno2" + vIdxAdd1Str]; // 우편번호 전체 조합(1)
        this["psnoAddr" + vIdxStr] = v_tfgpplRst["amlPsnoAddr" + vIdxAdd1Str]; // = this.psnoAddr0; // 기본주소(1)
        this["rmnAddr" + vIdxStr] = v_tfgpplRst["amlDetaAddr" + vIdxAdd1Str]; // = this.rmnAddr0; // 상세주소(1)
        this["telArno" + vIdxStr] = v_tfgpplRst["amlTelArno" + vIdxAdd1Str]; // = this.telArno1; // 전화번호 앞자리(1)
        this["telPofn" + vIdxStr] = v_tfgpplRst["amlTelPofn" + vIdxAdd1Str]; // = this.telPofn1; // 전화번호 중간자리(1)
        this["telSeq" + vIdxStr] = v_tfgpplRst["amlTelNo" + vIdxAdd1Str]; // = this.telSeq1; // 전화번호 뒷자리(1)
        // this.amlRlBtdt0 = v_tfgpplRst.amlRlBtdt1; // = this.amlRlBtdt0;
        // this.amlInpYn0 = v_tfgpplRst.amlInpYn1; // = this.amlInpYn0;
        // this["amlPsnoAddr" + vIdxStr] = v_tfgpplRst["amlPsnoAddr" + vIdxAdd1Str]; // = this.amlPsnoAddr0;
        // v_tfgpplRst.telNo1; // = this.telArno3 + this.telPofn3 + this.telSeq1; // 사업장 전화번호(1)???
        // v_tfgpplRst.amlRcno0; // = this.amlRcno0;
        if (3 > pIdx) {
          this["rcno" + vIdxStr] = v_tfgpplRst["amlRcno" + vIdxAdd1Str]; // = this.rcno0; // 개인식별번호(실명번호-전체)(1)
          this["rcno" + vIdxStr + "_1"] = v_tfgpplRst["amlRcno" + vIdxAdd1Str].substr (0, 6); // = this.rcno0; // 개인식별번호(실명번호-앞 6자리)(1)
          this["rcno" + vIdxStr + "_2"] = v_tfgpplRst["amlRcno" + vIdxAdd1Str].substr (6, 7); // = this.rcno0; // 개인식별번호(실명번호-뒤 7자리)(1)
        }
        else {
          this["bsno" + vIdxStr] = v_tfgpplRst["amlRcno" + vIdxAdd1Str]; // 개인사업자번호
          this["psrt" + vIdxStr] = v_tfgpplRst["amlPsrt" + vIdxAdd1Str]; // = this.psrt0; // 지분율(1)
          this["psnm" + vIdxStr] = v_tfgpplRst["amlPsnm" + vIdxAdd1Str]; // = this.psnm0; // 직위(1)
        }
        // 
      }
    },
    // ======================================================
    // (업무-화면 제어) fn_SetResultData:: 조회 후, 반환데이터 설정 및 화면제어
    // ======================================================
    fn_SetResultData () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetResultData ]___[ 시작 ]");
      let vIdx = this.chkAddr;
      let vIdxAdd1 = (Number (vIdx) + 1).toString ();
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인PEP등록/수정_처리_현재 인덱스]", " vIdx → ", vIdx, " vIdxAdd1 → ", vIdxAdd1);
      if (this.isLoggable) console.log ("+++++++++");
      // this.btn_confirm.set_enable (true);
      // if (this.tfgpplRst.length > 0) {
      if (undefined != this.tfgpplRst && null != this.tfgpplRst) {
        let v_tfgpplRst = this.tfgpplRst;
        // ------
        //
        // ------
        if (!_.isEmpty (v_tfgpplRst.persnCnm1) || 
          !_.isEmpty (v_tfgpplRst.persnCnm2) || 
          !_.isEmpty (v_tfgpplRst.persnCnm3)) {
          this.custYn = "Y";
        }
        else {
          this.custYn = "N";
        }
        // ------
        //
        // ------
        if (!_.isEmpty (v_tfgpplRst.persnCnm4) || 
          !_.isEmpty (v_tfgpplRst.persnCnm5) || 
          !_.isEmpty (v_tfgpplRst.persnCnm6)) {
          this.businessYn = "Y";
        }
        else {
          this.businessYn = "N";
        }
        // ------
        //
        // ------
        if (!_.isEmpty (v_tfgpplRst.persnCnm1)) {
          // this.ok_yn0 = "Y";
          this.okYn0 = "Y";
          this.fn_SetPepData (0);
        }
        else {
          // this.ok_yn0 = "N";
          this.okYn0 = "N";
        }
        if (!_.isEmpty (v_tfgpplRst.persnCnm2)) {
          // this.ok_yn1 = "Y";
          this.okYn1 = "Y";
          this.fn_SetPepData (1);
        }
        else {
          // this.ok_yn1 = "N";
          this.okYn1 = "N";
        }
        if (!_.isEmpty (v_tfgpplRst.persnCnm3)) {
          // this.ok_yn2 = "Y";
          this.okYn2 = "Y";
          this.fn_SetPepData (2);
        }
        else {
          // this.ok_yn2 = "N";
          this.okYn2 = "N";
        }
        if (!_.isEmpty (v_tfgpplRst.persnCnm4)) {
          // this.ok_yn3 = "Y";
          this.okYn3 = "Y";
          this.fn_SetPepData (3);
        }
        else {
          // this.ok_yn3 = "N";
          this.okYn3 = "N";
        }
        if (!_.isEmpty (v_tfgpplRst.persnCnm5)) {
          // this.ok_yn4 = "Y";
          this.okYn4 = "Y";
          this.fn_SetPepData (4);
        }
        else {
          // this.ok_yn4 = "N";
          this.okYn4 = "N";
        }
        if (!_.isEmpty (v_tfgpplRst.persnCnm6)) {
          // this.ok_yn5 = "Y";
          this.okYn5 = "Y";
          this.fn_SetPepData (5);
        }
        else {
          // this.ok_yn5 = "N";
          this.okYn5 = "N";
        }
        this.fn_DataEnable ("A", this.custYn); // 개인(예금주본인)여부 
        this.fn_DataEnable ("B", this.businessYn); // 관계법인 여부
        this.fn_DataEnable ("1", this.okYn0); // 개인1 활/비활성화 시
        this.fn_DataEnable ("2", this.okYn1); // 개인2 활/비활성화 시
        this.fn_DataEnable ("3", this.okYn2); // 개인3 활/비활성화 시
        this.fn_DataEnable ("4", this.okYn3); // 법인4 활/비활성화 시
        this.fn_DataEnable ("5", this.okYn4); // 법인5 활/비활성화 시
        this.fn_DataEnable ("6", this.okYn5); // 법인6 활/비활성화 시
        // ---
        // AML(PEP) 우편번호 우선설정
        // ---
        // if (!_.isEmpty (v_tfgpplRst.amlPsno11)) {
        //   // this.psno0 = v_tfgpplRst.amlPsno11 + v_tfgpplRst.amlPsno21;
        //   this.amlPsno0_1 = v_tfgpplRst.amlPsno11;
        //   this.amlPsno0_2 = v_tfgpplRst.amlPsno21;
        //   this.psno0 = this.amlPsno0_1 + this.amlPsno0_2;
        // }
        // if (!_.isEmpty (v_tfgpplRst.amlPsno12)) {
        //   // this.psno1 = v_tfgpplRst.amlPsno12 + v_tfgpplRst.amlPsno22;
        //   this.amlPsno1_1 = v_tfgpplRst.amlPsno12;
        //   this.amlPsno1_2 = v_tfgpplRst.amlPsno22;
        //   this.psno1 = this.amlPsno1_1 + this.amlPsno1_2;
        // }
        // if (!_.isEmpty (v_tfgpplRst.amlPsno13)) {
        //   // this.psno2 = v_tfgpplRst.amlPsno13 + v_tfgpplRst.amlPsno23;
        //   this.amlPsno2_1 = v_tfgpplRst.amlPsno13;
        //   this.amlPsno2_2 = v_tfgpplRst.amlPsno23;
        //   this.psno2 = this.amlPsno2_1 + this.amlPsno2_2;
        // }
        // if (!_.isEmpty (v_tfgpplRst.amlPsno14)) {
        //   // this.psno3 = v_tfgpplRst.amlPsno14 + v_tfgpplRst.amlPsno24;
        //   this.amlPsno3_1 = v_tfgpplRst.amlPsno14;
        //   this.amlPsno3_2 = v_tfgpplRst.amlPsno24;
        //   this.psno3 = this.amlPsno3_1 + this.amlPsno3_2;
        // }
        // if (!_.isEmpty (v_tfgpplRst.amlPsno15)) {
        //   // this.psno4 = v_tfgpplRst.amlPsno15 + v_tfgpplRst.amlPsno25;
        //   this.amlPsno4_1 = v_tfgpplRst.amlPsno15;
        //   this.amlPsno4_2 = v_tfgpplRst.amlPsno25;
        //   this.psno4 = this.amlPsno4_1 + this.amlPsno4_2;
        // }
        // if (!_.isEmpty (v_tfgpplRst.amlPsno16)) {
        //   // this.psno5 = v_tfgpplRst.amlPsno16 + v_tfgpplRst.amlPsno26;
        //   this.amlPsno5_1 = v_tfgpplRst.amlPsno16;
        //   this.amlPsno5_2 = v_tfgpplRst.amlPsno26;
        //   this.psno5 = this.amlPsno5_1 + this.amlPsno5_2;
        // }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetResultData ]___[ 종료 ]");
    },
    // ======================================================
    // (업무-화면 제어) fn_SetResultData_PEP::  조회 후, 반환데이터 설정 및 화면제어(PEP용 본인주소동일 케이스 설정)
    // ======================================================
    fn_SetResultData_PEP () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetResultData_PEP ]___[ 시작 ]");
      // let vIdx = "0";
      let vIdx = this.chkAddr;
      let vIdxAdd1 = (Number (vIdx) + 1).toString ();
      let vGErp = "Y";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인PEP등록/수정_처리_현재 인덱스]", " vIdx → ", vIdx, " vIdxAdd1 → ", vIdxAdd1);
      if (this.isLoggable) console.log ("+++++++++");
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if ("0" == vIdx) {
        // ---
        // psno0
        // ---
        // vIdx = "0";
        // if (!_.isEmpty (this.pepAddrRst.hmadPsno1)) {
        //   this.tfgpplRst.amlPsno11 = this.pepAddrRst.hmadPsno1 + this.pepAddrRst.hmadPsno2;
        // }
        // if (!_.isEmpty (this.pepAddrRst.homeAddrBldnNo)) {
        //   this.tfgpplRst.amlBldnNo1 = this.pepAddrRst.homeAddrBldnNo;
        // }
        // if ("Y" == vGErp) {
        //   if (!_.isEmpty (this.pepAddrRst.homePsnoAddr)) {
        //     this.tfgpplRst.amlDetaAddr1 = this.pepAddrRst.homePsnoAddr;
        //   }
        // }
        // else {
        //   if (!_.isEmpty (this.pepAddrRst.provNm)) {
        //     this.tfgpplRst.amlProvNm1 = this.pepAddrRst.provNm;
        //     this.tfgpplRst.amlCtcwdNm1 = this.pepAddrRst.ctcwdNm;
        //     this.tfgpplRst.amlEbmydNm1 = this.pepAddrRst.ebmydNm;
        //   }
        // }
        // if (!_.isEmpty (this.pepAddrRst.homeRmnAddr)) {
        //     this.tfgpplRst.amlDetaAddr1 = this.pepAddrRst.homePsnoAddr;
        // }
      }
      else if ("1" == vIdx) {
        //
      }
      else {
        //
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // ---------------------------
      // 개인PEP 정보항목 조회/등록/수정 시, 선택 인덱스별 동적대응으로 처리로직 변경함
      // , refactored by anarchi (2023.09.22)
      // ---------------------------
      if (!_.isEmpty (this.pepAddrRst["hmadPsno1" + vIdxAdd1])) {
        this.tfgpplRst["amlPsno1" + vIdxAdd1] = this.pepAddrRst["hmadPsno" + vIdxAdd1] + this.pepAddrRst["hmadPsno" + vIdxAdd1];
      }
      if (!_.isEmpty (this.pepAddrRst.homeAddrBldnNo)) {
        this.tfgpplRst["amlBldnNo" + vIdxAdd1] = this.pepAddrRst.homeAddrBldnNo;
      }
      if ("Y" == vGErp) {
        if (!_.isEmpty (this.pepAddrRst.homePsnoAddr)) {
          this.tfgpplRst["amlDetaAddr" + vIdxAdd1] = this.pepAddrRst.homePsnoAddr;
        }
      }
      else {
        if (!_.isEmpty (this.pepAddrRst.provNm)) {
          this.tfgpplRst["amlProvNm" + vIdxAdd1] = this.pepAddrRst.provNm;
          this.tfgpplRst["amlCtcwdNm" + vIdxAdd1] = this.pepAddrRst.ctcwdNm;
          this.tfgpplRst["amlEbmydNm" + vIdxAdd1] = this.pepAddrRst.ebmydNm;
        }
      }
      if (!_.isEmpty (this.pepAddrRst.homeRmnAddr)) {
          this.tfgpplRst["amlDetaAddr" + vIdxAdd1] = this.pepAddrRst.homePsnoAddr;
      }
      // ---------
      // 본인 주소와 동일 여부: ''으로 설정
      // ---------
      // this.ok_yn0 = "Y";
      this["ok_yn" + vIdx] = "Y";

      if ("KR" != this.pepAddrRst.natnNatC) {
        if (!_.isEmpty (this.pepAddrRst.csEnnm)) {
          // this.cnm0 = this.pepAddrRst.csEnnm;
          this["cnm" + vIdx] = this.pepAddrRst.csEnnm;
        }
      }
      else {
        if (!_.isEmpty (this.pepAddrRst.cnm)) {
          // this.cnm0 = this.pepAddrRst.cnm;
          this["cnm" + vIdx] = this.pepAddrRst.cnm;
        }
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인식별정보(실명번호 등)구분_코드목록]", " this.rcnoTypeItems → ", this.rcnoTypeItems);
      if (this.isLoggable) console.log ("+++++++++");
      if (!_.isEmpty (this.pepAddrRst.rcnoKindTc)) {
        // this.rcnoType0 = this.pepAddrRst.rcnoKindTc;
        this["rcnoType" + vIdx] = this.pepAddrRst.rcnoKindTc;
      }
      if (!_.isEmpty (this.pepAddrRst.rcno) && 13 == TSCommUtil.gfn_length (this.pepAddrRst.rcno)) {
        // this.rcno0_1 = this.pepAddrRst.rcno.substr (0, 6);
        // this.rcno0_2 = this.pepAddrRst.rcno.substr (6, 7);
        this["rcno" + vIdx + "_1"] = this.pepAddrRst.rcno.substr (0, 6);
        this["rcno" + vIdx + "_2"] = this.pepAddrRst.rcno.substr (6, 7);
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [본인주소와 동일 체크시, 개인식별정보(실명번호) 앞자리]", " this.pepAddrRst.rcno.substr (0, 6) → ", this.pepAddrRst.rcno.substr (0, 6));
        if (this.isLoggable) console.log ("+ ▶ [본인주소와 동일 체크시, 개인식별정보(실명번호) 뒷자리]", " this.pepAddrRst.rcno.substr (0, 7) → ", this.pepAddrRst.rcno.substr (6, 7));
        if (this.isLoggable) console.log ("+++++++++");
        // this.rcno0 = this.pepAddrRst.rcno;
        this["rcno" + vIdx] = this.pepAddrRst.rcno;
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [국적구분_코드목록]", " this.natnNatCItems → ", this.natnNatCItems);
      if (this.isLoggable) console.log ("+++++++++");
      if (!_.isEmpty (this.pepAddrRst.natnNatC)) {
        // this.natnNatC0 = this.pepAddrRst.natnNatC;
        this["natnNatC" + vIdx] = this.pepAddrRst.natnNatC;
      }
      if (!_.isEmpty (this.pepAddrRst.btdt)) {
        // this.btdt0 = this.pepAddrRst.btdt;
        this["btdt" + vIdx] = this.pepAddrRst.btdt;
      }
      if (!_.isEmpty (this.pepAddrRst.homeTelArno)) {
        // this.telArno0 = this.pepAddrRst.homeTelArno;
        this["telArno" + vIdx] = this.pepAddrRst.homeTelArno;
      }
      if (!_.isEmpty (this.pepAddrRst.homeTelPofn)) {
        // this.telPofn0 = this.pepAddrRst.homeTelPofn;
        this["telPofn" + vIdx] = this.pepAddrRst.homeTelPofn;
      }
      if (!_.isEmpty (this.pepAddrRst.homeTelSeq)) {
        // this.telSeq0 = this.pepAddrRst.homeTelSeq;
        this["telSeq" + vIdx] = this.pepAddrRst.homeTelSeq;
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [보험직업구분_코드목록]", " this.insrJobCItems → ", this.insrJobCItems);
      if (this.isLoggable) console.log ("+++++++++");
      if (!_.isEmpty (this.pepAddrRst.insrJobC)) {
        // this.jobC0 = this.pepAddrRst.insrJobC;
        this["jobC" + vIdx] = this.pepAddrRst.insrJobC;
      }
      if (!_.isEmpty (this.pepAddrRst.insrJobC)) {
        // this.insrJobTc0 = this.pepAddrRst.insrJobTc;
        this["insrJobTc" + vIdx] = this.pepAddrRst.insrJobTc;
        this.tfgpplRst.insrJobTc = this.pepAddrRst.insrJobTc;
      }
      if (!_.isEmpty (this.pepAddrRst.hmadPsno1)) {
        // this.hmadPsno1 = this.pepAddrRst.hmadPsno1;
        // this.hmadPsno2 = this.pepAddrRst.hmadPsno2;
        // this.psno0 = this.pepAddrRst.hmadPsno1 + "-" + this.pepAddrRst.hmadPsno2;
        // this["psno" + vIdx] = this.pepAddrRst.hmadPsno1 + "-" + this.pepAddrRst.hmadPsno2;
        this["amlPsno" + vIdx + "_1"] = this.pepAddrRst.hmadPsno1;
        this["amlPsno" + vIdx + "_2"] = this.pepAddrRst.hmadPsno2;
        this["psno" + vIdx] = this.pepAddrRst.hmadPsno1 + this.pepAddrRst.hmadPsno2;
      }
      if (!_.isEmpty (this.pepAddrRst.homePsnoAddr)) {
        // this.psnoAddr0 = this.pepAddrRst.homePsnoAddr;
        this["psnoAddr" + vIdx] = this.pepAddrRst.homePsnoAddr;
      }
      if (!_.isEmpty (this.pepAddrRst.homeRmnAddr)) {
        // this.rmnAddr0 = this.pepAddrRst.homeRmnAddr;
        this["rmnAddr" + vIdx] = this.pepAddrRst.homeRmnAddr;
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [입력요소 및 버튼 활/비활성화 인덱스]", " (Number (vIdx) + 1).toString () → ", (Number (vIdx) + 1).toString ());
      if (this.isLoggable) console.log ("+++++++++");
      // this.enabled1 = false;
      this["enabled" + (Number (vIdx) + 1).toString ()] = false;

      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetResultData_PEP ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_DataEnable
    // * 설명       : 화면 활성화
    // ======================================================
    fn_DataEnable (type, param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_DataEnable ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [활성화/비활성화 처리_(수신)파라미터]", " type → ", type, ", param → ", param);
      if (this.isLoggable) console.log ("+++++++++");
      // debugger;
      let _type = type;
      let _param = param;
      if (param === true) {
        _param = "N";
      }
      else if (param === false) {
        _param = "Y";
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [활성화/비활성화 처리_(내부)변수]", " _type → ", _type, ", _param → ", _param);
      if (this.isLoggable) console.log ("+ ▶ [개인PEP_상세정보조회 리턴개체(내부)변수]", " this.tfgpplRst → ", this.tfgpplRst);
      if (this.isLoggable) console.log ("+ ▶ [개인PEP_상세정보조회 리턴개체PEP(내부)변수]", " this.pepAddrRst → ", this.pepAddrRst);
      if (this.isLoggable) console.log ("+ ▶ [개인PEP_본인주소동일 체크여부1 (내부)변수]", " this.ok_yn0 → ", this.ok_yn0);
      if (this.isLoggable) console.log ("+ ▶ [개인PEP_본인주소동일 체크여부2 (내부)변수]", " this.ok_yn1 → ", this.ok_yn1);
      if (this.isLoggable) console.log ("+ ▶ [개인PEP_본인주소동일 체크여부3 (내부)변수]", " this.ok_yn2 → ", this.ok_yn2);
      if (this.isLoggable) console.log ("+++++++++");
      // ------
      // (개인) 예금주가 고객인 아닌 경우 체크
      // ------
      if (_type === "A" && _param === "Y") {
        this.enabled1Chk = false;
        this.enabled2Chk = false;
        this.enabled3Chk = false;
        // if (!_.isEmpty (this.cnm0)) {
        //   this.ok_yn0 = "Y";
        // }
        // else {
        //   this.ok_yn0 = "N";
        // }
        // if (!_.isEmpty (this.cnm1)) {
        //   this.ok_yn1 = "Y";
        // }
        // else {
        //   this.ok_yn1 = "N";
        // }
        // if (!_.isEmpty (this.cnm2)) {
        //   this.ok_yn2 = "Y";
        // }
        // else {
        //   this.ok_yn2 = "N";
        // }
      }
      // ------
      // (개인) 예금주가 고객인 경우 체크
      // ------
      else if (_type === "A" && _param !== "Y") {
        this.enabled1Chk = true;
        this.enabled2Chk = true;
        this.enabled3Chk = true;
        // this.enabled1 = true;
        // this.enabled2 = true;
        // this.enabled3 = true;
      }
      // ------
      // 고객 관련 법인인 존재할 경우 체크
      // ------
      else if (_type === "B" && _param === "Y") {
        this.enabled4Chk = false;
        this.enabled5Chk = false;
        this.enabled6Chk = false;
        // if (!_.isEmpty (this.cnm3)) {
        //   this.ok_yn3 = "Y";
        // }
        // else {
        //   this.ok_yn3 = "N";
        // }
        // if (!_.isEmpty (this.cnm4)) {
        //   this.ok_yn4 = "Y";
        // }
        // else {
        //   this.ok_yn4 = "N";
        // }
        // if (!_.isEmpty (this.cnm5)) {
        //   this.ok_yn5 = "Y";
        // }
        // else {
        //   this.ok_yn5 = "N";
        // }
      }
      // ------
      // 고객 관련 법인인 존재하지 않을 경우 체크
      // ------
      else if (_type === "B" && _param !== "Y") {
        this.enabled4Chk = true;
        this.enabled5Chk = true;
        this.enabled6Chk = true;
      }
      // ------
      //
      // ------
      if (_type === "1" && _param === "Y") {
        this.enabled1 = false;
      }
      if (_type === "1" && _param !== "Y") {
        this.enabled1 = true;
      }
      // ------
      //
      // ------
      if (_type === "2" && _param === "Y") {
        this.enabled2 = false;
      }
      if (_type === "2" && _param !== "Y") {
        this.enabled2 = true;
      }
      // ------
      //
      // ------
      if (_type === "3" && _param === "Y") {
        this.enabled3 = false;
      }
      if (_type === "3" && _param !== "Y") {
        this.enabled3 = true;
      }
      // ------
      //
      // ------
      if (_type === "4" && _param === "Y") {
        this.enabled4 = false;
      }
      if (_type === "4" && _param !== "Y") {
        this.enabled4 = true;
      }
      // ------
      //
      // ------
      if (_type === "5" && _param === "Y") {
        this.enabled5 = false;
      }
      if (_type === "5" && _param !== "Y") {
        this.enabled5 = true;
      }
      // ------
      //
      // ------
      if (_type === "6" && _param === "Y") {
        this.enabled6 = false;
      }
      if (_type === "6" && _param !== "Y") {
        this.enabled6 = true;
      }
      // ------
      //
      // ------
      if (_type === "1" && _param === "N") { // 정보항목 입력요소 초기화
        // this.enabled1 = true;
        // this.amlPersnRelC0 = "";
        // this.persnCnm0 = "";
        // this.persnRcnoTc0 = "";
        // this.rrnoBtdt0 = "";
        // // this.eddRno0 = "";
        // this.rcno0 = "";
        // this.rcno0_1 = "";
        // this.rcno0_2 = "";
        // // this.bsno0 = "";
        // this.amlRelIstm0 = "";
        // this.amlPsrt0 = "";
        // this.amlPsnm0 = "";
        // this.amlNatnC0 = "";
        // this.amlInsrJobC0 = "";
        // this.amlInsrStndIndsTypeC0 = "";
        // this.amlCsAddrUseYn0 = "";
        // this.psno0 = "";
        // this.psnoAddr0 = "";
        // this.rmnAddr0 = "";
        // this.amlTelArno0 = "";
        // this.amlTelPofn0 = "";
        // this.amlTelNo0 = "";
        // this.amlRlBtdt0 = "";
        // this.amlInpYn0 = "";
        // this.amlPsnoAddr0 = "";
        // this.telNo0 = "";
        // // this.amlRcno0 = "";
      }
      else if (_type === "2" && _param === "N") {
        // this.enabled2 = true;
        // this.amlPersnRelC1 = "";
        // this.persnCnm1 = "";
        // this.persnRcnoTc1 = "";
        // this.rrnoBtdt1 = "";
        // // this.eddRno1 = "";
        // this.rcno1 = "";
        // this.rcno1_1 = "";
        // this.rcno1_2 = "";
        // // this.bsno1 = "";
        // this.amlRelIstm1 = "";
        // this.amlPsrt1 = "";
        // this.amlPsnm1 = "";
        // this.amlNatnC1 = "";
        // this.amlInsrJobC1 = "";
        // this.amlInsrStndIndsTypeC1 = "";
        // this.amlCsAddrUseYn1 = "";
        // this.psno1 = "";
        // this.psnoAddr1 = "";
        // this.rmnAddr1 = "";
        // this.amlTelArno1 = "";
        // this.amlTelPofn1 = "";
        // this.amlTelNo1 = "";
        // this.amlRlBtdt1 = "";
        // this.amlInpYn1 = "";
        // this.amlPsnoAddr1 = "";
        // this.telNo1 = "";
        // // this.amlRcno1 = "";
      }
      else if (_type === "3" && _param === "N") {
        // this.enabled3 = true;
        // this.amlPersnRelC2 = "";
        // this.persnCnm2 = "";
        // this.persnRcnoTc2 = "";
        // this.rrnoBtdt2 = "";
        // // this.eddRno2 = "";
        // this.rcno2 = "";
        // this.rcno2_1 = "";
        // this.rcno2_2 = "";
        // // this.bsno2 = "";
        // this.amlRelIstm2 = "";
        // this.amlPsrt2 = "";
        // this.amlPsnm2 = "";
        // this.amlNatnC2 = "";
        // this.amlInsrJobC2 = "";
        // this.amlInsrStndIndsTypeC2 = "";
        // this.amlCsAddrUseYn2 = "";
        // this.psno2 = "";
        // this.psnoAddr2 = "";
        // this.rmnAddr2 = "";
        // this.amlTelArno2 = "";
        // this.amlTelPofn2 = "";
        // this.amlTelNo2 = "";
        // this.amlRlBtdt2 = "";
        // this.amlInpYn2 = "";
        // this.amlPsnoAddr2 = "";
        // this.telNo2 = "";
        // // this.amlRcno2 = "";
      }
      else if (_type === "4" && _param === "N") {
        // this.enabled4 = true;
        // this.amlPersnRelC3 = "";
        // this.persnCnm3 = "";
        // this.persnRcnoTc3 = "";
        // this.rrnoBtdt3 = "";
        // // this.eddRno3 = "";
        // // this.rcno3 = "";
        // // this.rcno3_1 = "";
        // // this.rcno3_2 = "";
        // this.bsno3 = "";
        // this.amlRelIstm3 = "";
        // this.amlPsrt3 = "";
        // this.amlPsnm3 = "";
        // this.amlNatnC3 = "";
        // this.amlInsrJobC3 = "";
        // this.amlInsrStndIndsTypeC3 = "";
        // this.amlCsAddrUseYn3 = "";
        // this.psno3 = "";
        // this.psnoAddr3 = "";
        // this.rmnAddr3 = "";
        // this.amlTelArno3 = "";
        // this.amlTelPofn3 = "";
        // this.amlTelNo3 = "";
        // this.amlRlBtdt3 = "";
        // this.amlInpYn3 = "";
        // this.amlPsnoAddr3 = "";
        // this.telNo3 = "";
        // // this.amlRcno3 = "";
      }
      else if (_type === "5" && _param === "N") {
        // this.enabled5 = true;
        // this.amlPersnRelC4 = "";
        // this.persnCnm4 = "";
        // this.persnRcnoTc4 = "";
        // this.rrnoBtdt4 = "";
        // // this.eddRno4 = "";
        // // this.rcno4 = "";
        // // this.rcno4_1 = "";
        // // this.rcno4_2 = "";
        // this.bsno4 = "";
        // this.amlRelIstm4 = "";
        // this.amlPsrt4 = "";
        // this.amlPsnm4 = "";
        // this.amlNatnC4 = "";
        // this.amlInsrJobC4 = "";
        // this.amlInsrStndIndsTypeC4 = "";
        // this.amlCsAddrUseYn4 = "";
        // this.psno4 = "";
        // this.psnoAddr4 = "";
        // this.rmnAddr4 = "";
        // this.amlTelArno4 = "";
        // this.amlTelPofn4 = "";
        // this.amlTelNo4 = "";
        // this.amlRlBtdt4 = "";
        // this.amlInpYn4 = "";
        // this.amlPsnoAddr4 = "";
        // this.telNo4 = "";
        // // this.amlRcno4 = "";
      }
      else if (_type === "6" && _param === "N") {
        // this.enabled6 = true;
        // this.amlPersnRelC5 = "";
        // this.persnCnm5 = "";
        // this.persnRcnoTc5 = "";
        // this.rrnoBtdt5 = "";
        // // this.eddRno5 = "";
        // // this.rcno5 = "";
        // // this.rcno5_1 = "";
        // // this.rcno5_2 = "";
        // this.bsno5 = "";
        // this.amlRelIstm5 = "";
        // this.amlPsrt5 = "";
        // this.amlPsnm5 = "";
        // this.amlNatnC5 = "";
        // this.amlInsrJobC5 = "";
        // this.amlInsrStndIndsTypeC5 = "";
        // this.amlCsAddrUseYn5 = "";
        // this.psno5 = "";
        // this.psnoAddr5 = "";
        // this.rmnAddr5 = "";
        // this.amlTelArno5 = "";
        // this.amlTelPofn5 = "";
        // this.amlTelNo5 = "";
        // this.amlRlBtdt5 = "";
        // this.amlInpYn5 = "";
        // this.amlPsnoAddr5 = "";
        // this.telNo5 = "";
        // // this.amlRcno5 = "";
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_DataEnable ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_Insert
    // * 설명       : 등록
    // ======================================================
    fn_Insert () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 시작 ]");
      let _custOrBizVals = [];
      _custOrBizVals.push (this.custYn);
      _custOrBizVals.push (this.businessYn);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인/법인PEP-등록여부 확인]", " _okYnVals → ", _custOrBizVals);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // 건별 기본 및 필수입력값 검증
      // ---------
      if (this.custYn === "N" && this.businessYn === "N") {
        let t_popupObj = {
          confirm: false,
          content: "등록 건수가 없습니다.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      // ---------
      // 개인/법인PEP 등록건수 필수값 검증여부 체크
      // ---------
      let _okYnVals = [];
      _okYnVals.push (this.okYn0);
      _okYnVals.push (this.okYn1);
      _okYnVals.push (this.okYn2);
      _okYnVals.push (this.okYn3);
      _okYnVals.push (this.okYn4);
      _okYnVals.push (this.okYn5);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인/법인PEP-등록 건수별 확인]", " _okYnVals → ", _okYnVals);
      if (this.isLoggable) console.log ("+++++++++");
      if (_.isEmpty (this.custYn)) {
        let t_popupObj = {
          confirm: false,
          content: "예금주고객 여부를 선택하여 주십시오.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      else if (this.custYn === "Y") {
        if (this.okYn0 === "N" && this.okYn1 === "N" && this.okYn2 === "N") {
          let t_popupObj = {
            confirm: false,
            content: "(개인)선택된 항목이 없습니다.",
          };
          this.$emit ("ts-alert-popup", 0, t_popupObj);
          return;
        }
        if (this.okYn0 === "Y") {
          if (_.isEmpty (this.cnm0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)성명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcnoType0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)구분은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcno0) || 13 !== TSCommUtil.gfn_length (this.rcno0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)실명번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psno0) || 5 > TSCommUtil.gfn_length (this.psno0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr0)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인1)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
        if (this.okYn1 === "Y") {
          if (_.isEmpty (this.cnm1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)성명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcnoType1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)구분은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcno1) || 13 !== TSCommUtil.gfn_length (this.rcno1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)실명번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psno1) || 5 > TSCommUtil.gfn_length (this.psno1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr1)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인2)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
        if (this.okYn2 === "Y") {
          if (_.isEmpty (this.cnm2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)성명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcnoType2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)구분은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcno2) || 13 !== TSCommUtil.gfn_length (this.rcno2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)실명번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psno2) || 5 > TSCommUtil.gfn_length (this.psno2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr2)) {
            let t_popupObj = {
              confirm: false,
              content: "(개인3)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
      }
      /* 법인 */
      if (_.isEmpty (this.businessYn)) {
        let t_popupObj = {
          confirm: false,
          content: "고객관련 법인 여부를 선택하여 주십시오.",
        };
        this.$emit ("ts-alert-popup", 0, t_popupObj);
        return;
      }
      else if (this.businessYn === "Y") {
        if (this.okYn3 === "N" && this.okYn4 === "N" && this.okYn5 === "N") {
          let t_popupObj = {
            confirm: false,
            content: "(법인)선택된 항목이 없습니다.",
          };
          this.$emit ("ts-alert-popup", 0, t_popupObj);
          return;
        }
        if (this.okYn3 === "Y") {
          if (_.isEmpty (this.cnm3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)단체명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          // if (_.isEmpty (this.rcnoType3)) {
          //   let t_popupObj = {
          //     confirm: false,
          //     content: "(법인1)구분은 필수 입력 항목입니다.",
          //   };
          //   this.$emit ("ts-alert-popup", 0, t_popupObj);
          //   return;
          // }
          if (_.isEmpty (this.bsno3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)사업자등록번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj)
            return;
          }
          if (_.isEmpty (this.psno3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr3)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인1)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
        if (this.okYn4 === "Y") {
          if (_.isEmpty (this.cnm4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)단체명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcnoType4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)구분은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.bsno4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)사업자등록번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psno4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr4)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인2)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
        if (this.okYn5 === "Y") {
          if (_.isEmpty (this.cnm5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)단체명은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rcnoType5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)구분은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.bsno5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)사업자등록번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlPersnRelC5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.amlRelIstm5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)관계는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.natnNatC5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)국적은 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psno5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)우편번호는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.psnoAddr5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)기본주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
          if (_.isEmpty (this.rmnAddr5)) {
            let t_popupObj = {
              confirm: false,
              content: "(법인3)상세주소는 필수 입력 항목입니다.",
            };
            this.$emit ("ts-alert-popup", 0, t_popupObj);
            return;
          }
        }
      }
      // ---------
      // '지속거래' 여부 확인
      // ---------
      if ("1" == this.amlPrcsPfrmType) { // 지속거래 아님 (신규등록)
        this.currTrnstId = this.insTrnstIds;
        this.tfgpplRst.amlProcType = "I";
        this.tfgpplRst.refNo = this.refNo;
      }
      else { // 지속거래임 (수정)
        this.currTrnstId = this.uptTrnstIds;
        this.tfgpplRst.amlProcType = "I2";
        this.tfgpplRst.refNo = this.refNo;
      }
      // ---------
      // 고객번호 확인
      // ---------
      let t_popupObj = {
        confirm: true,
        content: "저장 하시겠습니까?",
        confirmFunc: this.fn_InsertConfirm,
        closeFunc: this.fn_InserrtClose,
      };
      this.$emit ("ts-alert-popup", 0, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Insert ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_InsertConfirm
    // * 설명       : 등록 선택
    // ======================================================
    fn_InsertConfirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirm ]___[ 시작 ]");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      // let params = {};
      // let params = _.CloneDeep (this.tfgpplRs);
      let params = this.tfgpplRst;
      // ---
      // 개인1
      // ---
      params.inputOk1 = this.okYn0;
      params.persnCnm1 = this.cnm0; // 이름(1)
      params.persnRcnoTc1 = this.rcnoType0; // 개인식별번호종류(1)
      params.amlRcno1 = this.rcno0; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC1 = this.amlPersnRelC0; // 관계구분(1)
      params.amlRelIstm1 = this.amlRelIstm0; // 관계자(1)
      params.amlRlBtdt1 = (this.btdt0).replaceAll ("-", ""); // 생년월일(1)
      // params.amlPsrt1 = this.psrt0; // 지분율(1)
      params.amlPsnm1 = this.psnm0; // 직위(1)
      params.amlNatnC1 = this.natnNatC0; // 국적(1)
      params.amlInsrJobC1 = this.jobC0; // 보험직업 코드(1)
      // params.amlInsrStndIndsTypeC1 = this.jobC0; // 개인사업자업종 코드
      // params.amlCsAddrUseYn1 = this.ok_yn0; // ??? 보인주소와 동일 여부값
      params.amlPsno11 = this.amlPsno0_1; // 우편번호 앞자리(1)
      params.amlPsno21 = this.amlPsno0_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr1 = this.psnoAddr0; // 기본주소(1)
      params.amlDetaAddr1 = this.rmnAddr0; // 상세주소(1)
      params.amlTelArno1 = this.telArno0; // 전화번호 앞자리(1)
      params.amlTelPofn1 = this.telPofn0; // 전화번호 중간자리(1)
      params.amlTelNo1 = this.telSeq0; // 전화번호 뒷자리(1)
      // params.amlRlBtdt1 = this.amlRlBtdt0;
      // params.amlInpYn1 = this.amlInpYn0;
      // params.amlPsnoAddr1 = this.amlPsnoAddr0;
      // params.telNo1 = this.telArno0 + this.telPofn0 + this.telSeq0; // 사업장 전화번호(1)???
      // ---
      // 개인2
      // ---
      params.inputOk2 = this.okYn1;
      params.persnCnm2 = this.cnm1; // 이름(1)
      params.persnRcnoTc2 = this.rcnoType1; // 개인식별번호종류(1)
      params.amlRcno2 = this.rcno1; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC2 = this.amlPersnRelC1; // 관계구분(1)
      params.amlRelIstm2 = this.amlRelIstm1; // 관계자(1)
      params.amlRlBtdt2 = (this.btdt1).replaceAll ("-", ""); // 생년월일(1)
      // params.amlPsrt2 = this.psrt1; // 지분율(1)
      params.amlPsnm2 = this.psnm1; // 직위(1)
      params.amlNatnC2 = this.natnNatC1; // 국적(1)
      params.amlInsrJobC2 = this.jobC1; // 보험직업 코드(1)
      // params.amlInsrStndIndsTypeC2 = this.jobC1; // 개인사업자업종 코드
      // params.amlCsAddrUseYn2 = this.ok_yn1; // ??? 보인주소와 동일 여부값
      params.amlPsno12 = this.amlPsno1_1; // 우편번호 앞자리(1)
      params.amlPsno22 = this.amlPsno1_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr2 = this.psnoAddr1; // 기본주소(1)
      params.amlDetaAddr2 = this.rmnAddr1; // 상세주소(1)
      params.amlTelArno2 = this.telArno1; // 전화번호 앞자리(1)
      params.amlTelPofn2 = this.telPofn1; // 전화번호 중간자리(1)
      params.amlTelNo2 = this.telSeq1; // 전화번호 뒷자리(1)
      // params.amlRlBtdt2 = this.amlRlBtdt1;
      // params.amlInpYn2 = this.amlInpYn1;
      // params.amlPsnoAddr2 = this.amlPsnoAddr1;
      // params.telNo2 = this.telArno1 + this.telPofn1 + this.telSeq1; // 사업장 전화번호(1)???
      // ---
      // 개인3
      // ---
      params.inputOk3 = this.okYn2;
      params.persnCnm3 = this.cnm2; // 이름(1)
      params.persnRcnoTc3 = this.rcnoType2; // 개인식별번호종류(1)
      params.amlRcno3 = this.rcno2; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC3 = this.amlPersnRelC2; // 관계구분(1)
      params.amlRelIstm3 = this.amlRelIstm2; // 관계자(1)
      params.amlRlBtdt3 = (this.btdt2).replaceAll ("-", ""); // 생년월일(1)
      // params.amlPsrt3 = this.psrt2; // 지분율(1)
      params.amlPsnm3 = this.psnm2; // 직위(1)
      params.amlNatnC3 = this.natnNatC2; // 국적(1)
      params.amlInsrJobC3 = this.jobC2; // 보험직업 코드(1)
      // params.amlInsrStndIndsTypeC3 = this.jobC2; // 개인사업자업종 코드
      // params.amlCsAddrUseYn3 = this.ok_yn2; // ??? 보인주소와 동일 여부값
      params.amlPsno13 = this.amlPsno2_1; // 우편번호 앞자리(1)
      params.amlPsno23 = this.amlPsno2_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr3 = this.psnoAddr2; // 기본주소(1)
      params.amlDetaAddr3 = this.rmnAddr2; // 상세주소(1)
      params.amlTelArno3 = this.telArno2; // 전화번호 앞자리(1)
      params.amlTelPofn3 = this.telPofn2; // 전화번호 중간자리(1)
      params.amlTelNo3 = this.telSeq2; // 전화번호 뒷자리(1)
      // params.amlRlBtdt3 = this.amlRlBtdt2;
      // params.amlInpYn3 = this.amlInpYn2;
      // params.amlPsnoAddr3 = this.amlPsnoAddr2;
      // params.telNo3 = this.telArno2 + this.telPofn2 + this.telSeq2; // 사업장 전화번호(1)???
      // ---
      // 법인4
      // ---
      params.inputOk4 = this.okYn3;
      params.persnCnm4 = this.cnm3; // 이름(1)
      params.persnRcnoTc4 = this.rcnoType3; // 개인식별번호종류(1)
      params.amlRcno3 = this.rcno3; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC4 = this.amlPersnRelC3; // 관계구분(1)
      params.amlRelIstm4 = this.amlRelIstm3; // 관계자(1)
      // params.amlRlBtdt4 = (this.btdt3).replaceAll ("-", ""); // 생년월일(1)
      params.amlPsrt4 = this.psrt3; // 지분율(1)
      params.amlPsnm4 = this.psnm3; // 직위(1)
      params.amlNatnC4 = this.natnNatC3; // 국적(1)
      // params.amlInsrJobC4 = this.jobC3; // 보험직업 코드(1)
      params.amlInsrStndIndsTypeC4 = this.jobC3; // 개인사업자업종 코드
      // params.amlCsAddrUseYn4 = this.ok_yn3; // ??? 보인주소와 동일 여부값
      params.amlPsno14 = this.amlPsno3_1; // 우편번호 앞자리(1)
      params.amlPsno24 = this.amlPsno3_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr4 = this.psnoAddr3; // 기본주소(1)
      params.amlDetaAddr4 = this.rmnAddr3; // 상세주소(1)
      params.amlTelArno4 = this.telArno3; // 전화번호 앞자리(1)
      params.amlTelPofn4 = this.telPofn3; // 전화번호 중간자리(1)
      params.amlTelNo4 = this.telSeq3; // 전화번호 뒷자리(1)
      // params.amlRlBtdt4 = this.amlRlBtdt3;
      // params.amlInpYn4 = this.amlInpYn3;
      // params.amlPsnoAddr4 = this.amlPsnoAddr3;
      // params.telNo4 = this.telArno3 + this.telPofn3 + this.telSeq3; // 사업장 전화번호(1)???
      // ---
      // 법인5
      // ---
      params.inputOk5 = this.okYn4;
      params.persnCnm5 = this.cnm4; // 이름(1)
      params.persnRcnoTc5 = this.rcnoType4; // 개인식별번호종류(1)
      params.amlRcno5 = this.rcno4; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC5 = this.amlPersnRelC4; // 관계구분(1)
      params.amlRelIstm5 = this.amlRelIstm4; // 관계자(1)
      // params.amlRlBtdt4 = (this.btdt4).replaceAll ("-", ""); // 생년월일(1)
      params.amlPsrt5 = this.psrt4; // 지분율(1)
      params.amlPsnm5 = this.psnm4; // 직위(1)
      params.amlNatnC5 = this.natnNatC4; // 국적(1)
      // params.amlInsrJobC5 = this.jobC4; // 보험직업 코드(1)
      params.amlInsrStndIndsTypeC5 = this.jobC4; // 개인사업자업종 코드
      // params.amlCsAddrUseYn5 = this.ok_yn4; // ??? 보인주소와 동일 여부값
      params.amlPsno15 = this.amlPsno4_1; // 우편번호 앞자리(1)
      params.amlPsno25 = this.amlPsno4_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr5 = this.psnoAddr4; // 기본주소(1)
      params.amlDetaAddr5 = this.rmnAddr4; // 상세주소(1)
      params.amlTelArno5 = this.telArno4; // 전화번호 앞자리(1)
      params.amlTelPofn5 = this.telPofn4; // 전화번호 중간자리(1)
      params.amlTelNo5 = this.telSeq4; // 전화번호 뒷자리(1)
      // params.amlRlBtdt5 = this.amlRlBtdt4;
      // params.amlInpYn5 = this.amlInpYn4;
      // params.amlPsnoAddr5 = this.amlPsnoAddr4;
      // params.telNo5 = this.telArno4 + this.telPofn4 + this.telSeq4; // 사업장 전화번호(1)???
      // ---
      // 법인6
      // ---
      params.inputOk6 = this.okYn5;
      params.persnCnm6 = this.cnm5; // 이름(1)
      params.persnRcnoTc6 = this.rcnoType5; // 개인식별번호종류(1)
      params.amlRcno6 = this.rcno5; // 개인식별번호(실명번호)(1)
      params.amlPersnRelC6 = this.amlPersnRelC5; // 관계구분(1)
      params.amlRelIstm6 = this.amlRelIstm5; // 관계자(1)
      // params.amlRlBtdt6 = (this.btdt5).replaceAll ("-", ""); // 생년월일(1)
      params.amlPsrt6 = this.psrt5; // 지분율(1)
      params.amlPsnm6 = this.psnm5; // 직위(1)
      params.amlNatnC6 = this.natnNatC5; // 국적(1)
      // params.amlInsrJobC6 = this.jobC5; // 보험직업 코드(1)
      params.amlInsrStndIndsTypeC6 = this.jobC5; // 개인사업자업종 코드
      // params.amlCsAddrUseYn6 = this.ok_yn5; // ??? 보인주소와 동일 여부값
      params.amlPsno16 = this.amlPsno5_1; // 우편번호 앞자리(1)
      params.amlPsno26 = this.amlPsno5_2; // 우편번호 뒷자리(1)
      params.amlPsnoAddr6 = this.psnoAddr5; // 기본주소(1)
      params.amlDetaAddr6 = this.rmnAddr5; // 상세주소(1)
      params.amlTelArno6 = this.telArno5; // 전화번호 앞자리(1)
      params.amlTelPofn6 = this.telPofn5; // 전화번호 중간자리(1)
      params.amlTelNo6 = this.telSeq5; // 전화번호 뒷자리(1)
      // params.amlRlBtdt6 = this.amlRlBtdt5;
      // params.amlInpYn6 = this.amlInpYn5;
      // params.amlPsnoAddr6 = this.amlPsnoAddr5;
      // params.telNo6 = this.telArno5 + this.telPofn5 + this.telSeq5; // 사업장 전화번호(1)???
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600259_S' |
      // 'UF10600259' // [NEXT 사랑On 신탁]개인PEP 등록 (지속거래아님)-insertTPUP96020
      // 'C392_F10600260_S' |
      // 'UF10600260' // [NEXT 사랑On 신탁]개인PEP 수정-updateTPUP96020
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      // this.eaiCommObj.trnstId = insertTPUP96020 // 지속거래가 아님
      // this.eaiCommObj.trnstId = "updateTPUP96020"; // 지속거래임 // PO 오류 등록처리인데 조회 PO????
      // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600259_S' // 개인PEP 등록 (지속거래아님)
      // this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600260_S' // 개인PEP 수정
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F" + this.currTrnstId + "_S"; // 개인PEP 등록 (지속거래아님) / 개인PEP 수정
      this.eaiCommObj.params = params;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인PEP 등록(지속거래아님) 및 수정]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_InsertConfirmResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "U" + this.currTrnstId);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirm ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_InsertConfirmResult
    // * 설명       : 등록 콜백
    // ======================================================
    fn_InsertConfirmResult (res, errorCode) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirmResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인PEP 등록(지속거래아님) 및 수정]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res, "errorCode → ", errorCode);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------------------------
      // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 정보항목 추출 저장, added by anarchi (2023.09.12)
      // ------------------------------------
      // this.tpup95330List = res.data;
      // ---------
      // 
      // ---------
      if (undefined != res.data && !_.isEmpty (res.data)) {
        if ("F10600259" == this.currTrnstId) {
          // this.tfgpplRst = res.data;
          // this.tfgpplRstObj = res.data;
          // this.tfgpplRstArr = res.data;
        }
        else {
          // this.pepAddrRst = res.data;
          // this.pepAddrRstObj = res.data;
          // this.pepAddrRstArr = res.data;
        }
      }
      // ---------
      // 
      // ---------
      let t_popupObj = {
        confirm: false,
        content: "정상적으로 수정되었습니다.",
      };
      this.$emit ("ts-alert-popup", 0, t_popupObj);
      // ------------------------------------
      // ※ (프로세스 및 개별단위) 전자서식 발행을 위한 추가처리 (TOBE 변경요건에 맞추어)
      // ------------------------------------
      this.fn_confirmPrintTrue ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertConfirmResult ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_InserrtClose
    // * 설명       : 저장확인 취소
    // ======================================================
    fn_InsertClose () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertClose ]___[ 시작 ]");
      // ---------
      //
      // ---------
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_InsertClose ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_OpenPopup:: (공통업무별)팝업 열기
    // ======================================================
    fn_OpenPopup (type, flag) {
      switch (type) {
        case 0:
          this.pPopup100Obj.btnFlag = flag;
          this.$refs.popup100.fn_Open ();
          break;
        case 1:
          // this.$refs.popup103.fn_Open ();
          break;
        case 2:
          // this.$refs.popup104.fn_Open ();
          break;
        case 3:
          // this.pPopup103Obj.btnFlag = flag;
          // this.$refs.popup103.fn_Open ();
          break;
        case 5:
          this.pPopup107Obj.btnFlag = flag;
          this.$refs.popup107.fn_Open ();
          break
        case 6:
          this.chkAddr
          this.pPopup101Obj.btnFlag = flag;
          this.pPopup101Obj.procType = "tass";
          this.$refs.popup101.fn_Open ();
          break;
        case 8:
          // this.$refs.popupCdi.fn_Open ();
          break;
      }
    },
    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 우편번호 / 1: MDG/CDD정보비교 / 2: 고객정보 / 3: 직원번호(직원검색) / 4: 마케팅 동의여부 / 5: 고객ID선택
     *              7: FC 조회 / 8: cdi 고객조회 / 9: FATCA / 10 : 대리인 등록
     ******************************************************************************/
    fn_Popup_CallBack (type, pData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [공통업무 컴포넌트 팝업-(호출$콜백)]_[(수신)-파라미터]", " type → ", type, ", pData → ", pData);
      if (this.isLoggable) console.log ("+++++++++");
      let t_popupObj = {}
      switch (type) {
        case 0:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [우편번호_팝업$콜백-CASE]", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          // 자택(HOME), 직장(JOB)
          if ( pData.btnFlag == "HOME_0") { // [자택주소-0]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno0_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno0_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              // this.psno0 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호전체0
              this.psno0 = pData.vZipcd_head + pData.vZipcd_tail; // 우편번호전체0
              this.psnoAddr0 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소0
              this.rmnAddr0 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소0
            }
          }
          else if (pData.btnFlag == "HOME_1") { // [자택주소-11]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno1_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno1_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              // this.psno1 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호1
              this.psno1 = pData.vZipcd_head + pData.vZipcd_tail; // 우편번호1
              this.psnoAddr1 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소1
              this.rmnAddr1 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소1
            }
          }
          else if (pData.btnFlag == "HOME_2") { // [자택주소-2]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno2_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno2_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              this.psno2 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호2
              this.psnoAddr2 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소2
              this.rmnAddr2 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소2
            }
          }
          else if (pData.btnFlag == "JOB_0") { // [직장/사업장주소-3]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno3_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno3_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              this.psno3 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호3
              this.psnoAddr3 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소3
              this.rmnAddr3 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소3
            }
          }
          else if (pData.btnFlag == "JOB_1") { // [직장/사업장주소-4]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno4_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno4_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              this.psno4 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호4
              this.psnoAddr4 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소4
              this.rmnAddr4 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소4
            }
          }
          else if (pData.btnFlag == "JOB_2") { // [직장/사업장주소-5]
            if ("Y" == pData.v_addr_stdzt_yn) {
              this.amlPsno5_1 = pData.vZipcd_head; // 우편번호 앞자리0
              this.amlPsno5_2 = pData.vZipcd_tail; // 우편번호 뒷자리0
              this.psno5 = pData.vZipcd_head + "-" + pData.vZipcd_tail; // 우편번호5
              this.psnoAddr5 = pData.vZipcd_ubmyundong + " " + pData.v_bldg_no; // 기본주소5
              this.rmnAddr5 = pData.v_adtn_addr + " " + pData.v_stand_addr; // 상세주소5
            }
          }
          else if (pData.btnFlag == "ETC") { // 신탁 기타주소
            //
          }
          break;
        case 1:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [???_팝업$콜백-CASE]", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 2:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [???_팝업$콜백-CASE]", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 3:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [직원번호(직원검색)_팝업$콜백-CASE]", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 4:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [마케팅 동의여부_팝업$콜백-CASE]", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 5:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [???_팝업$콜백-CASE", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 7:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [FC검색_팝업$콜백-CASE", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 8:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [CDI검색_팝업$콜백-CASE", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 9:
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [FATCA_팝업$콜백-CASE", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
        case 10: // 대리인 등록
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [대리인검색_팝업$콜백-CASE", " type → ", type, ", pData.btnFlag → ", pData.btnFlag);
          if (this.isLoggable) console.log ("+++++++++");
          break;
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack ]___[ 종료 ]");
    },
    // ======================================================
    // * Function명 : fn_Popup_CallBack_Code
    // * 설명       : popup 컴포넌트에서 callback
    // ======================================================
    fn_Popup_CallBack_Code (type, pData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack_Code ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [공통업무 컴포넌트 팝업-(호출$콜백)]_[(수신)-파라미터]", " type → ", type, ", pData → ", pData, ", pData.btnFlag → ", pData.btnFlag);
      if (this.isLoggable) console.log ("+++++++++");
      // let vIdx = this.chkAddr;
      let vIdx = this.pPopup101Obj.btnFlag.split ("_")[1];
      let vIdxAdd1 = (Number (vIdx) + 1).toString ();
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [등록/수정(개인)PEP_현재 인덱스]", " vIdx → ", vIdx, " vIdxAdd1 → ", vIdxAdd1);
      if (this.isLoggable) console.log ("+++++++++");
      this["jobC" + vIdx] = pData.vjob_cd // 보험코드 (입력요소)
      this.fn_validErrRemoveRef (this.$refs["edt_insrJobC" + vIdx]); // css error 검출 삭제
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup_CallBack_Code ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) fn_validErrRemoveRef:: ref 값에 의해 mo-text-field 컴포넌트의 css error (error)class 제거
    // ======================================================
    fn_validErrRemoveRef (ref) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_validErrRemoveRef ]___[ 시작 ]");
      ref.$el.classList.remove ("error");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_validErrRemoveRef ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) fn_validErrRemoveInput:: INPUT, Mo-Text-Field 컴포넌트 css (error)class 제거
    // ======================================================
    fn_validErrRemoveInput (event, el) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_validErrRemoveInput ]___[ 시작 ]");
      let value = event.currentTarget.value;
      let _el = this.$refs[el].$el;
      if (!TSCommUtil.gfn_isNull (TSCommUtil.gfn_trim (value))) {
        _el.classList.remove ("error");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_validErrRemoveInput ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) fn_ChkOwnAddr:: 입력주소 본인여부 체크 시,
    // ======================================================
    fn_ChkOwnAddr (pIdx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ChkOwnAddr ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [본인주소 체크여부 확인-(호출)]_[(수신)-파라미터]", " pIdx → ", pIdx);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // 본인주소와 동일 체크 시, (PEP상세조회) PO_ID를 변경함.
      // this.chkAddr = pIdx;
      this.chkAddr = pIdx.toString ();
      let vIdx = this.chkAddr;
      let vIdxAdd1 = (Number (vIdx) + 1).toString ();
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [입력주소 본인여부 체크 시_현재 인덱스]", " vIdx → ", vIdx, " vIdxAdd1 → ", vIdxAdd1);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // switch (pIdx) {
      //   case 0:
      //     this.ok_yn0 = ("N" != this.ok_yn0) ? "Y" : "N";
      //     break;
      //   case 1:
      //     this.ok_yn1 = ("N" != this.ok_yn1) ? "Y" : "N";
      //     this.fn_Search ();
      //     break;
      //   case 2:
      //     this.ok_yn2 = ("N" != this.ok_yn2) ? "Y" : "N";
      //     this.fn_Search ();
      //     break;
      // }
      this["ok_yn" + this.chkAddr] = ("Y" != this["ok_yn" + this.chkAddr]) ? "N" : "Y";
      console.log ("this['ok_yn' + this.chkAddr]", this["ok_yn" + this.chkAddr]);
      let _selTrnstIdArr = this.selTrnstIds.split (",");
      if ("Y" == this["ok_yn" + this.chkAddr]) {
        this.currTrnstId = _selTrnstIdArr [1];
        this.fn_Search ();
      }
      else {
        this.currTrnstId = _selTrnstIdArr [0];
        this["psno" + vIdx] = "";
        this["psnoAddr" + vIdx] = "";
        this["rmnAddr" + vIdx] = "";
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ChkOwnAddr ]___[ 종료 ]");
    },
    // ======================================================
    // (공통-이벤트 핸들러) fn_SearchValid:: 개인식별정보(실명번호 등) 입력시, 필수값 검증
    // ======================================================
    fn_SearchValid (e, pIdx, pFlg, pMode) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchValid ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [필수값 검증 메소드-(수신)파라미터]", " e → ", e, " pIdx → ", pIdx, ", pFlg → ", pFlg, ", pMode → ", pMode);
      if (this.isLoggable) console.log ("+++++++++");
      let vIdx = pIdx;
      let vIdxStr = (Number (vIdx)).toString ();
      // let vIdxAdd1 = (Number (vIdx) + 1).toString ();
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실명번호 체크 시, 현재 처리건 인덱스 확인]", " vIdxStr → ", vIdxStr);
      if (this.isLoggable) console.log ("+++++++++");
      let rcnoFlgArr = pFlg.split ("_");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실명번호 체크 시, 현재 처리건 실명번호 정보항목명 확인]", " rcnoFlgArr → ", rcnoFlgArr);
      if (this.isLoggable) console.log ("+++++++++");
      let t_rcno;
      let t_rcnoOne, t_rcnoTwo;
      if (pMode == "P") {
        t_rcno = (e.clipboardData || window.clipboardData).getData ("text").replace (/[^0-9]/g, '').substr (0, 13);
        t_rcnoOne = t_rcno.substr (0, 6);
        t_rcnoTwo = t_rcno.substr (6, 7);
        if (this.isMobile) {
          // this.rcno0_1 = t_rcnoOne;
          // this.$refs["edt_rcno0_1"].focus ();
          this[rcnoFlgArr[0] + "_1"] = t_rcnoOne;
          // this[rcnoFlgArr[1] + "_2"] = t_rcnoTwo;
          // this.$refs[rcnoFlgArr[0] + "_1"].focus ();
          this.$refs[rcnoFlgArr[0] + "_2"].focus ();
          // this[rcnoFlgArr[0]] = t_rcnoOne;
        }
        else {
          // this.rcno0_1 = t_rcnoOne;
          // this.rcno0_2 = t_rcnoTwo;
          this[rcnoFlgArr[0] + "_1"] = t_rcnoOne;
          this[rcnoFlgArr[0] + "_2"] = t_rcnoTwo;
          this[rcnoFlgArr[0]] = t_rcnoOne + t_rcnoTwo;
        }
        // if (TSCommUtil.gfn_length (t_rcno) == 13) {
        //   if (!TSCommUtil.gfn_isNum (event.key) && event.key != "Enter" && event.key != "v" && !this.isMobile) {
        //     return;
        //   }
        //   this.fn_searchList ();
        // }
      }
      else {
        if ("1" == rcnoFlgArr[1]) {
          // t_rcno = this.rcno0_1;
          t_rcnoOne = this[rcnoFlgArr[0] + "_1"];
          if (TSCommUtil.gfn_length (t_rcnoOne) == 6) {
            if (!this.isMobile) {
              // this.$refs["edt_rcno0_2"].focus ();
              this.$refs[rcnoFlgArr[0] + "_2"].focus ();
            }
          }
          this[rcnoFlgArr[0]] = t_rcnoOne + this["valid_" + rcnoFlgArr[0] + "_2"];
        }
        else if ("2" == rcnoFlgArr[1]) { 
          // t_rcno = this.valid_rcno0_2;
          t_rcnoTwo = this["valid_" + rcnoFlgArr[0] + "_2"];
          this[rcnoFlgArr[0]] = this[rcnoFlgArr[0] + "_1"] + t_rcnoTwo;
          // if (TSCommUtil.gfn_length (t_rcno) == 7) {
          //   if (!TSCommUtil.gfn_isNum (event.key) && event.key !="Enter" && event.key != "v" && !this.isMobile) {
          //     return;
          //   }
          //   this.fn_searchList ();
          // }
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchValid ]___[ 종료 ]");
    },
    // ======================================================
    // (공통-이벤트 핸들러) 메소드명:: 보안키패드 체크, 실명번호 뒷자리
    // ======================================================
    fn_SetMaskedTxt_Rcno0 (pVal, pFlg) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno0 ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [보안키패드 입력값 검증 메소드-(수신)파라미터]", " pVal → ", pVal, ", pFlg → ", pFlg);
      if (this.isLoggable) console.log ("+++++++++");
      this.lv_masked_rcno0_2 = pVal;
      if (!TSCommUtil.gfn_isNull (TSCommUtil.gfn_trim (pVal))) {
        // this.$refs["edt_rcno0_2"].$el.classList.remove ("error");
        this.$refs["rcno0_2"].$el.classList.remove ("error");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno0 ]___[ 종료 ]");
    },
    // ======================================================
    // (공통-이벤트 핸들러) 메소드명:: 보안키패드 체크, 실명번호 뒷자리
    // ======================================================
    fn_SetMaskedTxt_Rcno1 (pVal, pFlg) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno1 ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [보안키패드 입력값 검증 메소드-(수신)파라미터]", " pVal → ", pVal, ", pFlg → ", pFlg);
      if (this.isLoggable) console.log ("+++++++++");
      this.lv_masked_rcno1_2 = pVal;
      if (!TSCommUtil.gfn_isNull (TSCommUtil.gfn_trim (pVal))) {
        // this.$refs["edt_rcno1_2"].$el.classList.remove ("error");
        this.$refs["rcno1_2"].$el.classList.remove ("error");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno1 ]___[ 종료 ]");
    },
    // ======================================================
    // (공통-이벤트 핸들러) 메소드명:: 보안키패드 체크, 실명번호 뒷자리
    // ======================================================
    fn_SetMaskedTxt_Rcno2 (pVal, pFlg) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno2 ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [보안키패드 입력값 검증 메소드-(수신)파라미터]", " pVal → ", pVal, ", pFlg → ", pFlg);
      if (this.isLoggable) console.log ("+++++++++");
      this.lv_masked_rcno2_2 = pVal;
      if (!TSCommUtil.gfn_isNull (TSCommUtil.gfn_trim (pVal))) {
        // this.$refs["edt_rcno2_2"].$el.classList.remove ("error");
        this.$refs["rcno2_2"].$el.classList.remove ("error");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SetMaskedTxt_Rcno2 ]___[ 종료 ]");
    },
  },
}
</script>
