/*
 * 업무구분: AML송수신승인처리
 * 화 면 명: MSPTS303P
 * 화면설명: AML송수신승인처리
 * 작 성 일: 2023.03.16
 * 작 성 자: 김경태 (전면개정 -> refined by anarchi)
 * 수정일 : 2024-08-06 정수진  사유 : ASR240500691 / [사랑on신탁] 자금세탁 위험평가 제도 대응
 * 수정일 : 2024-08-26 정수진 사유 : ASR240800403_[사랑On신탁] 개인고객확인서 서식변경

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="AML송수신승인처리">
    <template>
      <div class="wrap-modalcontents">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label> 계좌번호 </label>
              <mo-text-field v-model="ctnoDisp" disabled placeholder="입력하세요" />
            </div>
            <div class="wrap-input row">
              <label> 순번 </label>
              <mo-text-field v-model="procSeq" disabled placeholder="입력하세요" class="w70" />
            </div>
          </div>
        </div>
        <div class="wrap-button result mt6">
          <mo-button @click="fn_Aml" :disabled="btnDisAml"> AML송수신 </mo-button>
          <mo-button @click="fn_Approval" :disabled="btnDisAppr">{{btnAppr}}</mo-button>
        </div>
        <div class="wrap-table-title">
          <p class="exp-txt"> 지속거래인 경우 고객확인의무 관련정보(주소, 전화번호, 국적, 거주구분, 직업(직장일 경우 직장명)) 를 확인하세요. </p>
        </div>
        <div class="right-bg-white">
          <div class="wrap-table mt-2 x-scroll">
            <table class="table row-type even-border">
              <thead>
                <tr>
                  <th scope="col">실제 소유자</th>
                  <th scope="col">진행상태</th>
                  <th scope="col">계좌번호</th>
                  <th scope="col">처리일자</th>
                  <th scope="col">고객명</th>
                  <th scope="col">AML결과값</th>
                  <th scope="col">위험등급 결과값</th>
                  <th scope="col">처리결과</th>
                  <th scope="col">AML일괄 처리결과</th>
                </tr>
                <tr>
                  <th scope="col">AML 결과송신</th>
                  <th scope="col">거래구분</th>
                  <th scope="col">참조번호</th>
                  <th scope="col">업무구분</th>
                  <th scope="col">실명번호</th>
                  <th scope="col">신원검증</th>
                  <th scope="col">W/L대사(동일인확인)</th>
                  <th scope="col">EDD</th>
                  <th scope="col">PEP</th>
                </tr>
              </thead>
              <tbody>
                <!-- <template v-if="null != _tftarlList && 0 < _tftarlList.length"> -->
                <template v-if="0 < tftarlList.length">
                  <!-- 데이터모델 배열객체 접근오류 임시 조치 (BEGIN) -->
                  <template v-for="(data, idx) in tftarlList" >
                    <tr :key="idx.toString ()">
                      <td v-if="data.amlPrcsPfrmType !== '1'"> </td>
                      <td v-if="data.amlPrcsPfrmType == '1' && data.rlOwnMnCnfmYn == ''"><mo-button v-model="data.rlOwnMnCnfmYn" @click="fn_ListClick ('rlOwnMnCnfmYn', idx.toString (), data.rlOwnMnCnfmYn)" class="btn-input">등록</mo-button></td>
                      <td v-if="data.amlPrcsPfrmType == '1' && data.rlOwnMnCnfmYn != ''"><mo-button v-model="data.rlOwnMnCnfmYn" @click="fn_ListClick ('rlOwnMnCnfmYn', idx.toString (), data.rlOwnMnCnfmYn)" class="btn-input">조회</mo-button></td>
                      <td>{{ data.nowStatTypeNm }}</td>
                      <!-- 계좌번호 매스킹 처리 (보안성검토 조치), modified by anarchi (2023.09.14) -->
                      <td>{{ bnkAcnoMask (data.tacno + '-' + data.bnkbSeq + '-' + data.ctno.padStart (3, '0')) }}</td>
                      <td>{{ data.procDate.substring (0, 4)+'-'+data.procDate.substring (4, 6)+'-'+data.procDate.substring (6, 8) }}</td>
                      <td>{{ data.cnm }}</td>
                      <td>{{ data.amlRstValTcNm }} </td>
                      <td>{{ data.rskType }} </td>
                      <td>{{ data.amlRstCNm }}</td>
                      <td>{{ data.msgNm }}</td>
                    </tr>
                    <!-- <tr :key="idx.toString ()"> -->
                    <tr :key="idx + 999">
                      <td v-if="data.amlPrcsPfrmType == ''"></td>
                      <td v-else-if="data.amlPrcsPfrmType !== '1'"></td>
                      <td v-else-if="(data.amlPrcsPfrmType === '1' && parseInt (data.nowStatType) < 11)"></td>
                      <td v-else-if="(data.amlPrcsPfrmType === '1' && parseInt (data.nowStatType) > 11) && parseInt (data.nowStatType) > 50"><mo-button v-model="data.pepRegYn" class="btn-input"> 전송완료 </mo-button></td>
                      <td v-else-if="(data.amlPrcsPfrmType === '1' && parseInt (data.nowStatType) > 11) && parseInt (data.nowStatType) < 50"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('amlPrcsPfrmType', idx.toString (), data.amlPrcsPfrmType)" class="btn-input"> 전송 </mo-button></td>
                      <td v-if="data.amlPrcsPfrmType == '1'"> 신규 </td>
                      <td v-if="data.amlPrcsPfrmType != '1'"> 지속 </td>
                      <td class="break-all">{{ data.refNo }}</td>
                      <td>{{ data.amlScrnTcNm }}</td>
                      <td>{{ data.rcno }}</td>
                      <td v-if="data.idtyVrfcYn == 'Y'" @click="fn_ListClick ('idtyVrfcYn', idx.toString (), data.idtyVrfcYn)">일치</td>
                      <td v-if="data.idtyVrfcYn == 'N'" @click="fn_ListClick ('idtyVrfcYn', idx.toString (), data.idtyVrfcYn)">불일치</td>
                      <td v-if="data.idtyVrfcYn != 'Y' && data.idtyVrfcYn != 'N'"></td>
                      <td v-if="data.wlNvtnYn == 'Y'" @click="fn_ListClick ('wlNvtnYn', idx.toString (), data.wlNvtnYn)"> 동일인 </td>
                      <td v-if="data.wlNvtnYn == 'N'" @click="fn_ListClick ('wlNvtnYn', idx.toString (), data.wlNvtnYn)"> 동일인 아님 </td>
                      <td v-if="data.wlNvtnYn == 'X'" @click="fn_ListClick ('wlNvtnYn', idx.toString (), data.wlNvtnYn)"> 확인불가 </td>
                      <td v-if="data.wlNvtnYn != 'Y' && data.wlNvtnYn != 'N' && data.wlNvtnYn != 'X'">  </td>
                      <!-- EDD등록버튼 활성화/비활성화 및 레이블 처리 조건 보정함, corrected by anachi (2023.08.14) -->
                      <!-- <td v-if="(data.eddRegYn !== 'Y' && data.eddRegYn !== 'N')"></td> -->
                      <td v-if="(data.eddRegYn == '' || data.eddRegYn == '9')"></td>
                      <td v-else-if="(data.eddRegYn == 'Y') && (parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50)"><mo-button v-model="data.eddRegYn" @click="fn_ListClick ('eddRegYn', idx.toString (), data.eddRegYn)" class="btn-input"> 수정 </mo-button></td>
                      <td v-else-if="(data.eddRegYn != 'Y') && (parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50)"><mo-button v-model="data.eddRegYn" @click="fn_ListClick ('eddRegYn', idx.toString (), data.eddRegYn)" class="btn-input"> 등록 </mo-button></td>
                      <!-- <td v-else-if="(data.eddRegYn != 'Y') && !(parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50)"><mo-button v-model="data.eddRegYn" @click="fn_ListClick ('eddRegYn', idx.toString (), data.eddRegYn)" class="btn-input"> 조회 </mo-button></td> -->
                      <td v-else><mo-button v-model="data.eddRegYn" @click="fn_ListClick ('eddRegYn', idx.toString (), data.eddRegYn)" class="btn-input"> 조회 </mo-button></td>
                      <!-- PEP등록버튼 활성화/비활성화 및 레이블 처리 조건 보정함, corrected by anachi (2023.08.18) -->
                      <!-- <td v-if="(data.pepRegYn !== 'Y' && data.pepRegYn !== 'N')"></td> -->
                      <td v-if="(data.pepRegYn == '' || data.pepRegYn == '9')"></td>
                      <!-- <td v-else-if="(parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50) && (data.pepRegYn !== 'Y' || data.pepRegYn !== 'N')"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 조회 </mo-button></td>
                      <td v-else-if="(parseInt (data.nowStatType) >= 10 || parseInt (data.nowStatType) <= 50) && data.pepRegYn === 'Y'"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 수정 </mo-button></td>
                      <td v-else-if="(parseInt (data.nowStatType) >= 10 || parseInt (data.nowStatType) <= 50) && data.pepRegYn === 'N'"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 등록 </mo-button></td> -->
                      <td v-else-if="(parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50)"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 조회 </mo-button></td>
                      <td v-else-if="!(parseInt (data.nowStatType) < 10 || parseInt (data.nowStatType) > 50) && data.pepRegYn == 'Y'"><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 수정 </mo-button></td>
                      <td v-else><mo-button v-model="data.pepRegYn" @click="fn_ListClick ('pepRegYn', idx.toString (), data.pepRegYn)"  class="btn-input"> 등록 </mo-button></td>
                    </tr>
                  </template>
                  <!-- 데이터모델 배열객체 접근오류 임시 조치 (END)//-->
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
      </div>
    </template>
    <!-- <ts-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup> -->
    <ts-alert-popup
      ref="alertPopup_303p"
      :popupObj="pAlertPopupObj_303p"
    ></ts-alert-popup>
    <!-- <msp-ts-106p
      ref="popup106"
      :popup106Obj="pPopup106Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup106-callback="fn_Popup106Back"
    ></msp-ts-106p> -->
    <msp-ts-106p
      ref="popup106_303p"
      :popup106Obj="pPopup106Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup106-callback="fn_Popup106Back"
    ></msp-ts-106p>
    <msp-ts-304p
      ref="popup304"
      :popup304Obj="pPopup304Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup304-callback="fn_Popup304Back"
    ></msp-ts-304p>
    <msp-ts-306p
      ref="popup306"
      :popup306Obj="pPopup306Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup306-callback="fn_Popup306Back"
    ></msp-ts-306p>
    <msp-ts-307p
      ref="popup307"
      :popup307Obj="pPopup307Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup307-callback="fn_Popup307Back"
    ></msp-ts-307p>
  </mo-modal>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import _ from "lodash"
import _cloneDeep from "lodash/cloneDeep"
import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

import MSPTS106P from "@/ui/ts/MSPTS106P" //책임자승인요청
import MSPTS304P from "@/ui/ts/MSPTS304P" //실제소유자확인(개인)
import MSPTS306P from "@/ui/ts/MSPTS306P" //개인EDD등록
import MSPTS307P from "@/ui/ts/MSPTS307P" //개인PEP등록

export default {
  /******************************************************************************************
   * Vue 인스턴스 기본속성(화면명, ID 등) 정의
   ******************************************************************************************/
  // 현재 화면명
  name: "MSPTS303P",
  // 현재 화면 ID
  screenId: "MSPTS303P",
  /******************************************************************************************
   * Components 설정 영역
   ******************************************************************************************/
  components: {
    "ts-alert-popup": TSAlertPopup, // 공통알림창전용
    "msp-ts-106p": MSPTS106P, // 책임자승인요청
    "msp-ts-304p": MSPTS304P, // 실제소유자확인(개인)
    "msp-ts-306p": MSPTS306P, // 개인EDD등록
    "msp-ts-307p": MSPTS307P, // 개인PEP등록
  },
  /******************************************************************************************
   * Props 설정 영역
   ******************************************************************************************/
  props: {
    pPage: String, // 부모 페이지명
    pGdC: String, // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pProcTc: String,
    pPopupObj: {
      type: Object,
      default: null,
    },
  },
  /************************************************************************************************
   * 화면 전역 변수 선언 영역
   ************************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      procSeq: "", // 화면처리용 (사용자정의) 추가
      _ctno: "", // ※ 추가항목
      _tacno: "", // 화면처리용 (사용자정의) 추가
      _bnkbSeq: "", // 화면처리용 (사용자정의) 추가
      ctnoDisp: "", // 화면처리용 (사용자정의) 추가
      busyDate: "",
      btnAml: "",
      btnAppr: "승인요청",
      btnDisAml: true,
      btnDisAppr: true,
      admGrade: "", // 사용자정의
      aprvNo: 0, // ※※※ 승인번호 (사용자정의)
      refNo: 0, // ※참조번호 (사용자정의)
      tftarl: [],
      userId: "",
      userNm: "",
      toastMsg: "", // 문자발송 toast 문구
      searchKeyword: "", // 고객명
      trstTypC: "",
      gdTypDtlC: "",
      lv_Params: {},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      nowDate: moment (new Date ()).format ("YYYY-MM-DD"), // 오늘
      isSucs: false, // 리턴값 세팅여부
      // ---------
      // 디버깅 데이터 항목
      // ---------
      tftarlList: [], // 계약_AML계약관계자내역 반환 객체(?)
      _tftarlList: [], // 계약_AML계약관계자내역 반환 객체(복제본)(?)
      currntEddIdx: 0,
      currntPepIdx: 0,
      eaiCommObj: {
        lv_vm: "",
        trnstId: "",
        auth: "",
        commHeaderVO: {
          eaiId: "",
          fahrTrnId: "S",
          requestSystemCode: "F1391",
          reqSrvcNm: "",
          reqMthdNm: "",
          targetSystemCode: "",
          resVONm: "",
          reqVONm: "",
        },
        params: {},
        response: [],
        error_msg: "",
      },
      popup106: {}, // MSPTS106P
      popup106_303p: {}, // MSPTS106P (전용)
      pPopup106Obj: {},
      popup304: {}, // MSPTS304P
      pPopup304Obj: {},
      popup306: {}, // MSPTS304P
      pPopup306Obj: {},
      popup307: {},
      pPopup307Obj: {}, //
      alertPopup_303p: {}, //
      pAlertPopupObj_303p: {}, // 추가
      popupObj:{},
      pAlertPopupObj: {},
      // 로그인 사용자 정보 (added by anachi, 2023.08.04)
      lv_basInfo: this.getStore ("tsStore").getters.getBasInfo.data,
      lv_isProcess: this.getStore ("tsStore").getters.getState.isProcess,
      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      userInfo: {},
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 개별업무 / 프로세스 진행상태 여부
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
    modal () {
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 시작 ]");
      return this.$refs.modal;
      if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::modal ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    //
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    // (공통)코드 조회
    this.fn_ComCode ();
    // (공통)영업일 조회
    this.fn_GetBusyDate ();
    // this.fn_Init (); //onload시 초기화
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ("MSPTS303P");
    // 유저 정보 세팅 (*프로세스가 있는 경우 홈에서 들어가는 부분이 리셋되서, 주석처리함 ???)
    // let vm = this;
    // vm.$bizUtil.tsUtils.selBasInfo (vm, false).then (function (response) {
    //   vm.lv_basInfo = response.body;
    //   if (this.isLoggable) console.log (this.lv_basInfo);
    // }).catch (function (error) {
    //   window.vue.error (error);
    // });
    // this.lv_basInfo = this.userInfo;
    if (this.isLoggable) console.log ("■■■■■■■■■▦▦▦▦▦▦▦▦▦□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)

      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600046_S' |
      // 'UF10600046' // [NEXT 사랑On 신탁]콤보 목록을 조회-selectComboList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "txTSSTS90S1"; // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        "TUKEMK": [{
          cId: "5045",
        }],
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [(공통)코드조회---5045]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      // ------------------
      // ※ 원격트랜잭션서비스(PO)호출 API 변경 적용, modified by anarchi (2023.07.12)
      //   TSServiceUtil.invoke → TSServiceUtil.invokeInDirect
      // ------------------
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    },
    // ======================================================
    // (공통:데이터 초기화--콜백) fn_ComCodeCallBack::
    // ======================================================
    fn_ComCodeCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [(공통)코드조회---5045]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      for (let index in res.data.tukemk) {
          let item = res.data.tukemk[index];
          switch (item.cid) {
            case "5045":
              // this.natnNatCItems.push ({
              //   value: item.c,
              //   text: item.cnm,
              // });
              break;
          }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ComCodeCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert 팝업
     ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [공통팝업호출]_[(수신)-파라미터]", " type → ", type, " pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_303p, pPopupObj);
          }
          // this.alertPopup_303p = this.$refs.alertPopup_303p.fn_Open ();
          this.$refs.alertPopup_303p.fn_Open ();
          break;
        case 1:
          //
          break;
        case 2:
          //
          break;
        case 7:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_303p, pPopupObj);
          }
          // this.alertPopup_303p = this.$refs.alertPopup_303p.fn_Open ();
          this.$refs.alertPopup_303p.fn_Open ();
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) {
            TSInfoUtil.initAlertPopObj (type, this.pAlertPopupObj_303p, pPopupObj);
          }
          // this.alertPopup_303p = this.$refs.alertPopup_303p.fn_Open ();
          break;
        default:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (0, this.pAlertPopupObj_303p, pPopupObj);
          }
          // this.alertPopup_303p = this.$refs.alertPopup_303p.fn_Open ();
          this.$refs.alertPopup_303p.fn_Open ();
      }
      // this.$emit ('ts-alert-popup', 0, pData);
      // this.$refs.alertPopup.fn_OpenA (pData);
      // this.$refs.alertPopup_303p.fn_OpenA ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AlertPopup ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:초기화) fn_Init:: 화면초기화 처리
    // ======================================================
    fn_Init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      this.tftarlList = [];
      this._tftarlList = [];
      this.fn_Search ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    // ======================================================
    // (화면흐름 제어) fn_Open::
    // ======================================================
    fn_Open (param) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [AML송수신처리팝업]_[(수신)-파라미터]", " param → ", param,);
      if (this.isLoggable) console.log ("+++++++++");
      if (_.isEmpty (param.tacno)) {
        let t_popupObj = {
          confirm: false,
          content: "종합계좌번호가 제대로 넘어오지 않았습니다.",
        };
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.bnkbSeq)) {
        let t_popupObj = {
          confirm: false,
          content: "통장일련번호가 제대로 넘어오지 않았습니다.",
        };
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      if (_.isEmpty (param.ctno)) {
        let t_popupObj = {
          confirm: false,
          content: "계약번호가 제대로 넘어오지 않았습니다.",
        }
        this.fn_AlertPopup (0, t_popupObj);
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        return;
      }
      // if (_.isEmpty (param.procSeq)) {
      //   let t_popupObj = {
      //     confirm: false,
      //     content: "처리일련번호가 제대로 넘어오지 않았습니다."
      //   }
      //   this.$emit ("ts-alert-popup", 0, t_popupObj)
      //   return
      // }
      this._tacno = param.tacno;
      this._bnkbSeq = param.bnkbSeq;
      this._ctno = param.ctno.padStart (3, "0");
      this.procSeq = param.procSeq;
      this.vcnm = param.vcnm;       // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
      this.soptTc = param.soptTc;   // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
      // this.ctno = param.tacno + "-" + param.bnkbSeq + "-" + param.ctno.padStart (3, "0");
      // ---------
      // 계좌번호 매스킹 처리 (보안성검토 조치), modified by anarchi (2023.09.14)
      // ---------
      this.ctnoDisp = this.bnkAcnoMask (this._tacno + "-" + this._bnkbSeq + "-" + this._ctno);
      this.fn_Init (); // onload시 초기화
      this.modal.open ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Open ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 시작 ]");
      this.$emit ("ts-popup303-callback", {
        // 송신 파라미터 객체 설정
      });
      this.modal.close ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Close ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 시작 ]");
      //
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Clear ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_ListClick
    * 설명: 리스트 클릭
    *********************************************************/
    fn_ListClick (type, idx, data) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ListClick ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [목록 클릭이벤트-(수신)파라미터]", ", type → ", type, ", idx → ", idx, ", data → ", data);
      if (this.isLoggable) console.log ("+++++++++");
      let v_insertYn = "";
      let v_refNo = this.tftarlList[idx].refNo;
      let v_tacno = this.tftarlList[idx].tacno;
      let v_bnkbSeq = this.tftarlList[idx].bnkbSeq;
      let v_ctno = this.tftarlList[idx].ctno;
      let v_procSeq = this.tftarlList[idx].procSeq;
      let v_idtyVrfcYn = this.tftarlList[idx].idtyVrfcYn;
      let v_wlNvtnYn = this.tftarlList[idx].wlNvtnYn;
      let v_eddRegYn = this.tftarlList[idx].eddRegYn;
      let v_pepRegYn = this.tftarlList[idx].pepRegYn;
      let v_csId = this.tftarlList[idx].csId;
      let v_cnm = this.tftarlList[idx].cnm;
      let v_nowStatType = this.tftarlList[idx].nowStatType;
      let v_amlPrcsPfrmType = this.tftarlList[idx].amlPrcsPfrmType;
      let v_rlOwnMnCnfmYn = this.tftarlList[idx].rlOwnMnCnfmYn;
      let v_corpYn = this.tftarlList[idx].corpYn;

      // ------
      // AML처리결과 전송 프로세스 진행
      // ------
      if (Number (v_nowStatType) > 10 && Number (v_nowStatType) < 50) {
        v_insertYn = "Y";
      }
      else {
        v_insertYn = "N";
      }
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷ v_insertYn → ", v_insertYn);
      // if (this.isLoggable) console.log ("+++++++++");
      if (type === "rlOwnMnCnfmYn") {
        if (v_amlPrcsPfrmType === "1") {
          if (v_corpYn !== "Y") {
            this.fn_OpenMSPTS304P (this.tftarlList[idx]); // 실제소유자확인 팝업
            this.fn_confirmPrintTrue (this.tftarlList[idx]);
            // this.fn_checkProcess (this.tftarlList[idx]); // 실제소유자확인서 전자서식 저장/출력
          }
        }
      }
      if (v_insertYn == "Y") {
        // ---
        // ※ 신원검증
        // ---
        if (type === "idtyVrfcYn") {
          if (v_idtyVrfcYn !== "9") {
            let t_popupObj = {
              confirm: true,
              content: "신원검증을 등록하시겠습니까?",
              // confirmFunc: this.fn_idtyVrfcYnConfirm (idx, v_idtyVrfcYn),
              confirmFunc: this.fn_IdtyVrfcYnConfirm.bind (null, idx, v_idtyVrfcYn),
              // closeFunc: this.fn_idtyVrfcYnClose,
            }
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
          }
        }
        // ---
        // ※ WL대사
        // ---
        if (type === "wlNvtnYn") {
          if (v_idtyVrfcYn == "N") {
            let t_popupObj = {
              confirm: false,
              content: "신원검증된 고객만 가능합니다.",
            };
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
            return;
          }
          // ---
          // ※ 동일인여부
          // ---
          if (v_wlNvtnYn != "9") {
            let t_popupObj = {
              confirm: true,
              content: "WL대사 등록하시겠습니까?",
              // confirmFunc: this.fn_IdtyVrfcYnConfirm (idx, v_wlNvtnYn),
              confirmFunc: this.fn_IdtyVrfcYnConfirm.bind (null, idx, v_wlNvtnYn),
            }
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
          }
        }
        // ---
        // AML송수신결과송신
        // ---
        if (type === "amlPrcsPfrmType") {
          if (v_amlPrcsPfrmType === "1") {
            let v_wltemp = v_wlNvtnYn; // WL대사여부
            let v_eddtemp = v_eddRegYn; // EDD등록여부
            let v_peptemp = v_pepRegYn; // FEP등록여부
            let v_identemp = v_idtyVrfcYn; // 신원검증여부
            if (v_wltemp == "9") {
              v_wltemp = "해당사항없음";
            }
            if (v_wltemp == "X") {
              v_wltemp = "확인불가";
            }
            if (v_identemp == "9") {
              v_identemp = "해당사항없음";
            }
            if (v_eddtemp == "9") {
              v_eddtemp = "해당사항없음";
            }
            if (v_peptemp == "9") {
              v_peptemp = "해당사항없음";
            }
            let wArr = [];
            wArr.push ("AML 처리결과를 전송하시겠습니까?");
            wArr.push ("신원검증: " + v_identemp + "");
            wArr.push ("WL대사: " + v_wltemp + "");
            wArr.push ("EDD등록: " + v_eddtemp + "");
            wArr.push ("PEP등록: " + v_peptemp + "");
            this.pAlertPopupObj.content = wArr;
            this.pAlertPopupObj.confirm = true;
            this.pAlertPopupObj.confirmFunc = this.fn_AmlPrcsPfrmTypeConfirm.bind (null, idx);
            this.pAlertPopupObj.closeFunc = this.fn_AmlPrcsPfrmTypeConfirmClose;
            // this.$refs.alertPopup.fn_OpenA (this.pAlertPopupObj);
            // this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
            this.fn_AlertPopup (0, this.pAlertPopupObj);
          }
        }
      }
      // ---
      // 개인EDD등록
      // ---
      if (type === "eddRegYn") {
        if (v_eddRegYn !== "9") {
          if (v_idtyVrfcYn === "N" && v_insertYn === "Y") {
            let t_popupObj = {
              confirm: false,
              content: "신원검증된 고객만 가능합니다.",
            }
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
            return;
          }
          if (v_corpYn === "N") {
            let properties = {
              pPage: "MSPTS303P",
              csId: v_csId,
              tacno: v_tacno,
              cnm: v_cnm,
              insertYn: v_insertYn,
              refNo: v_refNo,
              amlPrcsPfrmType: v_amlPrcsPfrmType,
            };
            if (this.isLoggable) console.log ("+++++++++");
            if (this.isLoggable) console.log ("+ ▶ [popup306_개인EDD등록-팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
            if (this.isLoggable) console.log ("+++++++++");
            this.popup306 = this.$refs.popup306.fn_Open (properties); // 개인EDD등록 팝업
            // ---
            // 현재 EDD 등록/수정 처리될 결과데이터 인덱스 저장, adde by anarchi (2023.09.27)
            // ---
            this.currntEddIdx = idx;
          }
        }
      }
      // ---
      // 개인PEP등록
      // ---
      if (type === "pepRegYn") {
        if (v_pepRegYn !== "9") {
          // pep등록
          if (v_idtyVrfcYn === "N" && v_insertYn === "Y") {
            let t_popupObj = {
              confirm: false,
              content: "신원 검증된 고객만 가능합니다.",
            };
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
            return;
          }
          if (v_wlNvtnYn === "N" && v_insertYn === "Y") {
            let t_popupObj = {
              confirm: false,
              content: "WL대사 확인된 고객만 가능합니다.",
            };
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
            return;
          }
          let properties = {
            pPage: "MSPTS303P",
            csId: v_csId,
            tacno: v_tacno,
            cnm: v_cnm,
            insertYn: v_insertYn,
            refNo: v_refNo,
            amlPrcsPfrmType: v_amlPrcsPfrmType,
          };
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [popup307_개인PEP등록-팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
          if (this.isLoggable) console.log ("+++++++++");
          this.popup307 = this.$refs.popup307.fn_Open (properties); // 개인PEP등록 팝업
          // ---
          // 현재 PEP 등록/수정 처리될 결과데이터 인덱스 저장, adde by anarchi (2023.09.27)
          // ---
          this.currntPepIdx = idx;
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ListClick ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_IdtyVrfcYnConfirm
    * 설명: 신원검증 등록
    *********************************************************/
    fn_IdtyVrfcYnConfirm (idx, v_idtyVrfcYn) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdtyVrfcYnConfirm ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [신원검증 등록팝업-(콜백)]_[(수신)-리턴객체]", ", idx → ", idx, ", v_idtyVrfcYn → ", v_idtyVrfcYn);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let idYNX = "";
      if (v_idtyVrfcYn === "Y") {
        idYNX = "Y";
      }
      else if (v_idtyVrfcYn === "N") {
        idYNX = "N";
      }
      let pParams = {
        tftarl: {
          refNo: v_refNo,
          idtyVrfcYn: idYNX,
        },
        tftarlVO: {
          tacno: this._tacno,
          bnkbSeq: this._bnkbSeq,
          ctno: this._ctno,
          procSeq: this.procSeq,
        },
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600196_S' |
      // 'UF10600196' // [NEXT 사랑On 신탁]신원검증 Update 수정한다.-updateTPUP95020A
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      // this.eaiCommObj.trnstId = "updateTPUP95020A";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600196_S";
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [신원검증 Update 수정]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_IdtyVrfcYnConfirmResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600196");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdtyVrfcYnConfirm ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_IdtyVrfcYnConfirmConfirm
    * 설명: WL대사 등록 (☆☆☆ 현재 사용되지 않음.....)
    *********************************************************/
    fn_IdtyVrfcYnConfirmConfirm (idx, v_wlNvtnYn) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdtyVrfcYnConfirmConfirm ]___[ 시작 ]");
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷ idx → ", idx, "+ ▷ v_wlNvtnYn → ", v_wlNvtnYn);
      // if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        tftarl: {
          refNo: v_refNo,
          wlNvtnYn: v_wlNvtnYn,
        },
        tftarlVO: {
          tacno: this._tacno,
          bnkbSeq: this._bnkbSeq,
          ctno: this._ctno,
          procSeq: this.procSeq,
        },
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C391_F10600197_S' |
      // 'UF10600197' // [NEXT 사랑On 신탁]동일인여부 수정-updateTPUP95020B
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      this.eaiCommObj.params = pParams;
      // this.eaiCommObj.trnstId = "updateTPUP95020B";
      this.eaiCommObj.commHeaderVO.eaiId = "C391_F10600197_S";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [동일인여부 수정]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_IdtyVrfcYnConfirmResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600197");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_IdtyVrfcYnConfirmConfirm ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_AmlPrcsPfrmTypeConfirm
    * 설명: AML결과송신 등록
    *********************************************************/
    fn_AmlPrcsPfrmTypeConfirm (idx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirm ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ idx → ", idx);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        refNo: this.tftarlList[idx].refNo,
        nowStatType: this.tftarlList[idx].nowStatType,
        tacno: this.tftarlList[idx].tacno,
        bnkbSeq: this.tftarlList[idx].bnkbSeq,
        ctno: this.tftarlList[idx].ctno,
        procSeq: this.tftarlList[idx].procSeq,
        prosGb: "Z",
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600193_S' |
      // 'UF10600193' // [NEXT 사랑On 신탁]AML송수신승인처리팝업 [승인요청] 처리한다.-insertTPUP95020B
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "I";
      this.eaiCommObj.params = pParams;
      // this.eaiCommObj.trnstId = "insertTPUP95020B";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600193_S";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [AML송수신승인처리팝업 [승인요청] 처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_AmlPrcsPfrmTypeConfirmResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600193");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirm ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_AmlPrcsPfrmTypeConfirmResult
    * 설명: AML결과송신 등록 콜백
    *********************************************************/
    fn_AmlPrcsPfrmTypeConfirmResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirmResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [AML송수신승인처리팝업 [승인요청] 처리]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // if (!_.isEmpty (res.data) && 0 < res.data.tftarlList.length) {
      //   // @TO-DO:
      // }
      this.fn_Search ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirmResult ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_AmlPrcsPfrmTypeConfirmClose
    * 설명: AML결과송신 등록 콜백
    *********************************************************/
    fn_AmlPrcsPfrmTypeConfirmClose () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirmClose ]___[ 시작 ]");
      // ---------
      // ---------
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlPrcsPfrmTypeConfirmClose ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS106P
    * 설명: 책임자승인요청 팝업호출
    *********************************************************/
    fn_OpenMSPTS106P () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS106P ]___[ 시작 ]");
      let lv_vm = this;
      let properties = {
        pPage: "MSPTS303P",
        pFileName: "",
        pProcDate: "",
        // pBrcd: this.getStore ("userInfo").getters.getUserInfo.userDeptCd,
        // pReqEmno: this.getStore ("userInfo").getters.getUserInfo.userId,
        pBrcd: this.lv_basInfo.brcd,
        pReqEmno: this.lv_basInfo.usid,
        pMngAprvSeq: this.aprvNo,
        pAdmGrade: this.admGrade,
        pAmlYn: "Y",
        pTacno: this._tacno,
        pBnkbSeq: this._bnkbSeq,
        pCtno: this._ctno,
        pProcSeq: this.procSeq,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [책임자승인요청 팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      // this.popup106 = this.$refs.popup106.fn_Open (properties);
      this.$refs.popup106.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS106P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup106Back
    * 설명: 책임자승인요청 팝업호출 콜백
    *********************************************************/
    fn_Popup106Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup106Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [책임자승인요청 팝업-(호출$콜백)]_[(수신)-파라미터]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log ("+++++++++");
      if (!_.isEmpty (rtnData) && "Y" == rtnData) { // 승인결과: successYn
        let _admGrade = this.admGrade;
        if ("1" == _admGrade || "2" == _admGrade) {
          this.fn_Approval ();
        }
        else {
          this.fn_Search ();
        }
      }
      else {
        this.aprvNo = "";
      }
      // if (!_.isEmpty (_admGrade) && "2" == _admGrade) { // 2차 승인요청 처리일 경우
      //   this.btnDisAppr = ture; // 1/2차 승인요청 버튼 비활성화
      // }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup106Back ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS304P
    * 설명: 실제소유자 확인(개인) 팝업호출
    *********************************************************/
    fn_OpenMSPTS304P (tftarlList) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS304P ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자 확인(개인) 팝업-(호출)]_[(수신)-파라미터]", " tftarlList → ", tftarlList);
      if (this.isLoggable) console.log ("+++++++++");
      // let lv_vm = this;
      let v_refNo = tftarlList.refNo;
      let v_tacno = tftarlList.tacno;
      let v_bnkbSeq = tftarlList.bnkbSeq;
      let v_ctno = tftarlList.ctno;
      let v_procSeq = tftarlList.procSeq;
      let v_idtyVrfcYn = tftarlList.idtyVrfcYn;
      let v_wlNvtnYn = tftarlList.wlNvtnYn;
      let v_eddRegYn = tftarlList.eddRegYn;
      let v_pepRegYn = tftarlList.pepRegYn;
      let v_csId = tftarlList.csId;
      let v_cnm = tftarlList.cnm;
      let v_nowStatType = tftarlList.nowStatType;
      let v_amlPrcsPfrmType = tftarlList.amlPrcsPfrmType;
      let v_rlOwnMnCnfmYn = tftarlList.rlOwnMnCnfmYn;
      let v_corpYn = tftarlList.corpYn;
      let properties = {
        pPage: "MSPTS303P",
        rlOwnMnCnfmYn: v_rlOwnMnCnfmYn,
        refNo: v_refNo,
        tacno: v_tacno,
        bnkbSeq: v_bnkbSeq,
        ctno: v_ctno,
        procSeq: v_procSeq,
        idtyVrfcYn: v_idtyVrfcYn,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [실제소유자 확인(개인) 팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      this.popup304 = this.$refs.popup304.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS304P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup106Back
    * 설명: 실제소유자 확인(개인) 팝업호출 콜백
    *********************************************************/
    fn_Popup304Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup304Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [실제소유자 확인(개인) 팝업-(호출$콜백)]_[(수신)-리턴객체]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log ("+++++++++");
      // 1) 반환값 성공:'Y' 일 경우 처리
      // 2) 그 외 반환값 실패 일 경우
      this.fn_Search ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup304Back ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS306P
    * 설명: 개인EDD등록 팝업호출
    *********************************************************/
    fn_OpenMSPTS306P (tftarlList) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS306P ]___[ 시작 ]");
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [개인EDD등록 팝업-(호출)]_[(수신)-파라미터]", " tftarlList → ", tftarlList);
      // if (this.isLoggable) console.log ("+++++++++");
      // let lv_vm = this;
      let v_refNo = tftarlList.refNo;
      let v_tacno = tftarlList.tacno;
      let v_bnkbSeq = tftarlList.bnkbSeq;
      let v_ctno = tftarlList.ctno;
      let v_procSeq = tftarlList.procSeq;
      let v_idtyVrfcYn = tftarlList.idtyVrfcYn;
      let v_wlNvtnYn = tftarlList.wlNvtnYn;
      let v_eddRegYn = tftarlList.eddRegYn;
      let v_pepRegYn = tftarlList.pepRegYn;
      let v_csId = tftarlList.csId;
      let v_cnm = tftarlList.cnm;
      let v_nowStatType = tftarlList.nowStatType;
      let v_amlPrcsPfrmType = tftarlList.amlPrcsPfrmType;
      let v_rlOwnMnCnfmYn = tftarlList.rlOwnMnCnfmYn;
      let v_corpYn = tftarlList.corpYn;
      let properties = {
        pPage: "MSPTS303P",
        rlOwnMnCnfmYn: v_rlOwnMnCnfmYn,
        refNo: v_refNo,
        tacno: v_tacno,
        bnkbSeq: v_bnkbSeq,
        ctno: v_ctno,
        procSeq: v_procSeq,
        idtyVrfcYn: v_idtyVrfcYn,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인EDD등록 팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      this.popup306 = this.$refs.popup306.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS306P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup306Back
    * 설명: 개인EDD등록 팝업호출 콜백
    *********************************************************/
    fn_Popup306Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup306Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인EDD등록 팝업-(호출$콜백)]_[(수신)-리턴객체]", " rtnData → ", rtnData);
      // ---------
      // EDD등록여부(정보항목) 갱신 처리함.
      // ---------
      if (!_.isEmpty (rtnData.eddCorpRelYn) || !_.isEmpty (rtnData.eddPersnRelYn)) {
        this.tftarlList[this.currntEddIdx].eddRegYn = "Y";
      }
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup306Back ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS307P
    * 설명: 개인PEP등록 팝업호출
    *********************************************************/
    fn_OpenMSPTS307P (tftarlList) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS307P ]___[ 시작 ]");
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [개인PEP등록 팝업-(호출)]_[(수신)-파라미터]", " tftarlList → ", tftarlList);
      // if (this.isLoggable) console.log ("+++++++++");
      // let lv_vm = this;
      let v_refNo = tftarlList.refNo;
      let v_tacno = tftarlList.tacno;
      let v_bnkbSeq = tftarlList.bnkbSeq;
      let v_ctno = tftarlList.ctno;
      let v_procSeq = tftarlList.procSeq;
      let v_idtyVrfcYn = tftarlList.idtyVrfcYn;
      let v_wlNvtnYn = tftarlList.wlNvtnYn;
      let v_eddRegYn = tftarlList.eddRegYn;
      let v_pepRegYn = tftarlList.pepRegYn;
      let v_csId = tftarlList.csId;
      let v_cnm = tftarlList.cnm;
      let v_nowStatType = tftarlList.nowStatType;
      let v_amlPrcsPfrmType = tftarlList.amlPrcsPfrmType;
      let v_rlOwnMnCnfmYn = tftarlList.rlOwnMnCnfmYn;
      let v_corpYn = tftarlList.corpYn;
      let v_insertYn = "";
      if (Number (v_nowStatType) > 10 && Number (v_nowStatType) < 50) {
        v_insertYn = "Y";
      }
      else {
        v_insertYn = "N";
      }
      let properties = {
        pPage: "MSPTS303P",
        insertYn:v_insertYn,
        tacno: v_tacno,
        cnm: v_cnm,
        csId: v_csId,
        refNo: v_refNo,
        amlPrcsPfrmType: v_amlPrcsPfrmType,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [개인PEP등록 팝업-(호출)]_[(송신)-파라미터]", " properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      // this.popup307 = this.$refs.popup307.fn_Open (properties);
      this.$refs.popup307.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_OpenMSPTS307P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup307Back
    * 설명: 개인PEP등록 팝업호출 콜백
    *********************************************************/
    fn_Popup307Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup307Back ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [개인PEP등록 팝업-(호출$콜백)]_[(수신)-리턴객체]", " rtnData → ", rtnData);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Popup307Back ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_BtnEnable
     * 설명       : 버튼 셋팅
     ******************************************************************************/
    fn_BtnEnable () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_BtnEnable ]___[ 시작 ]");
      let checkNm1 = 0;
      let checkNm2 = 0;
      let btnEnable = true;
      if (this.tftarlList.length > 0) {
        for (let i = 0; i < this.tftarlList.length; i++) {
          if (this.tftarlList[i].nowStatType === "10") {
            this.btnDisAml = false;
          }
          if (this.tftarlList[i].nowStatType === "70" && this.tftarlList[i].amlPrcsPfrmType === "1") {
            this.btnDisAppr = false;
            this.btnAppr = "2차 승인요청";
            this.admGrade = "2";
            checkNm2 = checkNm2 + 1;
          }
          if (this.tftarlList[i].nowStatType === "71" && this.tftarlList[i].amlPrcsPfrmType === "1") {
            this.btnDisAppr = false;
            this.btnAppr = "2차 승인요청";
            this.admGrade = "2";
            checkNm2 = checkNm2 + 1;
          }
          if (this.tftarlList[i].nowStatType === "60" || this.tftarlList[i].nowStatType === "61") {
            if (this.tftarlList[i].amlPrcsPfrmType === "1") {
              this.btnDisAppr = false;
              this.btnAppr = "1차 승인요청";
              this.admGrade = "1";
              checkNm1 = checkNm1 + 1;
            }
          }
          if (Number (this.tftarlList[i].nowStatType) > 10 && Number (this.tftarlList[i].nowStatType) < 30) {
            btnEnable = false;
            this.btnDisAppr = true;
            this.btnAppr = "승인요청";
            this.admGrade = "";
          }
          if (Number (this.tftarlList[i].nowStatType) < 10) {
            btnEnable = false;
            this.btnDisAppr = true;
            this.btnAppr = "승인요청";
            this.admGrade = "";
          }
        }
        // if (this.isLoggable) console.log ("+++++++++");
        // if (this.isLoggable) console.log ("+ ▷▷ btnEnable → ", btnEnable, ", checkNm1 → ", checkNm1, ", checkNm2 → ", checkNm2,", this.btnAppr → ", this.btnAppr);
        // if (this.isLoggable) console.log ("+++++++++");
        if (!_.isEmpty (this.btnEnable)) {
          this.btnDisAppr = btnEnable;
        }
        if (checkNm1 > 0) {
          this.btnDisAppr = false;
          this.btnAppr = "1차 승인요청";
          this.admGrade = "1";
        }
        if (checkNm2 > 0) {
          this.btnDisAppr = false;
          this.btnAppr = "2차 승인요청";
          this.admGrade = "2";
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_BtnEnable ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      // debugger;
      const lv_Vm = this; // (현) Vue객체모델 인스턴스
      if (_.isEmpty (this._ctno)) {
        let t_popupObj = {
          confirm: false,
          content: "계약번호를 입력하세요.",
        };
        // this.$refs.alertPopup_303p.fn_Open (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      if (this.procSeq === 0) {
        let t_popupObj = {
          confirm: false,
          content: "순번을 입력하세요.",
        };
        // this.$refs.alertPopup_303p.fn_Open (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let pParams = {
        // tacno: this.ctno.substring (0, 7),
        // bnkbSeq: this.ctno.substring (8, 11),
        // ctno: this.ctno.substring (12, 15),
        tacno: this._tacno,
        bnkbSeq: this._bnkbSeq,
        ctno: this._ctno,
        // ---------
        // 검증을 위한 값 하드코딩함 (이슈조치 후 원복필수)
        procSeq: this.procSeq,
        // procSeq: "3",
        // ---------
        refNo: "1",
      };
      // this.tftarlList = [];
      // ---------
      // 'C392_F10600195_S' |
      // 'UF10600195' // [NEXT 사랑On 신탁]계약_AML계약관계자내역 목록조회-selectTPUP95020List
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.params = pParams;
      // this.eaiCommObj.trnstId = "selectTPUP96040";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600195_S";
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [계약_AML계약관계자내역 목록조회]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600195");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [계약_AML계약관계자내역 목록조회]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      // let lv_w = this;
      if (res && res.data && res.data.tftarl) {
        if (0 < res.data.tftarl.length) {
          if (this.isLoggable) console.log ("+++++++++");
          if (this.isLoggable) console.log ("+ ▶ [AML전문송신정보목록]]", " this.tftarlList → ", this.tftarlList);
          if (this.isLoggable) console.log ("+++++++++");
          // let __tftarlList = [];
          // ---------
          // 부적절한 중복 렌더링 오류 처리부분 복원함, restored by anarchi (2023.08.31)
          res.data.tftarl.forEach ((elmnt, idx) => {
            if (this.isLoggable) console.log ("res.data.tftarl[", idx, "] =========> ", elmnt);
            this.tftarlList.push (elmnt);
          });
          // this.tftarlList = [];
          this.tftarlList = res.data.tftarl;
          // this.tftarlList = _cloneDeep (res.data.tftarl);
          // ---------
          // this.tftarlList = res.data.tftarl.map ((item) => {
          //   return item;
          // });
          // this._tftarlList = []; // 초기화
          // this._tftarlList =  _.cloneDeep (this.tftarlList); // 객체복사
          this.fn_BtnEnable ();
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Approval
     * 설명       : 1/2차 승인요청
     ******************************************************************************/
    fn_Approval () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Approval ]___[ 시작 ]");
      if (_.isEmpty (this._ctno)) {
        let t_popupObj = {
          confirm: false,
          content: "계약번호가 잘못되었습니다.",
        };
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // ---------
      // 검증을 위한 값 하드코딩함 (이슈조치 후 원복필수 ==> 원복함)
      // this.procSeq = "3";
      // ---------
      if (Number (this.procSeq) < 1) {
        let t_popupObj = {
          confirm: false,
          content: "순번이 잘못되었습니다.",
        };
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      let eaiId = "";
      if (this.admGrade === "1") {
        eaiId = "C392_F10600198_S"; //1차 승인
      }
      else {
        eaiId = "C392_F10600199_S"; //2차 승인
      }
      let pParams = {
        refNo: this.tftarlList[0].refNo,
        // aprvNo: this.tftarlList[0].aprvNo,
        aprvNo: this.aprvNo,
        // procSeq: this.procSeq,
        // tacno: this.ctno.substring (0, 7),
        // bnkbSeq: this.ctno.substring (8, 11),
        // ctno: this.ctno.substring (12, 15),
        procSeq: this.procSeq,
        tacno: this._tacno,
        bnkbSeq: this._bnkbSeq,
        ctno: this._ctno,
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600198_S' |
      // 'UF10600198' // [NEXT 사랑On 신탁]1차 책임자승인 처리-updateTPUP95020D
      // 'C392_F10600199_S' |
      // 'UF10600199' // [NEXT 사랑On 신탁]2차 책임자승인 처리-updateTPUP95020E
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "U";
      this.eaiCommObj.params = pParams;
      // this.eaiCommObj.trnstId = trnstId;
      this.eaiCommObj.commHeaderVO.eaiId = eaiId;
      if (eaiId === "C392_F10600198_S") {
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [1-차 책임자승인 처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ApprovalResult, function (error) {
          if (this.isLoggable) console.log (error);
        }, "UF10600198");
      }
      else {
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [2-차 책임자승인 처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
        if (this.isLoggable) console.log ("+++++++++");
        TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ApprovalResult, function (error) {
          if (this.isLoggable) console.log (error);
        }, "UF10600199")
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Approval ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_ApprovalResult
     * 설명       : 1/2차 승인요청 콜백
     ******************************************************************************/
    fn_ApprovalResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ApprovalResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [1|2-차 책임자승인 처리]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      this.tftarl = res.data;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [1|2차 승인번호]", " this.tftarl.aprvNo → ", this.tftarl.aprvNo);
      if (this.isLoggable) console.log ("+++++++++");
      this.aprvNo = this.tftarl.aprvNo;
      if (Number (this.tftarl.aprvNo) > 0) {
        // let fileName = this.gfn_getBusyDate () + nexacro.getApplication().gds_userInfo.getColumn(0, "brcd") + this.ds_admission.getColumn(0, "aprvNo") + ".jpg";
        // let bSucc = system.saveToImageFile(this, fileName, "PNG");
        let props = {
          pPage: "MSPTS303P",
          pProcDate: this.busyDate,
          // pBrcd: this.getStore ("userInfo").getters.getUserInfo.userDeptCd,
          // pReqEmno: this.getStore ("userInfo").getters.getUserInfo.userId,
          pBrcd: this.lv_basInfo.brcd,
          pReqEmno: this.lv_basInfo.usid,
          pMngAprvSeq: this.tftarl.aprvNo,
          pFileName: "",
          pAdmGrade: this.admGrade,
          pAmlYn: "Y",
          // pTacno: this.ctno.substring (0, 7),
          // pBnkbSeq: this.ctno.substring (8, 11),
          // pCtno: this.ctno.substring (12, 15),
          // pProcSeq: this.procSeq,
          pTacno: this._tacno,
          pBnkbSeq: this._bnkbSeq,
          pCtno: this._ctno,
          pProcSeq: this.procSeq,
        };
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [popup106 팝업-(호출)]_[(송신)-파라미터]", " props → ", props);
        if (this.isLoggable) console.log ("+++++++++");
        // this.popup106 = this.$refs.popup106.fn_Open (props);
        // this.$refs.popup106.fn_Open (props);
        this.$refs.popup106_303p.fn_Open (props);
      }
      else {
        this.fn_Search ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_ApprovalResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Aml
     * 설명       : AML 송수신
     ******************************************************************************/
    fn_Aml () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Aml ]___[ 시작 ]");
      if (_.isEmpty (this._ctno)) {
        let t_popupObj = {
          confirm: false,
          content: "계약번호가 잘못되었습니다.",
        };
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      if (Number (this.procSeq) < 1) {
        let t_popupObj = {
          confirm: false,
          content: "순번이 잘못되었습니다.",
        };
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      // if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▷ this.tftarlList → ", this.tftarlList);
      // if (this.isLoggable) console.log ("+++++++++");
      if (this.tftarlList.length > 0) {
        for (let i=0; i<this.tftarlList.length; i++) {
          if (this.tftarlList[i].amlPrcsPfrmType === "1" && _.isEmpty (this.tftarlList[i].rlOwnMnCnfmYn)) {
            let t_popupObj = {
              confirm: false,
              content: "실제 소유자를 등록하십시오.",
            };
            // this.$refs.alertPopup.fn_OpenA (t_popupObj);
            this.fn_AlertPopup (0, t_popupObj);
            return;
          }
        }
      }
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.btnDisAml = true;
      let pParams = {
        refNo: this.tftarlList[0].refNo,
        // procSeq: this.procSeq,
        // tacno: this.ctno.substring (0, 7),
        // bnkbSeq: this.ctno.substring (8, 11),
        // ctno: this.ctno.substring (12, 15),
        procSeq: this.procSeq,
        tacno: this._tacno,
        bnkbSeq: this._bnkbSeq,
        ctno: this._ctno,
        aprvNo: this.aprvNo,
        prosGb: "Z",
      };
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600192_S' |
      // 'UF10600192' // [NEXT 사랑On 신탁]AML송수신승인처리팝업 [AML 송수신] 처리한다.-insertTPUP95020A
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "I";
      // this.eaiCommObj.trnstId = "insertTPUP95020A";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600192_S";
      this.eaiCommObj.params = pParams;
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [AML송수신승인처리팝업 [AML 송수신] 처리]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_AmlResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600192");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Aml ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AmlResult
     * 설명       : 승인 콜백
     ******************************************************************************/
    fn_AmlResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ [AML송수신승인처리팝업 [AML 송수신] 처리]_[EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      if (res.data.errorCode !== "") {
        let t_popupObj = {
          confirm: false,
          content: res.data.errorMsg,
        };
        // this.$refs.alertPopup.fn_OpenA (t_popupObj);
        this.fn_AlertPopup (0, t_popupObj);
        return;
      }
      this.tftarlList = res.data.tftarl;
      this.fn_BtnEnable ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_AmlResult ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 시작 ]");
      if (_.isEmpty (this.rowSelect)) {
        this.fn_Close ();
      }
      else {
        this.$emit ("ts-popup303-callback", {});
        this.fn_Close ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_Confirm ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected (idx) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 시작 ]");
      this.lv_SelectedItem = idx;
      let findIdx = this.tpup95330List.findIndex (obj => obj.chnlCustId === this.lv_SelectedItem);
      if (findIdx !== -1) {
        Object.assign (this.lv_SelectedItem, this.tpup95330List[findIdx]);
      }
      this.rowSelect = this.tpup95330List;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_RowSelected ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_GetBusyDate
     * 설명       : 영업일 조회
     ******************************************************************************/
    fn_GetBusyDate () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_GetBusyDate ]___[ 시작 ]");
      let salesYmd = window.vue.getStore ("tsStore").getters.getBasInfo.data.busyDate;
      this.busyDate = salesYmd;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_GetBusyDate ]___[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue (pTftarlList) {
      console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      console.log ("+++++++++");
      console.log ("+ ▶ [실제소유자 확인(개인) 리포트(전자서식)-(호출)]_[(수신)-파라미터]", " pTftarlList → ", pTftarlList);
      console.log ("+++++++++",this.soptTc);
      this.ds_searchReportList = []
      // ---------
      // ■ [실제소유자확인] 정보항목 매핑 정의
      //   +. AS-IS: TPUP95320.xfdl---AML송수신승인처리팝업
      //   +. 기본정보: cdd_pers_form_0700.mrd		TS000039_실제소유자 확인서_개인
      // ---------
      if (!_.isEmpty (pTftarlList)) {
        this.ds_searchParam = {
          chkYn    : pTftarlList.chkYn    , // 실제소유자 여부체크박스 (다를경우 화면단에서 입력) (1,2) [Y/N]
          rlOwnMnNm: pTftarlList.rlOwnMnNm, // 실제소유자 확인사항_성명 (다를경우 화면단에서 입력)
          rcnoEncr : pTftarlList.rcnoEncr , // 실제소유자 확인사항_실명번호 (다를경우 화면단에서 입력)
          natnNatC : pTftarlList.natnNatC , // 실제소유자 확인사항_국적 (다를경우 화면단에서 입력)
          chkYn    : pTftarlList.chkYn    , // 실제소유자 여부체크박스 (다를경우 화면단에서 입력) (1,2) [Y/N]
          rlOwnMnNm: pTftarlList.rlOwnMnNm, // 실제소유자 확인사항_성명 (다를경우 화면단에서 입력)
          rcnoEncr : pTftarlList.rcnoEncr , // 실제소유자 확인사항_실명번호 (다를경우 화면단에서 입력)
          natnNatC : pTftarlList.natnNatC , // 실제소유자 확인사항_국적 (다를경우 화면단에서 입력)
          // ---------
          csId: pTftarlList.csId,
          bnkbSeq: pTftarlList.bnkbSeq,
          tacno: pTftarlList.tacno,
          rcnoKindTc: pTftarlList.rcnoKindTc,
          ctno: pTftarlList.ctno,       //계약번호
          vcnm: pTftarlList.cnm,        // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경

          // rcno: pTftarlList.rcno,
          rlOwnMnCnfmYn: pTftarlList.rlOwnMnCnfmYn,
          rlOwnMnNatC: pTftarlList.rlOwnMnNatC,
          rlOwnMnNatCNm: pTftarlList.rlOwnMnNatCNm,
          rlOwnMnNm: pTftarlList.rlOwnMnNm,
          rlOwnMnQtn1: pTftarlList.rlOwnMnQtn1,
          rlOwnMnQtn2: pTftarlList.rlOwnMnQtn2,
          rlOwnMnQtn31: pTftarlList.rlOwnMnQtn31,
          rlOwnMnQtn32: pTftarlList.rlOwnMnQtn32,
          rlOwnMnQtn33: pTftarlList.rlOwnMnQtn33,
          rlOwnMnQtn4: pTftarlList.rlOwnMnQtn4,
          rqmnEmno: pTftarlList.rqmnEmno

        }
      }
      this.ds_searchReport.reportMrdNm = 'TS000001'
      this.ds_searchReport.reportUrl   = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))//ASR231000363 / 사랑온_3.0 신탁 4차 개선 전자서명리포트 추가
      if (!TSCommUtil.gfn_isNull(this.soptTc)) {                                                       // ASR240800403_[사랑On신탁] 개인고객확인서 서식변경
        if(this.soptTc == '10'){
          this.ds_searchReport.reportMrdNm = 'TS000107'; // 서식폼ID
          this.ds_searchReport.reportUrl   = 'reportTFAC20000'; // 서식 PO서비스 ID:
          this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
          this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
        } // 계약 프로세스 진행 시 개인정보확인서 세팅
        else if (this.soptTc == '20'){
          this.ds_searchReport.reportMrdNm = 'TS000115'; // 서식폼ID
          this.ds_searchReport.reportUrl   = 'reportTFAC20000'; // 서식 PO서비스 ID:
          this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
          this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
          this.ds_searchReport.reportMrdNm = 'TS000107'; // 서식폼ID
          this.ds_searchReport.reportUrl   = 'reportTFAC20000'; // 서식 PO서비스 ID:
          this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
          this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
          } // 계약 프로세스 진행, 수익자 있을 시 개인정보확인서(본인,수익자) 세팅
      } else {
      this.ds_searchReport.reportMrdNm = 'TS000114'
      this.ds_searchReport.reportUrl   = 'reportTFAC20000'
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam)
      this.ds_searchReportList.push(JSON.parse(JSON.stringify(this.ds_searchReport)))
      }  // 대리인 등록 시 개인정보확인서(대리인) 세팅
      this.fn_searchReport ()
    },
    /***************************************************************************************
     * 리포트 데이터 조회
     ***************************************************************************************/
    async fn_searchReport () {
      let formList = []
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = {
          formId: this.ds_searchReportList[i].reportMrdNm,
          params: this.ds_searchReportList[i]
        }
        formList.push (report)
      }
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) {
          this.$bizUtil.tsUtils.saveCvrPblParam (this, {
            tacno: this._tacno,
            bnkbSeq: this._bnkbSeq,
            accSeq: this._ctno.padStart (3, "0")
          })
          // ---------
          // 전자문서 출력을 위한 마지막 화면 설정
          // ---------saveCustInfo
          // this.getStore ("tsStore").dispatch ("IS_LAST_START");
          // TSInfoUtil.commReportInfo (this, formList, this.fn_Receipt , false);
          TSInfoUtil.commReportInfo (this, formList, "", false, "MSPTS303P")
          // TSInfoUtil.commReportInfo (this, formList, null, false);
        }
        else {
          this.$bizUtil.tsUtils.saveCvrPblParam (this, {
            tacno: this._tacno,
            bnkbSeq: this._bnkbSeq,
            accSeq: this._ctno.padStart (3, "0")
          })
          // ---------
          // 전자문서 출력을 위한 마지막 화면 설정
          // ---------
          // this.getStore ("tsStore").dispatch ("IS_LAST_START");
          TSInfoUtil.commReportInfo (this, formList, "", false, "MSPTS303P")
          // TSInfoUtil.commReportInfo (this, formList, null, "MSPTS303P");
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        this.$bizUtil.tsUtils.saveCvrPblParam (this, {
          tacno: this._tacno,
          bnkbSeq: this._bnkbSeq,
          accSeq: this._ctno.padStart (3, "0")
        });
        // ---------
        // 전자문서 출력을 위한 마지막 화면 설정
        // ---------
        // this.getStore ("tsStore").dispatch ("IS_LAST_START");
        TSInfoUtil.commReportInfo (this, formList, "", false, "MSPTS303P");
        // TSInfoUtil.commReportInfo (this, formList, null, "MSPTS303P");
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /******************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************/
    fn_checkProcess (ptftarlList) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_checkProcess ]__[ 시작 ]");
      if (this.isLoggable) console.log ("ptftarlList::: ", ptftarlList);
      let _tacno = ptftarlList.tacno;
      let _bnkbSeq = ptftarlList.bnkbSeq;
      let _ctno = ptftarlList.ctno;
      let _csId = ptftarlList.csId;
      if (this.isLoggable) console.log ("this.baseInfoObj::: ", this.baseInfoObj);
      let lv_Vm = this;
      let tmpContentTitle = "실제소유자확인서";
      let tmpContent = [];
      let t_type = 0;
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) {
          if (this.baseInfoObj.procTyp === "custInfoReg") {
            tmpContent.push ("실제소유자확인서를 발행합니다.");
          }
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        }
        else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      let t_popupObj = {
        confirm: true,
        confirmFunc: lv_Vm.fn_searchReport,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          tacno: _tacno,
          bnkbSeq: _bnkbSeq,
          accSeq: _ctno.padStart (3, "0"),
          csId: _csId,
        },
      };
      lv_Vm.fn_AlertPopup (t_type, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_checkProcess ]__[ 종료 ]");
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ======================================================
    // (업무:데이터 제어) bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    bnkAcnoMask (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 시작 ]");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull (_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask (_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 종료 ]");
    },
  },
}
</script>
