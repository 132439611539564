/*
* 업무구분: 신탁이익계산서
* 화 면 명: MSPTS203M
* 화면설명: 신탁이익계산서
* 접근권한: 
* 작 성 일: 2023.05.22
* 작 성 자: 김경태 -> (전면개정) refined by anarchi 
*/

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <ur-page-container class="fts" :show-title="false" title="신탁이익계산서" :topButton="true">
    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->
    <div class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left w100p mr0 justify-between">
                <div class="wrap-input row">
                  <label class="emphasis w61"> 계좌번호 </label>
                  <!-- (공통) 계좌 및 금액 관련 숫자입력 키패드 교체 적용, applyed by anarchi (2023.0318) // -->
                  <!-- <mo-text-field 
                    v-model="acno" 
                    class="w130 input-account" 
                    maxlength="11" 
                    mask="#######-###" 
                    :class="{ 'comp': acno.length > 9 }" 
                    @keyup="fn_AcnoChange ('');" 
                  /> -->
                  <mo-decimal-field 
                    v-model="acno" 
                    numeric 
                    clearable 
                    mask="#######-###" 
                    class="w130 input-account" 
                    maxlength="11" 
                    :class="{ 'comp': acno.length > 9 }" 
                    @keyup="fn_AcnoChange" 
                  />
                  <mo-dropdown 
                    v-model="accSeq" 
                    class="w250" 
                    placeholder=" " 
                    :items="accSeqItems" 
                    @input="fn_AccSeqChange" 
                  />
                  <mo-button class="btn-pop-download" @click="fn_OpenMSPTS206P ('00');"> </mo-button>
                  <mo-text-field v-model="cnm" disabled class="w120" />
                </div>
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 결산일자 </label>
                  <mo-dropdown 
                    v-model="baseDate" 
                    class="w150" 
                    placeholder=" " 
                    :items="baseDateItems" 
                    @input="fn_SearchTmtlReqSeq" 
                  />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p mr0">
                <div class="wrap-input row">
                  <label class="emphasis"> 승인번호 </label>
                  <mo-dropdown 
                    v-model="tmtlReqSeq" 
                    class="w130" 
                    placeholder=" " 
                    :items="tmtlReqSeqItems" 
                  />
                </div>
                <div class="wrap-input row mr35">
                  <label class="emphasis w61"> 수익자구분 </label>
                  <mo-dropdown :items="bnfcTcItems" v-model="bnfcTc" class="w150" />
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left w100p"></div>
              <div class="right">
                <div class="wrap-button row">
                  <!-- 전자서식(리포트)출력 연계개발/검증을 위한 임시 추가, added by anarchi, 2023.07.19 //-->
                  <!-- <mo-button @click="fn_confirmPrintTrue" primary> 전자문서 조회 </mo-button> -->
                  <mo-button @click="fn_Init"> 초기화 </mo-button>
                  <mo-button @click="fn_Search" primary> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>
      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title row">
          <h2 class="table-title"> 신탁이익계산서 </h2>
          <mo-radio-wrapper @input="fn_TypeChange" :items="typeItems" v-model="type" class="row" />
        </div>
        <div class="wrap-table">
          <table class="table col-type col2030">
            <tbody>
              <tr>
                <th><span> 신탁유형 </span></th>
                <td>{{ dsDetail.trstTypCNm }}</td>
                <th><span> 신탁상품 </span></th>
                <td>{{ dsDetail.gdNm }}</td>
              </tr>
              <tr>
                <!-- 계좌번호 매스킹 처리 (보안성검토 조치), modified by anarchi (2023.09.13) -->
                <th><span> 계좌번호 </span></th>
                <td>{{ bnkAcnoMask (dsDetail.accno) }}</td>
                <th><span> 고객명 </span></th>
                <td>{{ dsDetail.cnm }}</td>
              </tr>
              <tr>
                <th><span> 수탁원본 </span></th>
                <td class="aR">{{ dsDetail.trustAmt }}</td>
                <th><span> 과표금액 </span></th>
                <td class="aR">{{ dsDetail.txblWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> 해지원본 </span></th>
                <td class="aR">{{ dsDetail.tmtlWcurAmt }}</td>
                <th><span> 소득(법인)세 </span></th>
                <td class="aR">{{ dsDetail.itaxWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> 운용수익금 </span></th>
                <td class="aR">{{ dsDetail.totAppnBnfWcurAmt }}</td>
                <th><span> 주민세 </span></th>
                <td class="aR">{{ dsDetail.rtaxWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> 신탁보수 </span></th>
                <td class="aR">{{ dsDetail.totTrstFeeWcurAmt }}</td>
                <th><span> 총세금합 </span></th>
                <td class="aR">{{ dsDetail.totWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> 선취보수 </span></th>
                <td class="aR">{{ dsDetail.epayFeeAmt }}</td>
                <th><span> 기지급세금 </span></th>
                <td class="aR">{{ dsDetail.jbscTaxWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> 중도해지수수료 </span></th>
                <td class="aR">{{ dsDetail.mdtmComsWcurAmt }}</td>
                <th><span> 세후지급금액 </span></th>
                <td class="aR">{{ dsDetail.payAmt }}</td>
              </tr>
              <tr>
                <th><span> 신탁이익금 </span></th>
                <td class="aR">{{ dsDetail.trstPrftWcurAmt }}</td>
                <th><span> 해지후 수탁원본 </span></th>
                <td class="aR">{{ dsDetail.rcstRamtWcurAmt }}</td>
              </tr>
              <tr>
                <th><span> {{ typeName }} </span></th>
                <td class="aR">{{ dsDetail.taxbPrrt }}</td>
                <th><span>  </span></th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
		  </ur-box-container>
      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button v-if="!isProcessCheck" @click="fn_checkProcess" primary size="large" :disabled="isReportPrintDisabled"> 보고서 </mo-button>
          <!-- <mo-button v-if="!isProcessCheck" @click="fn_confirmPrintTrue" primary size="large" :disabled="isReportPrintDisabled"> 보고서 </mo-button> -->
          <mo-button v-else @click="fn_confirmPrintTrue" primary size="large" :disabled="isReceiptBtnDisabled"> 원천징수영수증으로 이동 </mo-button>
          <!-- <mo-button v-if="isProcessCheck" @click="fn_checkProcess" primary size="large" :disabled="isReportPrintDisabled"> 보고서 </mo-button>
          <mo-button v-else @click="fn_Receipt" primary size="large" :disabled="!isReceiptBtnDisabled"> 원천징수영수증으로 이동 </mo-button> -->
        </div>
      </ur-box-container>
    </div>
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    <msp-ts-206p
      ref="popup206"
      :popup206Obj="pPopup206Obj"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup206-callback="fn_Popup206Back"
    ></msp-ts-206p>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/
import _ from "lodash"
import _cloneDeep from "lodash/cloneDeep"
import moment from "moment"

import TSAlertPopup from "~/src/ui/ts/comm/TSAlertPopup"
import TSCommUtil from "~/src/ui/ts/comm/TSCommUtil"
import TSHeader from "~/src/ui/ts/comm/TSHeader" // header 영역 (공통)
import TSInfoUtil from "~/src/ui/ts/comm/TSInfoUtil" // Report (공통)
import TSServiceUtil from "~/src/ui/ts/comm/TSServiceUtil"

import MSPTS206P from "@/ui/ts/MSPTS206P" //계좌번호 조회

export default {
  /******************************************************************************************
   * Vue 인스턴스 기본속성(화면명, ID 등) 정의
   ******************************************************************************************/
  name: "MSPTS203M",
  screenId: "MSPTS203M",
  props: [],
  components: {
    "ts-header": TSHeader,
    "ts-alert-popup": TSAlertPopup,
    "msp-ts-206p": MSPTS206P,
  },
  /******************************************************************************************
   * Data 설정 영역
   ******************************************************************************************/
  data () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ DATA-MODEL:::data ]___[ 시작 ]");
    return {
      // ------
      // 개발/검증용 로깅출력 여부, added by anarchi (2023.08.7)
      // (※ 개발시: true, 운영이관시: false 설정할 것)
      // ------
      isLoggable: false,
      // ------
      // 화면간 이동 및 서브팝업 연계호출용 파라미터
      // ------
      pParams: {},
      // ------
      // 전자서식(리포트)출력 연계개발 공통 정보항목
      // ------
      ds_searchReportList: [], // 보고서 출력조회
      ds_resultReportList: [], // 보고서 출력조회
      ds_searchReport: {}, // 보고서 출력조회용
      ds_searchParam: {}, // 보고서 출력조회 파라미터(내부전용)
      pHeaderObj: {          
        title: "신탁이익계산서",
      },
      acno: "",
      accSeq:"",
      prrtCls: "", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.21)
      bnfcCsId: "0", // 데이타 정보항목 추가 (전자서식 관련), added by anarchi (2023.07.21)
      cnm: "",
      offrSeq: "",
      tmtlTc: "",
      type: "2",
      typeName: "연환산수익률",
      bnfcTc: "0",
      tmtlReqSeq: "",
      baseDate: "",
      btnSearch: false,
      btnClear: false,
      btnUpdate: true,
      btnDelete: true,
      btnConfirm: true,
      btnCancel: true,
      btnAdmission: false,
      btnAdmMemoInfo: true,
      btnAdmissionName: "승인요청",
      btnAML: false,
      bnkbSeqItems: [],
      accSeqItems: [],
      tmtlTcItems: [],
      bnfcTcItems: [],
      tmtlReqSeqItems: [],
      baseDateList: [],
      baseDateItems: [],
      dsDetail: Object.assign ({}, {}),
      tpup95090List:[],
      popup206: {}, // MSPTS206P
      pAlertPopupObj: {},
      pPopup206Obj: {},
      pPopup206Type: "",      //고객조회 팝업 구분
      eaiCommObj: TSCommUtil.gfn_eaiCommObj (),
      sSvcID: "",
      typeItems: [
        {value: "1", text: "단순수익률"},
        {value: "2", text: "연환산수익률"},
      ],
      isReceiptBtnDisabled: true, // 원천징수영수증 버튼 활성/비활성화 여부
      isReportPrintDisabled: true, // 보고서(리포트)출력 버튼 활성/비활성화 여부
    };
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ DATA-MODEL:::data ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Created 설정 영역
   ******************************************************************************************/
  created () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::created ]___[ 시작 ]");
    this.fn_InitParams ();
    this.fn_ComCode ();
    // this.fn_Init ();
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::created ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Before-Create 설정 영역
   ******************************************************************************************/
  beforeMount () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeMount ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeMount ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Mounted 설정 영역
   ******************************************************************************************/
  mounted () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::mounted ]___[ 시작 ]");
    this.fn_Init ();
    this.$bizUtil.insSrnLog ("MSPTS203M");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::mounted ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Before-Update 설정 영역
   ******************************************************************************************/
  beforeUpdate () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeUpdate ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeUpdate ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Updated 설정 영역
   ******************************************************************************************/
  updated () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::updated ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::updated ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Before-Destroy 설정 영역
   ******************************************************************************************/
  beforeDestroy () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::beforeDestroy ]___[ 종료 ]");
  },
  /******************************************************************************************
   * [Vue 인스턴스 생명주기 핸들러 (Life-Cycle Hook)] Destroyed 설정 영역
   ******************************************************************************************/
  destroyed () {
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::destroyed ]___[ 시작 ]");
    if (this.isLoggable) console.log ("■■■■■■■■■□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ LIFECYCLE-HOOK:::destroyed ]___[ 종료 ]");
  },
  /******************************************************************************************
   * Watch 설정 영역
   ******************************************************************************************/
  watch: {
    //
  },
  /******************************************************************************************
   * Computed 설정 영역
   ******************************************************************************************/
  computed: {
    // ---------
    // (전역 사용자 기본접속정보)
    // ---------
    baseInfoObj () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getBasInfo.data;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::baseInfoObj ]___[ 종료 ]");
    },
    // ---------
    // 개별업무 프로세스 진행상태 여부, 
    // ---------
    isProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 시작 ]");
      return this.getStore ("tsStore").getters.getState.isProcess;
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isProcessCheck ]___[ 종료 ]");
    },
    // ---------
    // (연계업무) 프로세스 진행의 마지막 여부
    // ---------
    isLastProcessCheck () {
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 시작 ]");
      return this.$bizUtil.tsUtils.getIsLastProc (this);
      if (this.isLoggable) console.log ("■■■■■■■■■", "[ VUE_ID:::", this.$options.screenId, " ]__[ DATA-HANDLER:::computed::isLastProcessCheck ]___[ 종료 ]");
    },
  },
  /******************************************************************************************
   * Methods 설정 영역
   ******************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_confirmPrintTrue, fn_confirmPrintFalse
     * 설명       : radio, mo-radio-wrapper 컴포넌트의 css error class 삭제
     ******************************************************************************/
    async fn_confirmPrintTrue () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_confirmPrintTrue ]___[ 시작 ]");
      this.ds_searchReportList = [];
      // ---------
      // ■ [신탁이익계산서] 정보항목 매핑 정의
      //   +. AS-IS: TFAC23400.xfdl:1135---[2340]신탁이익계산서
      //   +. 기본정보: TFAC23400.mrd		TS000058_신탁이익계산서
      // var reportMrdNm = "/cronix/fiduciary/contract/TFAC23400.mrd"; // 보고서 파일 경로
      // var reportUrl    = "reportTFAC23400List.do"; // 보고서 호출 서비스url
      // ---------
      this.ds_searchParam = {
        // tfbz61000List
        trstTypCNm       : "", // 신탁유형
        gdNm             : "", // 신탁상품
        accno            : "", // 계좌번호
        cnm              : "", // 고객명
        trustAmt         : "", // 수탁원본
        txblWcurAmt      : "", // 과표금액
        tmtlWcurAmt      : "", // 해지원본
        itaxWcurAmt      : "", // 소득(법인)세
        totAppnBnfWcurAmt: "", // 운용수익금
        rtaxWcurAmt      : "", // 주민세
        totTrstFeeWcurAmt: "", // 신탁보수
        totWcurAmt       : "", // 총세금합
        mdtmComsWcurAmt  : "", // 중도해지수수료
        jbscTaxWcurAmt   : "", // 기지급세금
        trstPrftWcurAmt  : "", // 신탁이익금
        payAmt           : "", // 세후 지급금액
        taxbPrrt         : "", // 연환산수익률
        rcstRamtWcurAmt  : "", // 해지후 수탁원본
        epayFeeAmt       : "", // 선취보수
      };
      // (tfbz61000List)
      // 리포트 파라미터 내 파라미터
      this.ds_searchParam.baseDate = this.baseDate;
      this.ds_searchParam.tmtlReqSeq = this.tmtlReqSeq;
      this.ds_searchParam.tacno = this.acno.substring (0, 7);
      this.ds_searchParam.bnkbSeq = this.acno.substring (7, 10);
      this.ds_searchParam.accSeq = this.accSeq;
      this.ds_searchParam.prrtCls = this.type;
      this.ds_searchParam.bnfcTc = this.bnfcTc;
      this.ds_searchParam.bnfcCsId = "0";
      // 전자서명 동의서
      this.ds_searchReport.reportMrdNm = "TS000001";
      this.ds_searchReport.reportUrl = "reportTFAC20000";
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
      // 신탁이익계산서
      this.ds_searchReport.reportMrdNm = "TS000058"; // 서식폼ID
      this.ds_searchReport.reportUrl = "reportTFAC23400List"; // 서식PO 서비스ID: TFAC23400.mrd (tfbz61000List)
      this.ds_searchReport.reportParam = JSON.stringify (this.ds_searchParam);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶▷ this.ds_searchReport → ", this.ds_searchReport);
      if (this.isLoggable) console.log ("+++++++++");
      this.ds_searchReportList.push (JSON.parse (JSON.stringify (this.ds_searchReport)));
      this.fn_searchReport ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_confirmPrintTrue ]___[ 종료 ]");
    },
    /***************************************************************************************
     * 리포트 데이터 조회 
     ***************************************************************************************/
    async fn_searchReport () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_searchReport ]___[ 시작 ]");
      let formList = [];
      let TS000058 = {};
      for (let i = 0; i < this.ds_searchReportList.length; i++) {
        let report = {
          formId: this.ds_searchReportList[i].reportMrdNm,
        };
        report.params = this.ds_searchReportList[i];
        formList.push (report);
      }
      // debugger;
      if (this.isProcessCheck) { 
        if (!this.isLastProcessCheck) {
          // TSInfoUtil.commReportInfo (this, this.ds_resultReportList, this.fn_goPage , false);
          TSInfoUtil.commReportInfo (this, formList, this.fn_Receipt , false);
        }
        else {
          TSInfoUtil.commReportInfo (this, formList);
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        TSInfoUtil.commReportInfo (this, formList);
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_searchReport ]___[ 종료 ]");
    },
    /******************************************************
     * Function명 : fn_checkProcess
     * 설명       : 프로세스에 따라 분기 처리
     ******************************************************/
    fn_checkProcess () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_checkProcess ]__[ 시작 ]");
      if (this.isLoggable) console.log ("this.baseInfoObj::: ", this.baseInfoObj);
      let lv_Vm = this;
      let tmpContentTitle = "신탁이익계산서 발행 완료";
      let tmpContent = [];
      let t_type = 0;
      if (this.isProcessCheck) {
        if (!this.isLastProcessCheck) {
          if (this.baseInfoObj.procTyp === "custInfoReg") {
            tmpContent.push ("원천징수영수증발행 화면으로 이동합니다.");
          }
          t_type = 7; // 프로세스 진행 중 마지막 화면이 아닐 경우
        }
        else {
          t_type = 9; // 프로세스 진행 중 마지막 화면일 경우
        }
      }
      else { // 프로세스 진행 중이 아닐 경우
        t_type = 9;
      }
      let t_popupObj = {
        confirm: true,
        // confirmFunc: lv_Vm.fn_ReportPrint,
        confirmFunc: lv_Vm.fn_confirmPrintTrue,
        content: tmpContent,
        contentTitle: tmpContentTitle,
        confirmData: {
          tacno: this.acno.substring (0, 7),
          bnkbSeq: this.acno.substring (7, 10),
          accSeq: this.accSeq,
          csId: this.csId,
        },
      };
      lv_Vm.fn_AlertPopup (t_type, t_popupObj);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_checkProcess ]__[ 종료 ]");
    },
    /******************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************/
    async fn_ReportPrint () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_ReportPrint ]__[ 시작 ]");
      let formList = [];
      let TS000058 = {
        formId: "TS000058",
      };
      // 리포트 테스트 데이터
      this.ds_tfio40000.newAddCls = "NEW";
      this.ds_acno.trstTypC = "001";
      this.ds_tfaacb.gdTypDtlC = "E";
      if (this.ds_tfio40000.newAddCls == "NEW") {
        // 리포트 파라미터 내 파라미터
        this.ds_searchParam = {};
        this.ds_searchParam.tacno = this.ds_acno.tacno;
        this.ds_searchParam.bnkbSeq = this.ds_acno.bnkbSeq;
        this.ds_searchParam.ctno = Number (this.ds_acno.accSeq);
        this.ds_searchParam.procType = "PP";
        this.ds_searchParam.signYn = "N";
        // 리포트 파라미터
        this.ds_searchReport.reportParam = {};
        this.ds_searchReport.reportMrdNm = "";
        this.ds_searchReport.reportUrl   = "reportTFIO42250";
        // 구분 값에 따른 설정
        if (this.ds_acno.trstTypC == "001") { // 특전금전신탁
          if (this.ds_tfaacb.gdTypDtlC == "A") { // 생전증여
            //
          }
          else { //특금 그외
            this.ds_searchParam.signYn = "Y"; // 직인을 사용하는 계약서 인 경우 Y
            this.ds_searchReport.reportMrdNm = "/cronix/fiduciary/contract/TFAC20007.mrd";
            this.ds_searchReport.reportParam = JSON.stringify(this.ds_searchParam);
            // 리포트 form 객체 목록 추가 
            TS000047.params = this.ds_searchReport;
            formList.push (TS000058);
          }
        }
      }
      /**
       * 개인신규(basInfo.procTyp : custInfoReg) 프로세스 일 경우 "fn_movePage_MSPTS213M" 이동
       */
      // TSInfoUtil.commReportInfo (this, formList, this.fn_movePage_MSPTS213M);
      TSInfoUtil.commReportInfo (this, formList)
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]_[ METHODS:::fn_ReportPrint ]__[ 종료 ]");
    },
    // ======================================================
    // (공통:비동기 서비스 호출) 메소드명:: fn_ComCode
    // ======================================================
    fn_ComCode () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_ComCode ]___[ 시작 ]");
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.trnstId = "txTSSTS90S1";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600046_S";
      this.eaiCommObj.params = {
        "TUKEMK": [
          {cId: "5147",},
        ]
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      // TSServiceUtil.invoke (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_ComCode ]___[ 종료 ]");
    }, 
    // ======================================================
    // (이벤트 콜백) 메소드명:: fn_ComCodeCallBack
    // ======================================================
    fn_ComCodeCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_ComCodeCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      for (let index in res.data.tukemk) {
        let item = res.data.tukemk[index];
        switch (item.cid) {
          case "5147":
            this.bnfcTcItems.push ({
              value: item.c,
              text: item.cnm,
            });
            break;
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_ComCodeCallBack ]___[ 종료 ]");
    },
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::pPopupObj ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ [로컬알림-공통팝업처리용]", " type → ", type, ", pPopupObj → ", pPopupObj);
      if (this.isLoggable) console.log ("+++++++++");
      switch (type) {
        case 0:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (0, this.pAlertPopupObj, pPopupObj);
          }
          this.$refs.alertPopup.fn_Open ();
          break;
        case 1:
          this.$refs.alertPopup_1.fn_Open ();
          break;
        case 2:
          this.$refs.alertPopup_2.fn_Open ();
          break;
        case 3:
          this.$refs.alertPopup_3.fn_Open ();
          break;
        case 4:
          this.$refs.alertPopup_4.fn_Open ();
          break;
        case 5:
          this.$refs.alertPopup_5.fn_Open ();
          break;
        case 6:
          this.$refs.alertPopup_6.fn_Open ();
          break;
        case 7:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (9, this.pAlertPopupObj, pPopupObj);
          }
          this.$refs.alertPopup.fn_Open ();
          break;
        case 8:
          this.$refs.alertPopup_8.fn_Open ();
          break;
        case 9:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (9, this.pAlertPopupObj, pPopupObj);
          }
          this.$refs.alertPopup.fn_Open ();
          break;
        default:
          if (!TSCommUtil.gfn_isNull (pPopupObj)) { // 얼럿 팝업 타입을 0으로 맞춤
            TSInfoUtil.initAlertPopObj (0, this.pAlertPopupObj, pPopupObj);
          }
          this.$refs.alertPopup.fn_Open ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::pPopupObj ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 초기화
    ******************************************************************************/
    fn_Init () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Init ]___[ 시작 ]");
      this.acno = "";
      this.accSeq = "";
      this.cnm = "";
      this.bnkbSeqItems = [];
      this.accSeqItems = [];
      this.tmtlTcItems = [];
      this.bnfcTc = "0";
      this.tmtlReqSeqItems = [];
      this.baseDateList = [];
      this.baseDateItems = [];
      this.dsDetail = {};
      // this.pParams = {};
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Init ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소
    ******************************************************************************/
    fn_Cancel () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Cancel ]___[ 시작 ]");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Cancel ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_AccSeqChange
    * 설명       : 
    ******************************************************************************/
    fn_AccSeqChange () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_AccSeqChange ]___[ 시작 ]");
      this.fn_SearchBaseDate ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_AccSeqChange ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_TypeChange
    * 설명       : 
    ******************************************************************************/
    fn_TypeChange () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_TypeChange ]___[ 시작 ]");
      if (this.type === "1") {
        this.typeName = "세전수익률";
      }
      else {
        this.typeName = "연환산수익률";
      }
      this.fn_Search ();
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_TypeChange ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_Search
    * 설명       : 조회버튼
    ******************************************************************************/
    fn_Search () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Search ]___[ 시작 ]");
      if ((this.acno.legnth + this.accSeq.length) < 13) {
        this.pAlertPopupObj.content = "계좌번호를 확인하세요.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      if (_.isEmpty (this.baseDate)) {
        this.pAlertPopupObj.content = "결산일자를 선택하세요.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      if (this.tmtlReqSeq < 0) {
        this.pAlertPopupObj.content = "승인번호를 선택하세요.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        return;
      }
      // ---------
      // 'C392_F10600105_S' |
      // 'UF10600105' // [NEXT 사랑On 신탁]신탁이익계산서 조회-selectTFAC23400
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600105_S";
      // ---------
      // EAI(PO) 전송 파라미터 설정
      // ---------
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accSeq: this.accSeq,
        baseDate: this.baseDate.replaceAll ("-", ""),
        tmtlReqSeq: this.tmtlReqSeq,
        prrtCls: this.type,
        bnfcTc: this.bnfcTc,
        bnfcCsId: "0",
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600105");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Search ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_SearchResult
    * 설명       : 조회 콜백
    ******************************************************************************/
    fn_SearchResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      if (TSCommUtil.gfn_isNull (res.data.accSeq) || 
          TSCommUtil.gfn_isNull (res.data.accno) || 
          TSCommUtil.gfn_isNull (res.data.baseDate) || 
          TSCommUtil.gfn_isNull (res.data.tacno)) {
        // ---------
        // '원천징수영수증발급' 화면이동 버튼 활성화 여부 처리
        // '원천징수영수증내역' 보고서 출력 버튼 활성화 여부 처리
        // ---------
        this.isReceiptBtnDisabled = true;
        this.isReportPrintDisabled = true;
        return;
      }
      else {
        this.dsDetail = res.data;
        this.dsDetail.trustAmt = this.dsDetail.trustAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.txblWcurAmt = this.dsDetail.txblWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.tmtlWcurAmt = this.dsDetail.tmtlWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.itaxWcurAmt = this.dsDetail.itaxWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.totAppnBnfWcurAmt = this.dsDetail.totAppnBnfWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.rtaxWcurAmt = this.dsDetail.rtaxWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.totTrstFeeWcurAmt = this.dsDetail.totTrstFeeWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.totWcurAmt = this.dsDetail.totWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.epayFeeAmt = this.dsDetail.epayFeeAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.jbscTaxWcurAmt = this.dsDetail.jbscTaxWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.mdtmComsWcurAmt = this.dsDetail.mdtmComsWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.payAmt = this.dsDetail.payAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.trstPrftWcurAmt = this.dsDetail.trstPrftWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.rcstRamtWcurAmt = this.dsDetail.rcstRamtWcurAmt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        this.dsDetail.taxbPrrt = this.dsDetail.taxbPrrt.replace (/\B(?=(\d{3})+(?!\d))/g, ",");
        // ---------
        // '원천징수영수증발급' 화면이동 버튼 활성화 여부 처리
        // '원천징수영수증내역' 보고서 출력 버튼 활성화 여부 처리
        // ---------
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶ [업무프로세스 여부]", " isProcessCheck () → ", this.isProcessCheck);
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isProcessCheck) {
          this.isReceiptBtnDisabled = false;
        }
        else {
          this.isReportPrintDisabled = false;
        }
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchResult ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_SearchBaseDate
    * 설명       : 계좌번호 입력시 해지기준일자 조회 
    ******************************************************************************/
    fn_SearchBaseDate () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchBaseDate ]___[ 시작 ]");
      if ((this.acno.length + this.accSeq.length) != 13) {
        return;
      }
      // ---------
      // 'C392_F10600106_S' |
      // 'UF10600106' // [NEXT 사랑On 신탁]신탁이익계산서조회를 위한 해지신청일련번호 목록을 조회-selectTFAC23400AList
      // ---------
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600106_S";
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accSeq: this.accSeq,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)파라미터]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_BaseDateCallBack, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600106");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchBaseDate ]___[ 종료 ]");
    },
    /******************************************************************************
    * Function명 : fn_BaseDateCallBack
    * 설명       : 해지기준일자 조회 후처리 
    ******************************************************************************/
    fn_BaseDateCallBack (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_BaseDateCallBack ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [EAI(PO)_(", res.commHeaderVO.eaiId, ")_(송신(콜백))리턴객체]", " res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      this.baseDateList = res.data.tfac23400;
      if (this.baseDateList.length == 0) {
        this.pAlertPopupObj.content = "해지신청내역이 없습니다.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        // ---------
        // '원천징수영수증발급' 버튼 활성화 여부 처리
        // ---------
        this.isReceiptBtnDisabled = true;
        return;
      }
      // ------------------
      // ※ PMPM22000016-191 [신탁] 변경/해지/이체 > 변경/해지 > 신탁이익계산서PMPM22000016-1141
      // [신탁/신탁이익계산서] 해지이력이 있는 계좌에 대한 처리 오류 조치함
      //   1) 코드 및 로직 오류부분 주석처리
      //   2) AS-IS 기준으로 처리로직 재코딩함.
      //   3) '계좌상품유형' 및 '결산일자' 목록(콤보) 처리 이벤트 핸들러 추가
      //   corrected by anarchi (2023.07.26)
      // ------------------
      // else {
      //   if (!_.isEmpty (this.baseDate)) {
      //     this.baseDate = this.baseDate;
      //   }
      //   else {
      //     for (let i = 0; i < this.baseDateList.length; i++) {
      //       let base = {};
      //       base.value = this.baseDateList[i].baseDate;
      //       base.text = this.baseDateList[i].baseDateFormat;
      //       this.baseDateItems.push (base);
      //     }
      //     this.baseDate = this.baseDateList[0].baseDate;
      //   }
      //   this.fn_SearchTmtlReqSeq (); // 해지일련번호 조회 
      // }
      for (let i = 0; i < this.baseDateList.length; i++) {
        let base = {};
        base.value = this.baseDateList[i].baseDate;
        base.text = this.baseDateList[i].baseDateFormat;
        this.baseDateItems.push (base);
      }
      this.baseDate = this.baseDateList[0].baseDate;
      this.fn_SearchTmtlReqSeq (); // 해지일련번호 조회
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_BaseDateCallBack ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_SearchTmtlReqSeq
    * 설명: 해지일련번호 조회 
    *********************************************************/
    fn_SearchTmtlReqSeq () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchTmtlReqSeq ]___[ 시작 ]");
      if ((this.acno.length + this.accSeq.length) != 13) {
        return;
      }
      if (this.baseDate.replaceAll ("-", "").length != 8) {		
        return;
      }
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600107_S";
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        accSeq: this.accSeq,
        baseDate: this.baseDate.replaceAll ("-", ""),
        prrtCls: this.type,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_SearchTmtlReqSeqResult, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600107");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchTmtlReqSeq ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_SearchTmtlReqSeqResult
    * 설명: 해지일련번호 조회 후처리 
    *********************************************************/
    fn_SearchTmtlReqSeqResult (res) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchTmtlReqSeqResult ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ res → ", res);
      if (this.isLoggable) console.log ("+++++++++");
      const tfac23400 = res.data.tfac23400;
      this.tmtlReqSeqItems = [];
      if (tfac23400.length == 0) {
        this.pAlertPopupObj.content = "해지신청 내역이 없습니다.";
        this.pAlertPopupObj.confirm = false;
        this.$refs.alertPopup.fn_Open (this.pAlertPopupObj);
        // ---------
        // '원천징수영수증발급' 버튼 활성화 여부 처리
        // ---------
        this.isReceiptBtnDisabled = true;
        return;;
      }
      else {
        for (let i = 0; i < tfac23400.length; i++) {
          let tmtl = {};
          tmtl.value = tfac23400[i].tmtlReqSeq;
          tmtl.text = tfac23400[i].tmtlReqSeq;
          this.tmtlReqSeqItems.push (tmtl);
        }
        this.tmtlReqSeq = tfac23400[0].tmtlReqSeq;
        // ---------
        // '원천징수영수증발급' 버튼 활성화 여부 처리
        // ---------
        // this.isReceiptBtnDisabled = false;
		    this.fn_Search ();
      }
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_SearchTmtlReqSeqResult ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_OpenMSPTS206P
    * 설명: 계좌번호 조회 팝업호출
    *********************************************************/
    fn_OpenMSPTS206P () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 시작 ]");
      let lv_vm = this;
      let properties = {
        pPage: "MSPTS201M", // ??? (해지신청화면으로??? -> 업무확인요)
        pGdC: lv_vm.gdC,             // 신탁상품
        pTrstTypeC: lv_vm.trstTypC,  // 신탁유형
        pGdTypDtlC: lv_vm.gdTypDtlC, // 상품유형
        pUrl: "2000",                // 
        pProcTc: "2000",             //
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ properties → ", properties);
      if (this.isLoggable) console.log ("+++++++++");
      this.popup206 = this.$refs.popup206.fn_Open (properties);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_OpenMSPTS206P ]___[ 종료 ]");
    },
    /*********************************************************
    * Function명: fn_Popup206Back
    * 설명: 계좌번호 조회 팝업호출 콜백
    *********************************************************/
    fn_Popup206Back (rtnData) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Popup206Back ]___[ 시작 ]");
      this.acno = rtnData.tacno + rtnData.bnkbSeq;
      this.accSeq = rtnData.accSeq;
      this.cnm = rtnData.cnm;
      this.fn_AcnoChange (this.accSeq);
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Popup206Back ]___[ 종료 ]");
    },
    // ======================================================
    // (이벤트 핸들러) 메소드명:: 계좌번호 입력시
    // ======================================================
    fn_AcnoChange (accSeq) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_AcnoChange ]___[ 시작 ]");
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▷ accSeq → ", accSeq);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.acno.length != 10) {
        return;
      }
      // ---------
      // □ 원격트랜잭션서비스(PO) 호출
      // 'C392_F10600245_S' |
      // 'UF10600245' // [NEXT 사랑On 신탁]계좌 정보 조회 TR 전송
      // ---------
      this.eaiCommObj = TSCommUtil.gfn_eaiCommObj ();
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.auth = "S";
      // this.eaiCommObj.trnstId = "selectTPUP95090List";
      this.eaiCommObj.commHeaderVO.eaiId = "C392_F10600245_S";
      this.eaiCommObj.params = {
        tacno: this.acno.substring (0, 7),
        bnkbSeq: this.acno.substring (7, 10),
        // accStatC: this.accStatC,
        accStatC: "11",
        brigAccYn: this.brigAccYn,
      };
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", this.eaiCommObj.commHeaderVO.eaiId, ")_(송신)-매개변수]", " this.eaiCommObj.params → ", this.eaiCommObj.params);
      if (this.isLoggable) console.log ("+++++++++");
      TSServiceUtil.invokeInDirect (this.eaiCommObj, function (response) {
        if (this.isLoggable) console.log ("+++++++++");
        if (this.isLoggable) console.log ("+ ▶▷ [계좌 정보 조회 TR 전송]_[EAI(PO)_(", response.commHeaderVO.eaiId, ")_(송신$콜백)-리턴객체]", " response → ", response);
        if (this.isLoggable) console.log ("+++++++++");
        if (response && response.data) {
          this.tpup95090List = response.data.tpup95090;
          // ------
          // 상품목록콤보 데이터 초기화
          this.accSeqItems = [];
          // ------
          let acc = {};
          for (let i = 0; i < this.tpup95090List.length; i++) {
            acc = {};
            acc.value = this.tpup95090List[i].accSeq;
            acc.text = this.tpup95090List[i].gdNm;
            this.accSeqItems.push (acc);
          }
          this.cnm = this.tpup95090List[0].cnm;
          // this.accSeq = this.tpup95090List[0].accSeq;
          if (!_.isEmpty (this.tpup95090List[0].admkNm)) {
            this.cnm = this.tpup95090List[0].cnm + "[" + this.tpup95090List[0].admkNm + "]";
          }
          this.bnkbAccYn = "Y";
          // if (_.isEmpty (accSeq)) { // 계좌일련번호가 존재하면 바로 세팅 
          //   this.accSeq = this.tpup95090List[0].accSeq;
          // }
          // else {
          //   this.accSeq = accSeq;
          // }
          if (this.tpup95090List.length > 0) {
            if (this.isProcessCheck) {
              if (!TSCommUtil.gfn_isNull (this.pParams.tacno)) {
                this.accSeq = this.pParams.accSeq;
                this.fn_SearchBaseDate ();
              }
            }
            else {
              this.accSeq = this.tpup95090List[0].accSeq;
              this.fn_SearchBaseDate ();
              // this.fn_AccSeqChange ();
            }
          }
        }
      }, function (error) {
        if (this.isLoggable) console.log (error);
      }, "UF10600245");
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_AcnoChange ]___[ 종료 ]");
    },
    // ======================================================
    // (흐름 제어) fn_Receipt:: 원천징수영수증 출력
    // ======================================================
    fn_Receipt () {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Receipt ]___[ 시작 ]");
      // debugger;
      let _sParamObj = {};
      if (!TSCommUtil.gfn_isNull (this.pParams.tacno)) {
        _sParamObj.tacno = this.pParams.tacno;
        _sParamObj.bnkbSeq = this.pParams.bnkbSeq;
        _sParamObj.accSeq = this.pParams.accSeq;
        _sParamObj.baseDate = this.pParams.baseDate;
      }
      else {
        _sParamObj.tacno = this.acno.substring (0, 7);
        _sParamObj.bnkbSeq = this.acno.substring (7, 10);
        _sParamObj.accSeq = this.accSeq;
        _sParamObj.baseDate = this.baseDate;
      }
      // if (this.isProcessCheck) {
      // }
      // else {
      //   if (!TSCommUtil.gfn_isNull (this.acno)) {
      //     // if (TSCommUtil.gfn_isNull (this.dsDetail.accSeq))
      //     // TSCommUtil.gfn_isNull (this.dsDetail.accno) || 
      //     // TSCommUtil.gfn_isNull (this.dsDetail.baseDate) || 
      //     // TSCommUtil.gfn_isNull (this.dsDetail.tacno)) {
      //     // ---------
      //     // '원천징수영수증발급' 버튼 활성화 여부 처리 (비활성화)
      //     // ---------
      //     this.isReceiptBtnDisabled = true;
      //     return;
      //   }
      // }
      // _sParamObj = {
      //   accSeq: "001",
      //   baseDate: "20230906",
      //   bnkbSeq: "999",
      //   tacno: "1102159",
      // };
      this.$router.push ({
        name: "MSPTS204M",
        params: _sParamObj,
      });
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::MSPTS203M ]__[ METHODS:::fn_Receipt ]___[ 종료 ]");
    },
    // ======================================================
    // (업무:데이터 초기화) fn_InitParams:: 업무프로세스 공통 파라미터 처리
    // ======================================================
    fn_InitParams () {
      if (this.isLoggable) console.log ("+++++++++");
      // if (this.isLoggable) console.log ("+ ▶ [Parent 내장객체-(수신)-파라미터]", " this.$parent → ", this.$parent);
      // if (this.isLoggable) console.log ("+ ▶ [El 내장객체-(수신)-파라미터]", " this.$el → ", this.$el);
      // if (this.isLoggable) console.log ("+ ▶ [Options 내장객체-(수신)-파라미터]", " this.$options → ", this.$options);
      // if (this.isLoggable) console.log ("+ ▶ [Refs 내장객체-(수신)-파라미터]", " this.$refs → ", this.$refs);
      if (this.isLoggable) console.log ("+ ▶ [Props 내장객체-(수신)-파라미터]", " this.$props → ", this.$props);
      if (this.isLoggable) console.log ("+ ▶ [Data 내장객체-(수신)-파라미터]", " this.$data → ", this.$data);
      if (this.isLoggable) console.log ("+ ▶ [Store 내장객체-(수신)-파라미터]", " this.$store → ", this.$store);
      if (this.isLoggable) console.log ("+ ▶ [Router 내장객체-(수신)-파라미터]", " this.$router → ", this.$router);
      if (this.isLoggable) console.log ("+ ▶ [Route 내장객체-(수신)-파라미터]", " this.$route → ", this.$route);
      if (this.isLoggable) console.log ("+++++++++");
      // let _cloneDeep = require ('lodash/cloneDeep');
      // this.pParams = this.$_cloneDeep (this.$route.params);
      this.pParams = _cloneDeep (this.$route.params);
      if (this.isLoggable) console.log ("+++++++++");
      if (this.isLoggable) console.log ("+ ▶ [사용자라우터객체-(저장)-파라미터]", " this.pParams → ", this.pParams);
      if (this.isLoggable) console.log ("+++++++++");
      // ---------------------------
      // (프로세스 연결):::
      //   → 업무화면간 이동 및 연계팝업 호출 시, (필수)인수 송수신 설정처리 추가
      // ---------------------------
      if (!TSCommUtil.gfn_isNull (this.pParams.tacno)) {
        let _rParamObj = {};
        _rParamObj.prevScrnNm = TSCommUtil.gfn_isNull_replace (this.$route.name, ""); // MSPTS205M(은행이체처리명세), MSPTS208M(신탁출금)
        _rParamObj.accSeq = TSCommUtil.gfn_isNull_replace (this.pParams.accSeq, "");
        _rParamObj.baseDate = TSCommUtil.gfn_isNull_replace (this.pParams.baseDate, "");
        _rParamObj.bnkbSeq = TSCommUtil.gfn_isNull_replace (this.pParams.bnkbSeq, "");
        _rParamObj.tacno = TSCommUtil.gfn_isNull_replace (this.pParams.tacno, "");
        if ("MSPTS205M" == _rParamObj.prevScrnNm) { // (이전) 화면이 은행이체처리명일 경우
          this.acno = _rParamObj.tacno;
          this.accSeq = _rParamObj.accSeq;
          this.baseDate = _rParamObj.baseDate;
          this.fn_AcnoChange ("");
        }
        else {
          // ---------
          // PMPP22000016-503 [신탁/신탁이익계산서] 자동 입력되는 계좌번호 뒤에 000 삭제
          //  → 개선요청 사항 반영함, added by anarchi (2023.08.11)
          //  → 종합계좌번호 이후 입력값은 사용자가 직접 (Key-in)을 통해 조회하게 함. (guided & commented by 김지원 부장)
          // ---------
          this.accSeq = _rParamObj.accSeq;
          this.acno = _rParamObj.tacno + _rParamObj.bnkbSeq;
          // this.acno = _rParamObj.tacno;
          this.baseDate = _rParamObj.baseDate;
          this.fn_AcnoChange ("");
        }
      }
      // ---------------------------------------------
      if (this.isLoggable) console.log ("++++++++++++++++++++++++++++++++++++");
      if (this.isLoggable) console.log ("this.$options.name:::", this.$options.name);
      if (this.isLoggable) console.log ("this.$options.screenId:::", this.$options.screenId);
      if (this.isLoggable) console.log ("this.$options.props:::", this.$options.props);
      if (this.isLoggable) console.log ("this.$options.components:::", this.$options.components);
      if (this.isLoggable) console.log ("this.$options.data:::", this.$options.data);
      if (this.isLoggable) console.log ("this.$options.methods:::", this.$options.methods);
      if (this.isLoggable) console.log ("++++++++++++++++++++++++++++++++++++");
      // ---------------------------------------------
    },
    // ======================================================
    // (업무:데이터 제어) bnkAcnoMask:: 계좌번호 매스킹 처리
    // ======================================================
    bnkAcnoMask (val) {
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 시작 ]");
      let _maskVal = val;
      if (!TSCommUtil.gfn_isNull (_maskVal)) {
        return TSCommUtil.gfn_bnkAcno_mask (_maskVal);
      }
      return _maskVal;
      if (this.isLoggable) console.log ("□□□□□□□□□", "[ VUE_ID:::", this.$options.screenId, " ]__[ METHODS:::bnkAcnoMask ]___[ 종료 ]");
    },
  },
}
</script>
